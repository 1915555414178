// SECTION :: START

.cs-more {
  padding-top: 105px;
  @include adaptive(767) {
    padding-top: 75px;
  }

  .c-title {
    padding-bottom: 120px;
    @include adaptive(767) {
      padding-bottom: 60px;
    }

    &__text {
      span {
        font-weight: bold;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    padding-bottom: 120px;
    @include adaptive(767) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding-bottom: 75px;
    }

    &--reverse {
      flex-direction: row-reverse;
      @include adaptive(767) {
        flex-direction: column-reverse;
      }

      .cs-more__block-left {
        padding-right: 0;
        padding-left: 90px;
        @include adaptive(1023) {
          padding-left: 45px;
        }
        @include adaptive(767) {
          padding-left: 0;
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-left,
    &-right {
      width: 50%;
    }

    &-left {
      padding-right: 90px;
      @include adaptive(1023) {
        width: 60%;
        padding-right: 45px;
      }
      @include adaptive(767) {
        padding-right: 0;
        width: 100%;
        text-align: center;
      }

      > div {
        width: 100%;
        max-width: 460px;
        @include adaptive(767) {
          max-width: 100%;
        }
      }
    }

    &-right {
      @include adaptive(1023) {
        width: 40%;
      }
      @include adaptive(767) {
        width: 100%;
        padding-bottom: 30px;
      }
    }

    p {
      padding-bottom: 20px;
      font-weight: 700;
      color: #CD084B;
      @include fluid(font-size, 320px, 1440px, 20px, 24px);
    }

    span {
      display: block;
      color: #939393;
      line-height: 1.6;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// SECTION :: END
