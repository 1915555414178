.icon-Industry-Standard {
  width:(72)*1px;
  height:(80)*1px;
}
.icon-Leads-Mess {
  width:(63)*1px;
  height:(81)*1px;
}
.icon-Leads-Trash {
  width:(68)*1px;
  height:(81)*1px;
}
.icon-Leads-time {
  width:(73)*1px;
  height:(82)*1px;
}
.icon-affiliate-benefits-1 {
  width:(40)*1px;
  height:(39)*1px;
}
.icon-affiliate-benefits-2 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-affiliate-benefits-3 {
  width:(44)*1px;
  height:(36)*1px;
}
.icon-affiliate-benefits-4 {
  width:(40)*1px;
  height:(36)*1px;
}
.icon-ai {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-analytics {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-angle {
  width:(30.727)*1px;
  height:(30.727)*1px;
}
.icon-arrow {
  width:(512)*1px;
  height:(512)*1px;
}
.icon-batman {
  width:(36)*1px;
  height:(39)*1px;
}
.icon-battery {
  width:(44)*1px;
  height:(28)*1px;
}
.icon-becoming-1 {
  width:(44)*1px;
  height:(46)*1px;
}
.icon-becoming-2 {
  width:(40)*1px;
  height:(36)*1px;
}
.icon-becoming-3 {
  width:(48)*1px;
  height:(36)*1px;
}
.icon-calendar {
  width:(14)*1px;
  height:(14)*1px;
}
.icon-cancel {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-check {
  width:(45.701)*1px;
  height:(45.7)*1px;
}
.icon-close {
  width:(47.97)*1px;
  height:(47.97)*1px;
}
.icon-cloud {
  width:(44)*1px;
  height:(42)*1px;
}
.icon-cs-description-email-1 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-cs-description-email-2 {
  width:(36)*1px;
  height:(40)*1px;
}
.icon-cs-description-email-3 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-cs-description-email-4 {
  width:(44)*1px;
  height:(44)*1px;
}
.icon-cs-description-pro-1 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-cs-description-pro-2 {
  width:(44)*1px;
  height:(46)*1px;
}
.icon-cs-description-pro-3 {
  width:(39)*1px;
  height:(42)*1px;
}
.icon-cs-description-pro-4 {
  width:(40)*1px;
  height:(42)*1px;
}
.icon-cs-description-slots-1 {
  width:(44)*1px;
  height:(32)*1px;
}
.icon-cs-description-slots-2 {
  width:(40)*1px;
  height:(36)*1px;
}
.icon-cs-description-slots-3 {
  width:(43)*1px;
  height:(43)*1px;
}
.icon-description {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-done {
  width:(26)*1px;
  height:(26)*1px;
}
.icon-duration {
  width:(15.999)*1px;
  height:(20)*1px;
}
.icon-email {
  width:(48)*1px;
  height:(36)*1px;
}
.icon-event-name {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-features {
  width:(40)*1px;
  height:(37)*1px;
}
.icon-features-box-0 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-features-box-1 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-features-box-2 {
  width:(40)*1px;
  height:(42)*1px;
}
.icon-features-box-3 {
  width:(44)*1px;
  height:(46)*1px;
}
.icon-features-box-4 {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-flag {
  width:(24)*1px;
  height:(28)*1px;
}
.icon-help-accounts {
  width:(48)*1px;
  height:(60)*1px;
}
.icon-help-admin-tools {
  width:(56)*1px;
  height:(56)*1px;
}
.icon-help-analytics {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-help-apps-settings {
  width:(72)*1px;
  height:(60)*1px;
}
.icon-help-calendar {
  width:(60)*1px;
  height:(62)*1px;
}
.icon-help-compaigns {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-help-contacts {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-help-faq {
  width:(64)*1px;
  height:(64)*1px;
}
.icon-help-flows {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-help-invoicing {
  width:(54)*1px;
  height:(60)*1px;
}
.icon-help-pipelines {
  width:(60)*1px;
  height:(56)*1px;
}
.icon-help-search {
  width:(59)*1px;
  height:(59)*1px;
}
.icon-hero-1 {
  width:(23)*1px;
  height:(24)*1px;
}
.icon-hero-2 {
  width:(40)*1px;
  height:(42)*1px;
}
.icon-hero-3 {
  width:(30)*1px;
  height:(30)*1px;
}
.icon-hero-4 {
  width:(35)*1px;
  height:(36)*1px;
}
.icon-hero-5 {
  width:(19)*1px;
  height:(26)*1px;
}
.icon-hero-6 {
  width:(24)*1px;
  height:(22)*1px;
}
.icon-hero-7 {
  width:(38)*1px;
  height:(40)*1px;
}
.icon-hero-8 {
  width:(29)*1px;
  height:(32)*1px;
}
.icon-i-email {
  width:(24)*1px;
  height:(19)*1px;
}
.icon-i-facebook {
  width:(11)*1px;
  height:(23)*1px;
}
.icon-i-linkdin {
  width:(22)*1px;
  height:(21)*1px;
}
.icon-i-share {
  width:(22)*1px;
  height:(22)*1px;
}
.icon-i-twitter {
  width:(21)*1px;
  height:(17)*1px;
}
.icon-invite-away {
  width:(16)*1px;
  height:(15)*1px;
}
.icon-invite-decline {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-invite-online {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-invite-wait {
  width:(18)*1px;
  height:(18)*1px;
}
.icon-leads {
  width:(44)*1px;
  height:(43)*1px;
}
.icon-lightning {
  width:(34)*1px;
  height:(41)*1px;
}
.icon-location {
  width:(16)*1px;
  height:(20)*1px;
}
.icon-login {
  width:(20)*1px;
  height:(20)*1px;
}
.icon-logo {
  width:(145)*1px;
  height:(36)*1px;
}
.icon-money {
  width:(26)*1px;
  height:(36)*1px;
}
.icon-pipelines {
  width:(40)*1px;
  height:(42)*1px;
}
.icon-play {
  width:(69)*1px;
  height:(80)*1px;
}
.icon-play-1 {
  width:(27)*1px;
  height:(33)*1px;
}
.icon-pro {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-proposal-triangle {
  width:(15)*1px;
  height:(18)*1px;
}
.icon-quote {
  width:(35)*1px;
  height:(24)*1px;
}
.icon-request {
  width:(48)*1px;
  height:(36)*1px;
}
.icon-resize {
  width:(40)*1px;
  height:(40)*1px;
}
.icon-right-arrow {
  width:(240.82)*1px;
  height:(240.82)*1px;
}
.icon-robot {
  width:(44)*1px;
  height:(42)*1px;
}
.icon-setting {
  width:(478.7)*1px;
  height:(478.7)*1px;
}
.icon-signup {
  width:(16)*1px;
  height:(20)*1px;
}
.icon-slots {
  width:(36)*1px;
  height:(36)*1px;
}
.icon-sneakers {
  width:(44)*1px;
  height:(42)*1px;
}
.icon-step-1 {
  width:(40)*1px;
  height:(36)*1px;
}
.icon-step-2 {
  width:(43)*1px;
  height:(43)*1px;
}
.icon-step-3 {
  width:(40)*1px;
  height:(42)*1px;
}
.icon-time {
  width:(18.095)*1px;
  height:(19)*1px;
}
.icon-timelines {
  width:(40)*1px;
  height:(36)*1px;
}
.icon-types-icon-0 {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-types-icon-1 {
  width:(60)*1px;
  height:(60)*1px;
}
.icon-types-icon-2 {
  width:(51)*1px;
  height:(62)*1px;
}
