// SECTION :: START

.features-calendar {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f8fafc;
  @include respond(max-small) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .c-title {
    padding-bottom: 63px;
    @include respond(max-small) {
      padding-bottom: 35px;
    }
  }

  &__wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include respond(max-small) {
      padding-bottom: 15px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__desc {
    width: 100%;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 45px;
    font-size: 19px;
    color: #939393;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;
    @include respond(max-small) {
      padding-bottom: 30px;
      font-size: 17px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}

// SECTION :: END
