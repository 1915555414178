// SECTION satisfied :: START

.satisfied {
  padding-top: 130px;
  padding-bottom: 75px;
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 50px;
  }

  &__title {
    padding-bottom: 60px;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #000;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
      text-align: center;
    }

    span {
      position: relative;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 98%;
        height: 22px;
        background-color: #ffd400;
        @include adaptive(1023) {
          height: 20px;
        }
      }
    }
  }

  &__block {
    width: 100%;
    max-width: 285px;
    @include adaptive(767) {
      max-width: 1005px;
      text-align: center;
    }

    &-wrapper {
      display: flex;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc(100% / 3);
        padding-bottom: 55px;
        @include adaptive(1023) {
          padding-right: 30px;
        }
        @include adaptive(767) {
          max-width: 100%;
          padding-right: 0;
        }
      }
    }

    &-desc {
      padding-bottom: 40px;
      min-height: 490px;
      font-size: 19px;
      line-height: 1.58;
      color: #939393;
      @include adaptive(1023) {
        font-size: 16px;
      }
      @include adaptive(767) {
        min-height: unset;
      }
    }

    &-img {
      overflow: hidden;
      position: relative;
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
      border-radius: 50%;
      @include adaptive(767) {
        margin-left: auto;
        margin-right: auto;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-name,
    &-pos {
      font-size: 19px;
      line-height: 1.58;
      color: #000000;
      @include adaptive(1023) {
        font-size: 18px;
      }
    }

    &-name {
      font-weight: 900;
    }
  }
}

// SECTION satisfied :: END