// SECTION more :: START

.more {
  position: relative;
  padding-top: 130px;
  background-color: #f8fafc;
  @include adaptive(767) {
    padding-top: 75px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: -60px;
      left: 35%;
      transform: translateX(-50%);
    }
  }

  .c-grid-fluid {
    padding-left: 0;
    padding-right: 0;
    @include adaptive(1279) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__wrapper {
    overflow: hidden;
    padding-top: 15px;
  }

  &__row {
    display: flex;
    align-items: center;
    padding-bottom: 130px;
    @include adaptive(767) {
      flex-wrap: wrap;
      padding-bottom: 75px;
    }

    &-1,
    &-3 {
      .more {
        &__cover,
        &__img {
          margin-left: auto;
          @include adaptive(767) {
            margin-right: auto;
          }
        }

        &__img {
          transform: translateX(200px);
          @include adaptive(1679) {
            transform: translateX(100px);
          }
          @include adaptive(1365) {
            transform: translateX(75px);
          }
          @include adaptive(767) {
            transform: translateX(0);
          }
        }
      }
    }

    &-2,
    &-4 {
      @include adaptive(767) {
        flex-direction: column-reverse;
      }

      .more {
        &__cover,
        &__img {
          margin-right: auto;
          @include adaptive(767) {
            margin-left: 0;
          }
        }

        &__img {
          transform: translateX(-200px);
          @include adaptive(1439) {
            transform: translateX(-250px);
          }
          @include adaptive(1365) {
            transform: translateX(-300px);
          }
          @include adaptive(1279) {
            transform: translateX(-75px);
          }
          @include adaptive(767) {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__col {
    width: 50%;
    @include adaptive(767) {
      width: 100%;
    }
  }

  &__cover {
    width: 100%;
    max-width: 520px;
  }

  &__title {
    padding-bottom: 80px;
    font-size: 40px;
    font-weight: 900;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
    }
    @include adaptive(424) {
      text-align: center;

      br {
        display: none;
      }
    }

    span {
      position: relative;

      i {
        z-index: 2;
        position: relative;
        font-style: normal;
      }

      &:before {
        content: '';
        z-index: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 98%;
        height: 22px;
        background-color: #ffd400;
        @include adaptive(1023) {
          height: 20px;
        }
      }
    }
  }

  &__img {
    display: block;
    height: auto;
    @include adaptive(1279) {
      max-width: 100%;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    border-radius: 20px;
    font-size: 20px;
    line-height: 1;
    color: #000000;
    @include adaptive(1365) {
      font-size: 18px;
    }
    @include adaptive(1023) {
      height: 35px;
      margin-right: 5px;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 1.3;
      font-size: 16px;
    }
    @include adaptive(424) {
      margin: 0;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 13px;
      width: 100%;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active {
      background-color: #00449d;
      color: #ffffff;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      transform: translateX(-20px);
      padding-bottom: 40px;
      @include adaptive(1279) {
        transform: translateX(0);
      }
      @include adaptive(767) {
        justify-content: center;
      }
      @include adaptive(424) {
        position: relative;
        left: -15px;
        width: calc(100% + 30px);
        justify-content: space-between;
      }
    }
  }

  &__subtitle {
    padding-bottom: 30px;
    font-size: 40px;
    font-weight: 300;
    color: #000000;
    @include adaptive(1365) {
      font-size: 36px;
    }
    @include adaptive(1023) {
      font-size: 33px;
      padding-bottom: 15px;

      br {
        display: none;
      }
    }
    @include adaptive(767) {
      font-size: 30px;
      text-align: center;
    }
  }

  &__desc {
    padding-bottom: 40px;
    font-size: 19px;
    line-height: 1.58;
    color: #000000;
    @include adaptive(1365) {
      font-size: 18px;
    }
    @include adaptive(1023) {
      font-size: 16px;
      padding-bottom: 20px;
    }
  }

  &__list {
    @include adaptive(767) {
      padding-bottom: 40px;
    }

    li {
      display: flex;
      padding-bottom: 30px;
      @include adaptive(1023) {
        padding-bottom: 20px;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      > div {
        &:nth-of-type(1) {
          width: 35px;
          padding-top: 6px;
        }
        &:nth-of-type(2) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #00449d;

      svg {
        width: 10px;
        height: 15px;
        fill: #fff;
      }
    }

    p {
      font-size: 19px;
      line-height: 1.58;
      color: #939393;
      @include adaptive(1365) {
        font-size: 18px;
      }
      @include adaptive(1023) {
        font-size: 16px;
      }
    }
  }
}

// SECTION more :: END
