// BUTTON :: START

.c-btn {
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 230px;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  border: 2px solid #cd084b;
  background-color: transparent;
  color: #cd084b;
  text-transform: uppercase;
  letter-spacing: 1.35px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: 'Muli', sans-serif;
  @include transition(background-color, color);

  &:hover {
    @include transition(background-color, color);
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &--center {
      justify-content: center;
    }
  }

  &--features {
    margin-left: auto;
    margin-right: auto;
  }

  &--apps {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  &--mind {
    min-width: 232px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  &--border {
    &:hover {
      background-color: #cd084b;
      color: $c-white;
    }
  }

  &--bg {
    background-color: #cd084b;
    color: $c-white;

    &:hover {
      background-color: transparent;
      color: #cd084b;
    }
  }
}

// BUTTON :: END
