// SECTION why :: START

.why {
  position: relative;
  padding-top: 40px;
  padding-bottom: 150px;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    &-left {
      width: 400px;
      padding-right: 15px;
      @include adaptive(1023) {
        width: 300px;
      }
      @include adaptive(767) {
        width: 100%;
        padding-right: 0;
      }
    }

    &-right {
      flex: 1;
      min-width: 1px;
      max-width: 100%;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #000000;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      padding-bottom: 40px;
      font-size: 33px;
      text-align: center;
    }
  }

  &__subtitle {
    padding-bottom: 30px;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
    color: #000000;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 30px;
      text-align: center;
    }
  }

  &__list {
    li {
      display: flex;
      padding-bottom: 30px;

      &:last-of-type {
        padding-bottom: 80px;
      }

      > div {
        &:nth-of-type(1) {
          width: 35px;
          padding-top: 6px;
        }
        &:nth-of-type(2) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #00449d;

      svg {
        width: 10px;
        height: 15px;
        fill: #fff;
      }
    }

    p {
      font-size: 19px;
      line-height: 1.58;
      color: #939393;
      @include adaptive(1023) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }

  &__btn {
    min-width: 239px;
    box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
    @include adaptive(767) {
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      color: #cd084b;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
      @include transition(background-color, color, box-shadow);
    }

    &-wrapper {
      display: flex;
    }
  }
}

// SECTION why :: END
