// SECTION :: START

.cs-description {
  padding-top: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &--bb0 {
    border-bottom: none;
  }

  &__wrapper {
    display: flex;
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    &-left,
    &-right {
      width: 50%;
      @include adaptive(767) {
        width: 100%;
      }
    }

    &-left {
      padding-right: 15px;
      @include adaptive(767) {
        padding-right: 0;
        padding-bottom: 50px;
      }

      > div {
        width: 100%;
        max-width: 460px;
        @include adaptive(767) {
          max-width: 100%;
        }
      }
    }

    &-right {
      padding-left: 15px;
      @include adaptive(767) {
        padding-left: 0;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .c-title {
    padding-bottom: 60px;
    @include adaptive(767) {
      padding-bottom: 30px;
    }

    &__text {
      span {
        font-weight: bold;
      }
    }
  }

  &__image {
    padding-bottom: 40px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
    color: #939393;
    line-height: 1.6;
    @include fluid(font-size, 320px, 1440px, 16px, 19px);

    &:not(:last-of-type) {
      padding-bottom: 40px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 50%;
      padding-bottom: 60px;
      @include adaptive(767) {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      min-height: 50px;
    }

    svg {
      fill: #00449D;
      @include adaptive(767) {
        transform: scale(0.9);
      }
    }

    p {
      width: 100%;
      max-width: 204px;
      padding-top: 20px;
      text-align: center;
      font-weight: 700;
      color: #000000;
      @include fluid(font-size, 320px, 1440px, 16px, 20px);
    }
  }

  &__slider {
    .swiper {
      &-slide {
        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

// SECTION :: END
