// SECTION scheduler :: START

&__information {
  display: none;

  &--join {
    display: block;
  }

  &-copyright {
    font-size: 20px;
    line-height: 1.3;
    color: #5390e3;
    @include adaptive(767) {
      font-size: 16px;
      line-height: normal;
    }
  }

  &.request {
    &:before {
      top: auto;
      right: -160px;
      bottom: -176px;
      margin-right: 0;
      background-size: contain;
      @include adaptive(767) {
        right: -75px;
        bottom: -75px;
        background-position: bottom right;
      }
    }

    .request {
      &__bg {
        width: 100%;
        margin-left: 0;
        border-radius: 0;
      }

      &__wrapper {
        padding-top: 120px;
        padding-bottom: 120px;
        @include adaptive(767) {
          padding-top: 60px;
          padding-bottom: 60px;
        }
      }
    }

    .c-form {
      &__row {
        padding-left: 0;
        padding-right: 0;
      }

      &__textarea {
        resize: none;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px 20px;
        border: none;
        box-shadow: none;
        color: #000;
        font-size: 18px;

        &-wrapper {
          width: 100%;
          height: 200px;
        }
      }

      &__field {
        &--textarea {
          width: 100%;
        }

        &--btn {
          flex-direction: row;
          justify-content: flex-start;
          padding-bottom: 139px;
          @include adaptive(767) {
            padding-bottom: 100px;
          }

          .c-btn {
            min-width: 213px;
            margin-left: 0;
            margin-right: 20px;

            &:last-of-type {
              margin-right: 0;
            }

            &.c-btn--border {
              border: 2px solid #5390e3;
              color: #ffffff;
              @include transition(border, background-color, color);

              &:hover {
                border-color: #CD084B;
                @include transition(border, background-color, color);
              }
            }
          }
        }
      }
    }
  }
}

// SECTION scheduler :: END
