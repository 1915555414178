// SECTION main :: START

.s-main {
  position: relative;
  padding-top: 200px;
  padding-bottom: 100px;
  @include adaptive(767) {
    padding-top: 150px;
  }

  &--mod {
    .s-main {
      &__bg {
        &-img {
          &-obj {
            &-1 {
              top: 24%;
              bottom: auto;
            }
          }
        }
        &-icon {
          &-1 {
            top: 200px;
            bottom: auto;
            left: 165px;
            width: 100px;
            height: 101px;
            background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
            background-size: cover;
            @include adaptive(1279) {
              top: 85px;
            }

            svg {
              transform: translateY(-9px);
            }
          }
          &-2 {
            left: 80px;
            top: auto;
            bottom: 120px;
            width: 144px;
            height: 153px;
            background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
            background-size: cover;
            @include adaptive(1279) {
              bottom: 80px;
            }

            svg {
              transform: translate(2px, -16px);
            }

          }
          &-4 {
            top: 60%;
          }
        }
      }
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &-obj {
        position: absolute;

        &-1 {
          left: 0;
          bottom: 30px;
        }
        &-2 {
          left: 20%;
          bottom: 50px;
        }
        &-3 {
          top: 50%;
          left: 50px;
        }
        &-4 {
          top: 30px;
          left: 55%;
        }
        &-5 {
          top: 200px;
          right: 20%;
        }
        &-6 {
          bottom: 30px;
          right: 22%;
        }
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      @include adaptive(767) {
        opacity: 0;
        visibility: hidden;
      }

      &-1 {
        left: 80px;
        bottom: 120px;
        width: 100px;
        height: 101px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
        background-size: cover;

        svg {
          transform: translateY(-9px);
        }
      }
      &-2 {
        top: 200px;
        left: 165px;
        width: 144px;
        height: 153px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
        background-size: cover;
        @include adaptive(1279) {
          left: 30px;
        }
        @include adaptive(1023) {
          top: 85px;
        }

        svg {
          transform: translate(2px, -16px);
        }
      }
      &-3 {
        top: 0;
        left: 60%;
        width: 124px;
        height: 104px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-7@2x.png");
        background-size: cover;
        @include adaptive(1023) {
          left: 40%;
        }

        svg {
          transform: translate(-2px, -20px);
        }
      }
      &-4 {
        top: 50%;
        right: 70px;
        width: 130px;
        height: 165px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-9@2x.png");
        background-size: cover;
        @include adaptive(1279) {
          right: 0;
        }
        @include adaptive(1023) {
          opacity: 0;
          visibility: hidden;
        }

        svg {
          transform: translate(2px, -23px);
        }
      }
      &-5 {
        bottom: -50px;
        right: 25%;
        width: 100px;
        height: 101px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-11@2x.png");
        background-size: cover;

        svg {
          transform: translateY(-10px);
        }
      }

      svg {
        fill: #fff;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__title,
  &__subtitle,
  &__desc {
    padding-bottom: 40px;
    text-align: center;
  }

  &__title,
  &__subtitle {
    color: #000000;
  }

  &__title {
    font-size: 60px;
    font-weight: 900;
    line-height: 1.17;
    @include adaptive(1279) {
      font-size: 55px;
    }
    @include adaptive(1023) {
      font-size: 48px;
    }
    @include adaptive(767) {
      font-size: 42px;

      br {
        display: none;
      }
    }
    @include adaptive(575) {
      font-size: 36px;
    }
  }

  &__subtitle {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
    @include adaptive(1279) {
      font-size: 36px;
    }
    @include adaptive(1023) {
      font-size: 33px;
    }
    @include adaptive(767) {
      font-size: 30px;
    }
  }

  &__desc {
    width: 100%;
    max-width: 758px;
    margin-left: auto;
    margin-right: auto;
    font-size: 19px;
    line-height: 1.58;
    color: #939393;
    @include adaptive(1279) {
      font-size: 18px;
    }
    @include adaptive(767) {
      font-size: 16px;
    }
  }

  &__btn {
    width: auto;
    max-width: unset;
    min-width: 239px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
    @include transition(background-color, color, box-shadow);


    &:hover {
      color: #cd084b;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
      @include transition(background-color, color, box-shadow);
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// SECTION main :: END
