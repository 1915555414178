// REQUEST :: START

.request {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    //mix-blend-mode: soft-light;
    opacity: 0.275;
    background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-request.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }


  &__bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: 100%;
    border-radius: 50%/100px 100px 0 0;

    &-inner {
      width: 100%;
      height: 100%;
      @include background-gradient(#00449D  , #001632, 'horizontal');
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    padding-top: 102px;
    padding-bottom: 99px;
    @include respond(max-small) {
      padding-top: 51px;
      padding-bottom: 50px;
    }
  }
}

// REQUEST :: END
