// SECTION calendar and scheduler main :: START

.cs-main {
  overflow: hidden;
  position: relative;
  padding-top: 177px;
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
  @include adaptive(767) {
    padding-top: 125px;
    padding-bottom: 75px;
  }

  &__bg {
    overflow: hidden;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: 50%;
    border-top-left-radius: 50% 0;
    border-top-right-radius: 50% 0;
    border-bottom-right-radius: 50% 100px;
    border-bottom-left-radius: 50% 100px;

    &-inner {
      width: 100%;
      height: 100%;
      background-color: #f8fafc;
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__title {
    padding-bottom: 30px;
    font-weight: 300;
    color: #000000;
    text-align: center;
    @include fluid(font-size, 320px, 1440px, 30px, 50px);
  }

  &__desc {
    width: 100%;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 45px;
    color: #939393;
    text-align: center;
    @include fluid(font-size, 320px, 1440px, 16px, 19px);
  }

  &__btn {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 73px;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  &__subtitle {
    padding-bottom: 20px;
    font-weight: 300;
    color: #000000;
    text-align: center;
    @include fluid(font-size, 320px, 1440px, 26px, 40px);
  }

  &__text {
    width: 100%;
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    color: #939393;
    text-align: center;
    line-height: 1.6;
    @include fluid(font-size, 320px, 1440px, 16px, 19px);
  }
}

// SECTION calendar and scheduler main :: END
