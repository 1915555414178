// FEATURES :: START

.features {
  &__wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
    @include respond(max-small) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__row {
    &-0 {
      padding-bottom: 62px;
    }

    &-1 {}
  }

  &__box {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 180px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    @include respond(max-small) {
      width: calc(50% - 10px);
      margin-bottom: 19px;
    }

    &:hover {
      .features__box-circle {
        background-color: transparent;
        @include transition(background-color);

        svg {
          fill: #D01756;
          @include transition(fill);
        }
      }
    }

    &-wrap {
      display: flex;
      justify-content: space-around;
      @include respond(max-small) {
        flex-wrap: wrap;
      }
    }

    &-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      border: 2px solid #D01756;
      background-color: #D01756;
      @include transition(background-color);

      svg {
        fill: $c-white;
        @include transition(fill);
      }
    }

    &-text {
      padding-top: 19px;
      font-size: 20px;
      font-weight: 600;
    }
  }
}

// FEATURES :: END
