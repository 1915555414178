// SECTION types :: START

.types {
  @include fluid(padding-top, 320px, 1439px, 55px, 101px);
  @include fluid(padding-bottom, 320px, 1439px, 25px, 50px);

  &__title {
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    @include fluid(font-size, 320px, 1600px, 30px, 40px);
    @include fluid(padding-bottom, 320px, 1439px, 35px, 70px);
  }

  &__block {
    width: 100%;
    max-width: 320px;
    min-height: 498px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background: $c-white;
    box-shadow: 0 15px 35px 0 rgba(0,68,157,0.07);

    &-wrapper {
      display: flex;
      align-items: flex-start;
      padding-bottom: 50px;
      @include respond(max-small) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc(100% / 3);
        padding-left: 5px;
        padding-right: 5px;
        @include respond(max-small) {
          max-width: 100%;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }

    &-top {
      min-height: calc(498px - 210px);
      padding: 40px 50px;
      @include respond(max-large) {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include respond(max-medium) {
        padding-left: 35px;
        padding-right: 35px;
      }
      @include respond(max-small) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    &-bottom {
      height: 210px;
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 2px solid #F8F8F8;
    }

    &-icon {
      display: flex;
      justify-content: center;
      min-height: 62px;
    }

    &-name {
      padding-top: 28px;
      padding-bottom: 20px;
      color: $c-black;
      text-align: center;
      font-weight: bold;
      @include fluid(font-size, 320px, 1430px, 22px, 26px);
    }

    &-desc {
      color: rgba($c-black, 0.4);
      text-align: center;
      @include fluid(font-size, 320px, 1430px, 15px, 17px);
    }

    &-limit {
      padding-bottom: 40px;
      color: rgba($c-black, 0.4);
      text-align: center;
      font-size: 17px;
      @include fluid(font-size, 320px, 1430px, 15px, 17px);

      span {
        color: $c-black;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 185px;
      height: 80px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      border: 2px solid #CD084B;
      background-color: transparent;
      color: #CD084B;
      letter-spacing: 1.35px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      @include transition(background-color, color);

      &.is-active,
      &:hover {
        background-color: #CD084B;
        color: $c-white;
        @include transition(background-color, color);
      }

      &-wrapper {
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  &__checkbox {
    opacity: 0;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.is-show {
      opacity: 1;
    }


    $form-label-height: auto;
    $form-label-margin-bottom: 10px;
    $form-label-font-size: 16px;
    $form-label-font-weight: normal;
    $form-checkbox-icon-wrapper-width: 40px;
    $form-checkbox-icon-width: 25px;
    $form-checkbox-icon-height: $form-checkbox-icon-width;
    $form-checkbox-icon-border-radius: 5px;
    $form-checkbox-icon-border-width: 2px;
    $form-checkbox-icon-border-color-inactive: rgba(#999999, 0.5);
    $form-checkbox-icon-border-color-active: rgba(#999999, 1);
    $form-checkbox-font-size: 19px;
    $form-checkbox-font-weight: normal;
    $form-checkbox-icon-inner-width: 7px;
    $form-checkbox-icon-inner-height: $form-checkbox-icon-inner-width * 2;
    $form-checkbox-icon-inner-border-color: rgba(#000, 1);
    $form-checkbox-icon-inner-border-width: 2px;
    $form-radio-icon-border-color-inactive: rgba(#000, 0.5);
    $form-radio-icon-border-color-active: rgba(#000, 1);
    $form-field-checkbox-height: 50px;

    .c-form {
      &__field {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        &__label {
          cursor: default;
          display: flex;
          align-items: center;
          height: $form-label-height;
          margin-bottom: $form-label-margin-bottom;
          font-size: $form-label-font-size;
          font-weight: $form-label-font-weight;
        }
      }

      &__checkbox {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;

        &-wrapper {
          position: relative;
          display: flex;
          width: 100%;
        }

        &-content {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        &-icon {
          i {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:checked {
          &+label {
            .c-form__checkbox-icon {
              i {
                border-color: #00449D;
                background-color: #00449D;
                @include transition(border-color, background-color);

                &:before {
                  opacity: 1;
                  @include transition(opacity);
                }
              }
            }
          }
        }

        &-wrapper {
          height: $form-field-checkbox-height;
        }

        &-icon {
          width: $form-checkbox-icon-wrapper-width;

          i {
            width: $form-checkbox-icon-width;
            height: $form-checkbox-icon-height;
            border-radius: $form-checkbox-icon-border-radius;
            border: $form-checkbox-icon-border-width solid $form-checkbox-icon-border-color-inactive;
            background-color: transparent;
            @include transition(border-color, background-color);

            &:before {
              content: '';
              opacity: 0;
              position: relative;
              top: -1px;
              left: 0.5px;
              transform: rotate(45deg);
              width: $form-checkbox-icon-inner-width;
              height: $form-checkbox-icon-inner-height;
              border: solid #fff;
              border-width: 0 $form-checkbox-icon-inner-border-width $form-checkbox-icon-inner-border-width 0;
              @include transition(opacity);
            }
          }
        }

        &-text {
          max-width: calc(100% - #{$form-checkbox-icon-wrapper-width});
          font-size: $form-checkbox-font-size;
          font-weight: $form-checkbox-font-weight;
        }
      }
    }
  }
}

// SECTION types :: END
