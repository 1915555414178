///
/// Mixin generate @font-face
/// ===============
@mixin mixinFontFace($fontName, $fontNameExt, $fontPath, $fontWeight, $fontExtension: eot woff2 woff ttf svg) {
	$src: null;
	$srcEot: null;

	$extensionModObj: (
		eot: "?iefix",
		svg: "#" + $fontName
	);

	$formats: (
		eot: "embedded-opentype",
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $fontExtension {


		$extensionModsValue:
			if(map-has-key($extensionModObj, $ext), $ext + map-get($extensionModObj, $ext), $ext);

		$format:
			if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);


		@if($ext == "eot") {
			$srcEot:
				append($srcEot, url(quote($fontPath + $fontName + $fontNameExt + "." + $ext)));
			$src:
				append($src,
					url(quote($fontPath + $fontName + $fontNameExt + "." + $extensionModsValue)) format(quote($format)), comma
				);
		} @else {
			$src:
				append($src,
					url(quote($fontPath + $fontName + $fontNameExt + "." + $extensionModsValue)) format(quote($format)), comma
				);
		}
	}

	@font-face {
		font-family: quote($fontName);

		src: $srcEot;
		src: $src;

		font-style: normal;
		font-weight: $fontWeight;
	}
}


/// ===============
/// CALL MIXIN FOR BUILD @FONT-FACE
///
/// Example:
///
/// @include mixinFontFace("JournalRegular", "-Bold", "../fonts/", normal, otf eot woff2 woff ttf svg);
/// @include mixinFontFace("SF-Pro-Text", "-Bold", "../fonts/", bold, otf);
/// @include mixinFontFace("SF-Pro-Text", "-Heavy", "../fonts/", 800, otf);
///
/// ===============

/* muli-200 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 200;
	src: url('../fonts/muli-v16-latin-200.eot'); /* IE9 Compat Modes */
	src: local('Muli ExtraLight'), local('Muli-ExtraLight'),
	url('../fonts/muli-v16-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-200.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-200.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-300 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/muli-v16-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Muli Light'), local('Muli-Light'),
	url('../fonts/muli-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-300.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-regular - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/muli-v16-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Muli Regular'), local('Muli-Regular'),
	url('../fonts/muli-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-regular.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-600 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/muli-v16-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Muli SemiBold'), local('Muli-SemiBold'),
	url('../fonts/muli-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-600.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-600.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-700 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/muli-v16-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Muli Bold'), local('Muli-Bold'),
	url('../fonts/muli-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-700.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-800 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/muli-v16-latin-800.eot'); /* IE9 Compat Modes */
	src: local('Muli ExtraBold'), local('Muli-ExtraBold'),
	url('../fonts/muli-v16-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-800.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-800.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-900 - latin */
@font-face {
	font-family: 'Muli';
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/muli-v16-latin-900.eot'); /* IE9 Compat Modes */
	src: local('Muli Black'), local('Muli-Black'),
	url('../fonts/muli-v16-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/muli-v16-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/muli-v16-latin-900.woff') format('woff'), /* Modern Browsers */
	url('../fonts/muli-v16-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/muli-v16-latin-900.svg#Muli') format('svg'); /* Legacy iOS */
}
/* open-sans-300 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Light'), local('OpenSans-Light'),
	url('../fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v17-latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
	url('../fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
	url('../fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
	url('../fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/open-sans-v17-latin-800.eot'); /* IE9 Compat Modes */
	src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
	url('../fonts/open-sans-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/open-sans-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/open-sans-v17-latin-800.woff') format('woff'), /* Modern Browsers */
	url('../fonts/open-sans-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/open-sans-v17-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
