// SECTION scheduler :: START

.scheduler {

  @import "scheduler-header";
  @import "scheduler-main";
  @import "scheduler-data";
  @import "scheduler-time";
  @import "scheduler-information";
  @import "scheduler-summary";
  @import "scheduler-invite";

  &__title {
    padding-bottom: 40px;
    font-weight: 900;
    font-size: 40px;
    line-height: 50px;

    @include adaptive(1279) {
      font-size: 34px;
      line-height: 1.4;
    }
    @include adaptive(767) {
      padding-bottom: 30px;
      font-size: 24px;
      line-height: normal;
    }

    span {
      padding-left: 20px;
      color: rgba(#000, 0.2);
    }

    &--white {
      color: #ffffff;
    }
  }

}

div#ui-datepicker-div {
  width: 270px;
  @include adaptive(424) {
    width: 260px;
  }
}

// SECTION scheduler :: END
