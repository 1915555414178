// SECTION :: START

.cs-slider {
  padding-top: 65px;
  padding-bottom: 65px;
  background-color: #f8fafc;
  @include adaptive(767) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .c-grid {
    max-width: 1270px;
  }

  &__wrapper {
    position: relative;
  }

  .c-title {
    padding-bottom: 80px;
    @include adaptive(767) {
      padding-bottom: 40px;
    }

    &__text {
      span {
        font-weight: bold;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 1070px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(1279) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include adaptive(767) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;

    &.swiper-button-disabled {
      cursor: not-allowed;
      opacity: 0.3;
      pointer-events: none;
    }

    &-wrapper {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include adaptive(767) {
        display: none;
      }
    }

    &--prev {
      left: 0;
      transform: translateY(-50%) rotate(90deg);
      @include adaptive(1279) {
        left: -5px;
      }
    }

    &--next {
      right: 0;
      transform: translateY(-50%) rotate(-90deg);
      @include adaptive(1279) {
        right: -5px;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    @include adaptive(767) {
      flex-wrap: wrap;
    }

    &-left,
    &-right {
      width: 50%;
      @include adaptive(767) {
        width: 100%;
      }
    }

    &-left {
      padding-right: 15px;
      @include adaptive(767) {
        padding-right: 0;

        .cs-slider__block-description {
          display: none;
        }
      }
    }

    &-right {
      padding-left: 15px;
      padding-right: 15px;
      @include adaptive(767) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 15px;
      }

      .cs-slider__block-description {
        display: none;
        @include adaptive(767) {
          display: block;
          padding-top: 30px;
        }
      }
    }

    &-title,
    &-subtitle,
    &-description {
      @include adaptive(767) {
        text-align: center;
      }
    }

    &-title {
      padding-bottom: 20px;
      font-weight: 700;
      color: #CD084B;
      @include fluid(font-size, 320px, 1440px, 20px, 24px);

      &--pb0 {
        padding-bottom: 0;
      }
    }

    &-subtitle {
      padding-bottom: 20px;
      color: #000000;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
    }

    &-description {
      width: 100%;
      max-width: 460px;
      color: #939393;
      line-height: 1.6;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
      @include adaptive(767) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .swiper {
    &-container {
      padding-bottom: 0;
      @include adaptive(767) {
        padding-bottom: 50px;
      }
    }
    &-pagination {
      display: none;
      position: absolute;
      justify-content: center;
      @include adaptive(767) {
        display: flex;
      }
    }
  }
}

// SECTION :: END
