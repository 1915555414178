// SECTION :: START

.cs-powerful {
  padding-top: 102px;
  padding-bottom: 117px;
  background-color: #f8fafc;
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .c-title {
    padding-bottom: 20px;

    &__text {
      span {
        font-weight: bold;
      }
    }
  }

  .c-text {
    padding-bottom: 60px;
  }

  &__block {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc(100% / 5);
        padding-left: 30px;
        padding-right: 30px;
        @include adaptive(1023) {
          padding-left: 15px;
          padding-right: 15px;
        }
        @include adaptive(767) {
          max-width: 50%;
          padding-bottom: 50px;

          &:last-of-type {
            max-width: 100%;
          }
        }
      }
    }

    &-head {
      overflow: hidden;
      position: relative;
      width: 105px;
      height: 105px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 25px;
      background-color: #D8D8D8;

      &--border {
        border: 1px solid #D4D4D4;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-body {
      padding-top: 20px;
    }

    p {
      font-weight: 700;
      color: #000000;
      text-align: center;
      @include fluid(font-size, 320px, 1440px, 18px, 20px);
    }
  }
}

// SECTION :: END
