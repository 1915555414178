// MODAL :: START

.c-modal {
  #viewAgreement & {
    height: 100%;

    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }

        &.is-shadow {
          box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
          @include transition(box-shadow);
        }
      }

      &__body {
        position: relative;
        height: calc(100% - 190px);
        @include adaptive(1279) {
          height: calc(100% - 140px);
        }
        @include adaptive(767) {
          height: calc(100% - 165px);
        }

        &.is-bottom {
          &:after {
            opacity: 0;
            visibility: hidden;
            @include transition(opacity, visibility);
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100px;
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
          @include transition(opacity, visibility);
        }

        &-scroll {
          overflow-y: auto;
          position: relative;
          height: 100%;
          padding-left: 60px;
          padding-right: 60px;
          @include adaptive(1279) {
            padding-left: 40px;
            padding-right: 40px;
          }
          @include adaptive(767) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;

        &-text {
          display: flex;
          align-items: center;
          font-size: 19px;
          font-weight: 900;
          line-height: 1.58;
          color: #000000;
          @include adaptive(1279) {
            font-size: 17px;
          }
          @include adaptive(767) {
            flex-wrap: wrap;
            font-size: 15px;
          }

          span {
            font-weight: normal;
            @include adaptive(767) {
              width: 100%;
            }
          }

          a {
            font-weight: normal;
            color: #00449d;
            @include adaptive(767) {
              width: 100%;
            }
          }
        }
      }

      &__desc {
        padding-bottom: 50px;
        font-size: 19px;
        line-height: 1.58;
        color: #000000;
        @include adaptive(1279) {
          font-size: 17px;
        }
        @include adaptive(767) {
          font-size: 15px;
        }

        span {
          font-weight: 900;
        }
      }

      &__subtitle {
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 26px;
        font-weight: 900;
        line-height: 1.15;
        color: #000000;
        @include adaptive(1279) {
          font-size: 22px;
        }
        @include adaptive(767) {
          font-size: 19px;
        }

        &--first {
          padding-top: 0;
        }
      }

      &__text {
        padding-bottom: 20px;
        font-size: 19px;
        line-height: 1.58;
        color: #000000;
        @include adaptive(1279) {
          font-size: 17px;
        }
        @include adaptive(767) {
          font-size: 15px;
        }

        a {
          color: #00449d;
        }
      }

      &__list {
        padding-left: 40px;
        @include adaptive(1279) {
          padding-left: 20px;
        }
        @include adaptive(767) {
          padding-left: 15px;
        }

        li {
          padding-bottom: 20px;
          font-size: 19px;
          line-height: 1.58;
          color: #000000;
          @include adaptive(1279) {
            font-size: 17px;
          }
          @include adaptive(767) {
            font-size: 15px;
          }

          a {
            color: #00449d;
          }

          span {
            font-weight: 900;
            text-transform: uppercase;
          }
        }
      }

      &__input {
        input {
          outline: none;
          width: 100%;
          height: 60px;
          padding: 0 20px;
          border-radius: 10px;
          border: none;
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.05);
          font-size: 20px;
          line-height: 1.5;
          color: rgba(#000000, 0.5);
          @include inputPlaceholder {
            font-size: 20px;
            line-height: 1.5;
            color: rgba(#000000, 0.5);
          }
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 50px;
          @include adaptive(767) {
            flex-wrap: wrap;
          }
        }
        
        &-left,
        &-right {
          width: 100%;
          max-width: calc((100% - 40px) / 2);
          @include adaptive(767) {
            max-width: 100%;
          }
        }

        &-left {
          @include adaptive(767) {
            padding-bottom: 8px;
          }
        }

        &-title {
          padding-bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.88;
          color: rgba(#000000, 0.5);
        }
      }
      
      &__btn {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        @include adaptive(767) {
          flex-wrap: wrap;
        }
        
        > a {
          width: auto;
          max-width: unset;
          padding-left: 50px;
          padding-right: 50px;
          margin-right: 40px;
          @include adaptive(767) {
            width: 100%;
            max-width: 100%;
            height: 60px;
            margin-right: 0;
          }

          &.c-btn--bg {
            box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
            @include transition(color, box-shadow, background-color);

            &:hover {
              color: #cd084b;
              box-shadow: none;
              @include transition(color, box-shadow, background-color);
            }
          }

          &:last-of-type {
            margin-right: 0;
            @include adaptive(767) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  #paymentHistory & {
    height: 100%;

    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }

        &.is-shadow {
          box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
          @include transition(box-shadow);
        }
      }

      &__body {
        position: relative;
        height: calc(100% - 140px);
        @include adaptive(1279) {
          height: calc(100% - 100px);
        }
        @include adaptive(767) {
          height: calc(100% - 165px);
        }

        &-content {
          position: relative;
          padding: 0 30px 30px;
          @include adaptive(767) {
            padding-left: 0;
            padding-right: 0;
          }

          &.is-scroll {
            box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
          }
        }

        &-scroll {
          overflow-y: auto;
          position: relative;
          height: calc(100% - 90px);
          max-height: 590px;
          padding-left: 50px;
          padding-right: 50px;
          @include adaptive(767) {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        @include adaptive(767) {
          flex-wrap: wrap;
        }

        > a {
          width: auto;
          max-width: unset;
          padding-left: 50px;
          padding-right: 50px;
          @include adaptive(767) {
            width: 100%;
            max-width: 100%;
            height: 60px;
          }
        }
      }

      &__table {
        &-head {
          overflow: hidden;
          z-index: 10;
          position: relative;
          border-radius: 10px;
          background-color: #f8fafc;

          .c-modal__table-th {
            min-height: 60px;
            padding: 5px 10px 5px 30px;
            @include adaptive(767) {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }

        &-body {
          .c-modal__table {
            &-tr {
              border-bottom: 1px solid darken(#f8fafc, 5%);

              &:first-of-type {
                > div {
                  align-items: flex-start;
                  min-height: 59px;
                }
              }
              &:last-of-type {
                border-bottom: none;
              }
            }

            &-td {
              min-height: 89px;
              padding: 5px 10px 5px 50px;
              @include adaptive(767) {
                padding-left: 5px;
                padding-right: 5px;
              }

              &:nth-of-type(1) {
                padding-left: 10px;
                @include adaptive(767) {
                  padding-left: 5px;
                }
              }
            }
          }
        }

        &-footer {
          padding-top: 30px;
          padding-bottom: 60px;
        }

        &-tr {
          display: flex;
          align-items: center;
        }

        &-th,
        &-td {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex: 1;
          min-width: 1px;
          max-width: 100%;
          @include adaptive(767) {
            text-align: center;
            justify-content: center;
          }
        }

        &-th {
          p {
            font-size: 19px;
            font-weight: bold;
            line-height: 1.58;
            color: #000000;
            @include adaptive(1023) {
              font-size: 17px;
            }
            @include adaptive(575) {
              font-size: 14px;
            }
            @include adaptive(374) {
              font-size: 12px;
            }
          }
        }

        &-td {
          span {
            font-size: 19px;
            line-height: 1.58;
            color: #000000;
            @include adaptive(1023) {
              font-size: 17px;
            }
            @include adaptive(424) {
              font-size: 14px;
            }
            @include adaptive(374){
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  #makeWithdrawal & {
    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }
      }

      &__body {
       padding: 0 60px 60px;
       @include adaptive(1279) {
         padding-left: 40px;
         padding-right: 40px;
       }
       @include adaptive(767) {
         padding-left: 15px;
         padding-right: 15px;
       }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__input {
        input {
          outline: none;
          width: 100%;
          height: 60px;
          padding: 0 20px;
          border-radius: 10px;
          border: none;
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.05);
          font-size: 20px;
          line-height: 1.5;
          color: rgba(#000000, 0.5);
          @include inputPlaceholder {
            font-size: 20px;
            line-height: 1.5;
            color: rgba(#000000, 0.5);
          }
        }

        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 50px;
        }

        &-left,
        &-right {
          width: 100%;
          max-width: calc((100% - 40px) / 2);
          @include adaptive(767) {
            max-width: 100%;
          }
        }

        &-left {
          @include adaptive(767) {
            padding-bottom: 8px;
          }
        }

        &-title {
          padding-bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.88;
          color: rgba(#000000, 0.5);
        }
      }

      &__btn {
        display: flex;
        align-items: center;

        > a {
          width: auto;
          max-width: unset;
          padding-left: 50px;
          padding-right: 50px;
          @include adaptive(767) {
            width: 100%;
            max-width: 100%;
            height: 60px;
          }

          &.c-btn--bg {
            box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
            @include transition(color, box-shadow, background-color);

            &:hover {
              color: #cd084b;
              box-shadow: none;
              @include transition(color, box-shadow, background-color);
            }
          }
        }
      }
    }
  }

  #declineEvent & {
    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }
      }

      &__body {
       padding: 0 60px 60px;
       @include adaptive(1279) {
         padding-left: 40px;
         padding-right: 40px;
       }
       @include adaptive(767) {
         padding-left: 15px;
         padding-right: 15px;
       }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__desc {
        padding-bottom: 47px;
        font-size: 19px;
        line-height: 1.3;
        color: #000;
        @include adaptive(767) {
          font-size: 17px;
        }
      }

      &__btn {
        box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
      }
    }
  }

  #rescheduleEvent & {
    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }
      }

      &__body {
       padding: 0 60px 60px;
       @include adaptive(1279) {
         padding-left: 40px;
         padding-right: 40px;
       }
       @include adaptive(767) {
         padding-left: 15px;
         padding-right: 15px;
       }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 30px;
        @include adaptive(1023) {
          justify-content: flex-start;
        }

        &:last-of-type {
          padding-bottom: 50px;
        }
      }

      &__col {
        width: 100%;

        &-1 {
          width: 40%;
          padding-right: 40px;
          @include adaptive(1023) {
            width: 55%;
          }
          @include adaptive(767) {
            width: 100%;
            padding-right: 0;
          }
        }

        &-2,
        &-3 {
          width: 30%;
        }

        &-2 {
          padding-right: 40px;
          @include adaptive(1023) {
            width: 45%;
            padding-right: 0;
          }
          @include adaptive(767) {
            width: 100%;

            .c-form__group--request {
              flex-wrap: nowrap;
            }
          }
        }

        &-3 {
          @include adaptive(1023) {
           width: 100%;

            .c-form__label {
              display: none;
            }
          }
        }
      }

      &__radio {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;

        &:checked {
          & + .c-modal__radio-label {
            .c-modal__radio-circle {
              border-color: #00449d;
              background-color: #00449d;
              @include transition(border-color, background-color);

              &:before {
                transform: scale(1);
                @include transition(transform);
              }
            }
          }
        }

        &-wrapper {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 56px;
          @include adaptive(1023) {
            min-height: unset;
          }
        }

        &-col {
          position: relative;
          padding-right: 30px;
          @include adaptive(767) {
            padding-bottom: 10px;
          }
        }

        &-label {
          cursor: pointer;
          display: flex;
          align-items: center;

          p {
            &:nth-of-type(1) {
              width: 50px;
            }
            &:nth-of-type(2) {
              flex: 1;
              min-width: 1px;
              max-width: 100%;
            }
          }
        }

        &-circle {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: solid 2px rgba(0, 0, 0, 0.05);
          background-color: transparent;
          @include transition(border-color, background-color);

          &:before {
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #ffffff;
            transform: scale(0);
            @include transition(transform);
          }
        }

        &-text {
          font-size: 20px;
          line-height: 1.5;
          @include adaptive(767) {
            font-size: 16px;
          }
        }
      }

      &__btn {
        box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
      }
    }

    .c-form {
      &__label {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.88;
        color: rgba(#000000, 0.5);

        &--hidden {
          opacity: 0;
        }
      }

      &__field {
        &--date {
          .c-form {
            &__input {
              border-radius: 10px;
              border: solid 2px rgba(0, 0, 0, 0.05);
              background-color: transparent;

              &-wrapper {
                p {
                  z-index: -1;
                  position: absolute;
                  top: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 100%;

                  svg {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }

      &__select {
        border-radius: 10px;
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
      }
    }
  }

  #contactOrganizer & {
    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }
      }

      &__body {
       padding: 0 60px 60px;
       @include adaptive(1279) {
         padding-left: 40px;
         padding-right: 40px;
       }
       @include adaptive(767) {
         padding-left: 15px;
         padding-right: 15px;
       }

        .c-form {
          &__row {
            padding-left: 0;
            padding-right: 0;
          }

          &__textarea {
            resize: none;
            border-radius: 10px;
            background-color: #fff;
            padding: 10px 20px;
            box-shadow: none;
            color: #000;
            font-size: 18px;
            border: 2px solid rgba(0, 0, 0, 0.05);

            &-wrapper {
              width: 100%;
              height: 200px;
            }
          }

          &__field {
            width: 100%;

            &--textarea {
              width: 100%;
            }
          }

          &__label {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
            color: rgba(#000, 0.5);
          }

          &__input {
            border: 2px solid rgba(0, 0, 0, 0.05);
          }

          &__file {
            z-index: 5;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &-cover {
              display: flex;
              position: relative;
              @include adaptive(374) {
                width: 100%;
              }
            }

            &-btn {
              width: 210px;
              @include adaptive(767) {
                width: 156px;
              }
              @include adaptive(374) {
                width: 100%;
              }
            }

            &-input {
              flex: 1;
              min-width: 1px;
              max-width: 100%;
              background-color: #fff;
              padding-left: 30px;
              box-shadow: none;
              border: none;
              border-radius: 0;
              font-size: 19px;
              line-height: 1.3;
              color: #000;
              @include adaptive(767) {
                padding-left: 15px;
                font-size: 16px;
              }
              @include adaptive(374) {
                display: none;
              }
            }

            &-wrapper {
              display: flex;
            }
          }
        }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__btn {
        max-width: 156px;
        margin-top: 20px;
        box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
      }
    }
  }

  #updateInfo & {
    height: 100%;

    .affiliate-appForm__heading--pt {
      padding-top: 100px;
      @include adaptive(767) {
        padding-top: 75px;
      }
    }

    .c-modal {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        background-color: #ffffff;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }

        &.is-shadow {
          box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
          @include transition(box-shadow);
        }
      }

      &__body {
        padding: 0 60px 60px;
        @include adaptive(1279) {
          padding: 0 40px 40px;
        }
        @include adaptive(767) {
          padding: 0 15px 15px;
        }
      }

      &__title {
        font-size: 40px;
        font-weight: 900;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__tax {
        font-size: 19px;
        font-weight: 800;
        line-height: 1.58;
        color: #000000;
        @include adaptive(767) {
          font-size: 17px;
        }

        span {
          font-weight: normal;
        }
      }

      &__desc {
        padding-bottom: 40px;
        font-size: 19px;
        line-height: 1.58;
        color: #000000;
        @include adaptive(767) {
          font-size: 17px;
        }

        &--wpb {
          padding-bottom: 20px;
        }
      }
    }
  }

  #enterprisePlans &,
  #startFreeTrial & {
     .c-modal {
       &__header {
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         min-height: 140px;
         padding: 50px 60px 40px;
         @include transition(box-shadow);
         @include adaptive(1279) {
           min-height: 100px;
           padding: 20px 40px;
         }
         @include adaptive(767) {
           min-height: 135px;
           padding: 30px 15px;
         }

         > div {
           &:nth-of-type(2) {
             padding-top: 15px;
             @include adaptive(767) {
               padding-top: 8px;
             }
           }
         }
       }

       &__body {
         padding: 0 60px 60px;
         @include adaptive(1279) {
           padding-left: 40px;
           padding-right: 40px;
         }
         @include adaptive(767) {
           padding-left: 15px;
           padding-right: 15px;
         }
       }

       &__title {
         padding-bottom: 15px;
         font-size: 40px;
         font-weight: 300;
         line-height: 1.25;
         color: #000000;
         @include adaptive(1279) {
           font-size: 36px;
         }
         @include adaptive(767) {
           font-size: 28px;
         }
       }

       &__desc {
         font-size: 20px;
         color: #000000;
         letter-spacing: 0;
         @include adaptive(1279) {
           font-size: 18px;
         }
         @include adaptive(767) {
           font-size: 16px;
         }
       }

       &__close {
         display: flex;
         align-items: center;
         justify-content: center;

         svg {
           width: 26px;
           height: 26px;
           fill: #000;
           @include adaptive(1279) {
             width: 22px;
             height: 22px;
           }
           @include adaptive(767) {
             width: 20px;
             height: 20px;
           }
         }
       }

       &__btn {
         max-width: 230px;
         margin-top: 20px;
         box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
         @include adaptive(767) {
           margin-left: auto;
           margin-right: auto;
         }
       }
     }

     .c-form {
       &__row {
         &--request {
           padding-left: 0;
           padding-right: 0;
         }
       }
       &__label {
         font-size: 16px;
         font-weight: 600;
         line-height: 1.88;
         color: rgba(#000000, 0.5);

         &--hidden {
           opacity: 0;
         }
       }

       &__field {
         &--dropdown {
           display: block;
           @include adaptive(767) {
             display: none;
           }

           &-select {
             display: none;
             @include adaptive(767) {
               display: block;
               margin-bottom: 30px;
             }
           }

           .scheduler__time-dropdown-content {
             width: 360px;

             &--hideInput {
               > div {
                 &:nth-of-type(1) {
                   display: none;
                 }
               }
             }
           }
         }

         &--date {
           .c-form {
             &__input {
               border-radius: 10px;
               border: solid 2px rgba(0, 0, 0, 0.05);
               background-color: transparent;

               &-wrapper {
                 p {
                   z-index: -1;
                   position: absolute;
                   top: 0;
                   right: 0;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   width: 50px;
                   height: 100%;

                   svg {
                     width: 18px;
                     height: 18px;
                   }
                 }
               }
             }
           }
         }
       }

       &__input {
         border: 2px solid rgba(0, 0, 0, 0.05);
       }

       &__select {
         border-radius: 10px;
         border: solid 2px rgba(0, 0, 0, 0.05);
         background-color: #ffffff;
       }
     }
   }

  #shareArticle & {
    .c-modal {
      &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 140px;
        padding: 50px 60px 40px;
        @include transition(box-shadow);
        @include adaptive(1279) {
          min-height: 100px;
          padding: 20px 40px;
        }
        @include adaptive(767) {
          min-height: 135px;
          padding: 30px 15px;
        }

        > div {
          &:nth-of-type(2) {
            padding-top: 15px;
            @include adaptive(767) {
              padding-top: 8px;
            }
          }
        }
      }

      &__body {
        padding: 0 60px 60px;
        @include adaptive(1279) {
          padding-left: 40px;
          padding-right: 40px;
        }
        @include adaptive(767) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &__title {
        padding-bottom: 15px;
        font-size: 40px;
        font-weight: 300;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 28px;
        }
      }

      &__desc {
        font-size: 20px;
        color: #000000;
        letter-spacing: 0;
        @include adaptive(1279) {
          font-size: 18px;
        }
        @include adaptive(767) {
          font-size: 16px;
        }
      }

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 26px;
          height: 26px;
          fill: #000;
          @include adaptive(1279) {
            width: 22px;
            height: 22px;
          }
          @include adaptive(767) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__btn {
        min-width: 230px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 20px;
        box-shadow: none;
        @include adaptive(767) {
          margin-left: auto;
          margin-right: auto;
        }

        &--share {
          border-color: #CD084B;
          background-color: #CD084B;
          color: #ffffff;
        }

        &--cancel {
          border-color: #CD084B;
          color: #CD084B;
        }

        &:not(:last-of-type) {
          margin-right: 15px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }

    .c-form {
      &__label {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.88;
        color: rgba(#000000, 0.5);

        &--hidden {
          opacity: 0;
        }
      }

      &__input {
        border: 2px solid rgba(0, 0, 0, 0.05);
      }

      &__select {
        border-radius: 10px;
        border: solid 2px rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
      }
    }
  }
}

// MODAL :: END
