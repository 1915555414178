// SECTION :: START

.perfect {
  padding-top: 59px;
  padding-bottom: 50px;

  &__title {
    width: 100%;
    max-width: 799px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 148px;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
    @include adaptive(1280) {
      font-size: 26px;
      padding-bottom: 120px;
    }
    @include adaptive(767) {
      font-size: 24px;
      padding-bottom: 50px;
    }
  }

  &__box {
    display: flex;
    align-items: flex-start;
    padding-bottom: 100px;
    @include adaptive(767) {
      padding-bottom: 50px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 743px;
      margin-left: auto;
      margin-right: auto;
    }

    &-left {
      width: 112px;
      @include adaptive(767) {
        width: 90px;

        svg {
          transform: scale(0.65);
        }
      }
    }

    &-right {
      flex: 1;
      min-width: 1px;
      max-width: 100%;
    }

    &-title {
      padding-bottom: 17px;
      font-weight: 600;
      font-size: 24px;
      color: #000000;
      letter-spacing: 0;
      line-height: 40px;
      @include adaptive(767) {
        font-size: 20px;
      }
    }

    &-desc {
      font-size: 18px;
      color: #939393;
      letter-spacing: 0;
      line-height: 31px;
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }
}

// SECTION :: END
