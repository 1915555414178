// SECTION HELP :: START

.help {
  @include fluid(padding-top, 320px, 1440px, 50px, 101px);

  &--block {
    h2 {
      text-align: center;
    }
  }

  &--topic {
    display: none;
  }

  &--inside {
    display: none;
  }

  h2 {
    padding-bottom: 40px;
    color: $c-black;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 25px, 40px);
  }

  h3 {
    padding-bottom: 30px;
    color: $c-black;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 20px, 26px);
  }

  &__back {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    color: #00449D;

    svg {
      width: 16px;
      height: 16px;
      fill: #00449D;
    }

    span {
      padding-left: 20px;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
    }
  }

  &__block {
    width: 100%;
    max-width: calc((100% - 120px) / 4);
    height: 200px;
    margin: 25px 15px;
    padding: 40px 15px;
    border-radius: 14px;
    background: #FFFFFF;
    box-shadow: 0 15px 35px 0 rgba(0,68,157,0.07);
    @include transition(background);
    @include respond(max-medium) {
      max-width: calc((100% - 90px) / 3);
    }
    @include respond(max-small) {
      max-width: calc((100% - 60px) / 2);
    }
    @include respond(max-extra-small) {
      max-width: calc(100% - 30px);
    }

    &.is-active,
    &:hover {
      background: #CD084B;
      @include transition(background);

      .help__block-img {
        svg {
          fill: $c-white;
          @include transition(fill);
        }
      }

      .help__block-name {
        color: $c-white;
        @include transition(color);
      }
    }

    &-wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      @include fluid(padding-bottom, 320px, 1440px, 75px, 174px);
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;

      svg {
        fill: #00449D;
        @include transition(fill);
      }
    }

    &-name {
      padding-top: 22px;
      color: $c-black;
      text-align: center;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
      @include transition(color);
    }
  }

  &__topic {
    @include fluid(padding-bottom, 320px, 1920px, 100px, 387px);

    .help__back,
    h2,
    .help__topic-link a {
      transform: translateX(150%);
      @include transition(transform 0.5s);

      .is-topic & {
        transform: translateX(0);
        @include transition(transform 0.75s);
      }
    }

    .help__topic-link > a {
      &.is-inside {
        transition-delay: 0s !important;
        transform: translateX(-150%);
        @include transition(transform 0.5s);
      }
    }

    &-link {
      display: flex;
      flex-direction: column;

      a {
        display: flex;
        align-items: center;
        min-height: 71px;
        border-bottom: 1px solid #D8D8D8;
        color: #00449D;
        @include fluid(font-size, 320px, 1440px, 16px, 19px);
        transform: translateX(0);
        transform-origin: center;
        @include transition(border-color);

        &:hover {
          border-color: #00449D;
          @include transition(border-color);

          i {
            transform: translateX(10px);
            @include transition(transform);
            @include respond(max-extra-small) {
              transform: translateX(5px);
            }
          }
        }

        span {
          padding-right: 20px;
          @include respond(max-extra-small) {
            width: 100%;
            max-width: calc(100% - 12px);
          }
        }

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          @include transition(transform);
        }

        svg {
          width: 12px;
          fill: #00449D;
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__inside {
    @include fluid(padding-bottom, 320px, 1440px, 75px, 174px);

    .help__back {
      transition-delay: 0.1s !important;
    }

    h2 {
      transition-delay: 0.2s !important;
    }

    .help__back,
    h2 {
      transform: translateX(150%);
      @include transition(transform 0.5s);

      .is-descr & {
        transform: translateX(0);
        @include transition(transform 0.75s);
      }
    }

    p,
    h5,
    ul li {
      color: #575757;
      font-weight: normal;
      @include fluid(font-size, 320px, 1440px, 15px, 17px);

      .is-descr & {
        animation-fill-mode: both;
        animation-name: slideInUp;
      }
    }

    p {
      padding-bottom: 30px;

      &:last-of-type {
        padding-bottom: 60px;
      }
    }

    h5 {
      display: flex;
      align-items: center;
      min-height: 66px;
      padding: 15px 30px;
      margin-bottom: 40px;
      border-radius: 6px;
      background-color: #F2F5FA;
    }

    ul {
      padding-left: 20px;
      padding-bottom: 15px;

      li {
        padding-bottom: 15px;
        list-style-type: lower-alpha;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
  }
}

// SECTION HELP :: END
