// COMMON :: START

// text
.c-text {
  width: 100%;
  max-width: 804px;
  margin-left: auto;
  margin-right: auto;
  color: #939393;
  line-height: 1.7;
  text-align: center;
  @include fluid(font-size, 320px, 1440px, 16px, 18px);
}
// text :: end

// search dropdown
.c-form {
  &__field {
    &.is-focus {
      .c-form__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        //@include transition(border-radius);
      }
    }
  }

  &__input {
    //@include transition(border-radius);
  }

  &__dropdown {
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    z-index: 100;
    position: absolute;
    top: calc(100%);
    left: 0;
    width: 100%;
    max-height: 200px;
    background-color: #ffffff;
    //border-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    //border: 2px solid rgba(#00449D, 0.1);
    border-left: 2px solid rgba(#00449D, 0.1);
    border-right: 2px solid rgba(#00449D, 0.1);
    border-bottom: 2px solid rgba(#00449D, 0.1);

    ul {
      display: flex;
      flex-direction: column;

      li {
        a {
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 40px;
          padding: 5px 20px;

          span,
          i,
          p {
            white-space: nowrap;
            color: $c-black;
            font-size: 18px;
            font-style: normal;
          }

          span {
            font-weight: bold;
            color: #00449D;
          }

          i {
            margin-right: 5px;
          }

          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
// search dropdown :: end

// slider
.slider {
  &--save,
  &--finding {
    .slider__content-left,
    .slider__content-right {
      display: flex;
      align-items: flex-start;
      @include respond(extra-large) {
        justify-content: flex-end;
      }
      @include respond(max-large) {
        justify-content: flex-start;
      }
    }

    .slider__content-left {
      padding-left: 15px;
      @include respond(extra-large) {
        padding-right: 60px;
      }
      @include respond(max-large) {
        padding-right: 15px;
      }
    }

    .slider__img {
      @include respond(medium) {
        transform: translateX(35px);
      }
    }

    .slider__pagination {
      @include respond(extra-large) {
        right: calc(50% + 60px);
      }
      @include respond(max-large) {
        left: 0;
        max-width: 40%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  &--control,
  &--manage {
    .slider__content {
      @include respond(medium) {
        flex-direction: row-reverse;
      }
    }

    .slider__content-left,
    .slider__content-right {
      display: flex;
      align-items: flex-start;
      @include respond(extra-large) {
        justify-content: flex-start;
      }
      @include respond(max-large) {
        justify-content: flex-end;
      }
    }

    .slider__content-left {
      padding-right: 15px;
      @include respond(extra-large) {
        padding-left: 60px;
      }
      @include respond(max-large) {
        padding-left: 15px;
      }
    }

    .slider__img {
      @include respond(medium) {
        transform: translateX(-35px);
      }
    }

    .slider__pagination {
      @include respond(extra-large) {
        left: calc(50% + 60px);
      }
      @include respond(max-large) {
        right: 0;
        max-width: 40%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    @include respond(max-small) {
      flex-direction: column-reverse;
    }

    &-left,
    &-right {
      @include respond(extra-large) {
        width: 50%;
      }
      @include respond(max-small) {
        width: 100% !important;
      }
    }

    &-left {
      @include respond(max-large) {
        width: 40%;
      }
    }
    &-right {
      @include respond(max-large) {
        width: 60%;
      }
    }
  }

  &__text {
    padding-bottom: 40px;
    color: #939393;
    text-align: left;
    line-height: 1.7;
    @include fluid(font-size, 320px, 1440px, 14px, 19px);
    @include respond(max-small) {
      padding-bottom: 30px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &-wrapper {
      width: 100%;
      max-width: 460px;
      min-height: 330px;
      padding-top: 30px;
      padding-bottom: 60px;
      @include respond(max-small) {
        max-width: 100%;
        min-height: 0;
        padding-bottom: 30px;
      }
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
    @include respond(max-small) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__pagination {
    z-index: 10;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 460px;
    @include respond(max-small) {
      left: 0;
      max-width: 100% !important;
      justify-content: center;
    }
  }

  &__button {
    cursor: pointer;
    outline: none;
    user-select: none;
    @include respond(max-large) {
      display: none !important;
    }

    &-prev {
      display: none !important;
    }

    &-next {
      color: #CD084B;
      text-transform: uppercase;
      letter-spacing: 1.35px;
      font-weight: bold;
      @include fluid(font-size, 320px, 1440px, 14px, 18px);
    }
  }
}
// slider :: end


// address
.address {
  &__row {
    color: #8F8F8F;

    p {
      font-size: 14px;
      @include respond(max-extra-small) {
        text-align: center;
      }
    }

    &-0 {
      p {
        min-height: 40px;
        padding-bottom: 15px;
      }
    }

    &-1 {
      padding-bottom: 40px;
      @include respond(max-extra-small) {
        padding-bottom: 27px;
      }
    }

    &-2 {
      display: flex;
      flex-direction: column;
      @include respond(max-extra-small) {
        align-items: center;
      }

      a {
        color: #8F8F8F;
        font-size: 14px;
        @include transition(color);

        &:hover {
          color: $c-white;
          @include transition(color);
        }

        &:first-of-type {
          padding-bottom: 10px;
          color: $c-white;

          &:hover {
            color: #8F8F8F;
          }
        }
      }
    }
  }
}
// address :: end


// copyright
.copyright {
  &__text {
    color: #8F8F8F;
    font-size: 14px;
  }
}
// copyright :: end

// COMMON :: END