/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* line 3, src/scss/_vendor/hamburger/_base.scss */
.hamburger {
  padding: 0 0;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

/* line 21, src/scss/_vendor/hamburger/_base.scss */
.hamburger:hover {
  opacity: 0.7;
}

/* line 31, src/scss/_vendor/hamburger/_base.scss */
.hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative;
}

/* line 38, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

/* line 43, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

/* line 55, src/scss/_vendor/hamburger/_base.scss */
.header--blue .hamburger-inner, .header--blue .hamburger-inner::before, .header--blue .hamburger-inner::after {
  background-color: #00449D;
}

/* line 59, src/scss/_vendor/hamburger/_base.scss */
.header--fixed .hamburger-inner, .header--fixed .hamburger-inner::before, .header--fixed .hamburger-inner::after {
  background-color: #fff;
}

/* line 64, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

/* line 70, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::before {
  top: -8px;
}

/* line 74, src/scss/_vendor/hamburger/_base.scss */
.hamburger-inner::after {
  bottom: -8px;
}

/*
   * Squeeze
   */
/* line 6, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* line 10, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

/* line 15, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* line 22, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* line 27, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

/* line 34, src/scss/_vendor/hamburger/types/_squeeze.scss */
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* line 1, src/scss/_generated/_spriteSVG.scss */
.icon-Industry-Standard {
  width: 72px;
  height: 80px;
}

/* line 5, src/scss/_generated/_spriteSVG.scss */
.icon-Leads-Mess {
  width: 63px;
  height: 81px;
}

/* line 9, src/scss/_generated/_spriteSVG.scss */
.icon-Leads-Trash {
  width: 68px;
  height: 81px;
}

/* line 13, src/scss/_generated/_spriteSVG.scss */
.icon-Leads-time {
  width: 73px;
  height: 82px;
}

/* line 17, src/scss/_generated/_spriteSVG.scss */
.icon-affiliate-benefits-1 {
  width: 40px;
  height: 39px;
}

/* line 21, src/scss/_generated/_spriteSVG.scss */
.icon-affiliate-benefits-2 {
  width: 40px;
  height: 40px;
}

/* line 25, src/scss/_generated/_spriteSVG.scss */
.icon-affiliate-benefits-3 {
  width: 44px;
  height: 36px;
}

/* line 29, src/scss/_generated/_spriteSVG.scss */
.icon-affiliate-benefits-4 {
  width: 40px;
  height: 36px;
}

/* line 33, src/scss/_generated/_spriteSVG.scss */
.icon-ai {
  width: 40px;
  height: 40px;
}

/* line 37, src/scss/_generated/_spriteSVG.scss */
.icon-analytics {
  width: 40px;
  height: 40px;
}

/* line 41, src/scss/_generated/_spriteSVG.scss */
.icon-angle {
  width: 30.727px;
  height: 30.727px;
}

/* line 45, src/scss/_generated/_spriteSVG.scss */
.icon-arrow {
  width: 512px;
  height: 512px;
}

/* line 49, src/scss/_generated/_spriteSVG.scss */
.icon-batman {
  width: 36px;
  height: 39px;
}

/* line 53, src/scss/_generated/_spriteSVG.scss */
.icon-battery {
  width: 44px;
  height: 28px;
}

/* line 57, src/scss/_generated/_spriteSVG.scss */
.icon-becoming-1 {
  width: 44px;
  height: 46px;
}

/* line 61, src/scss/_generated/_spriteSVG.scss */
.icon-becoming-2 {
  width: 40px;
  height: 36px;
}

/* line 65, src/scss/_generated/_spriteSVG.scss */
.icon-becoming-3 {
  width: 48px;
  height: 36px;
}

/* line 69, src/scss/_generated/_spriteSVG.scss */
.icon-calendar {
  width: 14px;
  height: 14px;
}

/* line 73, src/scss/_generated/_spriteSVG.scss */
.icon-cancel {
  width: 18px;
  height: 18px;
}

/* line 77, src/scss/_generated/_spriteSVG.scss */
.icon-check {
  width: 45.701px;
  height: 45.7px;
}

/* line 81, src/scss/_generated/_spriteSVG.scss */
.icon-close {
  width: 47.97px;
  height: 47.97px;
}

/* line 85, src/scss/_generated/_spriteSVG.scss */
.icon-cloud {
  width: 44px;
  height: 42px;
}

/* line 89, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-email-1 {
  width: 40px;
  height: 40px;
}

/* line 93, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-email-2 {
  width: 36px;
  height: 40px;
}

/* line 97, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-email-3 {
  width: 40px;
  height: 40px;
}

/* line 101, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-email-4 {
  width: 44px;
  height: 44px;
}

/* line 105, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-pro-1 {
  width: 40px;
  height: 40px;
}

/* line 109, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-pro-2 {
  width: 44px;
  height: 46px;
}

/* line 113, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-pro-3 {
  width: 39px;
  height: 42px;
}

/* line 117, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-pro-4 {
  width: 40px;
  height: 42px;
}

/* line 121, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-slots-1 {
  width: 44px;
  height: 32px;
}

/* line 125, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-slots-2 {
  width: 40px;
  height: 36px;
}

/* line 129, src/scss/_generated/_spriteSVG.scss */
.icon-cs-description-slots-3 {
  width: 43px;
  height: 43px;
}

/* line 133, src/scss/_generated/_spriteSVG.scss */
.icon-description {
  width: 18px;
  height: 18px;
}

/* line 137, src/scss/_generated/_spriteSVG.scss */
.icon-done {
  width: 26px;
  height: 26px;
}

/* line 141, src/scss/_generated/_spriteSVG.scss */
.icon-duration {
  width: 15.999px;
  height: 20px;
}

/* line 145, src/scss/_generated/_spriteSVG.scss */
.icon-email {
  width: 48px;
  height: 36px;
}

/* line 149, src/scss/_generated/_spriteSVG.scss */
.icon-event-name {
  width: 18px;
  height: 18px;
}

/* line 153, src/scss/_generated/_spriteSVG.scss */
.icon-features {
  width: 40px;
  height: 37px;
}

/* line 157, src/scss/_generated/_spriteSVG.scss */
.icon-features-box-0 {
  width: 40px;
  height: 40px;
}

/* line 161, src/scss/_generated/_spriteSVG.scss */
.icon-features-box-1 {
  width: 40px;
  height: 40px;
}

/* line 165, src/scss/_generated/_spriteSVG.scss */
.icon-features-box-2 {
  width: 40px;
  height: 42px;
}

/* line 169, src/scss/_generated/_spriteSVG.scss */
.icon-features-box-3 {
  width: 44px;
  height: 46px;
}

/* line 173, src/scss/_generated/_spriteSVG.scss */
.icon-features-box-4 {
  width: 40px;
  height: 40px;
}

/* line 177, src/scss/_generated/_spriteSVG.scss */
.icon-flag {
  width: 24px;
  height: 28px;
}

/* line 181, src/scss/_generated/_spriteSVG.scss */
.icon-help-accounts {
  width: 48px;
  height: 60px;
}

/* line 185, src/scss/_generated/_spriteSVG.scss */
.icon-help-admin-tools {
  width: 56px;
  height: 56px;
}

/* line 189, src/scss/_generated/_spriteSVG.scss */
.icon-help-analytics {
  width: 60px;
  height: 60px;
}

/* line 193, src/scss/_generated/_spriteSVG.scss */
.icon-help-apps-settings {
  width: 72px;
  height: 60px;
}

/* line 197, src/scss/_generated/_spriteSVG.scss */
.icon-help-calendar {
  width: 60px;
  height: 62px;
}

/* line 201, src/scss/_generated/_spriteSVG.scss */
.icon-help-compaigns {
  width: 60px;
  height: 60px;
}

/* line 205, src/scss/_generated/_spriteSVG.scss */
.icon-help-contacts {
  width: 60px;
  height: 60px;
}

/* line 209, src/scss/_generated/_spriteSVG.scss */
.icon-help-faq {
  width: 64px;
  height: 64px;
}

/* line 213, src/scss/_generated/_spriteSVG.scss */
.icon-help-flows {
  width: 60px;
  height: 60px;
}

/* line 217, src/scss/_generated/_spriteSVG.scss */
.icon-help-invoicing {
  width: 54px;
  height: 60px;
}

/* line 221, src/scss/_generated/_spriteSVG.scss */
.icon-help-pipelines {
  width: 60px;
  height: 56px;
}

/* line 225, src/scss/_generated/_spriteSVG.scss */
.icon-help-search {
  width: 59px;
  height: 59px;
}

/* line 229, src/scss/_generated/_spriteSVG.scss */
.icon-hero-1 {
  width: 23px;
  height: 24px;
}

/* line 233, src/scss/_generated/_spriteSVG.scss */
.icon-hero-2 {
  width: 40px;
  height: 42px;
}

/* line 237, src/scss/_generated/_spriteSVG.scss */
.icon-hero-3 {
  width: 30px;
  height: 30px;
}

/* line 241, src/scss/_generated/_spriteSVG.scss */
.icon-hero-4 {
  width: 35px;
  height: 36px;
}

/* line 245, src/scss/_generated/_spriteSVG.scss */
.icon-hero-5 {
  width: 19px;
  height: 26px;
}

/* line 249, src/scss/_generated/_spriteSVG.scss */
.icon-hero-6 {
  width: 24px;
  height: 22px;
}

/* line 253, src/scss/_generated/_spriteSVG.scss */
.icon-hero-7 {
  width: 38px;
  height: 40px;
}

/* line 257, src/scss/_generated/_spriteSVG.scss */
.icon-hero-8 {
  width: 29px;
  height: 32px;
}

/* line 261, src/scss/_generated/_spriteSVG.scss */
.icon-i-email {
  width: 24px;
  height: 19px;
}

/* line 265, src/scss/_generated/_spriteSVG.scss */
.icon-i-facebook {
  width: 11px;
  height: 23px;
}

/* line 269, src/scss/_generated/_spriteSVG.scss */
.icon-i-linkdin {
  width: 22px;
  height: 21px;
}

/* line 273, src/scss/_generated/_spriteSVG.scss */
.icon-i-share {
  width: 22px;
  height: 22px;
}

/* line 277, src/scss/_generated/_spriteSVG.scss */
.icon-i-twitter {
  width: 21px;
  height: 17px;
}

/* line 281, src/scss/_generated/_spriteSVG.scss */
.icon-invite-away {
  width: 16px;
  height: 15px;
}

/* line 285, src/scss/_generated/_spriteSVG.scss */
.icon-invite-decline {
  width: 18px;
  height: 18px;
}

/* line 289, src/scss/_generated/_spriteSVG.scss */
.icon-invite-online {
  width: 18px;
  height: 18px;
}

/* line 293, src/scss/_generated/_spriteSVG.scss */
.icon-invite-wait {
  width: 18px;
  height: 18px;
}

/* line 297, src/scss/_generated/_spriteSVG.scss */
.icon-leads {
  width: 44px;
  height: 43px;
}

/* line 301, src/scss/_generated/_spriteSVG.scss */
.icon-lightning {
  width: 34px;
  height: 41px;
}

/* line 305, src/scss/_generated/_spriteSVG.scss */
.icon-location {
  width: 16px;
  height: 20px;
}

/* line 309, src/scss/_generated/_spriteSVG.scss */
.icon-login {
  width: 20px;
  height: 20px;
}

/* line 313, src/scss/_generated/_spriteSVG.scss */
.icon-logo {
  width: 145px;
  height: 36px;
}

/* line 317, src/scss/_generated/_spriteSVG.scss */
.icon-money {
  width: 26px;
  height: 36px;
}

/* line 321, src/scss/_generated/_spriteSVG.scss */
.icon-pipelines {
  width: 40px;
  height: 42px;
}

/* line 325, src/scss/_generated/_spriteSVG.scss */
.icon-play {
  width: 69px;
  height: 80px;
}

/* line 329, src/scss/_generated/_spriteSVG.scss */
.icon-play-1 {
  width: 27px;
  height: 33px;
}

/* line 333, src/scss/_generated/_spriteSVG.scss */
.icon-pro {
  width: 40px;
  height: 40px;
}

/* line 337, src/scss/_generated/_spriteSVG.scss */
.icon-proposal-triangle {
  width: 15px;
  height: 18px;
}

/* line 341, src/scss/_generated/_spriteSVG.scss */
.icon-quote {
  width: 35px;
  height: 24px;
}

/* line 345, src/scss/_generated/_spriteSVG.scss */
.icon-request {
  width: 48px;
  height: 36px;
}

/* line 349, src/scss/_generated/_spriteSVG.scss */
.icon-resize {
  width: 40px;
  height: 40px;
}

/* line 353, src/scss/_generated/_spriteSVG.scss */
.icon-right-arrow {
  width: 240.82px;
  height: 240.82px;
}

/* line 357, src/scss/_generated/_spriteSVG.scss */
.icon-robot {
  width: 44px;
  height: 42px;
}

/* line 361, src/scss/_generated/_spriteSVG.scss */
.icon-setting {
  width: 478.7px;
  height: 478.7px;
}

/* line 365, src/scss/_generated/_spriteSVG.scss */
.icon-signup {
  width: 16px;
  height: 20px;
}

/* line 369, src/scss/_generated/_spriteSVG.scss */
.icon-slots {
  width: 36px;
  height: 36px;
}

/* line 373, src/scss/_generated/_spriteSVG.scss */
.icon-sneakers {
  width: 44px;
  height: 42px;
}

/* line 377, src/scss/_generated/_spriteSVG.scss */
.icon-step-1 {
  width: 40px;
  height: 36px;
}

/* line 381, src/scss/_generated/_spriteSVG.scss */
.icon-step-2 {
  width: 43px;
  height: 43px;
}

/* line 385, src/scss/_generated/_spriteSVG.scss */
.icon-step-3 {
  width: 40px;
  height: 42px;
}

/* line 389, src/scss/_generated/_spriteSVG.scss */
.icon-time {
  width: 18.095px;
  height: 19px;
}

/* line 393, src/scss/_generated/_spriteSVG.scss */
.icon-timelines {
  width: 40px;
  height: 36px;
}

/* line 397, src/scss/_generated/_spriteSVG.scss */
.icon-types-icon-0 {
  width: 60px;
  height: 60px;
}

/* line 401, src/scss/_generated/_spriteSVG.scss */
.icon-types-icon-1 {
  width: 60px;
  height: 60px;
}

/* line 405, src/scss/_generated/_spriteSVG.scss */
.icon-types-icon-2 {
  width: 51px;
  height: 62px;
}

/* muli-200 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/muli-v16-latin-200.eot");
  /* IE9 Compat Modes */
  src: local("Muli ExtraLight"), local("Muli-ExtraLight"), url("../fonts/muli-v16-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-200.woff2") format("woff2"), url("../fonts/muli-v16-latin-200.woff") format("woff"), url("../fonts/muli-v16-latin-200.ttf") format("truetype"), url("../fonts/muli-v16-latin-200.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-300 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/muli-v16-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Muli Light"), local("Muli-Light"), url("../fonts/muli-v16-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-300.woff2") format("woff2"), url("../fonts/muli-v16-latin-300.woff") format("woff"), url("../fonts/muli-v16-latin-300.ttf") format("truetype"), url("../fonts/muli-v16-latin-300.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-regular - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/muli-v16-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Muli Regular"), local("Muli-Regular"), url("../fonts/muli-v16-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-regular.woff2") format("woff2"), url("../fonts/muli-v16-latin-regular.woff") format("woff"), url("../fonts/muli-v16-latin-regular.ttf") format("truetype"), url("../fonts/muli-v16-latin-regular.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-600 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/muli-v16-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Muli SemiBold"), local("Muli-SemiBold"), url("../fonts/muli-v16-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-600.woff2") format("woff2"), url("../fonts/muli-v16-latin-600.woff") format("woff"), url("../fonts/muli-v16-latin-600.ttf") format("truetype"), url("../fonts/muli-v16-latin-600.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-700 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/muli-v16-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Muli Bold"), local("Muli-Bold"), url("../fonts/muli-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-700.woff2") format("woff2"), url("../fonts/muli-v16-latin-700.woff") format("woff"), url("../fonts/muli-v16-latin-700.ttf") format("truetype"), url("../fonts/muli-v16-latin-700.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-800 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/muli-v16-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Muli ExtraBold"), local("Muli-ExtraBold"), url("../fonts/muli-v16-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-800.woff2") format("woff2"), url("../fonts/muli-v16-latin-800.woff") format("woff"), url("../fonts/muli-v16-latin-800.ttf") format("truetype"), url("../fonts/muli-v16-latin-800.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* muli-900 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/muli-v16-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Muli Black"), local("Muli-Black"), url("../fonts/muli-v16-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/muli-v16-latin-900.woff2") format("woff2"), url("../fonts/muli-v16-latin-900.woff") format("woff"), url("../fonts/muli-v16-latin-900.ttf") format("truetype"), url("../fonts/muli-v16-latin-900.svg#Muli") format("svg");
  /* Legacy iOS */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-v17-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans-v17-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-300.woff") format("woff"), url("../fonts/open-sans-v17-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v17-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans-v17-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-regular.woff") format("woff"), url("../fonts/open-sans-v17-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans-v17-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/open-sans-v17-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-600.woff") format("woff"), url("../fonts/open-sans-v17-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans-v17-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-v17-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-700.woff") format("woff"), url("../fonts/open-sans-v17-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */
}

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-v17-latin-800.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("../fonts/open-sans-v17-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v17-latin-800.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-800.woff") format("woff"), url("../fonts/open-sans-v17-latin-800.ttf") format("truetype"), url("../fonts/open-sans-v17-latin-800.svg#OpenSans") format("svg");
  /* Legacy iOS */
}

/* line 8, src/scss/_shared/_grid.scss */
.c-grid, .c-grid-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

/* line 20, src/scss/_shared/_grid.scss */
.c-grid {
  max-width: 1070px;
}

/* line 27, src/scss/_shared/_grid.scss */
.c-grid-fluid {
  max-width: 100%;
}

/* line 1, src/scss/_shared/_main-reset.scss */
html,
body {
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

/* line 7, src/scss/_shared/_main-reset.scss */
html.is-hideScroll,
body.is-hideScroll {
  overflow: hidden;
}

/* line 13, src/scss/_shared/_main-reset.scss */
html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* line 19, src/scss/_shared/_main-reset.scss */
body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #fff;
  line-height: 1.42857143;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 33, src/scss/_shared/_main-reset.scss */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* line 38, src/scss/_shared/_main-reset.scss */
body > .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
}

/* line 44, src/scss/_shared/_main-reset.scss */
body > .body--start {
  justify-content: flex-start;
}

/* line 51, src/scss/_shared/_main-reset.scss */
* {
  box-sizing: border-box;
}

/* line 54, src/scss/_shared/_main-reset.scss */
*:before, *:after {
  box-sizing: border-box;
}

/* line 61, src/scss/_shared/_main-reset.scss */
button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

/* line 71, src/scss/_shared/_main-reset.scss */
button,
input {
  overflow: visible;
}

/* line 76, src/scss/_shared/_main-reset.scss */
input[type='text'],
input[type='email'] {
  appearance: none;
}

/* line 81, src/scss/_shared/_main-reset.scss */
button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

/* line 87, src/scss/_shared/_main-reset.scss */
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* line 93, src/scss/_shared/_main-reset.scss */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 99, src/scss/_shared/_main-reset.scss */
textarea {
  overflow: auto;
}

/* line 104, src/scss/_shared/_main-reset.scss */
p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}

/* line 112, src/scss/_shared/_main-reset.scss */
ul {
  margin: 0;
  padding: 0;
}

/* line 117, src/scss/_shared/_main-reset.scss */
ul li {
  list-style: none;
}

/* line 123, src/scss/_shared/_main-reset.scss */
a {
  outline: none;
  text-decoration: none;
  color: #00449D;
}

/* line 128, src/scss/_shared/_main-reset.scss */
a:hover, a:focus {
  text-decoration: none;
}

/* line 135, src/scss/_shared/_main-reset.scss */
input[type="text"] {
  appearance: none;
}

/* line 139, src/scss/_shared/_main-reset.scss */
picture {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 4, src/scss/_shared/_common.scss */
.c-text {
  width: 100%;
  max-width: 804px;
  margin-left: auto;
  margin-right: auto;
  color: #939393;
  line-height: 1.7;
  text-align: center;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 4, src/scss/_shared/_common.scss */
  .c-text {
    font-size: calc(16px + 2 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 4, src/scss/_shared/_common.scss */
  .c-text {
    font-size: 18px;
  }
}

/* line 20, src/scss/_shared/_common.scss */
.c-form__field.is-focus .c-form__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 32, src/scss/_shared/_common.scss */
.c-form__dropdown {
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  z-index: 100;
  position: absolute;
  top: calc(100%);
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 2px solid rgba(0, 68, 157, 0.1);
  border-right: 2px solid rgba(0, 68, 157, 0.1);
  border-bottom: 2px solid rgba(0, 68, 157, 0.1);
}

/* line 51, src/scss/_shared/_common.scss */
.c-form__dropdown ul {
  display: flex;
  flex-direction: column;
}

/* line 56, src/scss/_shared/_common.scss */
.c-form__dropdown ul li a {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 5px 20px;
}

/* line 63, src/scss/_shared/_common.scss */
.c-form__dropdown ul li a span,
.c-form__dropdown ul li a i,
.c-form__dropdown ul li a p {
  white-space: nowrap;
  color: #000;
  font-size: 18px;
  font-style: normal;
}

/* line 72, src/scss/_shared/_common.scss */
.c-form__dropdown ul li a span {
  font-weight: bold;
  color: #00449D;
}

/* line 77, src/scss/_shared/_common.scss */
.c-form__dropdown ul li a i {
  margin-right: 5px;
}

/* line 81, src/scss/_shared/_common.scss */
.c-form__dropdown ul li a p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* line 97, src/scss/_shared/_common.scss */
.slider--save .slider__content-left,
.slider--save .slider__content-right, .slider--finding .slider__content-left,
.slider--finding .slider__content-right {
  display: flex;
  align-items: flex-start;
}

@media screen and (min-width: 1200px) {
  /* line 97, src/scss/_shared/_common.scss */
  .slider--save .slider__content-left,
  .slider--save .slider__content-right, .slider--finding .slider__content-left,
  .slider--finding .slider__content-right {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1199px) {
  /* line 97, src/scss/_shared/_common.scss */
  .slider--save .slider__content-left,
  .slider--save .slider__content-right, .slider--finding .slider__content-left,
  .slider--finding .slider__content-right {
    justify-content: flex-start;
  }
}

/* line 109, src/scss/_shared/_common.scss */
.slider--save .slider__content-left, .slider--finding .slider__content-left {
  padding-left: 15px;
}

@media screen and (min-width: 1200px) {
  /* line 109, src/scss/_shared/_common.scss */
  .slider--save .slider__content-left, .slider--finding .slider__content-left {
    padding-right: 60px;
  }
}

@media screen and (max-width: 1199px) {
  /* line 109, src/scss/_shared/_common.scss */
  .slider--save .slider__content-left, .slider--finding .slider__content-left {
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  /* line 119, src/scss/_shared/_common.scss */
  .slider--save .slider__img, .slider--finding .slider__img {
    transform: translateX(35px);
  }
}

@media screen and (min-width: 1200px) {
  /* line 125, src/scss/_shared/_common.scss */
  .slider--save .slider__pagination, .slider--finding .slider__pagination {
    right: calc(50% + 60px);
  }
}

@media screen and (max-width: 1199px) {
  /* line 125, src/scss/_shared/_common.scss */
  .slider--save .slider__pagination, .slider--finding .slider__pagination {
    left: 0;
    max-width: 40%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  /* line 140, src/scss/_shared/_common.scss */
  .slider--control .slider__content, .slider--manage .slider__content {
    flex-direction: row-reverse;
  }
}

/* line 146, src/scss/_shared/_common.scss */
.slider--control .slider__content-left,
.slider--control .slider__content-right, .slider--manage .slider__content-left,
.slider--manage .slider__content-right {
  display: flex;
  align-items: flex-start;
}

@media screen and (min-width: 1200px) {
  /* line 146, src/scss/_shared/_common.scss */
  .slider--control .slider__content-left,
  .slider--control .slider__content-right, .slider--manage .slider__content-left,
  .slider--manage .slider__content-right {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1199px) {
  /* line 146, src/scss/_shared/_common.scss */
  .slider--control .slider__content-left,
  .slider--control .slider__content-right, .slider--manage .slider__content-left,
  .slider--manage .slider__content-right {
    justify-content: flex-end;
  }
}

/* line 158, src/scss/_shared/_common.scss */
.slider--control .slider__content-left, .slider--manage .slider__content-left {
  padding-right: 15px;
}

@media screen and (min-width: 1200px) {
  /* line 158, src/scss/_shared/_common.scss */
  .slider--control .slider__content-left, .slider--manage .slider__content-left {
    padding-left: 60px;
  }
}

@media screen and (max-width: 1199px) {
  /* line 158, src/scss/_shared/_common.scss */
  .slider--control .slider__content-left, .slider--manage .slider__content-left {
    padding-left: 15px;
  }
}

@media screen and (min-width: 768px) {
  /* line 168, src/scss/_shared/_common.scss */
  .slider--control .slider__img, .slider--manage .slider__img {
    transform: translateX(-35px);
  }
}

@media screen and (min-width: 1200px) {
  /* line 174, src/scss/_shared/_common.scss */
  .slider--control .slider__pagination, .slider--manage .slider__pagination {
    left: calc(50% + 60px);
  }
}

@media screen and (max-width: 1199px) {
  /* line 174, src/scss/_shared/_common.scss */
  .slider--control .slider__pagination, .slider--manage .slider__pagination {
    right: 0;
    max-width: 40%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 187, src/scss/_shared/_common.scss */
.slider__content {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  /* line 187, src/scss/_shared/_common.scss */
  .slider__content {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 1200px) {
  /* line 194, src/scss/_shared/_common.scss */
  .slider__content-left, .slider__content-right {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  /* line 194, src/scss/_shared/_common.scss */
  .slider__content-left, .slider__content-right {
    width: 100% !important;
  }
}

@media screen and (max-width: 1199px) {
  /* line 204, src/scss/_shared/_common.scss */
  .slider__content-left {
    width: 40%;
  }
}

@media screen and (max-width: 1199px) {
  /* line 209, src/scss/_shared/_common.scss */
  .slider__content-right {
    width: 60%;
  }
}

/* line 216, src/scss/_shared/_common.scss */
.slider__text {
  padding-bottom: 40px;
  color: #939393;
  text-align: left;
  line-height: 1.7;
  font-size: 14px;
}

@media screen and (min-width: 320px) {
  /* line 216, src/scss/_shared/_common.scss */
  .slider__text {
    font-size: calc(14px + 5 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 216, src/scss/_shared/_common.scss */
  .slider__text {
    font-size: 19px;
  }
}

@media screen and (max-width: 767px) {
  /* line 216, src/scss/_shared/_common.scss */
  .slider__text {
    padding-bottom: 30px;
  }
}

/* line 226, src/scss/_shared/_common.scss */
.slider__text:last-of-type {
  padding-bottom: 0;
}

/* line 230, src/scss/_shared/_common.scss */
.slider__text-wrapper {
  width: 100%;
  max-width: 460px;
  min-height: 330px;
  padding-top: 30px;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 230, src/scss/_shared/_common.scss */
  .slider__text-wrapper {
    max-width: 100%;
    min-height: 0;
    padding-bottom: 30px;
  }
}

/* line 244, src/scss/_shared/_common.scss */
.slider__img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  /* line 244, src/scss/_shared/_common.scss */
  .slider__img {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 254, src/scss/_shared/_common.scss */
.slider__pagination {
  z-index: 10;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 460px;
}

@media screen and (max-width: 767px) {
  /* line 254, src/scss/_shared/_common.scss */
  .slider__pagination {
    left: 0;
    max-width: 100% !important;
    justify-content: center;
  }
}

/* line 269, src/scss/_shared/_common.scss */
.slider__button {
  cursor: pointer;
  outline: none;
  user-select: none;
}

@media screen and (max-width: 1199px) {
  /* line 269, src/scss/_shared/_common.scss */
  .slider__button {
    display: none !important;
  }
}

/* line 277, src/scss/_shared/_common.scss */
.slider__button-prev {
  display: none !important;
}

/* line 281, src/scss/_shared/_common.scss */
.slider__button-next {
  color: #CD084B;
  text-transform: uppercase;
  letter-spacing: 1.35px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (min-width: 320px) {
  /* line 281, src/scss/_shared/_common.scss */
  .slider__button-next {
    font-size: calc(14px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 281, src/scss/_shared/_common.scss */
  .slider__button-next {
    font-size: 18px;
  }
}

/* line 295, src/scss/_shared/_common.scss */
.address__row {
  color: #8F8F8F;
}

/* line 298, src/scss/_shared/_common.scss */
.address__row p {
  font-size: 14px;
}

@media screen and (max-width: 575px) {
  /* line 298, src/scss/_shared/_common.scss */
  .address__row p {
    text-align: center;
  }
}

/* line 306, src/scss/_shared/_common.scss */
.address__row-0 p {
  min-height: 40px;
  padding-bottom: 15px;
}

/* line 312, src/scss/_shared/_common.scss */
.address__row-1 {
  padding-bottom: 40px;
}

@media screen and (max-width: 575px) {
  /* line 312, src/scss/_shared/_common.scss */
  .address__row-1 {
    padding-bottom: 27px;
  }
}

/* line 319, src/scss/_shared/_common.scss */
.address__row-2 {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 575px) {
  /* line 319, src/scss/_shared/_common.scss */
  .address__row-2 {
    align-items: center;
  }
}

/* line 326, src/scss/_shared/_common.scss */
.address__row-2 a {
  color: #8F8F8F;
  font-size: 14px;
  transition: color 0.3s ease-in-out;
}

/* line 331, src/scss/_shared/_common.scss */
.address__row-2 a:hover {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

/* line 336, src/scss/_shared/_common.scss */
.address__row-2 a:first-of-type {
  padding-bottom: 10px;
  color: #fff;
}

/* line 340, src/scss/_shared/_common.scss */
.address__row-2 a:first-of-type:hover {
  color: #8F8F8F;
}

/* line 353, src/scss/_shared/_common.scss */
.copyright__text {
  color: #8F8F8F;
  font-size: 14px;
}

/* line 1, src/scss/_shared/_viewPortChecker.scss */
.viewport-hide-js,
.viewport-hideTimeline-js {
  opacity: 0;
}

/* line 5, src/scss/_shared/_viewPortChecker.scss */
.viewport-show-js,
.viewport-startTimeline-js {
  opacity: 1;
}

/* line 1, src/scss/_shared/_magnificPopup.scss */
.modal {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

/* line 10, src/scss/_shared/_magnificPopup.scss */
.modal#viewAgreement, .modal#declineEvent, .modal#rescheduleEvent, .modal#contactOrganizer, .modal#makeWithdrawal, .modal#paymentHistory, .modal#startFreeTrial, .modal#shareArticle, .modal#enterprisePlans, .modal#updateInfo {
  overflow: hidden;
  max-width: 1040px;
  border-radius: 10px;
  background-color: #ffffff;
}

/* line 25, src/scss/_shared/_magnificPopup.scss */
.modal#viewAgreement .mfp-close, .modal#declineEvent .mfp-close, .modal#rescheduleEvent .mfp-close, .modal#contactOrganizer .mfp-close, .modal#makeWithdrawal .mfp-close, .modal#paymentHistory .mfp-close, .modal#startFreeTrial .mfp-close, .modal#shareArticle .mfp-close, .modal#enterprisePlans .mfp-close, .modal#updateInfo .mfp-close {
  display: none;
}

/* line 30, src/scss/_shared/_magnificPopup.scss */
.modal#startFreeTrial, .modal#enterprisePlans {
  overflow: unset;
}

/* line 35, src/scss/_shared/_magnificPopup.scss */
.modal#paymentHistory, .modal#viewAgreement {
  height: 80vh;
}

/* line 40, src/scss/_shared/_magnificPopup.scss */
.modal#shareArticle {
  max-width: 728px;
}

/* line 45, src/scss/_shared/_magnificPopup.scss */
.mfp-container {
  padding: 15px;
  border-radius: 10px;
}

/* line 55, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

/* line 61, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

/* line 67, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

/* line 71, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* line 77, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

/* line 81, src/scss/_shared/_magnificPopup.scss */
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* line 92, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
}

/* line 98, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

/* line 104, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateX(0);
}

/* line 108, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* line 114, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in.mfp-removing .mfp-with-anim {
  transform: translateX(100%);
  opacity: 0;
}

/* line 118, src/scss/_shared/_magnificPopup.scss */
.mfp-slide-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* line 2, src/scss/_shared/_swiper.scss */
.swiper-container {
  width: 100%;
  padding-bottom: 25px;
}

/* line 6, src/scss/_shared/_swiper.scss */
.swiper-container.schedulerData {
  overflow: unset;
  width: calc(100% + 40px);
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 6, src/scss/_shared/_swiper.scss */
  .swiper-container.schedulerData {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 22, src/scss/_shared/_swiper.scss */
.swiper-pagination {
  position: relative;
  display: flex;
  align-items: center;
}

/* line 27, src/scss/_shared/_swiper.scss */
.swiper-pagination-bullet {
  opacity: 1;
  outline: none;
  width: 15px;
  height: 15px;
  margin: 0 !important;
  margin-right: 15px !important;
  background-color: #C8C8C8;
}

@media screen and (min-width: 768px) {
  /* line 36, src/scss/_shared/_swiper.scss */
  .swiper-pagination-bullet:last-of-type {
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  /* line 36, src/scss/_shared/_swiper.scss */
  .swiper-pagination-bullet:last-of-type {
    margin-right: 0 !important;
  }
}

/* line 45, src/scss/_shared/_swiper.scss */
.swiper-pagination-bullet-active {
  background-color: #CD084B;
}

/* line 3, src/scss/_shared/_objectFitImages.scss */
.object-fit {
  width: 100%;
  height: 100%;
}

/* line 7, src/scss/_shared/_objectFitImages.scss */
.object-fit--coverCenter {
  object-fit: cover;
  object-position: center;
}

/* line 12, src/scss/_shared/_objectFitImages.scss */
.object-fit--containCenter {
  object-fit: contain;
  object-position: center;
}

/* line 3, src/scss/_shared/_iconSVG.scss */
.icon-svg {
  display: inline-block;
  fill: currentColor;
}

/* line 4, src/scss/_macros/_logotype.scss */
.logo--footer {
  padding-bottom: 25px;
}

/* line 8, src/scss/_macros/_logotype.scss */
.logo__link {
  display: flex;
}

@media screen and (max-width: 575px) {
  /* line 8, src/scss/_macros/_logotype.scss */
  .logo__link {
    justify-content: center;
  }
}

/* line 15, src/scss/_macros/_logotype.scss */
.logo__link:hover svg {
  fill: #cd084b;
  transition: fill 0.3s ease-in-out;
}

/* line 19, src/scss/_macros/_logotype.scss */
.header--blue .logo__link:hover svg {
  fill: #cd084b;
}

/* line 23, src/scss/_macros/_logotype.scss */
.header--fixed .logo__link:hover svg {
  fill: #cd084b;
}

/* line 29, src/scss/_macros/_logotype.scss */
.logo__link svg {
  fill: #fff;
  transition: fill 0.3s ease-in-out;
}

/* line 33, src/scss/_macros/_logotype.scss */
.header--blue .logo__link svg {
  fill: #00449D;
}

/* line 37, src/scss/_macros/_logotype.scss */
.header--iteration .logo__link svg {
  fill: #00449D;
}

/* line 41, src/scss/_macros/_logotype.scss */
.header--fixed .logo__link svg {
  fill: #fff;
}

/* line 4, src/scss/_macros/_nav.scss */
.nav--header {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* line 9, src/scss/_macros/_nav.scss */
.nav--header .nav__list {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 9, src/scss/_macros/_nav.scss */
  .nav--header .nav__list {
    flex-direction: column;
    width: 100%;
  }
}

/* line 19, src/scss/_macros/_nav.scss */
.nav--header .nav__item.is-active .nav__link {
  color: #000;
  transition: color 0.3s ease-in-out;
}

/* line 23, src/scss/_macros/_nav.scss */
.header__wrapper-right--inner .nav--header .nav__item.is-active .nav__link {
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.3s ease-in-out;
}

/* line 28, src/scss/_macros/_nav.scss */
.nav--header .nav__item.is-active .nav__link:after {
  background-color: #CD084B;
  transition: background-color 0.3s ease-in-out;
}

/* line 33, src/scss/_macros/_nav.scss */
.header--fixed .nav--header .nav__item.is-active .nav__link {
  color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 33, src/scss/_macros/_nav.scss */
  .header--fixed .nav--header .nav__item.is-active .nav__link {
    color: #000000;
    transition: color 0.3s ease-in-out;
  }
}

/* line 44, src/scss/_macros/_nav.scss */
.nav--header .nav__link {
  position: relative;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  color: #fff;
  font-weight: bold;
  font-family: 'Muli', sans-serif;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 991px) {
  /* line 44, src/scss/_macros/_nav.scss */
  .nav--header .nav__link {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 44, src/scss/_macros/_nav.scss */
  .nav--header .nav__link {
    align-items: center;
    justify-content: center;
    min-height: 50px;
    color: #000;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.025);
  }
}

/* line 66, src/scss/_macros/_nav.scss */
.nav--header .nav__link:after {
  content: "";
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 66, src/scss/_macros/_nav.scss */
  .nav--header .nav__link:after {
    top: calc(100% - 3px);
  }
}

/* line 80, src/scss/_macros/_nav.scss */
.header--blue .nav--header .nav__link {
  color: #00449D;
}

/* line 84, src/scss/_macros/_nav.scss */
.header--fixed .nav--header .nav__link {
  color: #fff;
}

/* line 91, src/scss/_macros/_nav.scss */
.nav--footer .nav__list {
  padding-bottom: 55px;
}

@media screen and (max-width: 575px) {
  /* line 91, src/scss/_macros/_nav.scss */
  .nav--footer .nav__list {
    padding-bottom: 27px;
  }
}

/* line 98, src/scss/_macros/_nav.scss */
.nav--footer .nav__link {
  display: flex;
  align-items: center;
  min-height: 40px;
  color: #8F8F8F;
  font-size: 14px;
}

@media screen and (max-width: 575px) {
  /* line 98, src/scss/_macros/_nav.scss */
  .nav--footer .nav__link {
    justify-content: center;
  }
}

/* line 108, src/scss/_macros/_nav.scss */
.nav--footer .nav__link:hover {
  color: #fff;
}

/* line 114, src/scss/_macros/_nav.scss */
.nav__link {
  transition: color 0.3s ease-in-out;
}

/* line 117, src/scss/_macros/_nav.scss */
.nav__link:hover {
  transition: color 0.3s ease-in-out;
}

/* line 3, src/scss/_macros/_title.scss */
.c-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

/* line 9, src/scss/_macros/_title.scss */
.c-title--features {
  padding-bottom: 71px;
}

/* line 12, src/scss/_macros/_title.scss */
.c-title--request {
  padding-bottom: 50px;
}

/* line 14, src/scss/_macros/_title.scss */
.c-title--request .c-title__text {
  color: #fff;
}

/* line 18, src/scss/_macros/_title.scss */
.c-title--cloud {
  padding-bottom: 20px;
}

/* line 21, src/scss/_macros/_title.scss */
.c-title--analytics {
  padding-bottom: 27px;
}

/* line 24, src/scss/_macros/_title.scss */
.c-title--pipelines {
  padding-bottom: 28px;
}

/* line 27, src/scss/_macros/_title.scss */
.c-title--leads {
  padding-bottom: 18px;
}

/* line 30, src/scss/_macros/_title.scss */
.c-title--ai {
  padding-bottom: 34px;
}

/* line 33, src/scss/_macros/_title.scss */
.c-title--apps {
  padding-bottom: 30px;
}

/* line 36, src/scss/_macros/_title.scss */
.c-title--mind {
  padding-bottom: 30px;
}

/* line 40, src/scss/_macros/_title.scss */
.c-title__text {
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0.8px;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  font-size: 25px;
}

@media screen and (min-width: 320px) {
  /* line 40, src/scss/_macros/_title.scss */
  .c-title__text {
    font-size: calc(25px + 15 * (100vw - 320px) / 1280);
  }
}

@media screen and (min-width: 1600px) {
  /* line 40, src/scss/_macros/_title.scss */
  .c-title__text {
    font-size: 40px;
  }
}

/* line 52, src/scss/_macros/_title.scss */
.c-title__icon-wrap {
  padding-bottom: 18px;
}

/* line 55, src/scss/_macros/_title.scss */
.c-title__icon-wrap svg {
  fill: #cd084b;
}

/* line 59, src/scss/_macros/_title.scss */
.c-title__icon-wrap .icon-request {
  fill: rgba(255, 255, 255, 0.4);
}

/* line 3, src/scss/_macros/_subtitle.scss */
.c-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 8, src/scss/_macros/_subtitle.scss */
.c-subtitle__text {
  padding-bottom: 20px;
  color: #CD084B;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 2.25px;
  font-weight: bold;
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  /* line 8, src/scss/_macros/_subtitle.scss */
  .c-subtitle__text {
    font-size: calc(12px + 6 * (100vw - 320px) / 1280);
  }
}

@media screen and (min-width: 1600px) {
  /* line 8, src/scss/_macros/_subtitle.scss */
  .c-subtitle__text {
    font-size: 18px;
  }
}

/* line 3, src/scss/_macros/_btn.scss */
.c-btn {
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 230px;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  border: 2px solid #cd084b;
  background-color: transparent;
  color: #cd084b;
  text-transform: uppercase;
  letter-spacing: 1.35px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: 'Muli', sans-serif;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 26, src/scss/_macros/_btn.scss */
.c-btn:hover {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 30, src/scss/_macros/_btn.scss */
.c-btn__wrapper {
  display: flex;
  align-items: center;
}

/* line 34, src/scss/_macros/_btn.scss */
.c-btn__wrapper--center {
  justify-content: center;
}

/* line 39, src/scss/_macros/_btn.scss */
.c-btn--features {
  margin-left: auto;
  margin-right: auto;
}

/* line 44, src/scss/_macros/_btn.scss */
.c-btn--apps {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

/* line 50, src/scss/_macros/_btn.scss */
.c-btn--mind {
  min-width: 232px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

/* line 58, src/scss/_macros/_btn.scss */
.c-btn--border:hover {
  background-color: #cd084b;
  color: #fff;
}

/* line 64, src/scss/_macros/_btn.scss */
.c-btn--bg {
  background-color: #cd084b;
  color: #fff;
}

/* line 68, src/scss/_macros/_btn.scss */
.c-btn--bg:hover {
  background-color: transparent;
  color: #cd084b;
}

/* line 4, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal {
  height: 100%;
}

/* line 8, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 8, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 8, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 25, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__header.is-shadow {
  box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* line 31, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__body {
  position: relative;
  height: calc(100% - 190px);
}

@media screen and (max-width: 1279px) {
  /* line 31, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__body {
    height: calc(100% - 140px);
  }
}

@media screen and (max-width: 767px) {
  /* line 31, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__body {
    height: calc(100% - 165px);
  }
}

/* line 42, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__body.is-bottom:after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 49, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__body:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 60, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__body-scroll {
  overflow-y: auto;
  position: relative;
  height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

@media screen and (max-width: 1279px) {
  /* line 60, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__body-scroll {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 60, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__body-scroll {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 77, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 77, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 77, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 90, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 95, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 95, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 95, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 110, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__info {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

/* line 115, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__info-text {
  display: flex;
  align-items: center;
  font-size: 19px;
  font-weight: 900;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 115, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__info-text {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 115, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__info-text {
    flex-wrap: wrap;
    font-size: 15px;
  }
}

/* line 130, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__info-text span {
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  /* line 130, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__info-text span {
    width: 100%;
  }
}

/* line 137, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__info-text a {
  font-weight: normal;
  color: #00449d;
}

@media screen and (max-width: 767px) {
  /* line 137, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__info-text a {
    width: 100%;
  }
}

/* line 147, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__desc {
  padding-bottom: 50px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 147, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__desc {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 147, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__desc {
    font-size: 15px;
  }
}

/* line 159, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__desc span {
  font-weight: 900;
}

/* line 164, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__subtitle {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.15;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 164, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__subtitle {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 164, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__subtitle {
    font-size: 19px;
  }
}

/* line 178, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__subtitle--first {
  padding-top: 0;
}

/* line 183, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__text {
  padding-bottom: 20px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 183, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__text {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 183, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__text {
    font-size: 15px;
  }
}

/* line 195, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__text a {
  color: #00449d;
}

/* line 200, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__list {
  padding-left: 40px;
}

@media screen and (max-width: 1279px) {
  /* line 200, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__list {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 200, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__list {
    padding-left: 15px;
  }
}

/* line 209, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__list li {
  padding-bottom: 20px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 209, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__list li {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 209, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__list li {
    font-size: 15px;
  }
}

/* line 221, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__list li a {
  color: #00449d;
}

/* line 225, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__list li span {
  font-weight: 900;
  text-transform: uppercase;
}

/* line 233, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__input input {
  outline: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 88, src/scss/_utilities/_mixin.scss */
#viewAgreement .c-modal .c-modal__input input.placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 89, src/scss/_utilities/_mixin.scss */
#viewAgreement .c-modal .c-modal__input input:-moz-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 90, src/scss/_utilities/_mixin.scss */
#viewAgreement .c-modal .c-modal__input input::-moz-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 91, src/scss/_utilities/_mixin.scss */
#viewAgreement .c-modal .c-modal__input input:-ms-input-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 92, src/scss/_utilities/_mixin.scss */
#viewAgreement .c-modal .c-modal__input input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 252, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__input-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 252, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__input-wrapper {
    flex-wrap: wrap;
  }
}

/* line 262, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__input-left, #viewAgreement .c-modal .c-modal__input-right {
  width: 100%;
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 262, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__input-left, #viewAgreement .c-modal .c-modal__input-right {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 271, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__input-left {
    padding-bottom: 8px;
  }
}

/* line 277, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__input-title {
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

/* line 286, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__btn {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 286, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__btn {
    flex-wrap: wrap;
  }
}

/* line 294, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__btn > a {
  width: auto;
  max-width: unset;
  padding-left: 50px;
  padding-right: 50px;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  /* line 294, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__btn > a {
    width: 100%;
    max-width: 100%;
    height: 60px;
    margin-right: 0;
  }
}

/* line 307, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__btn > a.c-btn--bg {
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 311, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__btn > a.c-btn--bg:hover {
  color: #cd084b;
  box-shadow: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 318, src/scss/_macros/_modal.scss */
#viewAgreement .c-modal .c-modal__btn > a:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  /* line 318, src/scss/_macros/_modal.scss */
  #viewAgreement .c-modal .c-modal__btn > a:last-of-type {
    margin-top: 15px;
  }
}

/* line 329, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal {
  height: 100%;
}

/* line 333, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 333, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 333, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 350, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__header.is-shadow {
  box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* line 356, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__body {
  position: relative;
  height: calc(100% - 140px);
}

@media screen and (max-width: 1279px) {
  /* line 356, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__body {
    height: calc(100% - 100px);
  }
}

@media screen and (max-width: 767px) {
  /* line 356, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__body {
    height: calc(100% - 165px);
  }
}

/* line 366, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__body-content {
  position: relative;
  padding: 0 30px 30px;
}

@media screen and (max-width: 767px) {
  /* line 366, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__body-content {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 374, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__body-content.is-scroll {
  box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
}

/* line 379, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__body-scroll {
  overflow-y: auto;
  position: relative;
  height: calc(100% - 90px);
  max-height: 590px;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 767px) {
  /* line 379, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__body-scroll {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* line 393, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 393, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 393, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 406, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 411, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 411, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 411, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 426, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__btn {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 426, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__btn {
    flex-wrap: wrap;
  }
}

/* line 433, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__btn > a {
  width: auto;
  max-width: unset;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 767px) {
  /* line 433, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__btn > a {
    width: 100%;
    max-width: 100%;
    height: 60px;
  }
}

/* line 447, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-head {
  overflow: hidden;
  z-index: 10;
  position: relative;
  border-radius: 10px;
  background-color: #f8fafc;
}

/* line 454, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-head .c-modal__table-th {
  min-height: 60px;
  padding: 5px 10px 5px 30px;
}

@media screen and (max-width: 767px) {
  /* line 454, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-head .c-modal__table-th {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* line 466, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-body .c-modal__table-tr {
  border-bottom: 1px solid #e6edf4;
}

/* line 470, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-body .c-modal__table-tr:first-of-type > div {
  align-items: flex-start;
  min-height: 59px;
}

/* line 475, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-body .c-modal__table-tr:last-of-type {
  border-bottom: none;
}

/* line 480, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-body .c-modal__table-td {
  min-height: 89px;
  padding: 5px 10px 5px 50px;
}

@media screen and (max-width: 767px) {
  /* line 480, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-body .c-modal__table-td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* line 488, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-body .c-modal__table-td:nth-of-type(1) {
  padding-left: 10px;
}

@media screen and (max-width: 767px) {
  /* line 488, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-body .c-modal__table-td:nth-of-type(1) {
    padding-left: 5px;
  }
}

/* line 498, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-footer {
  padding-top: 30px;
  padding-bottom: 60px;
}

/* line 503, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-tr {
  display: flex;
  align-items: center;
}

/* line 508, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-th, #paymentHistory .c-modal .c-modal__table-td {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  /* line 508, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-th, #paymentHistory .c-modal .c-modal__table-td {
    text-align: center;
    justify-content: center;
  }
}

/* line 523, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-th p {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 523, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-th p {
    font-size: 17px;
  }
}

@media screen and (max-width: 575px) {
  /* line 523, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-th p {
    font-size: 14px;
  }
}

@media screen and (max-width: 374px) {
  /* line 523, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-th p {
    font-size: 12px;
  }
}

/* line 541, src/scss/_macros/_modal.scss */
#paymentHistory .c-modal .c-modal__table-td span {
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 541, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-td span {
    font-size: 17px;
  }
}

@media screen and (max-width: 424px) {
  /* line 541, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-td span {
    font-size: 14px;
  }
}

@media screen and (max-width: 374px) {
  /* line 541, src/scss/_macros/_modal.scss */
  #paymentHistory .c-modal .c-modal__table-td span {
    font-size: 12px;
  }
}

/* line 562, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 562, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 562, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 580, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 580, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 580, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 592, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 592, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 592, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 605, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 610, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 610, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 610, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 626, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__input input {
  outline: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 88, src/scss/_utilities/_mixin.scss */
#makeWithdrawal .c-modal .c-modal__input input.placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 89, src/scss/_utilities/_mixin.scss */
#makeWithdrawal .c-modal .c-modal__input input:-moz-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 90, src/scss/_utilities/_mixin.scss */
#makeWithdrawal .c-modal .c-modal__input input::-moz-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 91, src/scss/_utilities/_mixin.scss */
#makeWithdrawal .c-modal .c-modal__input input:-ms-input-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 92, src/scss/_utilities/_mixin.scss */
#makeWithdrawal .c-modal .c-modal__input input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
}

/* line 645, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 50px;
}

/* line 652, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__input-left, #makeWithdrawal .c-modal .c-modal__input-right {
  width: 100%;
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 652, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__input-left, #makeWithdrawal .c-modal .c-modal__input-right {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 661, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__input-left {
    padding-bottom: 8px;
  }
}

/* line 667, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__input-title {
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

/* line 676, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__btn {
  display: flex;
  align-items: center;
}

/* line 680, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__btn > a {
  width: auto;
  max-width: unset;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 767px) {
  /* line 680, src/scss/_macros/_modal.scss */
  #makeWithdrawal .c-modal .c-modal__btn > a {
    width: 100%;
    max-width: 100%;
    height: 60px;
  }
}

/* line 691, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__btn > a.c-btn--bg {
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 695, src/scss/_macros/_modal.scss */
#makeWithdrawal .c-modal .c-modal__btn > a.c-btn--bg:hover {
  color: #cd084b;
  box-shadow: none;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 708, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 708, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 708, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 726, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 726, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 726, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 738, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 738, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 738, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 751, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 756, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 756, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 756, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 771, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__desc {
  padding-bottom: 47px;
  font-size: 19px;
  line-height: 1.3;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 771, src/scss/_macros/_modal.scss */
  #declineEvent .c-modal .c-modal__desc {
    font-size: 17px;
  }
}

/* line 781, src/scss/_macros/_modal.scss */
#declineEvent .c-modal .c-modal__btn {
  box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
}

/* line 789, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 789, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 789, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 807, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 807, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 807, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 819, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 819, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 819, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 832, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 837, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 837, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 837, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 852, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
}

@media screen and (max-width: 1023px) {
  /* line 852, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__row {
    justify-content: flex-start;
  }
}

/* line 862, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__row:last-of-type {
  padding-bottom: 50px;
}

/* line 867, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__col {
  width: 100%;
}

/* line 870, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__col-1 {
  width: 40%;
  padding-right: 40px;
}

@media screen and (max-width: 1023px) {
  /* line 870, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-1 {
    width: 55%;
  }
}

@media screen and (max-width: 767px) {
  /* line 870, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-1 {
    width: 100%;
    padding-right: 0;
  }
}

/* line 882, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__col-2, #rescheduleEvent .c-modal .c-modal__col-3 {
  width: 30%;
}

/* line 887, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__col-2 {
  padding-right: 40px;
}

@media screen and (max-width: 1023px) {
  /* line 887, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-2 {
    width: 45%;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 887, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-2 {
    width: 100%;
  }
  /* line 896, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-2 .c-form__group--request {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1023px) {
  /* line 902, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-3 {
    width: 100%;
  }
  /* line 906, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__col-3 .c-form__label {
    display: none;
  }
}

/* line 913, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

/* line 923, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio:checked + .c-modal__radio-label .c-modal__radio-circle {
  border-color: #00449d;
  background-color: #00449d;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 928, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio:checked + .c-modal__radio-label .c-modal__radio-circle:before {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

/* line 936, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 56px;
}

@media screen and (max-width: 1023px) {
  /* line 936, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__radio-wrapper {
    min-height: unset;
  }
}

/* line 947, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-col {
  position: relative;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  /* line 947, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__radio-col {
    padding-bottom: 10px;
  }
}

/* line 955, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* line 961, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-label p:nth-of-type(1) {
  width: 50px;
}

/* line 964, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-label p:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 972, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: transparent;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 984, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-circle:before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

/* line 996, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__radio-text {
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  /* line 996, src/scss/_macros/_modal.scss */
  #rescheduleEvent .c-modal .c-modal__radio-text {
    font-size: 16px;
  }
}

/* line 1005, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-modal__btn {
  box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
}

/* line 1011, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

/* line 1017, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__label--hidden {
  opacity: 0;
}

/* line 1025, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__field--date .c-form__input {
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: transparent;
}

/* line 1031, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__field--date .c-form__input-wrapper p {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
}

/* line 1042, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__field--date .c-form__input-wrapper p svg {
  width: 18px;
  height: 18px;
}

/* line 1053, src/scss/_macros/_modal.scss */
#rescheduleEvent .c-modal .c-form__select {
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

/* line 1063, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 1063, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1063, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 1081, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 1081, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1081, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 1093, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__row {
  padding-left: 0;
  padding-right: 0;
}

/* line 1098, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__textarea {
  resize: none;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: none;
  color: #000;
  font-size: 18px;
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 1108, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__textarea-wrapper {
  width: 100%;
  height: 200px;
}

/* line 1114, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__field {
  width: 100%;
}

/* line 1117, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__field--textarea {
  width: 100%;
}

/* line 1122, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

/* line 1129, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__input {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 1133, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__file {
  z-index: 5;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 1142, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__file-cover {
  display: flex;
  position: relative;
}

@media screen and (max-width: 374px) {
  /* line 1142, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body .c-form__file-cover {
    width: 100%;
  }
}

/* line 1150, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__file-btn {
  width: 210px;
}

@media screen and (max-width: 767px) {
  /* line 1150, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body .c-form__file-btn {
    width: 156px;
  }
}

@media screen and (max-width: 374px) {
  /* line 1150, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body .c-form__file-btn {
    width: 100%;
  }
}

/* line 1160, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__file-input {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  background-color: #fff;
  padding-left: 30px;
  box-shadow: none;
  border: none;
  border-radius: 0;
  font-size: 19px;
  line-height: 1.3;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 1160, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body .c-form__file-input {
    padding-left: 15px;
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) {
  /* line 1160, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__body .c-form__file-input {
    display: none;
  }
}

/* line 1181, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__body .c-form__file-wrapper {
  display: flex;
}

/* line 1188, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 1188, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1188, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 1201, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 1206, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 1206, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1206, src/scss/_macros/_modal.scss */
  #contactOrganizer .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 1221, src/scss/_macros/_modal.scss */
#contactOrganizer .c-modal .c-modal__btn {
  max-width: 156px;
  margin-top: 20px;
  box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
}

/* line 1229, src/scss/_macros/_modal.scss */
#updateInfo .c-modal {
  height: 100%;
}

/* line 1232, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .affiliate-appForm__heading--pt {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  /* line 1232, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .affiliate-appForm__heading--pt {
    padding-top: 75px;
  }
}

/* line 1240, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 1240, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1240, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 1257, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__header.is-shadow {
  box-shadow: 0px 11px 22px rgba(72, 130, 223, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

/* line 1263, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 1263, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__body {
    padding: 0 40px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1263, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__body {
    padding: 0 15px 15px;
  }
}

/* line 1273, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 1273, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1273, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 1286, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 1291, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 1291, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1291, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 1306, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__tax {
  font-size: 19px;
  font-weight: 800;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 1306, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__tax {
    font-size: 17px;
  }
}

/* line 1315, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__tax span {
  font-weight: normal;
}

/* line 1320, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__desc {
  padding-bottom: 40px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 1320, src/scss/_macros/_modal.scss */
  #updateInfo .c-modal .c-modal__desc {
    font-size: 17px;
  }
}

/* line 1329, src/scss/_macros/_modal.scss */
#updateInfo .c-modal .c-modal__desc--wpb {
  padding-bottom: 20px;
}

/* line 1339, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__header,
#startFreeTrial .c-modal .c-modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 1339, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__header,
  #startFreeTrial .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1339, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__header,
  #startFreeTrial .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 1356, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__header > div:nth-of-type(2),
#startFreeTrial .c-modal .c-modal__header > div:nth-of-type(2) {
  padding-top: 15px;
}

@media screen and (max-width: 767px) {
  /* line 1356, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__header > div:nth-of-type(2),
  #startFreeTrial .c-modal .c-modal__header > div:nth-of-type(2) {
    padding-top: 8px;
  }
}

/* line 1365, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__body,
#startFreeTrial .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 1365, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__body,
  #startFreeTrial .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1365, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__body,
  #startFreeTrial .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 1377, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__title,
#startFreeTrial .c-modal .c-modal__title {
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 1377, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__title,
  #startFreeTrial .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1377, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__title,
  #startFreeTrial .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 1391, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__desc,
#startFreeTrial .c-modal .c-modal__desc {
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
}

@media screen and (max-width: 1279px) {
  /* line 1391, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__desc,
  #startFreeTrial .c-modal .c-modal__desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1391, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__desc,
  #startFreeTrial .c-modal .c-modal__desc {
    font-size: 16px;
  }
}

/* line 1403, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__close,
#startFreeTrial .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 1408, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__close svg,
#startFreeTrial .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 1408, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__close svg,
  #startFreeTrial .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1408, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__close svg,
  #startFreeTrial .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 1423, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-modal__btn,
#startFreeTrial .c-modal .c-modal__btn {
  max-width: 230px;
  margin-top: 20px;
  box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
}

@media screen and (max-width: 767px) {
  /* line 1423, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-modal__btn,
  #startFreeTrial .c-modal .c-modal__btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 1436, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__row--request,
#startFreeTrial .c-modal .c-form__row--request {
  padding-left: 0;
  padding-right: 0;
}

/* line 1441, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__label,
#startFreeTrial .c-modal .c-form__label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

/* line 1447, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__label--hidden,
#startFreeTrial .c-modal .c-form__label--hidden {
  opacity: 0;
}

/* line 1453, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--dropdown,
#startFreeTrial .c-modal .c-form__field--dropdown {
  display: block;
}

@media screen and (max-width: 767px) {
  /* line 1453, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-form__field--dropdown,
  #startFreeTrial .c-modal .c-form__field--dropdown {
    display: none;
  }
}

/* line 1459, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--dropdown-select,
#startFreeTrial .c-modal .c-form__field--dropdown-select {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 1459, src/scss/_macros/_modal.scss */
  #enterprisePlans .c-modal .c-form__field--dropdown-select,
  #startFreeTrial .c-modal .c-form__field--dropdown-select {
    display: block;
    margin-bottom: 30px;
  }
}

/* line 1467, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--dropdown .scheduler__time-dropdown-content,
#startFreeTrial .c-modal .c-form__field--dropdown .scheduler__time-dropdown-content {
  width: 360px;
}

/* line 1472, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--dropdown .scheduler__time-dropdown-content--hideInput > div:nth-of-type(1),
#startFreeTrial .c-modal .c-form__field--dropdown .scheduler__time-dropdown-content--hideInput > div:nth-of-type(1) {
  display: none;
}

/* line 1482, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--date .c-form__input,
#startFreeTrial .c-modal .c-form__field--date .c-form__input {
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: transparent;
}

/* line 1488, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--date .c-form__input-wrapper p,
#startFreeTrial .c-modal .c-form__field--date .c-form__input-wrapper p {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
}

/* line 1499, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__field--date .c-form__input-wrapper p svg,
#startFreeTrial .c-modal .c-form__field--date .c-form__input-wrapper p svg {
  width: 18px;
  height: 18px;
}

/* line 1510, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__input,
#startFreeTrial .c-modal .c-form__input {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 1514, src/scss/_macros/_modal.scss */
#enterprisePlans .c-modal .c-form__select,
#startFreeTrial .c-modal .c-form__select {
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

/* line 1524, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 140px;
  padding: 50px 60px 40px;
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 1524, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__header {
    min-height: 100px;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1524, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__header {
    min-height: 135px;
    padding: 30px 15px;
  }
}

/* line 1541, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__header > div:nth-of-type(2) {
  padding-top: 15px;
}

@media screen and (max-width: 767px) {
  /* line 1541, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__header > div:nth-of-type(2) {
    padding-top: 8px;
  }
}

/* line 1550, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__body {
  padding: 0 60px 60px;
}

@media screen and (max-width: 1279px) {
  /* line 1550, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__body {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1550, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 1562, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__title {
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 1562, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1562, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__title {
    font-size: 28px;
  }
}

/* line 1576, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__desc {
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
}

@media screen and (max-width: 1279px) {
  /* line 1576, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1576, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__desc {
    font-size: 16px;
  }
}

/* line 1588, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 1593, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__close svg {
  width: 26px;
  height: 26px;
  fill: #000;
}

@media screen and (max-width: 1279px) {
  /* line 1593, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__close svg {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1593, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__close svg {
    width: 20px;
    height: 20px;
  }
}

/* line 1608, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__btn {
  min-width: 230px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  /* line 1608, src/scss/_macros/_modal.scss */
  #shareArticle .c-modal .c-modal__btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 1619, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__btn--share {
  border-color: #CD084B;
  background-color: #CD084B;
  color: #ffffff;
}

/* line 1625, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__btn--cancel {
  border-color: #CD084B;
  color: #CD084B;
}

/* line 1630, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__btn:not(:last-of-type) {
  margin-right: 15px;
}

/* line 1634, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-modal__btn-wrapper {
  display: flex;
  align-items: center;
}

/* line 1642, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-form__label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

/* line 1648, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-form__label--hidden {
  opacity: 0;
}

/* line 1653, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-form__input {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 1657, src/scss/_macros/_modal.scss */
#shareArticle .c-modal .c-form__select {
  border-radius: 10px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

/* line 3, src/scss/_macros/_form.scss */
.c-form {
  display: flex;
  flex-direction: column;
}

/* line 7, src/scss/_macros/_form.scss */
.c-form--assistance, .c-form--r-main {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* line 17, src/scss/_macros/_form.scss */
.c-form__row {
  display: flex;
}

/* line 20, src/scss/_macros/_form.scss */
.c-form__row--request {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 991px) {
  /* line 20, src/scss/_macros/_form.scss */
  .c-form__row--request {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 20, src/scss/_macros/_form.scss */
  .c-form__row--request {
    flex-wrap: wrap;
  }
}

/* line 31, src/scss/_macros/_form.scss */
.c-form__row--request .c-form__row-left {
  padding-right: 20px;
}

@media screen and (max-width: 991px) {
  /* line 31, src/scss/_macros/_form.scss */
  .c-form__row--request .c-form__row-left {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 31, src/scss/_macros/_form.scss */
  .c-form__row--request .c-form__row-left {
    padding-right: 0;
  }
}

/* line 40, src/scss/_macros/_form.scss */
.c-form__row--request .c-form__row-right {
  padding-left: 20px;
}

@media screen and (max-width: 991px) {
  /* line 40, src/scss/_macros/_form.scss */
  .c-form__row--request .c-form__row-right {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 40, src/scss/_macros/_form.scss */
  .c-form__row--request .c-form__row-right {
    padding-left: 0;
  }
}

/* line 51, src/scss/_macros/_form.scss */
.c-form__row-left, .c-form__row-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 51, src/scss/_macros/_form.scss */
  .c-form__row-left, .c-form__row-right {
    width: 100%;
  }
}

/* line 60, src/scss/_macros/_form.scss */
.c-form__group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 575px) {
  /* line 65, src/scss/_macros/_form.scss */
  .c-form__group--request {
    flex-wrap: wrap;
  }
}

/* line 70, src/scss/_macros/_form.scss */
.c-form__group--request .c-form__group-col {
  width: 50%;
}

@media screen and (max-width: 575px) {
  /* line 70, src/scss/_macros/_form.scss */
  .c-form__group--request .c-form__group-col {
    width: 100%;
  }
}

/* line 76, src/scss/_macros/_form.scss */
.c-form__group--request .c-form__group-col-0 {
  padding-right: 10px;
}

@media screen and (max-width: 575px) {
  /* line 76, src/scss/_macros/_form.scss */
  .c-form__group--request .c-form__group-col-0 {
    padding-right: 0;
  }
}

/* line 83, src/scss/_macros/_form.scss */
.c-form__group--request .c-form__group-col-1 {
  padding-left: 10px;
}

@media screen and (max-width: 575px) {
  /* line 83, src/scss/_macros/_form.scss */
  .c-form__group--request .c-form__group-col-1 {
    padding-left: 0;
  }
}

/* line 98, src/scss/_macros/_form.scss */
.c-form__field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

/* line 109, src/scss/_macros/_form.scss */
.c-form__field--btn {
  width: 100%;
  padding-top: 30px;
}

/* line 115, src/scss/_macros/_form.scss */
.c-form__label {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 6px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

/* line 127, src/scss/_macros/_form.scss */
.c-form__input-wrapper, .c-form__textarea-wrapper {
  position: relative;
  display: flex;
}

/* line 133, src/scss/_macros/_form.scss */
.c-form__input, .c-form__textarea {
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 143, src/scss/_macros/_form.scss */
.c-form__input {
  padding: 0 20px;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  color: #000;
  font-size: 18px;
}

/* line 151, src/scss/_macros/_form.scss */
.c-form--assistance .c-form__input,
.c-form--r-main .c-form__input {
  border: 2px solid rgba(0, 68, 157, 0.1);
}

/* line 156, src/scss/_macros/_form.scss */
.c-form__input-wrapper {
  min-height: 60px;
}

/* line 161, src/scss/_macros/_form.scss */
.c-form__select {
  position: relative;
  height: 100%;
  min-height: 60px;
  border-radius: 10px;
  background-color: #fff;
}

/* line 168, src/scss/_macros/_form.scss */
.c-form__select select {
  opacity: 0;
  cursor: pointer;
  outline: none;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
  box-shadow: none;
}

/* line 182, src/scss/_macros/_form.scss */
.c-form__select-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  line-height: 60px;
  width: calc(100% - 52px);
  padding-left: 20px;
  color: #000;
  font-size: 18px;
}

/* line 198, src/scss/_macros/_form.scss */
.c-form__select-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 52px;
  height: 100%;
}

/* line 208, src/scss/_macros/_form.scss */
.c-form__select-icon svg {
  width: 12px;
}

/* line 222, src/scss/_macros/_form.scss */
.c-form__notify {
  display: none;
}

/* line 226, src/scss/_macros/_form.scss */
.c-form__btn {
  min-width: 256px;
  margin-left: auto;
  margin-right: auto;
}

/* line 3, src/scss/_components/_header.scss */
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

/* line 10, src/scss/_components/_header.scss */
.header:before {
  opacity: 0;
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00449D;
  background: linear-gradient(to right, #00449D, #001632);
  transition: opacity 0.3s ease-in-out;
}

/* line 24, src/scss/_components/_header.scss */
.header--fixed:before {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 30, src/scss/_components/_header.scss */
  .header--iteration {
    background-color: #ffffff;
  }
}

/* line 37, src/scss/_components/_header.scss */
.header--iteration.header--fixed .header__close svg {
  fill: #fff;
}

/* line 44, src/scss/_components/_header.scss */
.header--iteration .header__wrapper {
  padding-top: 0;
}

/* line 48, src/scss/_components/_header.scss */
.header--iteration .header__wrapper-left .header__close {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 48, src/scss/_components/_header.scss */
  .header--iteration .header__wrapper-left .header__close {
    display: flex;
  }
}

/* line 56, src/scss/_components/_header.scss */
.header--iteration .header__wrapper-right {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  /* line 56, src/scss/_components/_header.scss */
  .header--iteration .header__wrapper-right {
    display: none;
  }
}

/* line 65, src/scss/_components/_header.scss */
.header--iteration .header__close {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

/* line 73, src/scss/_components/_header.scss */
.header--iteration .header__close svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

/* line 82, src/scss/_components/_header.scss */
.header--affiliate {
  background-color: #ffffff;
}

/* line 86, src/scss/_components/_header.scss */
.header--affiliate .header__wrapper {
  position: relative;
}

/* line 91, src/scss/_components/_header.scss */
.header--affiliate:before {
  opacity: 0 !important;
}

/* line 95, src/scss/_components/_header.scss */
.header--affiliate .affiliate-appSetup__progress {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 105, src/scss/_components/_header.scss */
.header--affiliate.header--fixed {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
}

/* line 109, src/scss/_components/_header.scss */
.header--affiliate.header--fixed .affiliate-appSetup__progress {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* line 116, src/scss/_components/_header.scss */
.header .c-grid {
  max-width: 100%;
}

@media screen and (min-width: 1366px) {
  /* line 116, src/scss/_components/_header.scss */
  .header .c-grid {
    padding-left: 70px;
    padding-right: 70px;
  }
}

/* line 124, src/scss/_components/_header.scss */
.header__wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 75px;
  padding-top: 19px;
  transition: min-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 124, src/scss/_components/_header.scss */
  .header__wrapper {
    padding-top: 0;
    min-height: 55px;
  }
}

/* line 137, src/scss/_components/_header.scss */
.header--fixed .header__wrapper {
  min-height: 65px;
  padding-top: 0;
  transition: min-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 137, src/scss/_components/_header.scss */
  .header--fixed .header__wrapper {
    min-height: 55px;
  }
}

/* line 146, src/scss/_components/_header.scss */
.header__wrapper-left {
  width: 211px;
}

@media screen and (max-width: 991px) {
  /* line 146, src/scss/_components/_header.scss */
  .header__wrapper-left {
    width: 170px;
  }
}

@media screen and (max-width: 767px) {
  /* line 146, src/scss/_components/_header.scss */
  .header__wrapper-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

/* line 158, src/scss/_components/_header.scss */
.header__wrapper-left--full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* line 164, src/scss/_components/_header.scss */
.header__wrapper-left--full > .header__wrapper-inside {
  display: flex;
  align-items: center;
}

/* line 168, src/scss/_components/_header.scss */
.header__wrapper-left--full > .header__wrapper-inside .header__get {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 168, src/scss/_components/_header.scss */
  .header__wrapper-left--full > .header__wrapper-inside .header__get {
    margin-left: 15px;
  }
}

/* line 177, src/scss/_components/_header.scss */
.header__wrapper-left .hamburger {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 177, src/scss/_components/_header.scss */
  .header__wrapper-left .hamburger {
    display: block;
  }
}

/* line 185, src/scss/_components/_header.scss */
.header__wrapper-right {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 211px);
}

@media screen and (max-width: 991px) {
  /* line 185, src/scss/_components/_header.scss */
  .header__wrapper-right {
    max-width: calc(100% - 170px);
  }
}

@media screen and (max-width: 767px) {
  /* line 185, src/scss/_components/_header.scss */
  .header__wrapper-right {
    display: none;
    z-index: 100;
    overflow-y: auto;
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 55px);
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.985);
  }
  /* line 206, src/scss/_components/_header.scss */
  .header__wrapper-right.is-open {
    display: block;
    animation-fill-mode: both;
    animation-duration: 0.45s;
    animation-name: c-zoomIn;
  }
  /* line 212, src/scss/_components/_header.scss */
  .header__wrapper-right.is-open .nav__link,
  .header__wrapper-right.is-open .header__link {
    color: #00449D;
  }
}

/* line 219, src/scss/_components/_header.scss */
.header__wrapper-right > div {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 219, src/scss/_components/_header.scss */
  .header__wrapper-right > div {
    flex-direction: column;
  }
}

/* line 226, src/scss/_components/_header.scss */
.header__wrapper-right > div:first-of-type {
  width: 100%;
  max-width: calc(100% - 211px);
}

@media screen and (max-width: 991px) {
  /* line 226, src/scss/_components/_header.scss */
  .header__wrapper-right > div:first-of-type {
    max-width: calc(100% - 170px);
  }
}

@media screen and (max-width: 767px) {
  /* line 226, src/scss/_components/_header.scss */
  .header__wrapper-right > div:first-of-type {
    max-width: 100%;
  }
}

/* line 237, src/scss/_components/_header.scss */
.header__wrapper-right > div:last-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 211px;
}

@media screen and (max-width: 991px) {
  /* line 237, src/scss/_components/_header.scss */
  .header__wrapper-right > div:last-of-type {
    width: 170px;
  }
}

@media screen and (max-width: 767px) {
  /* line 237, src/scss/_components/_header.scss */
  .header__wrapper-right > div:last-of-type {
    width: 100%;
  }
}

/* line 253, src/scss/_components/_header.scss */
.header__link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-family: 'Muli', sans-serif;
}

@media screen and (max-width: 767px) {
  /* line 253, src/scss/_components/_header.scss */
  .header__link {
    justify-content: center;
    width: 100%;
    min-height: 50px;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.025);
  }
}

/* line 267, src/scss/_components/_header.scss */
.header--blue .header__link {
  color: #00449D;
}

/* line 271, src/scss/_components/_header.scss */
.header--fixed .header__link {
  color: #fff;
}

/* line 276, src/scss/_components/_header.scss */
.header__link:hover svg {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

/* line 280, src/scss/_components/_header.scss */
.header--blue .header__link:hover svg {
  fill: #00449d;
}

/* line 284, src/scss/_components/_header.scss */
.header--fixed .header__link:hover svg {
  fill: white;
}

/* line 290, src/scss/_components/_header.scss */
.header__link-1 {
  border-bottom: none;
}

/* line 294, src/scss/_components/_header.scss */
.header__link svg {
  fill: rgba(255, 255, 255, 0.4);
  margin-right: 12px;
  transition: fill 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 294, src/scss/_components/_header.scss */
  .header__link svg {
    display: none;
  }
}

/* line 302, src/scss/_components/_header.scss */
.header--blue .header__link svg {
  fill: rgba(0, 68, 157, 0.4);
}

/* line 306, src/scss/_components/_header.scss */
.header--fixed .header__link svg {
  fill: rgba(255, 255, 255, 0.4);
}

/* line 312, src/scss/_components/_header.scss */
.header__get {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 219px;
  height: 60px;
  border: none !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.35px;
  text-align: center;
  color: #cd084b;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  /* line 312, src/scss/_components/_header.scss */
  .header__get {
    max-width: 150px;
    height: 40px;
    font-size: 14px;
    letter-spacing: 0.35px;
  }
}

@media screen and (max-width: 324px) {
  /* line 312, src/scss/_components/_header.scss */
  .header__get {
    max-width: 120px;
  }
}

/* line 336, src/scss/_components/_header.scss */
.header__get--blue {
  color: #00449d;
}

/* line 340, src/scss/_components/_header.scss */
.header__get--blue:hover svg {
  fill: #cd084b;
}

/* line 345, src/scss/_components/_header.scss */
.header__get--blue svg {
  fill: #00449d;
}

/* line 350, src/scss/_components/_header.scss */
.header__get span {
  padding-right: 10px;
}

/* line 354, src/scss/_components/_header.scss */
.header__get svg {
  width: 20px;
  height: 20px;
  transform: scale(-1);
  will-change: transform;
  transition: transform 0.3s ease-in-out;
}

/* line 362, src/scss/_components/_header.scss */
.header__get:hover {
  background-color: transparent;
  color: #cd084b;
}

/* line 366, src/scss/_components/_header.scss */
.header__get:hover svg {
  transform: scale(-1) translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

/* line 372, src/scss/_components/_header.scss */
.header--fixed .header__get {
  height: 50px;
  border-color: #fff;
  color: #fff;
}

/* line 377, src/scss/_components/_header.scss */
.header--fixed .header__get svg {
  fill: #fff;
}

/* line 383, src/scss/_components/_header.scss */
.header__setting {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

/* line 389, src/scss/_components/_header.scss */
.header__setting svg {
  width: 30px;
  height: 30px;
  fill: #00449d;
}

/* line 394, src/scss/_components/_header.scss */
.header--fixed .header__setting svg {
  fill: #fff;
}

@keyframes c-zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

/* line 412, src/scss/_components/_header.scss */
.zoomIn {
  animation-name: zoomIn;
}

/* line 3, src/scss/_components/_footer.scss */
.footer {
  background-color: #191919;
}

/* line 6, src/scss/_components/_footer.scss */
.footer__wrapper {
  padding-top: 75px;
  padding-bottom: 107px;
}

@media screen and (max-width: 575px) {
  /* line 6, src/scss/_components/_footer.scss */
  .footer__wrapper {
    padding-top: 37px;
    padding-bottom: 53px;
  }
}

/* line 15, src/scss/_components/_footer.scss */
.footer__row {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  /* line 19, src/scss/_components/_footer.scss */
  .footer__row-0 {
    flex-wrap: wrap;
  }
}

/* line 25, src/scss/_components/_footer.scss */
.footer__row-1 {
  justify-content: center;
  padding-top: 24px;
}

/* line 32, src/scss/_components/_footer.scss */
.footer__col-0 {
  width: 326px;
}

@media screen and (max-width: 991px) {
  /* line 32, src/scss/_components/_footer.scss */
  .footer__col-0 {
    width: 236px;
  }
}

@media screen and (max-width: 575px) {
  /* line 32, src/scss/_components/_footer.scss */
  .footer__col-0 {
    padding-bottom: 27px;
  }
}

/* line 42, src/scss/_components/_footer.scss */
.footer__col-1, .footer__col-2 {
  width: 100%;
  max-width: calc((100% - (326px + 126px)) / 2);
  padding-right: 15px;
}

@media screen and (max-width: 991px) {
  /* line 42, src/scss/_components/_footer.scss */
  .footer__col-1, .footer__col-2 {
    max-width: calc((100% - (236px + 126px)) / 2);
  }
}

/* line 52, src/scss/_components/_footer.scss */
.footer__col-3 {
  width: 126px;
}

@media screen and (max-width: 767px) {
  /* line 56, src/scss/_components/_footer.scss */
  .footer__col-0, .footer__col-1, .footer__col-2, .footer__col-3 {
    width: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 575px) {
  /* line 56, src/scss/_components/_footer.scss */
  .footer__col-0, .footer__col-1, .footer__col-2, .footer__col-3 {
    width: 100%;
    max-width: 100%;
  }
}

/* line 71, src/scss/_components/_footer.scss */
.footer__title {
  min-height: 30px;
  padding-bottom: 28px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 575px) {
  /* line 71, src/scss/_components/_footer.scss */
  .footer__title {
    text-align: center;
  }
}

/* line 3, src/scss/_components/homepage/_main.scss */
.main {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/homepage/_main.scss */
.main:before {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-main.png");
  background-repeat: no-repeat;
  background-position: center 151%;
  background-size: cover;
}

/* line 25, src/scss/_components/homepage/_main.scss */
.main__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: calc(100% - 102px);
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 39, src/scss/_components/homepage/_main.scss */
.main__bg-inner {
  width: 100%;
  height: 100%;
  background: #00449D;
  background: linear-gradient(to right, #00449D, #001632);
}

/* line 46, src/scss/_components/homepage/_main.scss */
.main__wrapper {
  z-index: 1;
  position: relative;
  padding-top: 146px;
}

@media screen and (max-width: 767px) {
  /* line 46, src/scss/_components/homepage/_main.scss */
  .main__wrapper {
    padding-top: 100px;
  }
}

/* line 55, src/scss/_components/homepage/_main.scss */
.main__subtitle, .main__title {
  text-align: center;
  font-family: 'Muli', sans-serif;
}

/* line 61, src/scss/_components/homepage/_main.scss */
.main__subtitle {
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: 2.25px;
  font-weight: bold;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 61, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    font-size: calc(16px + 2 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 61, src/scss/_components/homepage/_main.scss */
  .main__subtitle {
    font-size: 18px;
  }
}

/* line 70, src/scss/_components/homepage/_main.scss */
.main__title {
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 39px;
  color: #fff;
  line-height: 1.2;
  font-weight: 200;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 70, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 70, src/scss/_components/homepage/_main.scss */
  .main__title {
    font-size: 50px;
  }
}

/* line 83, src/scss/_components/homepage/_main.scss */
.main__btn {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}

/* line 90, src/scss/_components/homepage/_main.scss */
.main__video {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  min-height: 320px;
}

@media screen and (min-width: 320px) {
  /* line 90, src/scss/_components/homepage/_main.scss */
  .main__video {
    min-height: calc(320px + 152 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 90, src/scss/_components/homepage/_main.scss */
  .main__video {
    min-height: 472px;
  }
}

@media screen and (max-width: 767px) {
  /* line 90, src/scss/_components/homepage/_main.scss */
  .main__video {
    min-height: unset;
  }
}

/* line 110, src/scss/_components/homepage/_main.scss */
.main__video-wrapper {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

/* line 120, src/scss/_components/homepage/_main.scss */
.main__video-wrapper:hover .main__video-progress {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}

/* line 126, src/scss/_components/homepage/_main.scss */
.main__video-wrapper:before {
  z-index: 0;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-video-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* line 141, src/scss/_components/homepage/_main.scss */
.main__video-btn {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 141, src/scss/_components/homepage/_main.scss */
  .main__video-btn {
    width: 125px;
    height: 125px;
  }
}

/* line 160, src/scss/_components/homepage/_main.scss */
.main__video-btn:hover svg {
  transform: scale(0.9);
  transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 160, src/scss/_components/homepage/_main.scss */
  .main__video-btn:hover svg {
    transform: scale(0.75);
  }
}

/* line 169, src/scss/_components/homepage/_main.scss */
.main__video-btn svg {
  margin-left: 5px;
  fill: #003F91;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 169, src/scss/_components/homepage/_main.scss */
  .main__video-btn svg {
    transform: scale(0.85);
  }
}

/* line 180, src/scss/_components/homepage/_main.scss */
.main__video-progress {
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 41px;
  width: calc(100% - 100px);
  height: 10px;
  transform: translateY(100px);
  margin-left: auto;
  margin-right: auto;
  border-radius: 7px;
  background-color: #E1E1E1;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 180, src/scss/_components/homepage/_main.scss */
  .main__video-progress {
    width: calc(100% - 30px);
    bottom: 30px;
  }
}

/* line 201, src/scss/_components/homepage/_main.scss */
.main__video-progress span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #CD084B;
  transition: width 0.3s ease-in-out;
}

/* line 3, src/scss/_components/homepage/_ai.scss */
.ai {
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (min-width: 768px) {
  /* line 8, src/scss/_components/homepage/_ai.scss */
  .ai .c-grid-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 15, src/scss/_components/homepage/_ai.scss */
.ai__wrapper {
  padding-top: 99px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/homepage/_ai.scss */
  .ai__wrapper {
    padding-top: 49px;
  }
}

/* line 23, src/scss/_components/homepage/_ai.scss */
.ai__row {
  display: flex;
}

@media screen and (max-width: 1199px) {
  /* line 23, src/scss/_components/homepage/_ai.scss */
  .ai__row {
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  /* line 23, src/scss/_components/homepage/_ai.scss */
  .ai__row {
    flex-wrap: wrap;
  }
}

/* line 32, src/scss/_components/homepage/_ai.scss */
.ai__row-left, .ai__row-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 32, src/scss/_components/homepage/_ai.scss */
  .ai__row-left, .ai__row-right {
    width: 100%;
  }
}

/* line 40, src/scss/_components/homepage/_ai.scss */
.ai__row-left {
  padding-right: 70px;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  /* line 40, src/scss/_components/homepage/_ai.scss */
  .ai__row-left {
    padding-right: 0;
    padding-left: 0;
  }
}

/* line 50, src/scss/_components/homepage/_ai.scss */
.ai__text {
  text-align: left;
}

/* line 53, src/scss/_components/homepage/_ai.scss */
.ai__text-wrapper {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  padding-top: 46px;
}

@media screen and (max-width: 767px) {
  /* line 53, src/scss/_components/homepage/_ai.scss */
  .ai__text-wrapper {
    max-width: 100%;
    padding-top: 0;
    margin-bottom: 30px;
  }
}

/* line 65, src/scss/_components/homepage/_ai.scss */
.ai__text--pb {
  padding-bottom: 29px;
}

/* line 68, src/scss/_components/homepage/_ai.scss */
.ai__text--pb:last-of-type {
  padding-bottom: 38px;
}

/* line 74, src/scss/_components/homepage/_ai.scss */
.ai__btn {
  display: flex;
}

/* line 77, src/scss/_components/homepage/_ai.scss */
.ai__btn .c-btn {
  min-width: 232px;
}

/* line 82, src/scss/_components/homepage/_ai.scss */
.ai__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  /* line 82, src/scss/_components/homepage/_ai.scss */
  .ai__img {
    position: relative;
    right: -15px;
  }
}

/* line 3, src/scss/_components/homepage/_leads.scss */
.leads {
  overflow: hidden;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (min-width: 768px) {
  /* line 7, src/scss/_components/homepage/_leads.scss */
  .leads .c-grid-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 14, src/scss/_components/homepage/_leads.scss */
.leads__wrapper {
  z-index: 1;
  position: relative;
  padding-top: 99px;
  padding-bottom: 7px;
}

@media screen and (max-width: 767px) {
  /* line 14, src/scss/_components/homepage/_leads.scss */
  .leads__wrapper {
    padding-top: 50px;
  }
}

/* line 24, src/scss/_components/homepage/_leads.scss */
.leads__row {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 24, src/scss/_components/homepage/_leads.scss */
  .leads__row {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

/* line 31, src/scss/_components/homepage/_leads.scss */
.leads__row-left, .leads__row-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 31, src/scss/_components/homepage/_leads.scss */
  .leads__row-left, .leads__row-right {
    width: 100%;
  }
}

/* line 39, src/scss/_components/homepage/_leads.scss */
.leads__row-right {
  padding-left: 60px;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  /* line 39, src/scss/_components/homepage/_leads.scss */
  .leads__row-right {
    padding-left: 0;
  }
}

/* line 48, src/scss/_components/homepage/_leads.scss */
.leads__text {
  text-align: left;
}

/* line 51, src/scss/_components/homepage/_leads.scss */
.leads__text-wrapper {
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  padding-top: 62px;
}

@media screen and (max-width: 767px) {
  /* line 51, src/scss/_components/homepage/_leads.scss */
  .leads__text-wrapper {
    max-width: 100%;
    padding-top: 0;
    margin-bottom: 30px;
  }
}

/* line 63, src/scss/_components/homepage/_leads.scss */
.leads__text--pb {
  padding-bottom: 30px;
}

/* line 66, src/scss/_components/homepage/_leads.scss */
.leads__text--pb:last-of-type {
  padding-bottom: 39px;
}

/* line 72, src/scss/_components/homepage/_leads.scss */
.leads__btn {
  display: flex;
}

/* line 75, src/scss/_components/homepage/_leads.scss */
.leads__btn .c-btn {
  min-width: 232px;
}

/* line 80, src/scss/_components/homepage/_leads.scss */
.leads__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 80, src/scss/_components/homepage/_leads.scss */
  .leads__img {
    position: relative;
    left: -15px;
  }
}

/* line 3, src/scss/_components/homepage/_pipelines.scss */
.pipelines {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/homepage/_pipelines.scss */
.pipelines .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* line 11, src/scss/_components/homepage/_pipelines.scss */
.pipelines .c-grid-fluid img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 20, src/scss/_components/homepage/_pipelines.scss */
.pipelines__wrapper {
  z-index: 1;
  position: relative;
}

/* line 24, src/scss/_components/homepage/_pipelines.scss */
.pipelines__wrapper-0 {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  /* line 24, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__wrapper-0 {
    padding-top: 50px;
  }
}

/* line 33, src/scss/_components/homepage/_pipelines.scss */
.pipelines__wrapper-2 {
  padding-bottom: 118px;
}

@media screen and (max-width: 767px) {
  /* line 33, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__wrapper-2 {
    padding-bottom: 59px;
  }
}

/* line 41, src/scss/_components/homepage/_pipelines.scss */
.pipelines__slider {
  position: relative;
}

/* line 44, src/scss/_components/homepage/_pipelines.scss */
.pipelines__slider-item {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 52, src/scss/_components/homepage/_pipelines.scss */
.pipelines__slider-item.is-show {
  display: block;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-name: c-fadeIn;
}

/* line 59, src/scss/_components/homepage/_pipelines.scss */
.pipelines__slider-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 69, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 12px;
  margin-left: 25px;
  margin-right: 25px;
  color: #939393;
  font-weight: bold;
  text-align: center;
  transition: color 0.3s ease-in-out;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (min-width: 320px) {
  /* line 69, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs {
    font-size: calc(12px + 6 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 69, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs {
    font-size: 18px;
  }
}

@media screen and (min-width: 320px) {
  /* line 69, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs {
    margin-left: calc(5px + 20 * (100vw - 320px) / 1120);
    margin-right: calc(5px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 69, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  /* line 69, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs {
    width: 100%;
    max-width: calc(100% / 3);
  }
}

/* line 91, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs:first-of-type {
  margin-left: 0;
}

/* line 94, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs:last-of-type {
  margin-right: 0;
}

/* line 98, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transform: translateX(-100%);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 110, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs.is-active {
  color: #000;
  transition: color 0.3s ease-in-out;
}

/* line 114, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs.is-active:after {
  background-color: #000;
  transform: translateX(0);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 121, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs:hover {
  color: #000;
  transition: color 0.3s ease-in-out;
}

/* line 127, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-head {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 133, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-body {
  position: relative;
  width: 100%;
  max-width: 824px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 13px;
}

/* line 142, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-body > div {
  opacity: 1;
  z-index: -1;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 191px;
  padding: 35px 45px;
  border-radius: 13px;
  background-color: rgba(242, 245, 250, 0.5);
  transform: scale(0.95);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  transition: bottom 0.5s ease-in-out, transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

@media screen and (min-width: 320px) {
  /* line 142, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs-body > div {
    padding-top: calc(25px + 10 * (100vw - 320px) / 1120);
    padding-bottom: calc(25px + 10 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 142, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs-body > div {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 320px) {
  /* line 142, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs-body > div {
    padding-left: calc(30px + 15 * (100vw - 320px) / 1120);
    padding-right: calc(30px + 15 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 142, src/scss/_components/homepage/_pipelines.scss */
  .pipelines__tabs-body > div {
    padding-left: 45px;
    padding-right: 45px;
  }
}

/* line 160, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-body > div.is-active {
  display: flex;
  z-index: 1;
  position: relative;
  bottom: 0;
  transform: scale(1);
  background-color: #f2f5fa;
  transition: bottom 0.5s ease-in-out, transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

/* line 169, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-body > div.is-active p {
  opacity: 1;
  display: block;
  transition: opacity 0.5s ease-in-out;
}

/* line 176, src/scss/_components/homepage/_pipelines.scss */
.pipelines__tabs-body > div p {
  opacity: 0;
  display: none;
  line-height: 1.8;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

@keyframes c-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* line 3, src/scss/_components/homepage/_analytics.scss */
.analytics {
  overflow: hidden;
  background-color: #F8FAFC;
}

/* line 7, src/scss/_components/homepage/_analytics.scss */
.analytics__wrapper {
  padding-top: 98px;
  padding-bottom: 113px;
}

@media screen and (max-width: 767px) {
  /* line 7, src/scss/_components/homepage/_analytics.scss */
  .analytics__wrapper {
    padding-top: 49px;
    padding-bottom: 50px;
  }
}

/* line 16, src/scss/_components/homepage/_analytics.scss */
.analytics__text {
  padding-bottom: 60px;
}

@media screen and (max-width: 575px) {
  /* line 16, src/scss/_components/homepage/_analytics.scss */
  .analytics__text {
    padding-bottom: 30px;
  }
}

/* line 23, src/scss/_components/homepage/_analytics.scss */
.analytics__row {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 991px) {
  /* line 23, src/scss/_components/homepage/_analytics.scss */
  .analytics__row {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 33, src/scss/_components/homepage/_analytics.scss */
.analytics__row-left, .analytics__row-right {
  width: 50%;
}

@media screen and (max-width: 991px) {
  /* line 33, src/scss/_components/homepage/_analytics.scss */
  .analytics__row-left, .analytics__row-right {
    width: 100%;
  }
}

/* line 41, src/scss/_components/homepage/_analytics.scss */
.analytics__row-right {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  /* line 41, src/scss/_components/homepage/_analytics.scss */
  .analytics__row-right {
    margin-top: 30px;
  }
}

/* line 50, src/scss/_components/homepage/_analytics.scss */
.analytics__card {
  width: 100%;
  max-width: 430px;
}

/* line 56, src/scss/_components/homepage/_analytics.scss */
.analytics__card-0 .analytics__card-box {
  padding: 30px 30px 30px 60px;
}

/* line 59, src/scss/_components/homepage/_analytics.scss */
.analytics__card-0 .analytics__card-box--inner {
  left: 0;
}

/* line 62, src/scss/_components/homepage/_analytics.scss */
.analytics__card-0 .analytics__card-box--outer {
  left: 30px;
}

/* line 66, src/scss/_components/homepage/_analytics.scss */
.analytics__card-0 .analytics__card-circle {
  left: 44px;
}

/* line 70, src/scss/_components/homepage/_analytics.scss */
.analytics__card-0 .analytics__card-circle:hover svg {
  animation-name: swing;
}

/* line 80, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-box {
  padding: 30px 60px 30px 30px;
}

/* line 83, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-box--inner {
  right: 0;
}

/* line 86, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-box--outer {
  right: 30px;
}

/* line 90, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-circle {
  left: 15px;
}

/* line 94, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-circle:hover svg {
  animation-name: tada;
}

/* line 99, src/scss/_components/homepage/_analytics.scss */
.analytics__card-1 .analytics__card-wrapper {
  flex-direction: column;
}

/* line 105, src/scss/_components/homepage/_analytics.scss */
.analytics__card-box {
  position: relative;
  width: 100%;
  height: 280px;
  margin-bottom: 45px;
}

/* line 111, src/scss/_components/homepage/_analytics.scss */
.analytics__card-box--inner {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 410px;
  height: 240px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

/* line 124, src/scss/_components/homepage/_analytics.scss */
.analytics__card-box--outer {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

/* line 137, src/scss/_components/homepage/_analytics.scss */
.analytics__card-circle {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  bottom: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00439B;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 10px solid #F8F8F8;
}

/* line 151, src/scss/_components/homepage/_analytics.scss */
.analytics__card-circle svg {
  fill: #fff;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

/* line 159, src/scss/_components/homepage/_analytics.scss */
.analytics__card-wrapper {
  z-index: 1;
  position: relative;
  display: flex;
}

/* line 165, src/scss/_components/homepage/_analytics.scss */
.analytics__card-left {
  width: 110px;
}

/* line 168, src/scss/_components/homepage/_analytics.scss */
.analytics__card-left > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #E9E9E9;
}

/* line 177, src/scss/_components/homepage/_analytics.scss */
.analytics__card-left > div svg {
  fill: #CD084B;
}

/* line 183, src/scss/_components/homepage/_analytics.scss */
.analytics__card-right {
  width: 100%;
  max-width: calc(100% - 110px);
  padding-top: 18px;
}

/* line 189, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line {
  border-radius: 4px;
  background-color: #E9E9E9;
}

/* line 193, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-0 {
  height: 20px;
  margin-bottom: 14px;
}

/* line 197, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-1 {
  width: 50%;
  height: 20px;
  margin-bottom: 47px;
}

/* line 202, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-2 {
  width: 100%;
  max-width: 122px;
  height: 12px;
}

/* line 207, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-3 {
  width: 100%;
  max-width: 154px;
  height: 12px;
}

/* line 212, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-4 {
  height: 20px;
  margin-bottom: 14px;
}

/* line 216, src/scss/_components/homepage/_analytics.scss */
.analytics__card-line-5 {
  width: 100%;
  max-width: 260px;
  height: 12px;
}

/* line 224, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
}

/* line 229, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li:last-child {
  padding-bottom: 0;
}

/* line 234, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li > div:first-child {
  width: 50px;
}

/* line 237, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #CD084B;
}

/* line 246, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li > div:first-child span svg {
  width: 16px;
  fill: #fff;
}

/* line 252, src/scss/_components/homepage/_analytics.scss */
.analytics__card-list li > div:last-child {
  width: 100%;
  max-width: calc(100% - 50px);
}

/* line 260, src/scss/_components/homepage/_analytics.scss */
.analytics__card-graph {
  margin-bottom: 23px;
}

/* line 263, src/scss/_components/homepage/_analytics.scss */
.analytics__card-graph img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 272, src/scss/_components/homepage/_analytics.scss */
.analytics__card-title {
  display: flex;
  align-items: center;
  padding-left: 60px;
  letter-spacing: 0.6px;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (min-width: 320px) {
  /* line 272, src/scss/_components/homepage/_analytics.scss */
  .analytics__card-title {
    font-size: calc(18px + 6 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 272, src/scss/_components/homepage/_analytics.scss */
  .analytics__card-title {
    font-size: 24px;
  }
}

/* line 3, src/scss/_components/homepage/_cloud.scss */
.cloud {
  position: relative;
  border-bottom: 1px solid #D8D8D8;
}

/* line 7, src/scss/_components/homepage/_cloud.scss */
.cloud__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 15, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-0 {
  overflow: hidden;
}

/* line 19, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img {
  position: absolute;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 575px) {
  /* line 19, src/scss/_components/homepage/_cloud.scss */
  .cloud__bg-img {
    transform: scale(0.5);
  }
}

/* line 27, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-0 {
  top: 52px;
  left: 473px;
}

@media screen and (max-width: 575px) {
  /* line 27, src/scss/_components/homepage/_cloud.scss */
  .cloud__bg-img-0 {
    left: 20%;
  }
}

/* line 34, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-1 {
  left: -96px;
  bottom: 77px;
}

/* line 38, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-2 {
  left: 72px;
  bottom: 97px;
  mix-blend-mode: multiply;
}

/* line 43, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-3 {
  right: 329px;
  bottom: -36px;
}

@media screen and (max-width: 575px) {
  /* line 43, src/scss/_components/homepage/_cloud.scss */
  .cloud__bg-img-3 {
    right: 20%;
  }
}

/* line 50, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-4 {
  bottom: 213px;
  right: -96px;
}

/* line 54, src/scss/_components/homepage/_cloud.scss */
.cloud__bg-img-5 {
  right: -40px;
  bottom: 190px;
  mix-blend-mode: multiply;
}

/* line 62, src/scss/_components/homepage/_cloud.scss */
.cloud__wrapper {
  z-index: 1;
  position: relative;
  padding-top: 98px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 62, src/scss/_components/homepage/_cloud.scss */
  .cloud__wrapper {
    padding-top: 49px;
    padding-bottom: 50px;
  }
}

/* line 4, src/scss/_components/homepage/_features.scss */
.features__wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 4, src/scss/_components/homepage/_features.scss */
  .features__wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 14, src/scss/_components/homepage/_features.scss */
.features__row-0 {
  padding-bottom: 62px;
}

/* line 21, src/scss/_components/homepage/_features.scss */
.features__box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  /* line 21, src/scss/_components/homepage/_features.scss */
  .features__box {
    width: calc(50% - 10px);
    margin-bottom: 19px;
  }
}

/* line 35, src/scss/_components/homepage/_features.scss */
.features__box:hover .features__box-circle {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

/* line 39, src/scss/_components/homepage/_features.scss */
.features__box:hover .features__box-circle svg {
  fill: #D01756;
  transition: fill 0.3s ease-in-out;
}

/* line 46, src/scss/_components/homepage/_features.scss */
.features__box-wrap {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 767px) {
  /* line 46, src/scss/_components/homepage/_features.scss */
  .features__box-wrap {
    flex-wrap: wrap;
  }
}

/* line 54, src/scss/_components/homepage/_features.scss */
.features__box-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  border: 2px solid #D01756;
  background-color: #D01756;
  transition: background-color 0.3s ease-in-out;
}

/* line 67, src/scss/_components/homepage/_features.scss */
.features__box-circle svg {
  fill: #fff;
  transition: fill 0.3s ease-in-out;
}

/* line 73, src/scss/_components/homepage/_features.scss */
.features__box-text {
  padding-top: 19px;
  font-size: 20px;
  font-weight: 600;
}

/* line 3, src/scss/_components/homepage/_request.scss */
.request {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/homepage/_request.scss */
.request:before {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-request.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* line 27, src/scss/_components/homepage/_request.scss */
.request__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: 100%;
  border-radius: 50%/100px 100px 0 0;
}

/* line 38, src/scss/_components/homepage/_request.scss */
.request__bg-inner {
  width: 100%;
  height: 100%;
  background: #00449D;
  background: linear-gradient(to right, #00449D, #001632);
}

/* line 45, src/scss/_components/homepage/_request.scss */
.request__wrapper {
  z-index: 1;
  position: relative;
  padding-top: 102px;
  padding-bottom: 99px;
}

@media screen and (max-width: 767px) {
  /* line 45, src/scss/_components/homepage/_request.scss */
  .request__wrapper {
    padding-top: 51px;
    padding-bottom: 50px;
  }
}

/* line 3, src/scss/_components/features/_fully.scss */
.fully {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/features/_fully.scss */
.fully__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: calc(100% - 0px);
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 21, src/scss/_components/features/_fully.scss */
.fully__bg-inner {
  width: 100%;
  height: 100%;
  background: #F8FAFC;
  background: linear-gradient(to right, #F8FAFC, #F8FAFC);
}

/* line 28, src/scss/_components/features/_fully.scss */
.fully__wrapper {
  z-index: 1;
  position: relative;
  min-height: 519px;
  padding-top: 177px;
}

/* line 35, src/scss/_components/features/_fully.scss */
.fully__title {
  padding-bottom: 40px;
  color: #000;
  text-align: center;
  line-height: 1.2;
  font-weight: 200;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 35, src/scss/_components/features/_fully.scss */
  .fully__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 35, src/scss/_components/features/_fully.scss */
  .fully__title {
    font-size: 50px;
  }
}

/* line 44, src/scss/_components/features/_fully.scss */
.fully__btn {
  display: flex;
  margin-bottom: 70px;
}

/* line 48, src/scss/_components/features/_fully.scss */
.fully__btn .c-btn {
  min-width: 253px;
  margin-left: auto;
  margin-right: auto;
}

/* line 54, src/scss/_components/features/_fully.scss */
.fully__btn:hover {
  color: #cd084b;
}

/* line 3, src/scss/_components/features/_finding.scss */
.finding {
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_finding.scss */
  .finding {
    padding-bottom: 50px;
  }
}

/* line 10, src/scss/_components/features/_finding.scss */
.finding .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* line 15, src/scss/_components/features/_finding.scss */
.finding__wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/features/_finding.scss */
  .finding__wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

/* line 3, src/scss/_components/features/_manage.scss */
.manage {
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_manage.scss */
  .manage {
    padding-bottom: 50px;
  }
}

/* line 10, src/scss/_components/features/_manage.scss */
.manage .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* line 15, src/scss/_components/features/_manage.scss */
.manage__wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/features/_manage.scss */
  .manage__wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

/* line 3, src/scss/_components/features/_save.scss */
.save {
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_save.scss */
  .save {
    padding-bottom: 50px;
  }
}

/* line 10, src/scss/_components/features/_save.scss */
.save .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* line 15, src/scss/_components/features/_save.scss */
.save__wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 15, src/scss/_components/features/_save.scss */
  .save__wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

/* line 3, src/scss/_components/features/_control.scss */
.control {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_control.scss */
  .control {
    padding-bottom: 50px;
  }
}

/* line 9, src/scss/_components/features/_control.scss */
.control .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* line 14, src/scss/_components/features/_control.scss */
.control__wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 14, src/scss/_components/features/_control.scss */
  .control__wrapper {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

/* line 3, src/scss/_components/features/_calendar.scss */
.features-calendar {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_calendar.scss */
  .features-calendar {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 12, src/scss/_components/features/_calendar.scss */
.features-calendar .c-title {
  padding-bottom: 63px;
}

@media screen and (max-width: 767px) {
  /* line 12, src/scss/_components/features/_calendar.scss */
  .features-calendar .c-title {
    padding-bottom: 35px;
  }
}

/* line 19, src/scss/_components/features/_calendar.scss */
.features-calendar__wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

/* line 24, src/scss/_components/features/_calendar.scss */
.features-calendar__img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  /* line 24, src/scss/_components/features/_calendar.scss */
  .features-calendar__img {
    padding-bottom: 15px;
  }
}

/* line 32, src/scss/_components/features/_calendar.scss */
.features-calendar__img img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 41, src/scss/_components/features/_calendar.scss */
.features-calendar__desc {
  width: 100%;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 45px;
  font-size: 19px;
  color: #939393;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
}

@media screen and (max-width: 767px) {
  /* line 41, src/scss/_components/features/_calendar.scss */
  .features-calendar__desc {
    padding-bottom: 30px;
    font-size: 17px;
  }
}

/* line 58, src/scss/_components/features/_calendar.scss */
.features-calendar__btn {
  display: flex;
  justify-content: center;
}

/* line 3, src/scss/_components/features/_apps.scss */
.apps {
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_apps.scss */
  .apps {
    padding-bottom: 50px;
  }
}

/* line 10, src/scss/_components/features/_apps.scss */
.apps__wrapper {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  /* line 10, src/scss/_components/features/_apps.scss */
  .apps__wrapper {
    padding-top: 50px;
  }
}

/* line 17, src/scss/_components/features/_apps.scss */
.apps__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
}

@media screen and (max-width: 767px) {
  /* line 17, src/scss/_components/features/_apps.scss */
  .apps__block {
    width: 50%;
  }
}

/* line 27, src/scss/_components/features/_apps.scss */
.apps__block-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 58px;
}

@media screen and (max-width: 767px) {
  /* line 27, src/scss/_components/features/_apps.scss */
  .apps__block-wrapper {
    flex-wrap: wrap;
    padding-top: 30px;
  }
}

/* line 38, src/scss/_components/features/_apps.scss */
.apps__block-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
}

/* line 46, src/scss/_components/features/_apps.scss */
.apps__block-text {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

/* line 3, src/scss/_components/features/_mind.scss */
.mind {
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/features/_mind.scss */
  .mind {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 12, src/scss/_components/features/_mind.scss */
.mind__box {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 191px;
  padding: 35px 45px;
  border-radius: 13px;
  background-color: #f2f5fa;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 320px) {
  /* line 12, src/scss/_components/features/_mind.scss */
  .mind__box {
    padding-top: calc(25px + 10 * (100vw - 320px) / 1120);
    padding-bottom: calc(25px + 10 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 12, src/scss/_components/features/_mind.scss */
  .mind__box {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 320px) {
  /* line 12, src/scss/_components/features/_mind.scss */
  .mind__box {
    padding-left: calc(30px + 15 * (100vw - 320px) / 1120);
    padding-right: calc(30px + 15 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 12, src/scss/_components/features/_mind.scss */
  .mind__box {
    padding-left: 45px;
    padding-right: 45px;
  }
}

/* line 23, src/scss/_components/features/_mind.scss */
.mind__box-wrapper {
  position: relative;
  max-width: 824px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

/* line 31, src/scss/_components/features/_mind.scss */
.mind__box--outer {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
  transform: scale(0.95);
  background-color: rgba(242, 245, 250, 0.5);
}

/* line 42, src/scss/_components/features/_mind.scss */
.mind__box p {
  line-height: 1.8;
  text-align: center;
}

/* line 48, src/scss/_components/features/_mind.scss */
.mind__btn {
  display: flex;
}

/* line 3, src/scss/_components/support/_assistance.scss */
.assistance {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/support/_assistance.scss */
.assistance__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: calc(100% - 0px);
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 21, src/scss/_components/support/_assistance.scss */
.assistance__bg-inner {
  width: 100%;
  height: 100%;
  background: #F8FAFC;
  background: linear-gradient(to right, #F8FAFC, #F8FAFC);
}

/* line 28, src/scss/_components/support/_assistance.scss */
.assistance__wrapper {
  z-index: 1;
  position: relative;
  min-height: 519px;
  padding-top: 177px;
}

/* line 35, src/scss/_components/support/_assistance.scss */
.assistance__title {
  padding-bottom: 40px;
  color: #000;
  text-align: center;
  line-height: 1.2;
  font-weight: 200;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 35, src/scss/_components/support/_assistance.scss */
  .assistance__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 35, src/scss/_components/support/_assistance.scss */
  .assistance__title {
    font-size: 50px;
  }
}

/* line 44, src/scss/_components/support/_assistance.scss */
.assistance__subtitle {
  padding-bottom: 10px;
  color: rgba(0, 68, 157, 0.4);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2.25px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (min-width: 320px) {
  /* line 44, src/scss/_components/support/_assistance.scss */
  .assistance__subtitle {
    font-size: calc(14px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 44, src/scss/_components/support/_assistance.scss */
  .assistance__subtitle {
    font-size: 18px;
  }
}

/* line 54, src/scss/_components/support/_assistance.scss */
.assistance__info {
  z-index: -1;
  position: relative;
  text-align: center;
  padding-bottom: 50px;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 54, src/scss/_components/support/_assistance.scss */
  .assistance__info {
    padding-bottom: calc(50px + 62 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 54, src/scss/_components/support/_assistance.scss */
  .assistance__info {
    padding-bottom: 112px;
  }
}

@media screen and (min-width: 320px) {
  /* line 54, src/scss/_components/support/_assistance.scss */
  .assistance__info {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 54, src/scss/_components/support/_assistance.scss */
  .assistance__info {
    font-size: 19px;
  }
}

/* line 61, src/scss/_components/support/_assistance.scss */
.assistance__info a {
  color: #00449D;
}

/* line 3, src/scss/_components/support/_help.scss */
.help {
  padding-top: 50px;
}

@media screen and (min-width: 320px) {
  /* line 3, src/scss/_components/support/_help.scss */
  .help {
    padding-top: calc(50px + 51 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 3, src/scss/_components/support/_help.scss */
  .help {
    padding-top: 101px;
  }
}

/* line 7, src/scss/_components/support/_help.scss */
.help--block h2 {
  text-align: center;
}

/* line 12, src/scss/_components/support/_help.scss */
.help--topic {
  display: none;
}

/* line 16, src/scss/_components/support/_help.scss */
.help--inside {
  display: none;
}

/* line 20, src/scss/_components/support/_help.scss */
.help h2 {
  padding-bottom: 40px;
  color: #000;
  line-height: 1.2;
  font-weight: 200;
  font-size: 25px;
}

@media screen and (min-width: 320px) {
  /* line 20, src/scss/_components/support/_help.scss */
  .help h2 {
    font-size: calc(25px + 15 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 20, src/scss/_components/support/_help.scss */
  .help h2 {
    font-size: 40px;
  }
}

/* line 28, src/scss/_components/support/_help.scss */
.help h3 {
  padding-bottom: 30px;
  color: #000;
  line-height: 1.2;
  font-weight: 200;
  font-size: 20px;
}

@media screen and (min-width: 320px) {
  /* line 28, src/scss/_components/support/_help.scss */
  .help h3 {
    font-size: calc(20px + 6 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 28, src/scss/_components/support/_help.scss */
  .help h3 {
    font-size: 26px;
  }
}

/* line 36, src/scss/_components/support/_help.scss */
.help__back {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  color: #00449D;
}

/* line 42, src/scss/_components/support/_help.scss */
.help__back svg {
  width: 16px;
  height: 16px;
  fill: #00449D;
}

/* line 48, src/scss/_components/support/_help.scss */
.help__back span {
  padding-left: 20px;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 48, src/scss/_components/support/_help.scss */
  .help__back span {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 48, src/scss/_components/support/_help.scss */
  .help__back span {
    font-size: 19px;
  }
}

/* line 54, src/scss/_components/support/_help.scss */
.help__block {
  width: 100%;
  max-width: calc((100% - 120px) / 4);
  height: 200px;
  margin: 25px 15px;
  padding: 40px 15px;
  border-radius: 14px;
  background: #FFFFFF;
  box-shadow: 0 15px 35px 0 rgba(0, 68, 157, 0.07);
  transition: background 0.3s ease-in-out;
}

@media screen and (max-width: 991px) {
  /* line 54, src/scss/_components/support/_help.scss */
  .help__block {
    max-width: calc((100% - 90px) / 3);
  }
}

@media screen and (max-width: 767px) {
  /* line 54, src/scss/_components/support/_help.scss */
  .help__block {
    max-width: calc((100% - 60px) / 2);
  }
}

@media screen and (max-width: 575px) {
  /* line 54, src/scss/_components/support/_help.scss */
  .help__block {
    max-width: calc(100% - 30px);
  }
}

/* line 74, src/scss/_components/support/_help.scss */
.help__block.is-active, .help__block:hover {
  background: #CD084B;
  transition: background 0.3s ease-in-out;
}

/* line 80, src/scss/_components/support/_help.scss */
.help__block.is-active .help__block-img svg, .help__block:hover .help__block-img svg {
  fill: #fff;
  transition: fill 0.3s ease-in-out;
}

/* line 86, src/scss/_components/support/_help.scss */
.help__block.is-active .help__block-name, .help__block:hover .help__block-name {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

/* line 92, src/scss/_components/support/_help.scss */
.help__block-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 75px;
}

@media screen and (min-width: 320px) {
  /* line 92, src/scss/_components/support/_help.scss */
  .help__block-wrapper {
    padding-bottom: calc(75px + 99 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 92, src/scss/_components/support/_help.scss */
  .help__block-wrapper {
    padding-bottom: 174px;
  }
}

/* line 99, src/scss/_components/support/_help.scss */
.help__block-img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}

/* line 105, src/scss/_components/support/_help.scss */
.help__block-img svg {
  fill: #00449D;
  transition: fill 0.3s ease-in-out;
}

/* line 111, src/scss/_components/support/_help.scss */
.help__block-name {
  padding-top: 22px;
  color: #000;
  text-align: center;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

@media screen and (min-width: 320px) {
  /* line 111, src/scss/_components/support/_help.scss */
  .help__block-name {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 111, src/scss/_components/support/_help.scss */
  .help__block-name {
    font-size: 19px;
  }
}

/* line 120, src/scss/_components/support/_help.scss */
.help__topic {
  padding-bottom: 100px;
}

@media screen and (min-width: 320px) {
  /* line 120, src/scss/_components/support/_help.scss */
  .help__topic {
    padding-bottom: calc(100px + 287 * (100vw - 320px) / 1600);
  }
}

@media screen and (min-width: 1920px) {
  /* line 120, src/scss/_components/support/_help.scss */
  .help__topic {
    padding-bottom: 387px;
  }
}

/* line 123, src/scss/_components/support/_help.scss */
.help__topic .help__back,
.help__topic h2,
.help__topic .help__topic-link a {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
}

/* line 129, src/scss/_components/support/_help.scss */
.is-topic .help__topic .help__back, .is-topic
.help__topic h2, .is-topic
.help__topic .help__topic-link a {
  transform: translateX(0);
  transition: transform 0.75s ease-in-out;
}

/* line 136, src/scss/_components/support/_help.scss */
.help__topic .help__topic-link > a.is-inside {
  transition-delay: 0s !important;
  transform: translateX(-150%);
  transition: transform 0.5s ease-in-out;
}

/* line 143, src/scss/_components/support/_help.scss */
.help__topic-link {
  display: flex;
  flex-direction: column;
}

/* line 147, src/scss/_components/support/_help.scss */
.help__topic-link a {
  display: flex;
  align-items: center;
  min-height: 71px;
  border-bottom: 1px solid #D8D8D8;
  color: #00449D;
  font-size: 16px;
  transform: translateX(0);
  transform-origin: center;
  transition: border-color 0.3s ease-in-out;
}

@media screen and (min-width: 320px) {
  /* line 147, src/scss/_components/support/_help.scss */
  .help__topic-link a {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 147, src/scss/_components/support/_help.scss */
  .help__topic-link a {
    font-size: 19px;
  }
}

/* line 158, src/scss/_components/support/_help.scss */
.help__topic-link a:hover {
  border-color: #00449D;
  transition: border-color 0.3s ease-in-out;
}

/* line 162, src/scss/_components/support/_help.scss */
.help__topic-link a:hover i {
  transform: translateX(10px);
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 575px) {
  /* line 162, src/scss/_components/support/_help.scss */
  .help__topic-link a:hover i {
    transform: translateX(5px);
  }
}

/* line 171, src/scss/_components/support/_help.scss */
.help__topic-link a span {
  padding-right: 20px;
}

@media screen and (max-width: 575px) {
  /* line 171, src/scss/_components/support/_help.scss */
  .help__topic-link a span {
    width: 100%;
    max-width: calc(100% - 12px);
  }
}

/* line 179, src/scss/_components/support/_help.scss */
.help__topic-link a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  transition: transform 0.3s ease-in-out;
}

/* line 187, src/scss/_components/support/_help.scss */
.help__topic-link a svg {
  width: 12px;
  fill: #00449D;
  transform: rotate(-90deg);
}

/* line 196, src/scss/_components/support/_help.scss */
.help__inside {
  padding-bottom: 75px;
}

@media screen and (min-width: 320px) {
  /* line 196, src/scss/_components/support/_help.scss */
  .help__inside {
    padding-bottom: calc(75px + 99 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 196, src/scss/_components/support/_help.scss */
  .help__inside {
    padding-bottom: 174px;
  }
}

/* line 199, src/scss/_components/support/_help.scss */
.help__inside .help__back {
  transition-delay: 0.1s !important;
}

/* line 203, src/scss/_components/support/_help.scss */
.help__inside h2 {
  transition-delay: 0.2s !important;
}

/* line 207, src/scss/_components/support/_help.scss */
.help__inside .help__back,
.help__inside h2 {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
}

/* line 212, src/scss/_components/support/_help.scss */
.is-descr .help__inside .help__back, .is-descr
.help__inside h2 {
  transform: translateX(0);
  transition: transform 0.75s ease-in-out;
}

/* line 218, src/scss/_components/support/_help.scss */
.help__inside p,
.help__inside h5,
.help__inside ul li {
  color: #575757;
  font-weight: normal;
  font-size: 15px;
}

@media screen and (min-width: 320px) {
  /* line 218, src/scss/_components/support/_help.scss */
  .help__inside p,
  .help__inside h5,
  .help__inside ul li {
    font-size: calc(15px + 2 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 218, src/scss/_components/support/_help.scss */
  .help__inside p,
  .help__inside h5,
  .help__inside ul li {
    font-size: 17px;
  }
}

/* line 225, src/scss/_components/support/_help.scss */
.is-descr .help__inside p, .is-descr
.help__inside h5, .is-descr
.help__inside ul li {
  animation-fill-mode: both;
  animation-name: slideInUp;
}

/* line 231, src/scss/_components/support/_help.scss */
.help__inside p {
  padding-bottom: 30px;
}

/* line 234, src/scss/_components/support/_help.scss */
.help__inside p:last-of-type {
  padding-bottom: 60px;
}

/* line 239, src/scss/_components/support/_help.scss */
.help__inside h5 {
  display: flex;
  align-items: center;
  min-height: 66px;
  padding: 15px 30px;
  margin-bottom: 40px;
  border-radius: 6px;
  background-color: #F2F5FA;
}

/* line 249, src/scss/_components/support/_help.scss */
.help__inside ul {
  padding-left: 20px;
  padding-bottom: 15px;
}

/* line 253, src/scss/_components/support/_help.scss */
.help__inside ul li {
  padding-bottom: 15px;
  list-style-type: lower-alpha;
}

/* line 259, src/scss/_components/support/_help.scss */
.help__inside img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
}

/* line 3, src/scss/_components/pricing/_plan.scss */
.plan {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/pricing/_plan.scss */
.plan__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: calc(100% - 0px);
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 21, src/scss/_components/pricing/_plan.scss */
.plan__bg-inner {
  width: 100%;
  height: 100%;
  background: #F8FAFC;
  background: linear-gradient(to right, #F8FAFC, #F8FAFC);
}

/* line 28, src/scss/_components/pricing/_plan.scss */
.plan__wrapper {
  z-index: 1;
  position: relative;
  min-height: 519px;
  padding-top: 177px;
}

/* line 35, src/scss/_components/pricing/_plan.scss */
.plan__title {
  padding-bottom: 40px;
  color: #000;
  text-align: center;
  line-height: 1.2;
  font-weight: 200;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 35, src/scss/_components/pricing/_plan.scss */
  .plan__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 35, src/scss/_components/pricing/_plan.scss */
  .plan__title {
    font-size: 50px;
  }
}

/* line 44, src/scss/_components/pricing/_plan.scss */
.plan__subtitle {
  padding-bottom: 10px;
  color: rgba(0, 68, 157, 0.4);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2.25px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (min-width: 320px) {
  /* line 44, src/scss/_components/pricing/_plan.scss */
  .plan__subtitle {
    font-size: calc(14px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 44, src/scss/_components/pricing/_plan.scss */
  .plan__subtitle {
    font-size: 18px;
  }
}

/* line 54, src/scss/_components/pricing/_plan.scss */
.plan__tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  will-change: color;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 54, src/scss/_components/pricing/_plan.scss */
  .plan__tabs {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 54, src/scss/_components/pricing/_plan.scss */
  .plan__tabs {
    width: 100%;
    max-width: calc(100% / 3);
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 575px) {
  /* line 54, src/scss/_components/pricing/_plan.scss */
  .plan__tabs {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) {
  /* line 54, src/scss/_components/pricing/_plan.scss */
  .plan__tabs {
    font-size: 14px;
  }
}

/* line 83, src/scss/_components/pricing/_plan.scss */
.plan__tabs-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 25px;
  background-color: #00449d;
  box-shadow: 0px 15px 30px rgba(0, 68, 157, 0.2);
  backface-visibility: hidden;
  transform-origin: center;
  will-change: width, transform;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 97, src/scss/_components/pricing/_plan.scss */
.plan__tabs:after {
  opacity: 0;
  z-index: 0;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: rgba(0, 68, 157, 0.1);
  transition: opacity 0.3s ease-in-out;
}

/* line 111, src/scss/_components/pricing/_plan.scss */
.plan__tabs:first-of-type:after {
  opacity: 1;
  right: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 118, src/scss/_components/pricing/_plan.scss */
.plan__tabs:first-of-type.is-active:after {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 126, src/scss/_components/pricing/_plan.scss */
.plan__tabs:last-of-type:after {
  opacity: 1;
  left: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 133, src/scss/_components/pricing/_plan.scss */
.plan__tabs:last-of-type.is-active:after {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 140, src/scss/_components/pricing/_plan.scss */
.plan__tabs:hover {
  color: #00449D;
  transition: color 0.3s ease-in-out;
}

/* line 145, src/scss/_components/pricing/_plan.scss */
.plan__tabs.is-active {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

/* line 151, src/scss/_components/pricing/_plan.scss */
.plan__tabs.is-border-hide:after {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 157, src/scss/_components/pricing/_plan.scss */
.plan__tabs span {
  z-index: 2;
  position: relative;
}

/* line 162, src/scss/_components/pricing/_plan.scss */
.plan__tabs-cover {
  display: flex;
  justify-content: center;
}

/* line 167, src/scss/_components/pricing/_plan.scss */
.plan__tabs-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  /* line 167, src/scss/_components/pricing/_plan.scss */
  .plan__tabs-wrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 424px) {
  /* line 167, src/scss/_components/pricing/_plan.scss */
  .plan__tabs-wrapper {
    height: 40px;
  }
}

/* line 3, src/scss/_components/pricing/_types.scss */
.types {
  padding-top: 55px;
  padding-bottom: 25px;
}

@media screen and (min-width: 320px) {
  /* line 3, src/scss/_components/pricing/_types.scss */
  .types {
    padding-top: calc(55px + 46 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 3, src/scss/_components/pricing/_types.scss */
  .types {
    padding-top: 101px;
  }
}

@media screen and (min-width: 320px) {
  /* line 3, src/scss/_components/pricing/_types.scss */
  .types {
    padding-bottom: calc(25px + 25 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 3, src/scss/_components/pricing/_types.scss */
  .types {
    padding-bottom: 50px;
  }
}

/* line 7, src/scss/_components/pricing/_types.scss */
.types__title {
  text-align: center;
  line-height: 1;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  font-size: 30px;
  padding-bottom: 35px;
}

@media screen and (min-width: 320px) {
  /* line 7, src/scss/_components/pricing/_types.scss */
  .types__title {
    font-size: calc(30px + 10 * (100vw - 320px) / 1280);
  }
}

@media screen and (min-width: 1600px) {
  /* line 7, src/scss/_components/pricing/_types.scss */
  .types__title {
    font-size: 40px;
  }
}

@media screen and (min-width: 320px) {
  /* line 7, src/scss/_components/pricing/_types.scss */
  .types__title {
    padding-bottom: calc(35px + 35 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 7, src/scss/_components/pricing/_types.scss */
  .types__title {
    padding-bottom: 70px;
  }
}

/* line 16, src/scss/_components/pricing/_types.scss */
.types__block {
  width: 100%;
  max-width: 320px;
  min-height: 498px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 15px 35px 0 rgba(0, 68, 157, 0.07);
}

/* line 26, src/scss/_components/pricing/_types.scss */
.types__block-wrapper {
  display: flex;
  align-items: flex-start;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 26, src/scss/_components/pricing/_types.scss */
  .types__block-wrapper {
    flex-wrap: wrap;
  }
}

/* line 34, src/scss/_components/pricing/_types.scss */
.types__block-wrapper > div {
  width: 100%;
  max-width: calc(100% / 3);
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (max-width: 767px) {
  /* line 34, src/scss/_components/pricing/_types.scss */
  .types__block-wrapper > div {
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* line 47, src/scss/_components/pricing/_types.scss */
.types__block-top {
  min-height: calc(498px - 210px);
  padding: 40px 50px;
}

@media screen and (max-width: 1199px) {
  /* line 47, src/scss/_components/pricing/_types.scss */
  .types__block-top {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 991px) {
  /* line 47, src/scss/_components/pricing/_types.scss */
  .types__block-top {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 767px) {
  /* line 47, src/scss/_components/pricing/_types.scss */
  .types__block-top {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/* line 64, src/scss/_components/pricing/_types.scss */
.types__block-bottom {
  height: 210px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 2px solid #F8F8F8;
}

/* line 71, src/scss/_components/pricing/_types.scss */
.types__block-icon {
  display: flex;
  justify-content: center;
  min-height: 62px;
}

/* line 77, src/scss/_components/pricing/_types.scss */
.types__block-name {
  padding-top: 28px;
  padding-bottom: 20px;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

@media screen and (min-width: 320px) {
  /* line 77, src/scss/_components/pricing/_types.scss */
  .types__block-name {
    font-size: calc(22px + 4 * (100vw - 320px) / 1110);
  }
}

@media screen and (min-width: 1430px) {
  /* line 77, src/scss/_components/pricing/_types.scss */
  .types__block-name {
    font-size: 26px;
  }
}

/* line 86, src/scss/_components/pricing/_types.scss */
.types__block-desc {
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: 15px;
}

@media screen and (min-width: 320px) {
  /* line 86, src/scss/_components/pricing/_types.scss */
  .types__block-desc {
    font-size: calc(15px + 2 * (100vw - 320px) / 1110);
  }
}

@media screen and (min-width: 1430px) {
  /* line 86, src/scss/_components/pricing/_types.scss */
  .types__block-desc {
    font-size: 17px;
  }
}

/* line 92, src/scss/_components/pricing/_types.scss */
.types__block-limit {
  padding-bottom: 40px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: 17px;
  font-size: 15px;
}

@media screen and (min-width: 320px) {
  /* line 92, src/scss/_components/pricing/_types.scss */
  .types__block-limit {
    font-size: calc(15px + 2 * (100vw - 320px) / 1110);
  }
}

@media screen and (min-width: 1430px) {
  /* line 92, src/scss/_components/pricing/_types.scss */
  .types__block-limit {
    font-size: 17px;
  }
}

/* line 99, src/scss/_components/pricing/_types.scss */
.types__block-limit span {
  color: #000;
}

/* line 104, src/scss/_components/pricing/_types.scss */
.types__block-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 185px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid #CD084B;
  background-color: transparent;
  color: #CD084B;
  letter-spacing: 1.35px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 122, src/scss/_components/pricing/_types.scss */
.types__block-btn.is-active, .types__block-btn:hover {
  background-color: #CD084B;
  color: #fff;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 129, src/scss/_components/pricing/_types.scss */
.types__block-btn-wrapper {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

/* line 137, src/scss/_components/pricing/_types.scss */
.types__checkbox {
  opacity: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* line 143, src/scss/_components/pricing/_types.scss */
.types__checkbox.is-show {
  opacity: 1;
}

/* line 170, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* line 177, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__field__label {
  cursor: default;
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
}

/* line 188, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

/* line 196, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

/* line 202, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-content {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* line 211, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-icon i {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 221, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox:checked + label .c-form__checkbox-icon i {
  border-color: #00449D;
  background-color: #00449D;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 226, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox:checked + label .c-form__checkbox-icon i:before {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* line 235, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-wrapper {
  height: 50px;
}

/* line 239, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-icon {
  width: 40px;
}

/* line 242, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-icon i {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid rgba(153, 153, 153, 0.5);
  background-color: transparent;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 250, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-icon i:before {
  content: '';
  opacity: 0;
  position: relative;
  top: -1px;
  left: 0.5px;
  transform: rotate(45deg);
  width: 7px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transition: opacity 0.3s ease-in-out;
}

/* line 266, src/scss/_components/pricing/_types.scss */
.types__checkbox .c-form__checkbox-text {
  max-width: calc(100% - 40px);
  font-size: 19px;
  font-weight: normal;
}

/* line 3, src/scss/_components/pricing/_plans.scss */
.plans {
  padding-top: 25px;
  padding-bottom: 75px;
}

@media screen and (min-width: 320px) {
  /* line 3, src/scss/_components/pricing/_plans.scss */
  .plans {
    padding-top: calc(25px + 25 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 3, src/scss/_components/pricing/_plans.scss */
  .plans {
    padding-top: 50px;
  }
}

@media screen and (min-width: 320px) {
  /* line 3, src/scss/_components/pricing/_plans.scss */
  .plans {
    padding-bottom: calc(75px + -4 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 3, src/scss/_components/pricing/_plans.scss */
  .plans {
    padding-bottom: 71px;
  }
}

/* line 7, src/scss/_components/pricing/_plans.scss */
.plans__wrapper {
  display: none;
}

/* line 11, src/scss/_components/pricing/_plans.scss */
.plans__title {
  text-align: center;
  line-height: 1;
  font-weight: 400;
  font-family: "Muli", sans-serif;
  font-size: 30px;
  padding-bottom: 40px;
}

@media screen and (min-width: 320px) {
  /* line 11, src/scss/_components/pricing/_plans.scss */
  .plans__title {
    font-size: calc(30px + 10 * (100vw - 320px) / 1280);
  }
}

@media screen and (min-width: 1600px) {
  /* line 11, src/scss/_components/pricing/_plans.scss */
  .plans__title {
    font-size: 40px;
  }
}

@media screen and (min-width: 320px) {
  /* line 11, src/scss/_components/pricing/_plans.scss */
  .plans__title {
    padding-bottom: calc(40px + 40 * (100vw - 320px) / 1119);
  }
}

@media screen and (min-width: 1439px) {
  /* line 11, src/scss/_components/pricing/_plans.scss */
  .plans__title {
    padding-bottom: 80px;
  }
}

/* line 20, src/scss/_components/pricing/_plans.scss */
.plans__block {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 575px) {
  /* line 20, src/scss/_components/pricing/_plans.scss */
  .plans__block {
    flex-wrap: wrap;
  }
}

/* line 31, src/scss/_components/pricing/_plans.scss */
.plans__block-3 li:last-of-type p, .plans__block-5 li:last-of-type p {
  color: #000;
}

/* line 38, src/scss/_components/pricing/_plans.scss */
.plans__block-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 42, src/scss/_components/pricing/_plans.scss */
.plans__block-wrapper > div {
  min-height: 150px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #D8D8D8;
}

/* line 48, src/scss/_components/pricing/_plans.scss */
.plans__block-wrapper > div:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

/* line 56, src/scss/_components/pricing/_plans.scss */
.plans__block-left {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  /* line 56, src/scss/_components/pricing/_plans.scss */
  .plans__block-left {
    padding-right: 15px;
  }
}

@media screen and (max-width: 575px) {
  /* line 56, src/scss/_components/pricing/_plans.scss */
  .plans__block-left {
    flex: unset;
    max-width: 100%;
  }
}

/* line 73, src/scss/_components/pricing/_plans.scss */
.plans__block-right {
  min-width: 252px;
  padding-right: 67px;
}

@media screen and (max-width: 767px) {
  /* line 73, src/scss/_components/pricing/_plans.scss */
  .plans__block-right {
    min-width: 185px;
    padding-right: 0;
  }
}

@media screen and (max-width: 575px) {
  /* line 73, src/scss/_components/pricing/_plans.scss */
  .plans__block-right {
    min-width: unset;
    width: 100%;
  }
}

/* line 86, src/scss/_components/pricing/_plans.scss */
.plans__block-title {
  display: flex;
  align-items: center;
  padding-bottom: 21px;
  font-weight: 500;
  font-size: 22px;
}

@media screen and (min-width: 320px) {
  /* line 86, src/scss/_components/pricing/_plans.scss */
  .plans__block-title {
    font-size: calc(22px + 4 * (100vw - 320px) / 447);
  }
}

@media screen and (min-width: 767px) {
  /* line 86, src/scss/_components/pricing/_plans.scss */
  .plans__block-title {
    font-size: 26px;
  }
}

@media screen and (max-width: 575px) {
  /* line 86, src/scss/_components/pricing/_plans.scss */
  .plans__block-title {
    flex-direction: column-reverse;
    text-align: center;
  }
}

/* line 97, src/scss/_components/pricing/_plans.scss */
.plans__block-title span {
  display: flex;
  align-items: center;
  height: 24px;
  margin-left: 19px;
  padding: 5px 15px;
  border-radius: 12px;
  background-color: #00449D;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  /* line 97, src/scss/_components/pricing/_plans.scss */
  .plans__block-title span {
    font-size: calc(12px + 2 * (100vw - 320px) / 447);
  }
}

@media screen and (min-width: 767px) {
  /* line 97, src/scss/_components/pricing/_plans.scss */
  .plans__block-title span {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  /* line 97, src/scss/_components/pricing/_plans.scss */
  .plans__block-title span {
    margin-left: 0;
    margin-bottom: 5px;
  }
}

/* line 116, src/scss/_components/pricing/_plans.scss */
.plans__block ul {
  display: flex;
  flex-direction: column;
}

/* line 120, src/scss/_components/pricing/_plans.scss */
.plans__block ul li {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

/* line 125, src/scss/_components/pricing/_plans.scss */
.plans__block ul li.is-hide {
  display: none;
}

/* line 130, src/scss/_components/pricing/_plans.scss */
.plans__block ul li > div:nth-of-type(1) {
  width: 30px;
}

/* line 134, src/scss/_components/pricing/_plans.scss */
.plans__block ul li > div:nth-of-type(2) {
  width: 100%;
  max-width: calc(100% - 30px);
}

/* line 140, src/scss/_components/pricing/_plans.scss */
.plans__block ul li svg {
  height: 16px;
  fill: #00449D;
}

/* line 145, src/scss/_components/pricing/_plans.scss */
.plans__block ul li p {
  color: #939393;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 145, src/scss/_components/pricing/_plans.scss */
  .plans__block ul li p {
    font-size: calc(16px + 3 * (100vw - 320px) / 447);
  }
}

@media screen and (min-width: 767px) {
  /* line 145, src/scss/_components/pricing/_plans.scss */
  .plans__block ul li p {
    font-size: 19px;
  }
}

/* line 152, src/scss/_components/pricing/_plans.scss */
.plans__block-price {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 35px;
}

@media screen and (min-width: 320px) {
  /* line 152, src/scss/_components/pricing/_plans.scss */
  .plans__block-price {
    font-size: calc(35px + 5 * (100vw - 320px) / 447);
  }
}

@media screen and (min-width: 767px) {
  /* line 152, src/scss/_components/pricing/_plans.scss */
  .plans__block-price {
    font-size: 40px;
  }
}

@media screen and (max-width: 575px) {
  /* line 152, src/scss/_components/pricing/_plans.scss */
  .plans__block-price {
    text-align: center;
  }
}

/* line 161, src/scss/_components/pricing/_plans.scss */
.plans__block-per {
  padding-bottom: 20px;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 161, src/scss/_components/pricing/_plans.scss */
  .plans__block-per {
    font-size: calc(16px + 3 * (100vw - 320px) / 447);
  }
}

@media screen and (min-width: 767px) {
  /* line 161, src/scss/_components/pricing/_plans.scss */
  .plans__block-per {
    font-size: 19px;
  }
}

@media screen and (max-width: 575px) {
  /* line 161, src/scss/_components/pricing/_plans.scss */
  .plans__block-per {
    text-align: center;
  }
}

/* line 169, src/scss/_components/pricing/_plans.scss */
.plans__block-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 185px;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 2px solid #CD084B;
  background-color: transparent;
  color: #CD084B;
  letter-spacing: 1.35px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 190, src/scss/_components/pricing/_plans.scss */
.plans__block-btn:hover {
  background-color: #CD084B;
  color: #fff;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 196, src/scss/_components/pricing/_plans.scss */
.plans__block-btn-wrapper {
  display: flex;
}

/* line 202, src/scss/_components/pricing/_plans.scss */
.plans .types__checkbox {
  opacity: 1;
}

/* line 206, src/scss/_components/pricing/_plans.scss */
.plans .types__checkbox .c-form__field {
  margin-bottom: 0;
}

/* line 212, src/scss/_components/pricing/_plans.scss */
.plans__row {
  display: none;
  padding-top: 50px;
}

/* line 216, src/scss/_components/pricing/_plans.scss */
.plans__row-1 {
  display: block;
}

/* line 221, src/scss/_components/pricing/_plans.scss */
.plans__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
  /* line 221, src/scss/_components/pricing/_plans.scss */
  .plans__header {
    padding-bottom: 50px;
  }
}

/* line 232, src/scss/_components/pricing/_plans.scss */
.plans__header-1 svg {
  width: 40px;
  height: 40px;
}

/* line 238, src/scss/_components/pricing/_plans.scss */
.plans__header-2 svg {
  width: 42px;
  height: 42px;
}

/* line 244, src/scss/_components/pricing/_plans.scss */
.plans__header-3 svg {
  width: 34px;
  height: 42px;
}

/* line 250, src/scss/_components/pricing/_plans.scss */
.plans__header svg {
  fill: #CD084B;
}

/* line 254, src/scss/_components/pricing/_plans.scss */
.plans__header-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 254, src/scss/_components/pricing/_plans.scss */
  .plans__header-title {
    font-size: 36px;
  }
}

/* line 266, src/scss/_components/pricing/_plans.scss */
.plans__header-subtitle {
  font-size: 19px;
  text-align: center;
  color: #575757;
}

@media screen and (max-width: 767px) {
  /* line 266, src/scss/_components/pricing/_plans.scss */
  .plans__header-subtitle {
    font-size: 16px;
  }
}

/* line 276, src/scss/_components/pricing/_plans.scss */
.plans__box {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 10px);
  padding: 40px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #f8fafc;
}

@media screen and (max-width: 1023px) {
  /* line 276, src/scss/_components/pricing/_plans.scss */
  .plans__box {
    padding-left: 20px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 276, src/scss/_components/pricing/_plans.scss */
  .plans__box {
    padding-bottom: 20px;
  }
}

/* line 296, src/scss/_components/pricing/_plans.scss */
.plans__box--popular {
  height: calc(100% + 10px);
  margin-top: 0;
  padding-top: 50px;
  background-color: #fff;
  box-shadow: 0px 15px 35px rgba(0, 68, 157, 0.07);
}

/* line 303, src/scss/_components/pricing/_plans.scss */
.plans__box--popular img {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
}

/* line 311, src/scss/_components/pricing/_plans.scss */
.plans__box-title {
  z-index: 3;
  position: relative;
  padding-bottom: 50px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 1023px) {
  /* line 311, src/scss/_components/pricing/_plans.scss */
  .plans__box-title {
    font-size: 22px;
  }
}

/* line 324, src/scss/_components/pricing/_plans.scss */
.plans__box-body {
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* line 331, src/scss/_components/pricing/_plans.scss */
.plans__box-price {
  padding-bottom: 50px;
}

/* line 334, src/scss/_components/pricing/_plans.scss */
.plans__box-price p {
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  color: #00449d;
  line-height: 1;
}

@media screen and (max-width: 1023px) {
  /* line 334, src/scss/_components/pricing/_plans.scss */
  .plans__box-price p {
    font-size: 40px;
  }
}

/* line 344, src/scss/_components/pricing/_plans.scss */
.plans__box-price p sup {
  position: relative;
  left: 10px;
  top: -22px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.13em;
  color: rgba(0, 68, 157, 0.4);
}

@media screen and (max-width: 1023px) {
  /* line 344, src/scss/_components/pricing/_plans.scss */
  .plans__box-price p sup {
    top: -20px;
  }
}

/* line 357, src/scss/_components/pricing/_plans.scss */
.plans__box-price p i {
  font-style: normal;
}

/* line 362, src/scss/_components/pricing/_plans.scss */
.plans__box-price span {
  position: relative;
  left: 20px;
  display: block;
  font-size: 18px;
  text-align: center;
  color: #939393;
  line-height: 1;
}

@media screen and (max-width: 1023px) {
  /* line 362, src/scss/_components/pricing/_plans.scss */
  .plans__box-price span {
    left: 0;
    font-size: 16px;
  }
}

/* line 377, src/scss/_components/pricing/_plans.scss */
.plans__box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 185px;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid #cd084b;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-align: center;
  color: #cd084b;
  will-change: background-color, color;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {
  /* line 377, src/scss/_components/pricing/_plans.scss */
  .plans__box-btn {
    height: 55px;
  }
}

/* line 402, src/scss/_components/pricing/_plans.scss */
.plans__box-btn:hover {
  background-color: #cd084b;
  color: #ffffff;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 408, src/scss/_components/pricing/_plans.scss */
.plans__box-btn-wrapper {
  display: flex;
}

/* line 413, src/scss/_components/pricing/_plans.scss */
.plans__box-list {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

/* line 418, src/scss/_components/pricing/_plans.scss */
.plans__box-list--pt0 {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  /* line 418, src/scss/_components/pricing/_plans.scss */
  .plans__box-list--pt0 {
    padding-top: 50px;
  }
}

/* line 425, src/scss/_components/pricing/_plans.scss */
.plans__box-list li {
  position: relative;
  display: flex;
  padding-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  /* line 425, src/scss/_components/pricing/_plans.scss */
  .plans__box-list li {
    padding-bottom: 15px;
  }
}

/* line 433, src/scss/_components/pricing/_plans.scss */
.plans__box-list li:last-of-type {
  padding-bottom: 0;
}

/* line 437, src/scss/_components/pricing/_plans.scss */
.plans__box-list li i {
  position: absolute;
  top: 8px;
  left: -18px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #00449d;
}

@media screen and (max-width: 1023px) {
  /* line 437, src/scss/_components/pricing/_plans.scss */
  .plans__box-list li i {
    left: -10px;
  }
}

/* line 451, src/scss/_components/pricing/_plans.scss */
.plans__box-list li p {
  font-size: 16px;
  color: #939393;
}

/* line 458, src/scss/_components/pricing/_plans.scss */
.plans__box-list li p.hide span:nth-of-type(2) {
  opacity: 0;
}

/* line 464, src/scss/_components/pricing/_plans.scss */
.plans__box-list li p span {
  font-weight: 500;
  color: #000000;
}

/* line 472, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

/* line 482, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-1 > div {
  max-width: calc((100% - 80px) / 3);
}

@media screen and (max-width: 1023px) {
  /* line 482, src/scss/_components/pricing/_plans.scss */
  .plans__box-wrapper-1 > div {
    max-width: calc((100% - 30px) / 3);
  }
}

/* line 489, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-2 {
  max-width: 858px;
}

/* line 492, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-2 > div {
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 1023px) {
  /* line 492, src/scss/_components/pricing/_plans.scss */
  .plans__box-wrapper-2 > div {
    max-width: calc((100% - 15px) / 2);
  }
}

/* line 499, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 {
  max-width: 854px;
}

/* line 502, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 > div {
  max-width: 100%;
}

/* line 507, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 .plans__box svg {
  z-index: 0;
  position: absolute;
  right: -20px;
  bottom: 60px;
  width: 320px;
  height: 395px;
  fill: #F8FAFC;
}

/* line 517, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 .plans__box-title {
  text-align: left;
}

/* line 521, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 .plans__box-body {
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 521, src/scss/_components/pricing/_plans.scss */
  .plans__box-wrapper-3 .plans__box-body {
    flex-direction: column;
  }
}

/* line 528, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper-3 .plans__box-body-right {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  /* line 528, src/scss/_components/pricing/_plans.scss */
  .plans__box-wrapper-3 .plans__box-body-right {
    padding-left: 0;
  }
}

/* line 538, src/scss/_components/pricing/_plans.scss */
.plans__box-wrapper > div {
  width: 100%;
}

@media screen and (max-width: 767px) {
  /* line 538, src/scss/_components/pricing/_plans.scss */
  .plans__box-wrapper > div {
    max-width: 100%;
    padding-bottom: 30px;
  }
}

/* line 3, src/scss/_components/resources/_main.scss */
.r-main {
  overflow: hidden;
  position: relative;
}

/* line 7, src/scss/_components/resources/_main.scss */
.r-main__bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: calc(100% - 0px);
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 21, src/scss/_components/resources/_main.scss */
.r-main__bg-inner {
  width: 100%;
  height: 100%;
  background: #F8FAFC;
  background: linear-gradient(to right, #F8FAFC, #F8FAFC);
}

/* line 28, src/scss/_components/resources/_main.scss */
.r-main__wrapper {
  z-index: 1;
  position: relative;
  min-height: 519px;
  padding-top: 177px;
}

/* line 35, src/scss/_components/resources/_main.scss */
.r-main__title {
  padding-bottom: 40px;
  color: #000;
  text-align: center;
  line-height: 1.2;
  font-weight: 200;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 35, src/scss/_components/resources/_main.scss */
  .r-main__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 35, src/scss/_components/resources/_main.scss */
  .r-main__title {
    font-size: 50px;
  }
}

/* line 44, src/scss/_components/resources/_main.scss */
.r-main__subtitle {
  padding-bottom: 10px;
  color: rgba(0, 68, 157, 0.4);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2.25px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (min-width: 320px) {
  /* line 44, src/scss/_components/resources/_main.scss */
  .r-main__subtitle {
    font-size: calc(14px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 44, src/scss/_components/resources/_main.scss */
  .r-main__subtitle {
    font-size: 18px;
  }
}

/* line 54, src/scss/_components/resources/_main.scss */
.r-main__btn {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  border-radius: 15px;
  border: 2px solid rgba(0, 68, 157, 0.4);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  color: rgba(0, 68, 157, 0.4);
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media screen and (max-width: 575px) {
  /* line 54, src/scss/_components/resources/_main.scss */
  .r-main__btn {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
}

/* line 81, src/scss/_components/resources/_main.scss */
.r-main__btn.is-active, .r-main__btn:hover {
  border-color: #00449D;
  background-color: #00449D;
  color: #fff;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 89, src/scss/_components/resources/_main.scss */
.r-main__btn-wrapper {
  z-index: -1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 632px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 91px;
}

@media screen and (max-width: 575px) {
  /* line 89, src/scss/_components/resources/_main.scss */
  .r-main__btn-wrapper {
    padding-bottom: 45px;
  }
}

/* line 3, src/scss/_components/resources/_exchequer.scss */
.exchequer {
  padding-top: 101px;
  padding-bottom: 170px;
}

/* line 7, src/scss/_components/resources/_exchequer.scss */
.exchequer__title {
  padding-bottom: 70px;
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.5;
}

/* line 17, src/scss/_components/resources/_exchequer.scss */
.exchequer__block {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding: 20px 20px 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 15px 35px 0 rgba(0, 68, 157, 0.07);
}

/* line 28, src/scss/_components/resources/_exchequer.scss */
.exchequer__block:hover .exchequer__block-top img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

/* line 43, src/scss/_components/resources/_exchequer.scss */
.exchequer__block--articles .exchequer__block-badge {
  background-color: #d0021b;
}

/* line 43, src/scss/_components/resources/_exchequer.scss */
.exchequer__block--feature .exchequer__block-badge {
  background-color: #7319C1;
}

/* line 43, src/scss/_components/resources/_exchequer.scss */
.exchequer__block--releases .exchequer__block-badge {
  background-color: #F5A623;
}

/* line 43, src/scss/_components/resources/_exchequer.scss */
.exchequer__block--study .exchequer__block-badge {
  background-color: #0054C1;
}

/* line 43, src/scss/_components/resources/_exchequer.scss */
.exchequer__block--video .exchequer__block-badge {
  background-color: #7ED321;
}

/* line 49, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-top {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 200px;
  margin-bottom: 26px;
  border-radius: 7px;
}

/* line 57, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-top img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

/* line 70, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-play {
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 81, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-play svg {
  fill: #fff;
}

/* line 86, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 24px;
  margin-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
}

/* line 97, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-badge p {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: #FFF;
  letter-spacing: 1px;
  line-height: 1.3;
}

/* line 107, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-title {
  padding-bottom: 10px;
  font-size: 26px;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.3;
}

/* line 115, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-text {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
  line-height: 25px;
}

/* line 122, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-wrapper {
  position: relative;
  margin-left: -40px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
  /* line 122, src/scss/_components/resources/_exchequer.scss */
  .exchequer__block-wrapper {
    margin-left: -15px;
  }
}

/* line 131, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-wrapper > div {
  display: flex;
  width: 100%;
  max-width: calc(100% / 3);
  padding-left: 40px;
}

@media screen and (max-width: 991px) {
  /* line 131, src/scss/_components/resources/_exchequer.scss */
  .exchequer__block-wrapper > div {
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 131, src/scss/_components/resources/_exchequer.scss */
  .exchequer__block-wrapper > div {
    max-width: calc(100% / 2);
  }
}

@media screen and (max-width: 575px) {
  /* line 131, src/scss/_components/resources/_exchequer.scss */
  .exchequer__block-wrapper > div {
    max-width: 100%;
  }
}

/* line 146, src/scss/_components/resources/_exchequer.scss */
.exchequer__block-wrapper > div.is-hide {
  display: none;
}

/* line 4, src/scss/_components/resources/_facilities.scss */
.facilities__main {
  display: flex;
  align-items: flex-end;
  min-height: 700px;
  padding-top: 150px;
  padding-bottom: 100px;
  background: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 991px) {
  /* line 4, src/scss/_components/resources/_facilities.scss */
  .facilities__main {
    min-height: 600px;
    padding-top: 100px;
  }
}

@media screen and (max-width: 575px) {
  /* line 4, src/scss/_components/resources/_facilities.scss */
  .facilities__main {
    height: auto;
    padding-top: 125px;
  }
}

/* line 23, src/scss/_components/resources/_facilities.scss */
.facilities__main .c-grid {
  height: 100%;
}

@media screen and (max-width: 575px) {
  /* line 23, src/scss/_components/resources/_facilities.scss */
  .facilities__main .c-grid {
    min-height: 100%;
  }
}

/* line 30, src/scss/_components/resources/_facilities.scss */
.facilities__main .facilities__wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

/* line 36, src/scss/_components/resources/_facilities.scss */
.facilities__main-row {
  display: flex;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 767px) {
  /* line 36, src/scss/_components/resources/_facilities.scss */
  .facilities__main-row {
    flex-wrap: wrap;
  }
}

/* line 45, src/scss/_components/resources/_facilities.scss */
.facilities__main-row > div:nth-of-type(1) {
  flex: 1;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  /* line 45, src/scss/_components/resources/_facilities.scss */
  .facilities__main-row > div:nth-of-type(1) {
    padding-right: 0;
    padding-bottom: 30px;
    text-align: center;
  }
}

/* line 55, src/scss/_components/resources/_facilities.scss */
.facilities__main-row > div:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  width: 324px;
}

@media screen and (max-width: 767px) {
  /* line 55, src/scss/_components/resources/_facilities.scss */
  .facilities__main-row > div:nth-of-type(2) {
    width: 100%;
    justify-content: center;
  }
}

/* line 67, src/scss/_components/resources/_facilities.scss */
.facilities__main-link {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

/* line 72, src/scss/_components/resources/_facilities.scss */
.facilities__main-link svg {
  width: 14px;
  height: 14px;
  fill: #fff;
}

/* line 78, src/scss/_components/resources/_facilities.scss */
.facilities__main-link span {
  padding-left: 20px;
  font-size: 19px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1.3;
}

/* line 87, src/scss/_components/resources/_facilities.scss */
.facilities__main-title {
  padding-bottom: 20px;
  font-weight: 200;
  font-size: 50px;
  color: #FFFFFF;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  /* line 87, src/scss/_components/resources/_facilities.scss */
  .facilities__main-title {
    font-size: 40px;
  }
}

/* line 98, src/scss/_components/resources/_facilities.scss */
.facilities__main-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  padding: 5px 20px;
  border-radius: 15px;
  background-color: #F5A623;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: center;
}

/* line 114, src/scss/_components/resources/_facilities.scss */
.facilities__main-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 216px;
  height: 70px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1.35px;
  text-align: center;
  line-height: 32px;
  background-color: #CD084B;
  border: 2px solid #cd084b;
  border-radius: 10px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 133, src/scss/_components/resources/_facilities.scss */
.facilities__main-btn:hover {
  color: #CD084B;
  background-color: transparent;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 139, src/scss/_components/resources/_facilities.scss */
.facilities__main-btn-wrapper {
  display: flex;
}

/* line 145, src/scss/_components/resources/_facilities.scss */
.facilities__description {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (max-width: 575px) {
  /* line 145, src/scss/_components/resources/_facilities.scss */
  .facilities__description {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* line 155, src/scss/_components/resources/_facilities.scss */
.facilities__description p a,
.facilities__description li a {
  color: #00449d;
}

/* line 158, src/scss/_components/resources/_facilities.scss */
.facilities__description p a:visited, .facilities__description p a:hover, .facilities__description p a:focus,
.facilities__description li a:visited,
.facilities__description li a:hover,
.facilities__description li a:focus {
  color: #00449d;
}

/* line 166, src/scss/_components/resources/_facilities.scss */
.facilities__description p {
  padding-bottom: 30px;
  font-size: 17px;
  color: #575757;
  letter-spacing: 0;
  line-height: 26px;
}

@media screen and (max-width: 575px) {
  /* line 166, src/scss/_components/resources/_facilities.scss */
  .facilities__description p {
    font-size: 15px;
  }
}

/* line 177, src/scss/_components/resources/_facilities.scss */
.facilities__description h6 {
  display: flex;
  align-items: center;
  min-height: 66px;
  margin-bottom: 40px;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #f2f5fa;
  font-size: 17px;
  font-weight: normal;
  color: #575757;
  letter-spacing: 0;
  line-height: 26px;
}

@media screen and (max-width: 575px) {
  /* line 177, src/scss/_components/resources/_facilities.scss */
  .facilities__description h6 {
    font-size: 15px;
  }
}

/* line 195, src/scss/_components/resources/_facilities.scss */
.facilities__description h2 {
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 26px;
  color: #000000;
  letter-spacing: 0;
  line-height: 50px;
}

@media screen and (max-width: 575px) {
  /* line 195, src/scss/_components/resources/_facilities.scss */
  .facilities__description h2 {
    font-size: 22px;
  }
}

/* line 207, src/scss/_components/resources/_facilities.scss */
.facilities__description ul {
  padding-left: 15px;
}

/* line 210, src/scss/_components/resources/_facilities.scss */
.facilities__description ul li {
  padding-left: 20px;
  padding-bottom: 15px;
  list-style-type: lower-alpha;
  font-size: 17px;
  color: #575757;
  letter-spacing: 0;
  line-height: 26px;
}

@media screen and (max-width: 575px) {
  /* line 210, src/scss/_components/resources/_facilities.scss */
  .facilities__description ul li {
    font-size: 15px;
  }
}

/* line 225, src/scss/_components/resources/_facilities.scss */
.facilities__more {
  padding-bottom: 80px;
}

@media screen and (max-width: 575px) {
  /* line 225, src/scss/_components/resources/_facilities.scss */
  .facilities__more {
    padding-bottom: 40px;
  }
}

/* line 231, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block {
  overflow: hidden;
  display: flex;
  min-height: 408px;
  margin-bottom: 60px;
  border-radius: 10px;
  background-color: #00449d;
}

@media screen and (max-width: 767px) {
  /* line 231, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block {
    flex-wrap: wrap;
  }
}

/* line 242, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block:last-of-type {
  margin-bottom: 0;
}

/* line 246, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 251, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left {
  overflow: hidden;
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: 767px) {
  /* line 251, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-left {
    flex: auto;
    height: 350px;
    border-bottom-left-radius: 10px;
  }
}

@media screen and (max-width: 575px) {
  /* line 251, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-left {
    height: 250px;
  }
}

/* line 269, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left:hover .main__video-progress {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}

/* line 275, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left--image {
  display: flex;
  align-items: flex-end;
  padding: 22px 22px 0;
}

/* line 283, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left--image img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 292, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left img,
.facilities__more .facilities__block-left iframe {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 304, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left video {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #F7F7F7;
  object-fit: cover;
  object-position: center;
}

/* line 317, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left .main__video-btn {
  background-color: transparent;
}

/* line 320, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-left .main__video-btn svg {
  fill: #fff;
  width: auto;
  height: 54px;
}

/* line 328, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 360px;
  padding: 30px 49px 30px 41px;
}

@media screen and (max-width: 991px) {
  /* line 328, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-right {
    width: 300px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  /* line 328, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-right {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  /* line 328, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-right {
    padding: 30px 15px;
  }
}

/* line 348, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-title {
  padding-bottom: 20px;
  font-size: 26px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 40px;
}

@media screen and (max-width: 575px) {
  /* line 348, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-title {
    font-size: 22px;
  }
}

/* line 359, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-text {
  font-size: 17px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 26px;
}

@media screen and (max-width: 767px) {
  /* line 359, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-text {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 575px) {
  /* line 359, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-text {
    font-size: 15px;
  }
}

/* line 372, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-link {
  display: flex;
  align-items: center;
}

/* line 376, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-link span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.82px;
  line-height: 26px;
}

@media screen and (max-width: 575px) {
  /* line 376, src/scss/_components/resources/_facilities.scss */
  .facilities__more .facilities__block-link span {
    font-size: 12px;
  }
}

/* line 388, src/scss/_components/resources/_facilities.scss */
.facilities__more .facilities__block-link svg {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
}

/* line 398, src/scss/_components/resources/_facilities.scss */
.facilities__share {
  padding-bottom: 80px;
}

@media screen and (max-width: 575px) {
  /* line 398, src/scss/_components/resources/_facilities.scss */
  .facilities__share {
    padding-bottom: 40px;
  }
}

/* line 404, src/scss/_components/resources/_facilities.scss */
.facilities__share .facilities__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: 10px;
  background-color: #f2f5fa;
}

@media screen and (max-width: 575px) {
  /* line 404, src/scss/_components/resources/_facilities.scss */
  .facilities__share .facilities__wrapper {
    flex-wrap: wrap;
  }
}

/* line 415, src/scss/_components/resources/_facilities.scss */
.facilities__share .facilities__wrapper > p {
  padding-right: 30px;
  font-weight: bold;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 50px;
}

@media screen and (max-width: 575px) {
  /* line 415, src/scss/_components/resources/_facilities.scss */
  .facilities__share .facilities__wrapper > p {
    font-size: 16px;
    padding-right: 0;
    padding-bottom: 15px;
  }
}

/* line 431, src/scss/_components/resources/_facilities.scss */
.facilities__share-social {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  /* line 431, src/scss/_components/resources/_facilities.scss */
  .facilities__share-social {
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
  }
}

/* line 440, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--fb {
  background-image: linear-gradient(-179deg, #4C79D3 2%, #224A9B 94%);
}

/* line 443, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--fb svg {
  fill: #fff;
}

/* line 447, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--ln {
  background-image: linear-gradient(-179deg, #2198D3 3%, #107FB7 95%);
}

/* line 450, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--ln svg {
  fill: #fff;
}

/* line 454, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--tw {
  background-image: linear-gradient(-179deg, #4FBAFD 5%, #2AA3EF 92%);
}

/* line 457, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--tw svg {
  fill: #fff;
}

/* line 461, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--em {
  background-image: linear-gradient(-179deg, #4B4B4B 7%, #000000 91%);
}

/* line 464, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--em svg {
  fill: #fff;
}

/* line 468, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--sh {
  background-image: linear-gradient(-179deg, #FFFFFF 7%, #FDFDFD 88%);
  box-shadow: 0 2px 10px 0 rgba(0, 68, 157, 0.1);
}

/* line 472, src/scss/_components/resources/_facilities.scss */
.facilities__share-social--sh svg {
  fill: #000;
}

/* line 477, src/scss/_components/resources/_facilities.scss */
.facilities__share-social > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin-right: 10px;
}

/* line 486, src/scss/_components/resources/_facilities.scss */
.facilities__share-social > a:last-of-type {
  margin-right: 0;
}

/* line 493, src/scss/_components/resources/_facilities.scss */
.facilities__similar {
  padding-bottom: 131px;
}

/* line 496, src/scss/_components/resources/_facilities.scss */
.facilities__similar-title {
  padding-bottom: 30px;
  font-size: 26px;
  color: #000000;
  letter-spacing: 0;
  line-height: 50px;
}

@media screen and (max-width: 575px) {
  /* line 496, src/scss/_components/resources/_facilities.scss */
  .facilities__similar-title {
    font-size: 22px;
  }
}

/* line 3, src/scss/_components/iteration/_iteration.scss */
.iteration {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/iteration/_iteration.scss */
  .iteration {
    overflow-y: auto;
    height: auto;
    max-height: unset;
  }
}

/* line 14, src/scss/_components/iteration/_iteration.scss */
.iteration__slide {
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: 75px 15px;
}

@media screen and (max-width: 1023px) {
  /* line 14, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  /* line 14, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide {
    height: auto;
    max-height: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 424px) {
  /* line 14, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* line 39, src/scss/_components/iteration/_iteration.scss */
.iteration__slide > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 100%;
}

/* line 46, src/scss/_components/iteration/_iteration.scss */
.iteration__slide > div img {
  max-height: 100%;
}

/* line 52, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--image img {
  z-index: 2;
  position: relative;
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* line 65, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--text p {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 898px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 52px;
  color: #9b9b9b;
}

@media screen and (max-width: 1439px) {
  /* line 65, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--text p {
    font-size: 44px;
  }
}

@media screen and (max-width: 1365px) {
  /* line 65, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--text p {
    font-size: 40px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 65, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--text p {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 65, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--text p {
    max-width: 600px !important;
    font-size: 33px;
  }
}

@media screen and (max-width: 767px) {
  /* line 65, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--text p {
    max-width: 100% !important;
    font-size: 26px;
  }
}

/* line 93, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--text p span {
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 99, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--1 {
    padding-top: 150px;
  }
}

/* line 105, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--2 {
  padding-left: 120px;
  padding-right: 354px;
}

@media screen and (max-width: 1439px) {
  /* line 105, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--2 {
    padding-left: 70px;
    padding-right: 304px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 105, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--2 {
    padding-right: 244px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 105, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 105, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 125, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--4 {
  padding-left: 368px;
  padding-right: 250px;
}

@media screen and (max-width: 1439px) {
  /* line 125, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--4 {
    padding-left: 318px;
    padding-right: 200px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 125, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--4 {
    padding-left: 258px;
    padding-right: 140px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 125, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--4 {
    padding-left: 100px;
    padding-right: 90px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 125, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--4 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 151, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--5 .iteration__bg-path, .iteration__slide--27 .iteration__bg-path, .iteration__slide--33 .iteration__bg-path {
  top: 50%;
  right: -36%;
  transform: translateY(-50%);
  width: 1298px;
  height: 1298px;
  border-radius: 50%;
  background-color: #fafafa;
}

@media screen and (max-width: 1279px) {
  /* line 151, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 .iteration__bg-path, .iteration__slide--27 .iteration__bg-path, .iteration__slide--33 .iteration__bg-path {
    width: 1024px;
    height: 1024px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 151, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 .iteration__bg-path, .iteration__slide--27 .iteration__bg-path, .iteration__slide--33 .iteration__bg-path {
    left: 56%;
    right: auto;
  }
}

@media screen and (max-width: 767px) {
  /* line 151, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 .iteration__bg-path, .iteration__slide--27 .iteration__bg-path, .iteration__slide--33 .iteration__bg-path {
    width: 600px;
    height: 600px;
  }
}

/* line 176, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--5 {
  padding-right: 320px;
}

@media screen and (max-width: 1439px) {
  /* line 176, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 {
    padding-right: 170px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 176, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 {
    padding-right: 110px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 176, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--5 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 190, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--6 {
  padding-right: 303px;
}

@media screen and (max-width: 1439px) {
  /* line 190, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--6 {
    padding-right: 253px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 190, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--6 {
    padding-right: 193px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 190, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--6 {
    padding-right: 143px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 190, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 206, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--6 p {
  max-width: 1040px;
}

@media screen and (max-width: 1279px) {
  /* line 206, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--6 p {
    max-width: 898px;
  }
}

/* line 214, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--7, .iteration__slide--8, .iteration__slide--10, .iteration__slide--11, .iteration__slide--13, .iteration__slide--14, .iteration__slide--15, .iteration__slide--17, .iteration__slide--19, .iteration__slide--20, .iteration__slide--23, .iteration__slide--24, .iteration__slide--25, .iteration__slide--26, .iteration__slide--27, .iteration__slide--28, .iteration__slide--29, .iteration__slide--30, .iteration__slide--31 {
  padding-right: 282px;
}

@media screen and (max-width: 1439px) {
  /* line 214, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--7, .iteration__slide--8, .iteration__slide--10, .iteration__slide--11, .iteration__slide--13, .iteration__slide--14, .iteration__slide--15, .iteration__slide--17, .iteration__slide--19, .iteration__slide--20, .iteration__slide--23, .iteration__slide--24, .iteration__slide--25, .iteration__slide--26, .iteration__slide--27, .iteration__slide--28, .iteration__slide--29, .iteration__slide--30, .iteration__slide--31 {
    padding-right: 232px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 214, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--7, .iteration__slide--8, .iteration__slide--10, .iteration__slide--11, .iteration__slide--13, .iteration__slide--14, .iteration__slide--15, .iteration__slide--17, .iteration__slide--19, .iteration__slide--20, .iteration__slide--23, .iteration__slide--24, .iteration__slide--25, .iteration__slide--26, .iteration__slide--27, .iteration__slide--28, .iteration__slide--29, .iteration__slide--30, .iteration__slide--31 {
    padding-right: 173px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 214, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--7, .iteration__slide--8, .iteration__slide--10, .iteration__slide--11, .iteration__slide--13, .iteration__slide--14, .iteration__slide--15, .iteration__slide--17, .iteration__slide--19, .iteration__slide--20, .iteration__slide--23, .iteration__slide--24, .iteration__slide--25, .iteration__slide--26, .iteration__slide--27, .iteration__slide--28, .iteration__slide--29, .iteration__slide--30, .iteration__slide--31 {
    padding-right: 123px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 214, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--7, .iteration__slide--8, .iteration__slide--10, .iteration__slide--11, .iteration__slide--13, .iteration__slide--14, .iteration__slide--15, .iteration__slide--17, .iteration__slide--19, .iteration__slide--20, .iteration__slide--23, .iteration__slide--24, .iteration__slide--25, .iteration__slide--26, .iteration__slide--27, .iteration__slide--28, .iteration__slide--29, .iteration__slide--30, .iteration__slide--31 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 250, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--8 p {
  max-width: 760px;
}

/* line 259, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--9 .iteration__bg-path, .iteration__slide--16 .iteration__bg-path {
  top: 0;
  right: -68%;
  width: 1286px;
  height: 100%;
  transform: skewX(-30deg);
  background-color: #f8fafc;
}

@media screen and (max-width: 1279px) {
  /* line 259, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--9 .iteration__bg-path, .iteration__slide--16 .iteration__bg-path {
    width: 1086px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 259, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--9 .iteration__bg-path, .iteration__slide--16 .iteration__bg-path {
    left: 55%;
    right: auto;
  }
}

/* line 278, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--9 {
  padding-right: 170px;
}

@media screen and (max-width: 1439px) {
  /* line 278, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--9 {
    padding-right: 120px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 278, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--9 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 292, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--10 .iteration__bg img {
  position: absolute;
}

/* line 295, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--10 .iteration__bg img:nth-of-type(1) {
  right: 220px;
  bottom: 250px;
}

/* line 300, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--10 .iteration__bg img:nth-of-type(2) {
  right: -350px;
  bottom: -90px;
}

@media screen and (max-width: 1365px) {
  /* line 300, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--10 .iteration__bg img:nth-of-type(2) {
    transform: scale(0.5);
  }
}

/* line 313, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--11 p {
  max-width: 1040px;
}

@media screen and (max-width: 1279px) {
  /* line 313, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--11 p {
    max-width: 898px;
  }
}

/* line 328, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--13 p, .iteration__slide--15 p, .iteration__slide--18 p, .iteration__slide--22 p, .iteration__slide--24 p, .iteration__slide--26 p, .iteration__slide--32 p {
  max-width: 1315px;
}

@media screen and (max-width: 1279px) {
  /* line 328, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--13 p, .iteration__slide--15 p, .iteration__slide--18 p, .iteration__slide--22 p, .iteration__slide--24 p, .iteration__slide--26 p, .iteration__slide--32 p {
    max-width: 898px;
  }
}

/* line 336, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--16 {
  padding-right: 329px;
}

@media screen and (max-width: 1439px) {
  /* line 336, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--16 {
    padding-right: 279px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 336, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--16 {
    padding-right: 219px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 336, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--16 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 350, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--18 {
  padding-right: 231px;
}

@media screen and (max-width: 1439px) {
  /* line 350, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--18 {
    padding-right: 181px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 350, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--18 {
    padding-right: 121px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 350, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--18 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 365, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--20 p {
  max-width: 920px;
}

@media screen and (max-width: 1279px) {
  /* line 365, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--20 p {
    max-width: 898px;
  }
}

/* line 373, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--21 {
  padding-right: 418px;
}

@media screen and (max-width: 1439px) {
  /* line 373, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--21 {
    padding-right: 368px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 373, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--21 {
    padding-right: 308px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 373, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--21 {
    padding-right: 228px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 373, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--21 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 390, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--22 {
  padding-right: 241px;
}

@media screen and (max-width: 1439px) {
  /* line 390, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--22 {
    padding-right: 191px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 390, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--22 {
    padding-right: 131px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 390, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--22 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 404, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--27 {
  padding-left: 135px;
}

@media screen and (max-width: 1023px) {
  /* line 404, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--27 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 413, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--27 .iteration__bg-path {
  right: -20%;
}

@media screen and (max-width: 767px) {
  /* line 413, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--27 .iteration__bg-path {
    left: 50%;
    right: auto;
  }
}

/* line 425, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--28 p {
  max-width: 1180px;
}

@media screen and (max-width: 1279px) {
  /* line 425, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--28 p {
    max-width: 898px;
  }
}

/* line 435, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--29 .iteration__title {
  padding-left: 227px;
}

@media screen and (max-width: 1279px) {
  /* line 435, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--29 .iteration__title {
    padding-left: 167px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 435, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--29 .iteration__title {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  /* line 435, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--29 .iteration__title {
    padding-left: 0;
  }
}

/* line 451, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--30 p {
  max-width: 1420px;
}

@media screen and (max-width: 1279px) {
  /* line 451, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--30 p {
    max-width: 898px;
  }
}

/* line 459, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--32 {
  padding-right: 158px;
}

@media screen and (max-width: 1439px) {
  /* line 459, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--32 {
    padding-right: 108px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 459, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--32 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 470, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--33 {
  padding-left: 110px;
  padding-right: 59px;
}

@media screen and (max-width: 1439px) {
  /* line 470, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--33 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 470, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--33 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 483, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--33 .iteration__bg-path {
  left: 0;
  right: auto;
}

@media screen and (max-width: 767px) {
  /* line 483, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--33 .iteration__bg-path {
    left: 60%;
  }
}

/* line 494, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--34 {
  padding-left: 210px;
  padding-right: 170px;
}

@media screen and (max-width: 1439px) {
  /* line 494, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--34 {
    padding-left: 160px;
    padding-right: 120px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 494, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--34 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 494, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--34 {
    padding-bottom: 150px;
  }
}

/* line 512, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--34 .iteration__bg img {
  position: absolute;
}

/* line 515, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--34 .iteration__bg img:nth-of-type(1) {
  top: 100px;
  left: 100px;
}

@media screen and (max-width: 767px) {
  /* line 515, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--34 .iteration__bg img:nth-of-type(1) {
    top: 50px;
    left: 50px;
    transform: scale(0.75);
  }
}

/* line 525, src/scss/_components/iteration/_iteration.scss */
.iteration__slide--34 .iteration__bg img:nth-of-type(2) {
  right: -100px;
  bottom: -100px;
}

@media screen and (max-width: 767px) {
  /* line 525, src/scss/_components/iteration/_iteration.scss */
  .iteration__slide--34 .iteration__bg img:nth-of-type(2) {
    transform: scale(0.5);
    right: -160px;
    bottom: -160px;
  }
}

/* line 540, src/scss/_components/iteration/_iteration.scss */
.iteration__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

/* line 548, src/scss/_components/iteration/_iteration.scss */
.iteration__bg-path {
  position: absolute;
}

/* line 553, src/scss/_components/iteration/_iteration.scss */
.iteration__title {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 350px;
  line-height: 341px;
  color: #fafafa;
}

@media screen and (max-width: 1534px) {
  /* line 553, src/scss/_components/iteration/_iteration.scss */
  .iteration__title {
    font-size: 300px;
    line-height: 300px;
  }
}

@media screen and (max-width: 1439px) {
  /* line 553, src/scss/_components/iteration/_iteration.scss */
  .iteration__title {
    font-size: 250px;
    line-height: 250px;
  }
}

@media screen and (max-width: 1335px) {
  /* line 553, src/scss/_components/iteration/_iteration.scss */
  .iteration__title {
    font-size: 200px;
    line-height: 190px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 553, src/scss/_components/iteration/_iteration.scss */
  .iteration__title {
    font-size: 150px;
    line-height: 140px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 553, src/scss/_components/iteration/_iteration.scss */
  .iteration__title {
    font-size: 100px;
    line-height: 110px;
  }
}

/* line 585, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages {
  width: 100%;
  max-width: calc(100% / 3);
}

@media screen and (max-width: 767px) {
  /* line 585, src/scss/_components/iteration/_iteration.scss */
  .iteration__advantages {
    max-width: 100%;
    padding-bottom: 30px;
  }
}

/* line 598, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages-1 .iteration__advantages-img img, .iteration__advantages-3 .iteration__advantages-img img {
  position: relative;
  left: -25px;
}

/* line 607, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages-wrapper {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  width: 100%;
  max-width: 1253px;
  height: auto !important;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 607, src/scss/_components/iteration/_iteration.scss */
  .iteration__advantages-wrapper {
    flex-wrap: wrap;
  }
}

/* line 620, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages-img {
  min-height: 220px;
}

/* line 623, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  /* line 623, src/scss/_components/iteration/_iteration.scss */
  .iteration__advantages-img img {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 634, src/scss/_components/iteration/_iteration.scss */
.iteration__advantages-text {
  width: 100%;
  max-width: 310px;
  padding-left: 35px;
  padding-top: 35px;
}

@media screen and (max-width: 767px) {
  /* line 634, src/scss/_components/iteration/_iteration.scss */
  .iteration__advantages-text {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

/* line 648, src/scss/_components/iteration/_iteration.scss */
.iteration__search {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
}

/* line 656, src/scss/_components/iteration/_iteration.scss */
.iteration__search-title {
  width: 100%;
  max-width: 525px;
  padding-bottom: 90px;
  font-weight: bold;
  font-size: 26px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 656, src/scss/_components/iteration/_iteration.scss */
  .iteration__search-title {
    font-size: 22px;
  }
}

/* line 668, src/scss/_components/iteration/_iteration.scss */
.iteration__search-img {
  padding-bottom: 50px;
  max-height: 100%;
}

/* line 672, src/scss/_components/iteration/_iteration.scss */
.iteration__search-img img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
}

/* line 680, src/scss/_components/iteration/_iteration.scss */
.iteration__search-desc {
  padding-left: 80px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 680, src/scss/_components/iteration/_iteration.scss */
  .iteration__search-desc {
    font-size: 18px;
  }
}

/* line 691, src/scss/_components/iteration/_iteration.scss */
.iteration__ai {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
}

/* line 699, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-title {
  width: 100%;
  max-width: 525px;
  padding-bottom: 90px;
  font-weight: bold;
  font-size: 26px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 699, src/scss/_components/iteration/_iteration.scss */
  .iteration__ai-title {
    padding-bottom: 50px;
    font-size: 22px;
  }
}

/* line 712, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-badge {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 712, src/scss/_components/iteration/_iteration.scss */
  .iteration__ai-badge {
    padding-bottom: 50px;
  }
}

/* line 725, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-badge a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #00449d;
}

@media screen and (max-width: 767px) {
  /* line 725, src/scss/_components/iteration/_iteration.scss */
  .iteration__ai-badge a {
    max-width: 141px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* line 743, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-badge a p {
  position: relative;
  top: -1px;
  font-family: 'Open Sans', sans-serif;
  padding-right: 20px;
  line-height: 1;
  font-size: 18px;
  color: #fff;
}

/* line 753, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-badge a svg {
  width: 18px;
  height: 18px;
}

/* line 760, src/scss/_components/iteration/_iteration.scss */
.iteration__ai-desc {
  padding-left: 80px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 760, src/scss/_components/iteration/_iteration.scss */
  .iteration__ai-desc {
    font-size: 18px;
  }
}

/* line 771, src/scss/_components/iteration/_iteration.scss */
.iteration__column {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}

/* line 779, src/scss/_components/iteration/_iteration.scss */
.iteration__column-img {
  padding-bottom: 50px;
  max-height: 100%;
}

/* line 783, src/scss/_components/iteration/_iteration.scss */
.iteration__column-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 790, src/scss/_components/iteration/_iteration.scss */
.iteration__column-text {
  padding-left: 100px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 790, src/scss/_components/iteration/_iteration.scss */
  .iteration__column-text {
    font-size: 18px;
  }
}

/* line 801, src/scss/_components/iteration/_iteration.scss */
.iteration__pipelines {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

/* line 809, src/scss/_components/iteration/_iteration.scss */
.iteration__pipelines-title {
  padding-left: 112px;
  padding-bottom: 40px;
  font-weight: bold;
  font-size: 26px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 809, src/scss/_components/iteration/_iteration.scss */
  .iteration__pipelines-title {
    padding-left: 0;
    font-size: 22px;
  }
}

/* line 821, src/scss/_components/iteration/_iteration.scss */
.iteration__pipelines-img {
  padding-bottom: 20px;
  max-height: 100%;
}

/* line 825, src/scss/_components/iteration/_iteration.scss */
.iteration__pipelines-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 832, src/scss/_components/iteration/_iteration.scss */
.iteration__pipelines-desc {
  padding-left: 160px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 832, src/scss/_components/iteration/_iteration.scss */
  .iteration__pipelines-desc {
    padding-left: 80px;
    font-size: 18px;
  }
}

/* line 844, src/scss/_components/iteration/_iteration.scss */
.iteration__contacts {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 920px;
  margin-right: auto;
}

/* line 851, src/scss/_components/iteration/_iteration.scss */
.iteration__contacts-title {
  min-height: 67px;
  padding-left: 115px;
  padding-bottom: 25px;
  font-weight: bold;
  font-size: 30px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 851, src/scss/_components/iteration/_iteration.scss */
  .iteration__contacts-title {
    padding-left: 0;
    font-size: 22px;
  }
}

/* line 864, src/scss/_components/iteration/_iteration.scss */
.iteration__contacts-img {
  max-height: calc(100% - 67px);
}

/* line 867, src/scss/_components/iteration/_iteration.scss */
.iteration__contacts-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 875, src/scss/_components/iteration/_iteration.scss */
.iteration__schedule {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

/* line 883, src/scss/_components/iteration/_iteration.scss */
.iteration__schedule-img {
  max-height: calc(100% - 84px);
}

/* line 886, src/scss/_components/iteration/_iteration.scss */
.iteration__schedule-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 893, src/scss/_components/iteration/_iteration.scss */
.iteration__schedule-desc {
  min-height: 84px;
  padding-top: 50px;
  padding-left: 120px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 893, src/scss/_components/iteration/_iteration.scss */
  .iteration__schedule-desc {
    padding-left: 80px;
    font-size: 18px;
  }
}

/* line 907, src/scss/_components/iteration/_iteration.scss */
.iteration__data {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

/* line 915, src/scss/_components/iteration/_iteration.scss */
.iteration__data-title {
  width: 100%;
  max-width: 450px;
  min-height: 136px;
  padding-bottom: 80px;
  font-weight: normal;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  /* line 915, src/scss/_components/iteration/_iteration.scss */
  .iteration__data-title {
    font-size: 22px;
  }
}

/* line 927, src/scss/_components/iteration/_iteration.scss */
.iteration__data-img {
  padding-left: 50px;
  max-height: calc(100% - 136px);
}

/* line 931, src/scss/_components/iteration/_iteration.scss */
.iteration__data-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 939, src/scss/_components/iteration/_iteration.scss */
.iteration__almanac {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

/* line 947, src/scss/_components/iteration/_iteration.scss */
.iteration__almanac-img {
  max-height: calc(100% - 56px);
}

/* line 950, src/scss/_components/iteration/_iteration.scss */
.iteration__almanac-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 957, src/scss/_components/iteration/_iteration.scss */
.iteration__almanac-desc {
  min-height: 56px;
  padding-left: 120px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 957, src/scss/_components/iteration/_iteration.scss */
  .iteration__almanac-desc {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 18px;
  }
}

/* line 971, src/scss/_components/iteration/_iteration.scss */
.iteration__flow {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}

/* line 979, src/scss/_components/iteration/_iteration.scss */
.iteration__flow-img {
  max-height: 100%;
}

/* line 982, src/scss/_components/iteration/_iteration.scss */
.iteration__flow-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 989, src/scss/_components/iteration/_iteration.scss */
.iteration__flow-desc {
  padding-left: 120px;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 767px) {
  /* line 989, src/scss/_components/iteration/_iteration.scss */
  .iteration__flow-desc {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 18px;
  }
}

/* line 1002, src/scss/_components/iteration/_iteration.scss */
.iteration__setting {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

/* line 1010, src/scss/_components/iteration/_iteration.scss */
.iteration__setting-img {
  max-height: calc(100% - 84px);
}

/* line 1013, src/scss/_components/iteration/_iteration.scss */
.iteration__setting-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* line 1020, src/scss/_components/iteration/_iteration.scss */
.iteration__setting-desc {
  width: 100%;
  max-width: 640px;
  min-height: 84px;
  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
  font-size: 20px;
  color: #848484;
}

@media screen and (max-width: 1023px) {
  /* line 1020, src/scss/_components/iteration/_iteration.scss */
  .iteration__setting-desc {
    top: 0;
    padding-top: 30px;
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1020, src/scss/_components/iteration/_iteration.scss */
  .iteration__setting-desc {
    font-size: 18px;
  }
}

/* line 1040, src/scss/_components/iteration/_iteration.scss */
.iteration__now {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

/* line 1048, src/scss/_components/iteration/_iteration.scss */
.iteration__now-title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 68px;
  color: #000;
}

@media screen and (max-width: 1439px) {
  /* line 1048, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-title {
    font-size: 58px;
  }
}

@media screen and (max-width: 1365px) {
  /* line 1048, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 1048, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-title {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1048, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-title {
    font-size: 40px;
  }
}

/* line 1067, src/scss/_components/iteration/_iteration.scss */
.iteration__now-desc {
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 24px;
  color: #9b9b9b;
}

@media screen and (max-width: 1365px) {
  /* line 1067, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-desc {
    font-size: 22px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 1067, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-desc {
    font-size: 20px;
  }
}

/* line 1080, src/scss/_components/iteration/_iteration.scss */
.iteration__now-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 216px;
  height: 70px;
  border-radius: 10px;
  background-color: #00449d;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.08em;
  color: #fff;
}

@media screen and (max-width: 1439px) {
  /* line 1080, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-btn {
    height: 60px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1365px) {
  /* line 1080, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-btn {
    height: 55px;
    letter-spacing: 0.04em;
    font-size: 16px;
  }
}

@media screen and (max-width: 1365px) {
  /* line 1080, src/scss/_components/iteration/_iteration.scss */
  .iteration__now-btn {
    height: 50px;
    width: 100%;
    max-width: 200px;
    letter-spacing: 0.02em;
    font-size: 14px;
  }
}

/* line 1111, src/scss/_components/iteration/_iteration.scss */
.iteration__now-btn-wrapper {
  display: flex;
}

/* line 1118, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-container {
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

@media screen and (max-width: 767px) {
  /* line 1118, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-container {
    opacity: 1;
  }
}

/* line 1128, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-wrapper {
  position: relative;
}

@media screen and (max-width: 767px) {
  /* line 1128, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-wrapper {
    display: block;
    height: 100% !important;
  }
}

/* line 1136, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide {
  z-index: 0;
  width: auto;
}

@media screen and (max-width: 1023px) {
  /* line 1136, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide {
    width: 100% !important;
  }
}

/* line 1145, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > img,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > p,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > div, .iteration .swiper-slide.viewport-startTimeline-js .iteration__slide__title,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__advantages {
  animation-fill-mode: both;
  animation-duration: 0.75s;
}

/* line 1154, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > img,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > p,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > div:last-of-type {
  animation-name: slideInRight;
}

@media screen and (max-width: 767px) {
  /* line 1154, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > img,
  .iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > p,
  .iteration .swiper-slide.viewport-startTimeline-js .iteration__slide > div:last-of-type {
    animation-name: slideInUp;
  }
}

/* line 1163, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide__title,
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__bg {
  animation-name: fadeIn, zoomIn;
}

/* line 1168, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__advantages {
  animation-name: slideInRight;
}

@media screen and (max-width: 767px) {
  /* line 1168, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__advantages {
    animation-name: slideInUp;
  }
}

/* line 1174, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__advantages-2 {
  animation-delay: 0.2s;
}

/* line 1177, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide.viewport-startTimeline-js .iteration__slide .iteration__advantages-3 {
  animation-delay: 0.4s;
}

/* line 1184, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide:nth-of-type(1) {
  width: 100%;
}

/* line 1187, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide:nth-of-type(3) {
  width: 1383px;
}

@media screen and (max-width: 1279px) {
  /* line 1187, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide:nth-of-type(3) {
    width: 1024px;
  }
}

/* line 1193, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide:nth-of-type(12) {
  width: 1250px;
}

@media screen and (max-width: 1439px) {
  /* line 1193, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide:nth-of-type(12) {
    width: 1000px;
  }
}

/* line 1199, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide:nth-of-type(23) {
  width: 1050px;
}

/* line 1202, src/scss/_components/iteration/_iteration.scss */
.iteration .swiper-slide:nth-of-type(33) {
  width: 1400px;
}

@media screen and (max-width: 1279px) {
  /* line 1202, src/scss/_components/iteration/_iteration.scss */
  .iteration .swiper-slide:nth-of-type(33) {
    width: 1100px;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(200px, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

/* line 3, src/scss/_components/sale/_main.scss */
.s-main {
  position: relative;
  padding-top: 200px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_main.scss */
  .s-main {
    padding-top: 150px;
  }
}

/* line 16, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-img-obj-1 {
  top: 24%;
  bottom: auto;
}

/* line 23, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-icon-1 {
  top: 200px;
  bottom: auto;
  left: 165px;
  width: 100px;
  height: 101px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 23, src/scss/_components/sale/_main.scss */
  .s-main--mod .s-main__bg-icon-1 {
    top: 85px;
  }
}

/* line 35, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-icon-1 svg {
  transform: translateY(-9px);
}

/* line 39, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-icon-2 {
  left: 80px;
  top: auto;
  bottom: 120px;
  width: 144px;
  height: 153px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 39, src/scss/_components/sale/_main.scss */
  .s-main--mod .s-main__bg-icon-2 {
    bottom: 80px;
  }
}

/* line 51, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-icon-2 svg {
  transform: translate(2px, -16px);
}

/* line 56, src/scss/_components/sale/_main.scss */
.s-main--mod .s-main__bg-icon-4 {
  top: 60%;
}

/* line 64, src/scss/_components/sale/_main.scss */
.s-main__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 72, src/scss/_components/sale/_main.scss */
.s-main__bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 79, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj {
  position: absolute;
}

/* line 82, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-1 {
  left: 0;
  bottom: 30px;
}

/* line 86, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-2 {
  left: 20%;
  bottom: 50px;
}

/* line 90, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-3 {
  top: 50%;
  left: 50px;
}

/* line 94, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-4 {
  top: 30px;
  left: 55%;
}

/* line 98, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-5 {
  top: 200px;
  right: 20%;
}

/* line 102, src/scss/_components/sale/_main.scss */
.s-main__bg-img-obj-6 {
  bottom: 30px;
  right: 22%;
}

/* line 109, src/scss/_components/sale/_main.scss */
.s-main__bg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 767px) {
  /* line 109, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon {
    opacity: 0;
    visibility: hidden;
  }
}

/* line 121, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-1 {
  left: 80px;
  bottom: 120px;
  width: 100px;
  height: 101px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
  background-size: cover;
}

/* line 129, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-1 svg {
  transform: translateY(-9px);
}

/* line 133, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-2 {
  top: 200px;
  left: 165px;
  width: 144px;
  height: 153px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 133, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon-2 {
    left: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 133, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon-2 {
    top: 85px;
  }
}

/* line 147, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-2 svg {
  transform: translate(2px, -16px);
}

/* line 151, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-3 {
  top: 0;
  left: 60%;
  width: 124px;
  height: 104px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-7@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  /* line 151, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon-3 {
    left: 40%;
  }
}

/* line 162, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-3 svg {
  transform: translate(-2px, -20px);
}

/* line 166, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-4 {
  top: 50%;
  right: 70px;
  width: 130px;
  height: 165px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-9@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 166, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon-4 {
    right: 0;
  }
}

@media screen and (max-width: 1023px) {
  /* line 166, src/scss/_components/sale/_main.scss */
  .s-main__bg-icon-4 {
    opacity: 0;
    visibility: hidden;
  }
}

/* line 181, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-4 svg {
  transform: translate(2px, -23px);
}

/* line 185, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-5 {
  bottom: -50px;
  right: 25%;
  width: 100px;
  height: 101px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-11@2x.png");
  background-size: cover;
}

/* line 193, src/scss/_components/sale/_main.scss */
.s-main__bg-icon-5 svg {
  transform: translateY(-10px);
}

/* line 198, src/scss/_components/sale/_main.scss */
.s-main__bg-icon svg {
  fill: #fff;
}

/* line 204, src/scss/_components/sale/_main.scss */
.s-main__wrapper {
  z-index: 2;
  position: relative;
}

/* line 209, src/scss/_components/sale/_main.scss */
.s-main__title, .s-main__subtitle, .s-main__desc {
  padding-bottom: 40px;
  text-align: center;
}

/* line 216, src/scss/_components/sale/_main.scss */
.s-main__title, .s-main__subtitle {
  color: #000000;
}

/* line 221, src/scss/_components/sale/_main.scss */
.s-main__title {
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 221, src/scss/_components/sale/_main.scss */
  .s-main__title {
    font-size: 55px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 221, src/scss/_components/sale/_main.scss */
  .s-main__title {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  /* line 221, src/scss/_components/sale/_main.scss */
  .s-main__title {
    font-size: 42px;
  }
  /* line 234, src/scss/_components/sale/_main.scss */
  .s-main__title br {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  /* line 221, src/scss/_components/sale/_main.scss */
  .s-main__title {
    font-size: 36px;
  }
}

/* line 243, src/scss/_components/sale/_main.scss */
.s-main__subtitle {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 243, src/scss/_components/sale/_main.scss */
  .s-main__subtitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 243, src/scss/_components/sale/_main.scss */
  .s-main__subtitle {
    font-size: 33px;
  }
}

@media screen and (max-width: 767px) {
  /* line 243, src/scss/_components/sale/_main.scss */
  .s-main__subtitle {
    font-size: 30px;
  }
}

/* line 258, src/scss/_components/sale/_main.scss */
.s-main__desc {
  width: 100%;
  max-width: 758px;
  margin-left: auto;
  margin-right: auto;
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1279px) {
  /* line 258, src/scss/_components/sale/_main.scss */
  .s-main__desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 258, src/scss/_components/sale/_main.scss */
  .s-main__desc {
    font-size: 16px;
  }
}

/* line 274, src/scss/_components/sale/_main.scss */
.s-main__btn {
  width: auto;
  max-width: unset;
  min-width: 239px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 286, src/scss/_components/sale/_main.scss */
.s-main__btn:hover {
  color: #cd084b;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 292, src/scss/_components/sale/_main.scss */
.s-main__btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 3, src/scss/_components/sale/_why.scss */
.why {
  position: relative;
  padding-top: 40px;
  padding-bottom: 150px;
}

/* line 8, src/scss/_components/sale/_why.scss */
.why__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 15, src/scss/_components/sale/_why.scss */
.why__bg img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/* line 23, src/scss/_components/sale/_why.scss */
.why__wrapper {
  z-index: 2;
  position: relative;
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 23, src/scss/_components/sale/_why.scss */
  .why__wrapper {
    flex-wrap: wrap;
  }
}

/* line 31, src/scss/_components/sale/_why.scss */
.why__wrapper-left {
  width: 400px;
  padding-right: 15px;
}

@media screen and (max-width: 1023px) {
  /* line 31, src/scss/_components/sale/_why.scss */
  .why__wrapper-left {
    width: 300px;
  }
}

@media screen and (max-width: 767px) {
  /* line 31, src/scss/_components/sale/_why.scss */
  .why__wrapper-left {
    width: 100%;
    padding-right: 0;
  }
}

/* line 43, src/scss/_components/sale/_why.scss */
.why__wrapper-right {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 50, src/scss/_components/sale/_why.scss */
.why__title {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 50, src/scss/_components/sale/_why.scss */
  .why__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 50, src/scss/_components/sale/_why.scss */
  .why__title {
    padding-bottom: 40px;
    font-size: 33px;
    text-align: center;
  }
}

/* line 65, src/scss/_components/sale/_why.scss */
.why__subtitle {
  padding-bottom: 30px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 65, src/scss/_components/sale/_why.scss */
  .why__subtitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 65, src/scss/_components/sale/_why.scss */
  .why__subtitle {
    font-size: 30px;
    text-align: center;
  }
}

/* line 81, src/scss/_components/sale/_why.scss */
.why__list li {
  display: flex;
  padding-bottom: 30px;
}

/* line 85, src/scss/_components/sale/_why.scss */
.why__list li:last-of-type {
  padding-bottom: 80px;
}

/* line 90, src/scss/_components/sale/_why.scss */
.why__list li > div:nth-of-type(1) {
  width: 35px;
  padding-top: 6px;
}

/* line 94, src/scss/_components/sale/_why.scss */
.why__list li > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 102, src/scss/_components/sale/_why.scss */
.why__list span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00449d;
}

/* line 111, src/scss/_components/sale/_why.scss */
.why__list span svg {
  width: 10px;
  height: 15px;
  fill: #fff;
}

/* line 118, src/scss/_components/sale/_why.scss */
.why__list p {
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1023px) {
  /* line 118, src/scss/_components/sale/_why.scss */
  .why__list p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 118, src/scss/_components/sale/_why.scss */
  .why__list p {
    font-size: 16px;
  }
}

/* line 131, src/scss/_components/sale/_why.scss */
.why__btn {
  min-width: 239px;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
}

@media screen and (max-width: 767px) {
  /* line 131, src/scss/_components/sale/_why.scss */
  .why__btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 139, src/scss/_components/sale/_why.scss */
.why__btn:hover {
  color: #cd084b;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 145, src/scss/_components/sale/_why.scss */
.why__btn-wrapper {
  display: flex;
}

/* line 3, src/scss/_components/sale/_results.scss */
.results {
  overflow: hidden;
  position: relative;
  min-height: 770px;
  padding-top: 140px;
  padding-bottom: 60px;
  background-image: linear-gradient(242deg, #001632 0%, #00449d);
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_results.scss */
  .results {
    padding-top: 100px;
  }
}

/* line 14, src/scss/_components/sale/_results.scss */
.results__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-main.png");
  background-repeat: no-repeat;
  background-position: center 120px;
  background-size: cover;
}

/* line 32, src/scss/_components/sale/_results.scss */
.results__wrapper {
  z-index: 2;
  position: relative;
}

/* line 37, src/scss/_components/sale/_results.scss */
.results__title {
  padding-bottom: 80px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #ffffff;
}

@media screen and (max-width: 1023px) {
  /* line 37, src/scss/_components/sale/_results.scss */
  .results__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 37, src/scss/_components/sale/_results.scss */
  .results__title {
    font-size: 33px;
  }
}

@media screen and (max-width: 424px) {
  /* line 37, src/scss/_components/sale/_results.scss */
  .results__title {
    text-align: center;
  }
  /* line 52, src/scss/_components/sale/_results.scss */
  .results__title br {
    display: none;
  }
}

/* line 57, src/scss/_components/sale/_results.scss */
.results__title span {
  position: relative;
}

/* line 60, src/scss/_components/sale/_results.scss */
.results__title span:before {
  content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98%;
  height: 22px;
  background-color: #cd084b;
}

@media screen and (max-width: 1023px) {
  /* line 60, src/scss/_components/sale/_results.scss */
  .results__title span:before {
    height: 20px;
  }
}

/* line 77, src/scss/_components/sale/_results.scss */
.results__box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* line 81, src/scss/_components/sale/_results.scss */
.results__box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 3);
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  /* line 81, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div {
    max-width: calc(100% / 2);
  }
  /* line 88, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div:nth-of-type(even) {
    text-align: right;
  }
}

@media screen and (max-width: 424px) {
  /* line 81, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div {
    max-width: 100%;
    padding-bottom: 40px;
    text-align: center;
  }
  /* line 97, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div:nth-of-type(even) {
    text-align: center;
  }
}

/* line 102, src/scss/_components/sale/_results.scss */
.results__box-wrapper > div:nth-of-type(2), .results__box-wrapper > div:nth-of-type(5) {
  padding-left: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 102, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div:nth-of-type(2), .results__box-wrapper > div:nth-of-type(5) {
    padding-left: 0;
  }
}

/* line 110, src/scss/_components/sale/_results.scss */
.results__box-wrapper > div:nth-of-type(3), .results__box-wrapper > div:nth-of-type(6) {
  padding-left: 100px;
}

@media screen and (max-width: 1023px) {
  /* line 110, src/scss/_components/sale/_results.scss */
  .results__box-wrapper > div:nth-of-type(3), .results__box-wrapper > div:nth-of-type(6) {
    padding-left: 0;
  }
}

/* line 120, src/scss/_components/sale/_results.scss */
.results__box-num {
  padding-bottom: 20px;
  font-size: 60px;
  font-weight: 300;
  line-height: 1.17;
  color: #ffffff;
}

@media screen and (max-width: 1023px) {
  /* line 120, src/scss/_components/sale/_results.scss */
  .results__box-num {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 120, src/scss/_components/sale/_results.scss */
  .results__box-num {
    font-size: 45px;
  }
}

@media screen and (max-width: 424px) {
  /* line 120, src/scss/_components/sale/_results.scss */
  .results__box-num {
    padding-bottom: 10px;
  }
}

/* line 137, src/scss/_components/sale/_results.scss */
.results__box-desc {
  font-size: 19px;
  line-height: 1.58;
  color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 1023px) {
  /* line 137, src/scss/_components/sale/_results.scss */
  .results__box-desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 137, src/scss/_components/sale/_results.scss */
  .results__box-desc {
    font-size: 16px;
  }
}

/* line 3, src/scss/_components/sale/_more.scss */
.more {
  position: relative;
  padding-top: 130px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_more.scss */
  .more {
    padding-top: 75px;
  }
}

/* line 11, src/scss/_components/sale/_more.scss */
.more__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 18, src/scss/_components/sale/_more.scss */
.more__bg img {
  position: absolute;
  top: -60px;
  left: 35%;
  transform: translateX(-50%);
}

/* line 26, src/scss/_components/sale/_more.scss */
.more .c-grid-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 1279px) {
  /* line 26, src/scss/_components/sale/_more.scss */
  .more .c-grid-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 35, src/scss/_components/sale/_more.scss */
.more__wrapper {
  overflow: hidden;
  padding-top: 15px;
}

/* line 40, src/scss/_components/sale/_more.scss */
.more__row {
  display: flex;
  align-items: center;
  padding-bottom: 130px;
}

@media screen and (max-width: 767px) {
  /* line 40, src/scss/_components/sale/_more.scss */
  .more__row {
    flex-wrap: wrap;
    padding-bottom: 75px;
  }
}

/* line 52, src/scss/_components/sale/_more.scss */
.more__row-1 .more__cover, .more__row-1 .more__img, .more__row-3 .more__cover, .more__row-3 .more__img {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  /* line 52, src/scss/_components/sale/_more.scss */
  .more__row-1 .more__cover, .more__row-1 .more__img, .more__row-3 .more__cover, .more__row-3 .more__img {
    margin-right: auto;
  }
}

/* line 60, src/scss/_components/sale/_more.scss */
.more__row-1 .more__img, .more__row-3 .more__img {
  transform: translateX(200px);
}

@media screen and (max-width: 1679px) {
  /* line 60, src/scss/_components/sale/_more.scss */
  .more__row-1 .more__img, .more__row-3 .more__img {
    transform: translateX(100px);
  }
}

@media screen and (max-width: 1365px) {
  /* line 60, src/scss/_components/sale/_more.scss */
  .more__row-1 .more__img, .more__row-3 .more__img {
    transform: translateX(75px);
  }
}

@media screen and (max-width: 767px) {
  /* line 60, src/scss/_components/sale/_more.scss */
  .more__row-1 .more__img, .more__row-3 .more__img {
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  /* line 75, src/scss/_components/sale/_more.scss */
  .more__row-2, .more__row-4 {
    flex-direction: column-reverse;
  }
}

/* line 82, src/scss/_components/sale/_more.scss */
.more__row-2 .more__cover, .more__row-2 .more__img, .more__row-4 .more__cover, .more__row-4 .more__img {
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 82, src/scss/_components/sale/_more.scss */
  .more__row-2 .more__cover, .more__row-2 .more__img, .more__row-4 .more__cover, .more__row-4 .more__img {
    margin-left: 0;
  }
}

/* line 90, src/scss/_components/sale/_more.scss */
.more__row-2 .more__img, .more__row-4 .more__img {
  transform: translateX(-200px);
}

@media screen and (max-width: 1439px) {
  /* line 90, src/scss/_components/sale/_more.scss */
  .more__row-2 .more__img, .more__row-4 .more__img {
    transform: translateX(-250px);
  }
}

@media screen and (max-width: 1365px) {
  /* line 90, src/scss/_components/sale/_more.scss */
  .more__row-2 .more__img, .more__row-4 .more__img {
    transform: translateX(-300px);
  }
}

@media screen and (max-width: 1279px) {
  /* line 90, src/scss/_components/sale/_more.scss */
  .more__row-2 .more__img, .more__row-4 .more__img {
    transform: translateX(-75px);
  }
}

@media screen and (max-width: 767px) {
  /* line 90, src/scss/_components/sale/_more.scss */
  .more__row-2 .more__img, .more__row-4 .more__img {
    transform: translateX(0);
  }
}

/* line 109, src/scss/_components/sale/_more.scss */
.more__col {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 109, src/scss/_components/sale/_more.scss */
  .more__col {
    width: 100%;
  }
}

/* line 116, src/scss/_components/sale/_more.scss */
.more__cover {
  width: 100%;
  max-width: 520px;
}

/* line 121, src/scss/_components/sale/_more.scss */
.more__title {
  padding-bottom: 80px;
  font-size: 40px;
  font-weight: 900;
}

@media screen and (max-width: 1023px) {
  /* line 121, src/scss/_components/sale/_more.scss */
  .more__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 121, src/scss/_components/sale/_more.scss */
  .more__title {
    font-size: 33px;
  }
}

@media screen and (max-width: 424px) {
  /* line 121, src/scss/_components/sale/_more.scss */
  .more__title {
    text-align: center;
  }
  /* line 134, src/scss/_components/sale/_more.scss */
  .more__title br {
    display: none;
  }
}

/* line 139, src/scss/_components/sale/_more.scss */
.more__title span {
  position: relative;
}

/* line 142, src/scss/_components/sale/_more.scss */
.more__title span i {
  z-index: 2;
  position: relative;
  font-style: normal;
}

/* line 148, src/scss/_components/sale/_more.scss */
.more__title span:before {
  content: '';
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98%;
  height: 22px;
  background-color: #ffd400;
}

@media screen and (max-width: 1023px) {
  /* line 148, src/scss/_components/sale/_more.scss */
  .more__title span:before {
    height: 20px;
  }
}

/* line 164, src/scss/_components/sale/_more.scss */
.more__img {
  display: block;
  height: auto;
}

@media screen and (max-width: 1279px) {
  /* line 164, src/scss/_components/sale/_more.scss */
  .more__img {
    max-width: 100%;
  }
}

/* line 172, src/scss/_components/sale/_more.scss */
.more__tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 1;
  color: #000000;
}

@media screen and (max-width: 1365px) {
  /* line 172, src/scss/_components/sale/_more.scss */
  .more__tabs {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 172, src/scss/_components/sale/_more.scss */
  .more__tabs {
    height: 35px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.3;
    font-size: 16px;
  }
}

@media screen and (max-width: 424px) {
  /* line 172, src/scss/_components/sale/_more.scss */
  .more__tabs {
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    width: 100%;
  }
}

/* line 203, src/scss/_components/sale/_more.scss */
.more__tabs:last-of-type {
  margin-right: 0;
}

/* line 207, src/scss/_components/sale/_more.scss */
.more__tabs.is-active {
  background-color: #00449d;
  color: #ffffff;
}

/* line 212, src/scss/_components/sale/_more.scss */
.more__tabs-wrapper {
  display: flex;
  align-items: center;
  transform: translateX(-20px);
  padding-bottom: 40px;
}

@media screen and (max-width: 1279px) {
  /* line 212, src/scss/_components/sale/_more.scss */
  .more__tabs-wrapper {
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  /* line 212, src/scss/_components/sale/_more.scss */
  .more__tabs-wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 424px) {
  /* line 212, src/scss/_components/sale/_more.scss */
  .more__tabs-wrapper {
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
    justify-content: space-between;
  }
}

/* line 232, src/scss/_components/sale/_more.scss */
.more__subtitle {
  padding-bottom: 30px;
  font-size: 40px;
  font-weight: 300;
  color: #000000;
}

@media screen and (max-width: 1365px) {
  /* line 232, src/scss/_components/sale/_more.scss */
  .more__subtitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 232, src/scss/_components/sale/_more.scss */
  .more__subtitle {
    font-size: 33px;
    padding-bottom: 15px;
  }
  /* line 244, src/scss/_components/sale/_more.scss */
  .more__subtitle br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 232, src/scss/_components/sale/_more.scss */
  .more__subtitle {
    font-size: 30px;
    text-align: center;
  }
}

/* line 254, src/scss/_components/sale/_more.scss */
.more__desc {
  padding-bottom: 40px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1365px) {
  /* line 254, src/scss/_components/sale/_more.scss */
  .more__desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 254, src/scss/_components/sale/_more.scss */
  .more__desc {
    font-size: 16px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 268, src/scss/_components/sale/_more.scss */
  .more__list {
    padding-bottom: 40px;
  }
}

/* line 273, src/scss/_components/sale/_more.scss */
.more__list li {
  display: flex;
  padding-bottom: 30px;
}

@media screen and (max-width: 1023px) {
  /* line 273, src/scss/_components/sale/_more.scss */
  .more__list li {
    padding-bottom: 20px;
  }
}

/* line 280, src/scss/_components/sale/_more.scss */
.more__list li:last-of-type {
  padding-bottom: 0;
}

/* line 285, src/scss/_components/sale/_more.scss */
.more__list li > div:nth-of-type(1) {
  width: 35px;
  padding-top: 6px;
}

/* line 289, src/scss/_components/sale/_more.scss */
.more__list li > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 297, src/scss/_components/sale/_more.scss */
.more__list span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00449d;
}

/* line 306, src/scss/_components/sale/_more.scss */
.more__list span svg {
  width: 10px;
  height: 15px;
  fill: #fff;
}

/* line 313, src/scss/_components/sale/_more.scss */
.more__list p {
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1365px) {
  /* line 313, src/scss/_components/sale/_more.scss */
  .more__list p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 313, src/scss/_components/sale/_more.scss */
  .more__list p {
    font-size: 16px;
  }
}

/* line 3, src/scss/_components/sale/_satisfied.scss */
.satisfied {
  padding-top: 130px;
  padding-bottom: 75px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_satisfied.scss */
  .satisfied {
    padding-top: 75px;
    padding-bottom: 50px;
  }
}

/* line 11, src/scss/_components/sale/_satisfied.scss */
.satisfied__title {
  padding-bottom: 60px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000;
}

@media screen and (max-width: 1023px) {
  /* line 11, src/scss/_components/sale/_satisfied.scss */
  .satisfied__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 11, src/scss/_components/sale/_satisfied.scss */
  .satisfied__title {
    font-size: 33px;
    text-align: center;
  }
}

/* line 25, src/scss/_components/sale/_satisfied.scss */
.satisfied__title span {
  position: relative;
}

/* line 28, src/scss/_components/sale/_satisfied.scss */
.satisfied__title span:before {
  content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98%;
  height: 22px;
  background-color: #ffd400;
}

@media screen and (max-width: 1023px) {
  /* line 28, src/scss/_components/sale/_satisfied.scss */
  .satisfied__title span:before {
    height: 20px;
  }
}

/* line 44, src/scss/_components/sale/_satisfied.scss */
.satisfied__block {
  width: 100%;
  max-width: 285px;
}

@media screen and (max-width: 767px) {
  /* line 44, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block {
    max-width: 1005px;
    text-align: center;
  }
}

/* line 52, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-wrapper {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 52, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-wrapper {
    flex-wrap: wrap;
  }
}

/* line 58, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-wrapper > div {
  width: 100%;
  max-width: calc(100% / 3);
  padding-bottom: 55px;
}

@media screen and (max-width: 1023px) {
  /* line 58, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-wrapper > div {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 58, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-wrapper > div {
    max-width: 100%;
    padding-right: 0;
  }
}

/* line 72, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-desc {
  padding-bottom: 40px;
  min-height: 490px;
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1023px) {
  /* line 72, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-desc {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  /* line 72, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-desc {
    min-height: unset;
  }
}

/* line 86, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-img {
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  /* line 86, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-img {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 98, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 109, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-name, .satisfied__block-pos {
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 109, src/scss/_components/sale/_satisfied.scss */
  .satisfied__block-name, .satisfied__block-pos {
    font-size: 18px;
  }
}

/* line 119, src/scss/_components/sale/_satisfied.scss */
.satisfied__block-name {
  font-weight: 900;
}

/* line 3, src/scss/_components/sale/_join.scss */
.join {
  overflow: hidden;
  position: relative;
  min-height: 530px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: linear-gradient(250deg, #001632 0%, #00449d);
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_join.scss */
  .join {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* line 15, src/scss/_components/sale/_join.scss */
.join__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 23, src/scss/_components/sale/_join.scss */
.join__bg:before {
  content: '';
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-join-bg@2x.png");
  background-repeat: no-repeat;
  background-position: center 35%;
  background-size: cover;
}

/* line 44, src/scss/_components/sale/_join.scss */
.join__wrapper {
  z-index: 2;
  position: relative;
}

/* line 49, src/scss/_components/sale/_join.scss */
.join__desc {
  padding-bottom: 60px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #ffffff;
}

@media screen and (max-width: 1279px) {
  /* line 49, src/scss/_components/sale/_join.scss */
  .join__desc {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 49, src/scss/_components/sale/_join.scss */
  .join__desc {
    font-size: 33px;
  }
}

@media screen and (max-width: 424px) {
  /* line 49, src/scss/_components/sale/_join.scss */
  .join__desc {
    font-size: 30px;
  }
}

/* line 66, src/scss/_components/sale/_join.scss */
.join__btn {
  min-width: 239px;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  will-change: background-color, border-color, color;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 72, src/scss/_components/sale/_join.scss */
.join__btn:hover {
  border-color: #fff;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 78, src/scss/_components/sale/_join.scss */
.join__btn-wrapper {
  display: flex;
}

/* line 3, src/scss/_components/sale/_impressive.scss */
.impressive {
  position: relative;
  padding-top: 130px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_impressive.scss */
  .impressive {
    padding-top: 100px;
  }
}

/* line 13, src/scss/_components/sale/_impressive.scss */
.impressive--mod .impressive__wrapper-bg-1 {
  display: none;
}

/* line 17, src/scss/_components/sale/_impressive.scss */
.impressive--mod .impressive__wrapper-1 {
  padding-bottom: 100px;
}

/* line 24, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 {
  padding: 0;
}

/* line 29, src/scss/_components/sale/_impressive.scss */
.impressive--mod2.is-sticky .impressive__form {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

/* line 38, src/scss/_components/sale/_impressive.scss */
.impressive--mod2.is-normal .impressive__form {
  position: static;
  background-color: transparent;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

/* line 49, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 .impressive__wrapper-2 {
  padding-top: 150px;
  padding-bottom: 105px;
}

@media screen and (max-width: 767px) {
  /* line 49, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__wrapper-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 59, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 .impressive__subtitle {
  width: 100%;
  max-width: 791px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
  font-weight: 900;
  font-size: 30px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 70px;
}

@media screen and (max-width: 767px) {
  /* line 59, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__subtitle {
    padding-bottom: 50px;
    font-size: 25px;
    line-height: 1.8;
  }
}

/* line 78, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 .impressive__form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 741px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 150px;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 78, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__form {
    transform: translateY(100%);
    flex-direction: column;
    z-index: 1050;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: center;
    width: 100%;
    height: 142px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease-in-out;
  }
}

/* line 109, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 .impressive__form-input {
  outline: none;
  position: relative;
  width: calc(100% - (186px + 10px));
  height: 66px;
  padding: 0 11px;
  border: none;
  box-shadow: none;
  border-radius: 7px;
  background-color: #fff;
  font-size: 14px;
  color: black;
  letter-spacing: 0;
}

/* line 88, src/scss/_utilities/_mixin.scss */
.impressive--mod2 .impressive__form-input.placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 89, src/scss/_utilities/_mixin.scss */
.impressive--mod2 .impressive__form-input:-moz-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 90, src/scss/_utilities/_mixin.scss */
.impressive--mod2 .impressive__form-input::-moz-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 91, src/scss/_utilities/_mixin.scss */
.impressive--mod2 .impressive__form-input:-ms-input-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 92, src/scss/_utilities/_mixin.scss */
.impressive--mod2 .impressive__form-input::-webkit-input-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  /* line 109, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__form-input {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

/* line 135, src/scss/_components/sale/_impressive.scss */
.impressive--mod2 .impressive__form-btn {
  max-width: 186px;
}

@media screen and (max-width: 767px) {
  /* line 135, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__form-btn {
    max-width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 145, src/scss/_components/sale/_impressive.scss */
  .impressive--mod2 .impressive__copyright {
    padding-top: 50px;
  }
}

/* line 153, src/scss/_components/sale/_impressive.scss */
.impressive__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 160, src/scss/_components/sale/_impressive.scss */
.impressive__bg img {
  position: absolute;
  top: -180px;
  right: 0;
}

/* line 167, src/scss/_components/sale/_impressive.scss */
.impressive__wrapper {
  position: relative;
}

/* line 172, src/scss/_components/sale/_impressive.scss */
.impressive__wrapper-2 {
  padding-top: 140px;
  padding-bottom: 120px;
  background-color: #191919;
}

@media screen and (max-width: 767px) {
  /* line 172, src/scss/_components/sale/_impressive.scss */
  .impressive__wrapper-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 182, src/scss/_components/sale/_impressive.scss */
.impressive__wrapper-bg {
  z-index: 0;
  position: absolute;
}

/* line 186, src/scss/_components/sale/_impressive.scss */
.impressive__wrapper-bg-1 {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background-color: #191919;
}

/* line 194, src/scss/_components/sale/_impressive.scss */
.impressive__wrapper-bg-2 {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-impressive-bg@2x.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
}

/* line 213, src/scss/_components/sale/_impressive.scss */
.impressive__row {
  z-index: 2;
  position: relative;
}

/* line 221, src/scss/_components/sale/_impressive.scss */
.impressive__title {
  padding-bottom: 60px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
  color: #000;
}

@media screen and (max-width: 1023px) {
  /* line 221, src/scss/_components/sale/_impressive.scss */
  .impressive__title {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 221, src/scss/_components/sale/_impressive.scss */
  .impressive__title {
    font-size: 33px;
    text-align: center;
  }
  /* line 234, src/scss/_components/sale/_impressive.scss */
  .impressive__title br {
    display: none;
  }
}

/* line 239, src/scss/_components/sale/_impressive.scss */
.impressive__title span {
  position: relative;
}

/* line 242, src/scss/_components/sale/_impressive.scss */
.impressive__title span:before {
  content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98%;
  height: 22px;
  background-color: #ffd400;
}

@media screen and (max-width: 1023px) {
  /* line 242, src/scss/_components/sale/_impressive.scss */
  .impressive__title span:before {
    height: 20px;
  }
}

/* line 258, src/scss/_components/sale/_impressive.scss */
.impressive__subtitle, .impressive__desc {
  padding-bottom: 50px;
  text-align: center;
  color: #ffffff;
}

/* line 265, src/scss/_components/sale/_impressive.scss */
.impressive__subtitle {
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 265, src/scss/_components/sale/_impressive.scss */
  .impressive__subtitle {
    font-size: 55px;
  }
}

@media screen and (max-width: 767px) {
  /* line 265, src/scss/_components/sale/_impressive.scss */
  .impressive__subtitle {
    font-size: 50px;
  }
}

@media screen and (max-width: 424px) {
  /* line 265, src/scss/_components/sale/_impressive.scss */
  .impressive__subtitle {
    font-size: 42px;
  }
}

/* line 280, src/scss/_components/sale/_impressive.scss */
.impressive__desc {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 280, src/scss/_components/sale/_impressive.scss */
  .impressive__desc {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 280, src/scss/_components/sale/_impressive.scss */
  .impressive__desc {
    font-size: 33px;
  }
}

/* line 292, src/scss/_components/sale/_impressive.scss */
.impressive__text {
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  font-size: 19px;
  line-height: 1.58;
  text-align: center;
  color: #939393;
}

/* line 304, src/scss/_components/sale/_impressive.scss */
.impressive__btn {
  min-width: 239px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 311, src/scss/_components/sale/_impressive.scss */
.impressive__btn:hover {
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 316, src/scss/_components/sale/_impressive.scss */
.impressive__btn-wrapper {
  display: flex;
  padding-bottom: 140px;
}

@media screen and (max-width: 767px) {
  /* line 316, src/scss/_components/sale/_impressive.scss */
  .impressive__btn-wrapper {
    padding-bottom: 100px;
  }
}

/* line 325, src/scss/_components/sale/_impressive.scss */
.impressive__copyright {
  font-size: 16px;
  text-align: center;
  color: #8f8f8f;
}

/* line 331, src/scss/_components/sale/_impressive.scss */
.impressive__card {
  width: 100%;
  min-height: 380px;
  padding: 30px 15px 30px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

@media screen and (max-width: 1279px) {
  /* line 331, src/scss/_components/sale/_impressive.scss */
  .impressive__card {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 331, src/scss/_components/sale/_impressive.scss */
  .impressive__card {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 348, src/scss/_components/sale/_impressive.scss */
.impressive__card-1 svg {
  width: 40px;
  height: 40px;
}

/* line 354, src/scss/_components/sale/_impressive.scss */
.impressive__card-2 svg {
  width: 40px;
  height: 40px;
}

/* line 360, src/scss/_components/sale/_impressive.scss */
.impressive__card-3 svg {
  width: 34px;
  height: 42px;
}

/* line 366, src/scss/_components/sale/_impressive.scss */
.impressive__card-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 366, src/scss/_components/sale/_impressive.scss */
  .impressive__card-wrapper {
    flex-wrap: wrap;
  }
}

/* line 374, src/scss/_components/sale/_impressive.scss */
.impressive__card-wrapper > div {
  position: relative;
  width: 100%;
  max-width: calc((100% - 70px) / 3);
}

@media screen and (max-width: 1279px) {
  /* line 374, src/scss/_components/sale/_impressive.scss */
  .impressive__card-wrapper > div {
    max-width: calc((100% - 30px) / 3);
  }
}

@media screen and (max-width: 767px) {
  /* line 374, src/scss/_components/sale/_impressive.scss */
  .impressive__card-wrapper > div {
    max-width: 100%;
    padding-bottom: 30px;
  }
  /* line 385, src/scss/_components/sale/_impressive.scss */
  .impressive__card-wrapper > div:last-of-type {
    padding-bottom: 0;
  }
}

/* line 392, src/scss/_components/sale/_impressive.scss */
.impressive__card svg {
  fill: #cd084b;
}

/* line 396, src/scss/_components/sale/_impressive.scss */
.impressive__card-title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: bold;
  line-height: 2.11;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #cd084b;
}

/* line 407, src/scss/_components/sale/_impressive.scss */
.impressive__card-desc {
  padding-bottom: 40px;
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1279px) {
  /* line 407, src/scss/_components/sale/_impressive.scss */
  .impressive__card-desc {
    min-height: 124px;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 407, src/scss/_components/sale/_impressive.scss */
  .impressive__card-desc {
    min-height: unset;
  }
}

/* line 421, src/scss/_components/sale/_impressive.scss */
.impressive__card-info {
  padding-bottom: 10px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

/* line 428, src/scss/_components/sale/_impressive.scss */
.impressive__card-price {
  font-size: 44px;
  font-weight: 900;
  line-height: 1.14;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 428, src/scss/_components/sale/_impressive.scss */
  .impressive__card-price {
    font-size: 40px;
  }
}

/* line 439, src/scss/_components/sale/_impressive.scss */
.impressive__table {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1023px) {
  /* line 439, src/scss/_components/sale/_impressive.scss */
  .impressive__table {
    width: 1024px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 447, src/scss/_components/sale/_impressive.scss */
  .impressive__table-wrapper {
    white-space: nowrap;
    overflow-x: auto;
    position: relative;
    width: calc(100% + 15px);
  }
}

/* line 456, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

/* line 465, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* line 470, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div:nth-of-type(1) {
  max-width: 265px;
}

@media screen and (max-width: 575px) {
  /* line 470, src/scss/_components/sale/_impressive.scss */
  .impressive__table-bg > div:nth-of-type(1) {
    max-width: 175px;
  }
}

/* line 476, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div:nth-of-type(2) {
  max-width: 200px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

/* line 481, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div:nth-of-type(3) {
  max-width: 230px;
}

/* line 484, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div:nth-of-type(4) {
  max-width: 150px;
}

@media screen and (max-width: 575px) {
  /* line 484, src/scss/_components/sale/_impressive.scss */
  .impressive__table-bg > div:nth-of-type(4) {
    max-width: 200px;
  }
}

/* line 490, src/scss/_components/sale/_impressive.scss */
.impressive__table-bg > div:nth-of-type(5) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 498, src/scss/_components/sale/_impressive.scss */
.impressive__table-head, .impressive__table-body, .impressive__table-foot {
  display: flex;
  flex-direction: column;
}

/* line 509, src/scss/_components/sale/_impressive.scss */
.impressive__table-head .impressive__table-row-1 > div {
  padding: 30px 5px;
  font-size: 19px;
  font-weight: bold;
  line-height: 2.11;
  letter-spacing: 1px;
  color: #cd084b;
  text-align: center;
  text-transform: uppercase;
}

/* line 522, src/scss/_components/sale/_impressive.scss */
.impressive__table-head .impressive__table-row-2 span,
.impressive__table-head .impressive__table-row-2 p {
  text-align: center;
  color: #000000;
}

/* line 528, src/scss/_components/sale/_impressive.scss */
.impressive__table-head .impressive__table-row-2 span {
  display: block;
  font-size: 19px;
}

/* line 533, src/scss/_components/sale/_impressive.scss */
.impressive__table-head .impressive__table-row-2 p {
  font-size: 29px;
  font-weight: 900;
}

/* line 542, src/scss/_components/sale/_impressive.scss */
.impressive__table-head .impressive__table-col-1 p {
  font-size: 19px;
  font-weight: 400;
  color: #939393;
  text-align: left;
}

/* line 555, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-row {
  min-height: 60px;
}

/* line 559, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-row:nth-of-type(odd)::before {
  content: '';
  position: absolute;
  left: -40px;
  top: 0;
  width: calc(100% + 80px);
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 68, 157, 0.03);
}

@media screen and (max-width: 1279px) {
  /* line 559, src/scss/_components/sale/_impressive.scss */
  .impressive__table-body .impressive__table-row:nth-of-type(odd)::before {
    left: -15px;
    width: calc(100% + 30px);
  }
}

/* line 576, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 581, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-col-1 {
  justify-content: flex-start;
}

/* line 584, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-col-1 p {
  text-align: left !important;
}

/* line 589, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-col p {
  font-size: 19px;
  text-align: center;
  color: #000000;
}

/* line 594, src/scss/_components/sale/_impressive.scss */
.impressive__table-body .impressive__table-col p strong {
  font-weight: 700;
}

/* line 603, src/scss/_components/sale/_impressive.scss */
.impressive__table-foot .impressive__table-col {
  padding: 10px;
}

/* line 607, src/scss/_components/sale/_impressive.scss */
.impressive__table-foot p {
  padding-bottom: 20px;
  font-size: 19px;
  text-align: center;
  color: #000000;
}

/* line 614, src/scss/_components/sale/_impressive.scss */
.impressive__table-foot a {
  min-width: unset;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 622, src/scss/_components/sale/_impressive.scss */
.impressive__table-foot a:hover {
  color: #cd084b;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 630, src/scss/_components/sale/_impressive.scss */
.impressive__table-row {
  position: relative;
  display: flex;
}

/* line 635, src/scss/_components/sale/_impressive.scss */
.impressive__table-col {
  width: 100%;
  padding: 15px 5px;
}

/* line 639, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-1 {
  max-width: 265px;
}

@media screen and (max-width: 575px) {
  /* line 639, src/scss/_components/sale/_impressive.scss */
  .impressive__table-col-1 {
    max-width: 175px;
  }
}

/* line 645, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-1 p {
  font-size: 19px;
  color: #939393 !important;
  text-align: left;
}

/* line 651, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-2 {
  max-width: 200px;
}

/* line 654, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-3 {
  max-width: 230px;
}

/* line 657, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-4 {
  max-width: 150px;
}

@media screen and (max-width: 575px) {
  /* line 657, src/scss/_components/sale/_impressive.scss */
  .impressive__table-col-4 {
    max-width: 200px;
  }
}

/* line 663, src/scss/_components/sale/_impressive.scss */
.impressive__table-col-5 {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 669, src/scss/_components/sale/_impressive.scss */
.impressive__table-col > * {
  z-index: 5;
  white-space: normal;
  position: relative;
}

/* line 676, src/scss/_components/sale/_impressive.scss */
.impressive__table .check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00449d;
}

/* line 685, src/scss/_components/sale/_impressive.scss */
.impressive__table .check svg {
  width: 10px;
  height: 15px;
  fill: #fff;
}

/* line 3, src/scss/_components/sale/_tired.scss */
.tired {
  position: relative;
  padding-top: 40px;
  padding-bottom: 91px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/sale/_tired.scss */
  .tired {
    padding-bottom: 50px;
  }
}

/* line 11, src/scss/_components/sale/_tired.scss */
.tired__bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 19, src/scss/_components/sale/_tired.scss */
.tired__bg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 767px) {
  /* line 19, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon {
    opacity: 0;
    visibility: hidden;
  }
}

/* line 31, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-1 {
  top: 228px;
  left: 157px;
  width: 100px;
  height: 101px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  /* line 31, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon-1 {
    top: 40%;
    left: 30px;
  }
}

/* line 43, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-1 svg {
  transform: translate(2px, -8px);
}

/* line 47, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-2 {
  left: 57px;
  bottom: 96px;
  width: 144px;
  height: 153px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 47, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon-2 {
    left: 0;
  }
}

@media screen and (max-width: 1023px) {
  /* line 47, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon-2 {
    display: none;
  }
}

/* line 61, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-2 svg {
  transform: translate(2px, -20px);
}

/* line 65, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-4 {
  top: calc(50% - 54px);
  right: 50px;
  width: 130px;
  height: 165px;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-9@2x.png");
  background-size: cover;
}

@media screen and (max-width: 1279px) {
  /* line 65, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon-4 {
    right: 0;
  }
}

@media screen and (max-width: 1023px) {
  /* line 65, src/scss/_components/sale/_tired.scss */
  .tired__bg-icon-4 {
    opacity: 0;
    visibility: hidden;
  }
}

/* line 80, src/scss/_components/sale/_tired.scss */
.tired__bg-icon-4 svg {
  transform: translate(0px, -25px);
}

/* line 85, src/scss/_components/sale/_tired.scss */
.tired__bg-icon svg {
  fill: #fff;
}

/* line 91, src/scss/_components/sale/_tired.scss */
.tired__wrapper {
  z-index: 2;
  position: relative;
}

/* line 96, src/scss/_components/sale/_tired.scss */
.tired__logo {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 96, src/scss/_components/sale/_tired.scss */
  .tired__logo {
    padding-bottom: 75px;
  }
}

/* line 104, src/scss/_components/sale/_tired.scss */
.tired__logo svg {
  fill: #00449D;
}

/* line 109, src/scss/_components/sale/_tired.scss */
.tired__title {
  width: 100%;
  max-width: 866px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  font-weight: 900;
  font-size: 60px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 109, src/scss/_components/sale/_tired.scss */
  .tired__title {
    font-size: 55px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 109, src/scss/_components/sale/_tired.scss */
  .tired__title {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  /* line 109, src/scss/_components/sale/_tired.scss */
  .tired__title {
    font-size: 42px;
  }
  /* line 130, src/scss/_components/sale/_tired.scss */
  .tired__title br {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  /* line 109, src/scss/_components/sale/_tired.scss */
  .tired__title {
    font-size: 36px;
  }
}

/* line 139, src/scss/_components/sale/_tired.scss */
.tired__subtitle {
  position: relative;
  width: 100%;
  max-width: 604px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
}

@media screen and (max-width: 1279px) {
  /* line 139, src/scss/_components/sale/_tired.scss */
  .tired__subtitle {
    font-size: 26px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 139, src/scss/_components/sale/_tired.scss */
  .tired__subtitle {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  /* line 139, src/scss/_components/sale/_tired.scss */
  .tired__subtitle {
    font-size: 17px;
  }
}

/* line 160, src/scss/_components/sale/_tired.scss */
.tired__subtitle:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 20px;
  right: 20px;
  width: calc(100% - 40px);
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: #00449d;
}

/* line 173, src/scss/_components/sale/_tired.scss */
.tired__subtitle span {
  display: block;
  font-weight: 900;
  color: #00449d;
}

/* line 180, src/scss/_components/sale/_tired.scss */
.tired__text {
  padding-top: 40px;
  padding-bottom: 38px;
  font-size: 19px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
}

@media screen and (max-width: 1279px) {
  /* line 180, src/scss/_components/sale/_tired.scss */
  .tired__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  /* line 180, src/scss/_components/sale/_tired.scss */
  .tired__text {
    font-size: 16px;
    line-height: 1.4;
  }
}

/* line 197, src/scss/_components/sale/_tired.scss */
.tired__form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 741px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 197, src/scss/_components/sale/_tired.scss */
  .tired__form {
    flex-direction: column;
  }
}

/* line 210, src/scss/_components/sale/_tired.scss */
.tired__form-input {
  outline: none;
  position: relative;
  width: calc(100% - (186px + 10px));
  height: 66px;
  padding: 0 11px;
  box-shadow: none;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-size: 14px;
  color: black;
  letter-spacing: 0;
}

/* line 88, src/scss/_utilities/_mixin.scss */
.tired__form-input.placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 89, src/scss/_utilities/_mixin.scss */
.tired__form-input:-moz-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 90, src/scss/_utilities/_mixin.scss */
.tired__form-input::-moz-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 91, src/scss/_utilities/_mixin.scss */
.tired__form-input:-ms-input-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

/* line 92, src/scss/_utilities/_mixin.scss */
.tired__form-input::-webkit-input-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  /* line 210, src/scss/_components/sale/_tired.scss */
  .tired__form-input {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* line 235, src/scss/_components/sale/_tired.scss */
.tired__form-btn {
  max-width: 186px;
  box-shadow: 0 10px 20px 0 rgba(205, 8, 75, 0.3);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 235, src/scss/_components/sale/_tired.scss */
  .tired__form-btn {
    max-width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

/* line 245, src/scss/_components/sale/_tired.scss */
.tired__form-btn:hover {
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 3, src/scss/_components/sale/_perfect.scss */
.perfect {
  padding-top: 59px;
  padding-bottom: 50px;
}

/* line 7, src/scss/_components/sale/_perfect.scss */
.perfect__title {
  width: 100%;
  max-width: 799px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 148px;
  font-size: 30px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
}

@media screen and (max-width: 1280px) {
  /* line 7, src/scss/_components/sale/_perfect.scss */
  .perfect__title {
    font-size: 26px;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  /* line 7, src/scss/_components/sale/_perfect.scss */
  .perfect__title {
    font-size: 24px;
    padding-bottom: 50px;
  }
}

/* line 28, src/scss/_components/sale/_perfect.scss */
.perfect__box {
  display: flex;
  align-items: flex-start;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 28, src/scss/_components/sale/_perfect.scss */
  .perfect__box {
    padding-bottom: 50px;
  }
}

/* line 36, src/scss/_components/sale/_perfect.scss */
.perfect__box-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 743px;
  margin-left: auto;
  margin-right: auto;
}

/* line 45, src/scss/_components/sale/_perfect.scss */
.perfect__box-left {
  width: 112px;
}

@media screen and (max-width: 767px) {
  /* line 45, src/scss/_components/sale/_perfect.scss */
  .perfect__box-left {
    width: 90px;
  }
  /* line 50, src/scss/_components/sale/_perfect.scss */
  .perfect__box-left svg {
    transform: scale(0.65);
  }
}

/* line 56, src/scss/_components/sale/_perfect.scss */
.perfect__box-right {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 62, src/scss/_components/sale/_perfect.scss */
.perfect__box-title {
  padding-bottom: 17px;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  line-height: 40px;
}

@media screen and (max-width: 767px) {
  /* line 62, src/scss/_components/sale/_perfect.scss */
  .perfect__box-title {
    font-size: 20px;
  }
}

/* line 74, src/scss/_components/sale/_perfect.scss */
.perfect__box-desc {
  font-size: 18px;
  color: #939393;
  letter-spacing: 0;
  line-height: 31px;
}

@media screen and (max-width: 767px) {
  /* line 74, src/scss/_components/sale/_perfect.scss */
  .perfect__box-desc {
    font-size: 16px;
  }
}

/* line 6, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main {
  position: relative;
  padding-top: 163px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  /* line 6, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main {
    padding-top: 125px;
  }
}

/* line 14, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__bg {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 23, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__bg > div {
  position: absolute;
  top: -200px;
  right: -200px;
  width: 1080px;
  height: 1080px;
  border-radius: 50%;
  background-color: #f8fafc;
}

@media screen and (max-width: 1023px) {
  /* line 23, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__bg > div {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 23, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__bg > div {
    display: none;
  }
}

/* line 41, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__wrapper {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}

@media screen and (max-width: 1023px) {
  /* line 41, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper {
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 767px) {
  /* line 41, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper {
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
}

/* line 55, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__wrapper-left, .affiliate-main__wrapper-right {
  width: 50%;
}

@media screen and (max-width: 1023px) {
  /* line 55, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-left, .affiliate-main__wrapper-right {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  /* line 63, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-left {
    max-width: 55%;
  }
}

@media screen and (max-width: 767px) {
  /* line 63, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-left {
    max-width: 100%;
  }
}

/* line 71, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__wrapper-left .affiliate-main__img {
  display: none;
  max-width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  /* line 71, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-left .affiliate-main__img {
    display: block;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 84, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-right {
    max-width: 45%;
  }
}

@media screen and (max-width: 767px) {
  /* line 84, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__wrapper-right {
    max-width: 100%;
  }
}

/* line 94, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__title {
  padding-bottom: 30px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 94, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__title {
    font-size: 55px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 94, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__title {
    font-size: 46px;
  }
}

@media screen and (max-width: 767px) {
  /* line 94, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__title {
    font-size: 36px;
    text-align: center;
  }
}

/* line 111, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__desc {
  padding-bottom: 60px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 111, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__desc {
    font-size: 36px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 111, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__desc {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 111, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__desc {
    padding-bottom: 50px;
    font-size: 26px;
    text-align: center;
  }
}

/* line 129, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__btn {
  width: auto;
  min-width: 225px;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 129, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 139, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__btn:hover {
  box-shadow: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 144, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__btn-wrapper {
  display: flex;
}

/* line 149, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  /* line 149, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__img {
    display: none;
  }
}

/* line 159, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-main__text {
  z-index: 2;
  position: relative;
  font-size: 19px;
  line-height: 1.58;
}

@media screen and (max-width: 767px) {
  /* line 159, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-main__text {
    font-size: 16px;
    text-align: center;
  }
}

/* line 173, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits {
  padding-bottom: 60px;
}

/* line 176, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__title {
  padding-bottom: 40px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  /* line 176, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__title {
    font-size: 33px;
    text-align: center;
  }
}

/* line 187, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__desc {
  padding-bottom: 60px;
  font-size: 19px;
  line-height: 1.58;
}

@media screen and (max-width: 767px) {
  /* line 187, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__desc {
    font-size: 16px;
    text-align: center;
  }
}

/* line 197, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__list {
  display: flex;
  flex-direction: column;
}

/* line 202, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item {
  display: flex;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px rgba(216, 216, 216, 0.8);
}

@media screen and (max-width: 767px) {
  /* line 202, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item {
    flex-wrap: wrap;
  }
}

/* line 211, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item:last-of-type {
  margin-bottom: 0;
  border: none;
}

/* line 216, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item > div {
  width: 100%;
}

/* line 219, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item > div:nth-of-type(1) {
  max-width: 80px;
  padding-top: 10px;
}

@media screen and (max-width: 767px) {
  /* line 219, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item > div:nth-of-type(1) {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 15px;
  }
}

/* line 228, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item > div:nth-of-type(1) svg {
  fill: #00449D;
}

@media screen and (max-width: 767px) {
  /* line 228, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item > div:nth-of-type(1) svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 238, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item > div:nth-of-type(2) {
  max-width: 330px;
}

@media screen and (max-width: 1023px) {
  /* line 238, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item > div:nth-of-type(2) {
    max-width: 270px;
  }
}

@media screen and (max-width: 767px) {
  /* line 238, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item > div:nth-of-type(2) {
    max-width: 100%;
    text-align: center;
  }
}

/* line 249, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item > div:nth-of-type(3) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 256, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item h6 {
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #00449d;
}

@media screen and (max-width: 767px) {
  /* line 256, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item h6 {
    font-size: 33px;
  }
}

/* line 267, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item span {
  display: block;
  width: 100%;
  max-width: 200px;
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 767px) {
  /* line 267, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item span {
    max-width: 100%;
    padding-bottom: 15px;
    font-size: 16px;
  }
}

/* line 281, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-benefits__item p {
  font-size: 19px;
  line-height: 1.58;
}

@media screen and (max-width: 767px) {
  /* line 281, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-benefits__item p {
    font-size: 16px;
    text-align: center;
  }
}

/* line 294, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions {
  position: relative;
  padding-top: 110px;
  padding-bottom: 110px;
  background: linear-gradient(237deg, #001632 0.09%, #00449d 100%);
}

@media screen and (max-width: 767px) {
  /* line 294, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* line 304, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__bg {
  z-index: 0;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.275;
  background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/svg/commissions.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

@media screen and (max-width: 767px) {
  /* line 304, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__bg {
    background-size: cover;
  }
}

/* line 324, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__wrapper {
  z-index: 2;
  position: relative;
}

/* line 329, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__title {
  padding-bottom: 40px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  /* line 329, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__title {
    font-size: 33px;
    text-align: center;
  }
}

/* line 341, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__desc {
  padding-bottom: 60px;
  font-size: 19px;
  line-height: 1.58;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 341, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__desc {
    font-size: 16px;
    text-align: center;
    padding-bottom: 40px;
  }
}

/* line 354, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__box-wrapper {
  display: flex;
  padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
  /* line 354, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box-wrapper {
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
}

/* line 362, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 5);
}

@media screen and (max-width: 767px) {
  /* line 362, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box-wrapper > div {
    max-width: 100%;
  }
}

/* line 371, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__box p {
  padding-bottom: 10px;
  font-size: 40px;
  line-height: 1.25;
  color: #ffffff;
}

@media screen and (max-width: 1023px) {
  /* line 371, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box p {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 371, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box p {
    font-size: 33px;
    text-align: center;
  }
}

/* line 385, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__box span {
  display: block;
  width: 100%;
  max-width: 170px;
  font-size: 19px;
  line-height: 1.58;
  color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 1023px) {
  /* line 385, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box span {
    padding-right: 15px;
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 385, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__box span {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
  }
}

/* line 408, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 408, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__text {
    flex-wrap: wrap;
  }
}

/* line 414, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text > div {
  width: 100%;
}

/* line 417, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text > div:nth-of-type(1) {
  max-width: 230px;
}

@media screen and (max-width: 767px) {
  /* line 417, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__text > div:nth-of-type(1) {
    max-width: 100%;
    text-align: center;
    padding-bottom: 15px;
  }
}

/* line 425, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text > div:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 432, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text p {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #ffd400;
}

@media screen and (max-width: 767px) {
  /* line 432, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__text p {
    font-size: 33px;
  }
}

/* line 442, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-commisions__text span {
  display: block;
  font-size: 19px;
  line-height: 1.58;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 442, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-commisions__text span {
    font-size: 16px;
    text-align: center;
  }
}

/* line 457, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming {
  padding-top: 110px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  /* line 457, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming {
    padding-bottom: 40px;
  }
}

/* line 464, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__title {
  padding-bottom: 40px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  /* line 464, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__title {
    font-size: 33px;
    text-align: center;
  }
}

/* line 475, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__desc {
  padding-bottom: 60px;
  font-size: 19px;
  line-height: 1.58;
}

@media screen and (max-width: 767px) {
  /* line 475, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__desc {
    font-size: 16px;
    text-align: center;
    padding-bottom: 40px;
  }
}

/* line 486, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box {
  height: 100%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 486, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box {
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
}

/* line 500, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 500, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-wrapper {
    flex-wrap: wrap;
  }
}

/* line 507, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-wrapper > div {
  width: 100%;
  max-width: calc((100% - 70px) / 3);
  min-height: 100%;
  padding-bottom: 35px;
}

@media screen and (max-width: 1023px) {
  /* line 507, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-wrapper > div {
    max-width: calc((100% - 30px) / 3);
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 507, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-wrapper > div {
    max-width: 100%;
    min-height: unset;
  }
}

/* line 523, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-num {
  text-align: right;
  font-size: 110px;
  font-weight: 900;
  line-height: 1;
  color: rgba(0, 68, 157, 0.05);
}

@media screen and (max-width: 1023px) {
  /* line 523, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-num {
    font-size: 100px;
  }
}

@media screen and (max-width: 767px) {
  /* line 523, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-num {
    font-size: 90px;
  }
}

/* line 537, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-icon {
  display: flex;
  align-items: center;
  min-height: 51px;
}

/* line 543, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.15;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 543, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  /* line 543, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-title {
    font-size: 22px;
  }
}

/* line 558, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-becoming__box-desc {
  font-size: 19px;
  line-height: 1.58;
  color: #939393;
}

@media screen and (max-width: 1023px) {
  /* line 558, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-desc {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 558, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-becoming__box-desc {
    font-size: 16px;
  }
}

/* line 574, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question {
  padding-top: 55px;
  padding-bottom: 200px;
}

@media screen and (max-width: 767px) {
  /* line 574, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-question {
    padding-bottom: 100px;
  }
}

/* line 581, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question__title {
  padding-bottom: 40px;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (max-width: 767px) {
  /* line 581, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-question__title {
    font-size: 33px;
    text-align: center;
  }
}

/* line 592, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question__desc {
  padding-bottom: 60px;
  font-size: 19px;
  line-height: 1.58;
}

@media screen and (max-width: 767px) {
  /* line 592, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-question__desc {
    font-size: 16px;
    text-align: center;
    padding-bottom: 40px;
  }
}

/* line 603, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question__btn {
  min-width: 225px;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 603, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-question__btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 612, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question__btn:hover {
  box-shadow: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 617, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-question__btn-wrapper {
  display: flex;
}

/* line 625, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media screen and (max-width: 1023px) {
  /* line 625, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) {
  /* line 625, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm {
    padding-top: 125px;
  }
}

/* line 635, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__title {
  padding-bottom: 30px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 635, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 635, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__title {
    font-size: 36px;
    text-align: center;
  }
}

/* line 649, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__subtitle, .affiliate-appForm__heading {
  font-size: 40px;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 649, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__subtitle, .affiliate-appForm__heading {
    font-size: 33px;
  }
}

@media screen and (max-width: 767px) {
  /* line 649, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__subtitle, .affiliate-appForm__heading {
    font-size: 26px;
  }
}

/* line 661, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__subtitle {
  padding-bottom: 110px;
  font-weight: 300;
}

@media screen and (max-width: 1279px) {
  /* line 661, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__subtitle {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  /* line 661, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__subtitle {
    text-align: center;
  }
}

/* line 672, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__heading {
  padding-bottom: 40px;
  font-weight: 900;
}

/* line 676, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__heading--pt {
  padding-top: 70px;
}

@media screen and (max-width: 767px) {
  /* line 676, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__heading--pt {
    padding-top: 40px;
  }
}

/* line 684, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__btn {
  width: auto;
  max-width: unset;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 684, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__btn {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 698, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__btn:hover {
  box-shadow: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* line 703, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__btn-wrapper {
  display: flex;
  padding-top: 30px;
}

/* line 709, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__tab {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 2em;
  background-color: transparent;
  font-size: 19px;
  line-height: 1.1;
  text-align: center;
  color: #000000;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 709, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__tab {
    font-size: 17px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 424px) {
  /* line 709, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__tab {
    font-size: 14px;
  }
}

/* line 734, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__tab.is-active {
  color: #ffffff;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 739, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__tab-bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 2em;
  background-color: #00449d;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

/* line 750, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__tab-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

/* line 758, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appForm__container {
  display: none;
  padding-top: 100px;
}

@media screen and (max-width: 1279px) {
  /* line 758, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__container {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  /* line 758, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appForm__container {
    padding-top: 20px;
  }
}

/* line 773, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup .affiliate-appForm__heading--pt {
  padding-top: 100px;
}

/* line 777, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup .affiliate-inside__link {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* line 783, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__wrapper-header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-radius: 0 10px 10px 0;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

/* line 798, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__wrapper-body {
  padding-top: 200px;
  padding-bottom: 200px;
}

/* line 804, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress {
  width: 100%;
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

/* line 812, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-title {
  position: relative;
  padding-bottom: 12px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: #000000;
}

/* line 821, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-title:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

/* line 832, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-title-wrapper {
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
}

/* line 840, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-line {
  position: relative;
  height: 10px;
  border-radius: 8px;
  background-color: #e3ebf4;
}

/* line 846, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-line i {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 8px;
  background-color: #00449d;
  font-style: normal;
  transition: width 0.3s ease-in-out;
}

/* line 858, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__progress-line span {
  position: absolute;
  right: 0;
  bottom: calc(100% + 12px);
  transform: translateX(50%);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: #00449d;
}

/* line 871, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__title {
  padding-bottom: 30px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
}

@media screen and (max-width: 1279px) {
  /* line 871, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 871, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__title {
    font-size: 36px;
    text-align: center;
  }
}

/* line 885, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__subtitle {
  font-size: 40px;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 885, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__subtitle {
    font-size: 33px;
  }
}

@media screen and (max-width: 767px) {
  /* line 885, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__subtitle {
    font-size: 26px;
  }
}

/* line 896, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__subtitle {
  padding-bottom: 110px;
  font-weight: 300;
}

@media screen and (max-width: 1279px) {
  /* line 896, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__subtitle {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  /* line 896, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-appSetup__subtitle {
    text-align: center;
  }
}

/* line 907, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__step {
  display: none;
}

/* line 910, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__step-1 {
  display: block;
}

/* line 913, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-appSetup__step-1 .affiliate-appForm__heading {
  padding-top: 0;
}

/* line 922, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media screen and (max-width: 1279px) {
  /* line 922, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) {
  /* line 922, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

/* line 935, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__wrapper-1 {
  padding-bottom: 110px;
}

@media screen and (max-width: 1279px) {
  /* line 935, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__wrapper-1 {
    padding-bottom: 100px;
  }
}

/* line 941, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__wrapper-2 {
  padding-bottom: 110px;
}

@media screen and (max-width: 1279px) {
  /* line 941, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__wrapper-2 {
    padding-bottom: 100px;
  }
}

/* line 947, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__wrapper-3 {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: linear-gradient(254deg, #001632 0%, #00449d);
}

@media screen and (max-width: 767px) {
  /* line 947, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__wrapper-3 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* line 956, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__wrapper-4 {
  padding-top: 110px;
  padding-bottom: 110px;
}

@media screen and (max-width: 1279px) {
  /* line 956, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__wrapper-4 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* line 966, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__title {
  padding-bottom: 30px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.17;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 966, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 966, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__title {
    font-size: 45px;
  }
}

/* line 980, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__subtitle {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 980, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__subtitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 980, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__subtitle {
    font-size: 30px;
  }
}

/* line 992, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__subtitle span {
  position: relative;
  font-weight: bold;
}

/* line 996, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__subtitle span:before {
  z-index: -1;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: #ffd400;
}

/* line 1009, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__heading {
  padding-bottom: 40px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.25;
}

@media screen and (max-width: 1279px) {
  /* line 1009, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__heading {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1009, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__heading {
    font-size: 30px;
  }
}

/* line 1022, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__heading--black {
  color: #000000;
}

/* line 1027, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__list {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 1027, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__list {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* line 1036, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__list li {
  padding-right: 100px;
}

@media screen and (max-width: 767px) {
  /* line 1036, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__list li {
    padding-right: 0;
    padding-bottom: 15px;
  }
}

/* line 1043, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__list li:last-of-type {
  padding-right: 0;
}

/* line 1048, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__list p {
  padding-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.25;
  color: #000000;
}

@media screen and (max-width: 1279px) {
  /* line 1048, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__list p {
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1048, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__list p {
    padding-bottom: 0;
  }
}

/* line 1062, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__list span {
  font-size: 19px;
  line-height: 1.58;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1279px) {
  /* line 1062, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__list span {
    font-size: 17px;
  }
}

/* line 1072, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__btn {
  height: 60px;
  margin-right: 40px;
  letter-spacing: 1.35px;
}

@media screen and (max-width: 767px) {
  /* line 1072, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__btn {
    margin-bottom: 15px;
  }
}

/* line 1080, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__btn:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  /* line 1080, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__btn:last-of-type {
    margin-bottom: 0;
  }
}

/* line 1087, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__btn-wrapper {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 1087, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__btn-wrapper {
    flex-direction: column;
  }
}

/* line 1094, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__btn-wrapper-2 .affiliate-inside__btn {
  width: auto;
  max-width: unset;
  margin-right: 0;
  padding-left: 40px;
  padding-right: 40px;
}

/* line 1105, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  /* line 1105, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__table {
    width: 768px;
  }
}

/* line 1113, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-scroll {
  position: relative;
}

@media screen and (max-width: 767px) {
  /* line 1113, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__table-scroll {
    overflow-x: auto;
    width: calc(100% + 15px);
  }
}

/* line 1121, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-head {
  border-radius: 10px;
  background-color: #f8fafc;
}

/* line 1127, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-body > div {
  border-bottom: 1px solid rgba(216, 216, 216, 0.8);
}

/* line 1130, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-body > div:last-of-type {
  border: none;
}

/* line 1136, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-tr {
  display: flex;
  align-items: center;
}

/* line 1141, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-th, .affiliate-inside__table-td {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 1148, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-th {
  padding: 15px 30px;
  font-size: 19px;
  font-weight: bold;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 1148, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__table-th {
    padding: 15px;
    font-size: 17px;
  }
}

/* line 1159, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__table-td {
  padding: 30px;
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

@media screen and (max-width: 1023px) {
  /* line 1159, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__table-td {
    padding: 15px;
    font-size: 17px;
  }
}

/* line 1172, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__input-title {
  padding-bottom: 20px;
  font-size: 19px;
  line-height: 1.58;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  /* line 1172, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__input-title {
    font-size: 17px;
  }
}

/* line 1182, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__input-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 1182, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* line 1190, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__input-wrapper input {
  outline: none;
  flex: 1;
  min-width: 1px;
  max-width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: none;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  font-size: 19px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 1190, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__input-wrapper input {
    flex: unset;
    width: 100%;
    font-size: 17px;
  }
}

/* line 1210, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__input-wrapper a {
  display: block;
  margin-left: 20px;
  padding-right: 5px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.58;
  text-align: right;
  color: #ffd400;
}

@media screen and (max-width: 767px) {
  /* line 1210, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-inside__input-wrapper a {
    margin-left: 0;
    padding-top: 15px;
    font-size: 17px;
  }
}

/* line 1228, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* line 1235, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link:hover span:before {
  width: calc(100% - 20px);
  transition: width 0.3s ease-in-out;
}

/* line 1241, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link:hover svg {
  transform: scale(-1) translateX(-5px);
  transition: transform 0.3s ease-in-out;
}

/* line 1247, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link span {
  overflow: hidden;
  position: relative;
  padding-right: 20px;
  font-size: 19px;
  font-weight: bold;
  color: #00449d;
}

/* line 1255, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link span:before {
  content: '';
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00449d;
  transition: width 0.3s ease-in-out;
}

/* line 1268, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-inside__link svg {
  fill: #00449d;
  width: 20px;
  height: 20px;
  transform: scale(-1);
  transition: transform 0.3s ease-in-out;
}

/* line 1281, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__wrapper {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media screen and (max-width: 1279px) {
  /* line 1281, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1281, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__wrapper {
    padding-top: 125px;
    padding-bottom: 100px;
  }
}

/* line 1294, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 1301, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__img-wrapper {
  padding-bottom: 80px;
}

/* line 1306, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__title, .affiliate-submitted__desc {
  color: #000000;
  text-align: center;
}

/* line 1312, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__title {
  padding-bottom: 15px;
  font-weight: 900;
  font-size: 60px;
  line-height: 1.4;
}

@media screen and (max-width: 1279px) {
  /* line 1312, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 1312, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__title {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1312, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__title {
    font-size: 40px;
    line-height: normal;
  }
}

/* line 1329, src/scss/_components/affiliate/_affiliate.scss */
.affiliate-submitted__desc {
  width: 100%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  font-size: 19px;
}

@media screen and (max-width: 1279px) {
  /* line 1329, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__desc {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1329, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate-submitted__desc {
    font-size: 15px;
  }
}

/* line 1346, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__form-hidden {
  display: none;
}

/* line 1350, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__form-group {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 1350, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__form-group {
    flex-wrap: wrap;
  }
}

/* line 1358, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__form-col {
  width: 100%;
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 1358, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__form-col {
    max-width: 100%;
  }
}

/* line 1368, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 1373, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__input-wrapper--empty {
    display: none;
  }
}

/* line 1377, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input-wrapper--empty label {
  opacity: 0;
}

/* line 1381, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input-wrapper--empty .affiliate__input {
  display: flex;
  align-items: center;
  height: 60px;
}

/* line 1387, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input-wrapper--empty p {
  font-size: 19px;
  line-height: 1.58;
  color: #000000;
}

/* line 1395, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input-label {
  display: block;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.88;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 767px) {
  /* line 1395, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__input-label {
    font-size: 14px;
  }
}

/* line 1407, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input input {
  outline: none;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: none;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  font-size: 19px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 1407, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__input input {
    height: 50px;
    font-size: 16px;
  }
}

/* line 1423, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__input input[disabled] {
  background-color: rgba(0, 0, 0, 0.05);
}

/* line 1429, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

/* line 1439, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio:checked + .affiliate__radio-label .affiliate__radio-circle {
  border-color: #00449d;
  background-color: #00449d;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 1444, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio:checked + .affiliate__radio-label .affiliate__radio-circle:before {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

/* line 1452, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 1459, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__radio-wrapper--wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* line 1467, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-col {
  padding-right: 60px;
}

@media screen and (max-width: 1023px) {
  /* line 1467, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__radio-col {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1467, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__radio-col {
    padding-right: 30px;
    padding-bottom: 10px;
  }
}

/* line 1478, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* line 1484, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-label p:nth-of-type(1) {
  width: 50px;
}

/* line 1487, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-label p:nth-of-type(2) {
  flex: 1;
  min-width: 1px;
  max-width: 100%;
}

/* line 1495, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: transparent;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 1507, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-circle:before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

/* line 1519, src/scss/_components/affiliate/_affiliate.scss */
.affiliate__radio-text {
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  /* line 1519, src/scss/_components/affiliate/_affiliate.scss */
  .affiliate__radio-text {
    font-size: 16px;
  }
}

/* line 3, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header {
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
}

/* line 8, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header .c-grid {
  position: relative;
}

/* line 12, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header-wrapper {
  display: flex;
  align-items: center;
  height: 158px;
}

@media screen and (max-width: 1279px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper {
    height: 108px;
  }
}

@media screen and (max-width: 767px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper {
    height: 70px;
  }
}

/* line 23, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header-wrapper-left {
  display: flex;
  align-items: center;
}

/* line 27, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header-wrapper-left span {
  display: block;
  width: 2px;
  height: 38px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba(0, 68, 157, 0.1);
}

@media screen and (max-width: 767px) {
  /* line 27, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper-left span {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* line 40, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header-wrapper-left p {
  font-weight: 300;
  font-size: 20px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 40, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper-left p {
    font-size: 18px;
  }
}

@media screen and (max-width: 424px) {
  /* line 40, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper-left p {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) {
  /* line 40, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper-left p {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  /* line 56, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-wrapper-right {
    display: none;
  }
}

/* line 63, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header-marker {
  position: absolute;
  top: -6px;
  right: 15px;
}

@media screen and (max-width: 1279px) {
  /* line 63, src/scss/_components/scheduler/_scheduler-header.scss */
  .scheduler__header-marker {
    top: -36px;
  }
}

/* line 72, src/scss/_components/scheduler/_scheduler-header.scss */
.scheduler__header svg {
  fill: #00449D;
}

/* line 3, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main {
  padding-top: 110px;
  padding-bottom: 120px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main {
    padding-top: 55px;
    padding-bottom: 60px;
  }
}

/* line 12, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-subtitle {
  padding-bottom: 24px;
  font-weight: 300;
  font-size: 26px;
  color: #000;
}

@media screen and (max-width: 1279px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-subtitle {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-subtitle {
    padding-bottom: 12px;
    font-size: 16px;
  }
}

/* line 26, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-title {
  padding-bottom: 37px;
  font-weight: normal;
  font-size: 50px;
  color: #000;
}

@media screen and (max-width: 1279px) {
  /* line 26, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-title {
    font-size: 40px;
    line-height: normal;
  }
  /* line 35, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-title br {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  /* line 26, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-title {
    font-size: 33px;
  }
}

@media screen and (max-width: 767px) {
  /* line 26, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-title {
    font-size: 26px;
  }
}

/* line 47, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-heading {
  width: 100%;
  max-width: 750px;
  padding-bottom: 40px;
  font-weight: 900;
  font-size: 60px;
  line-height: 70px;
  color: #000;
}

@media screen and (max-width: 1439px) {
  /* line 47, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-heading {
    font-size: 50px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1279px) {
  /* line 47, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-heading {
    font-size: 45px;
    line-height: 55px;
  }
}

@media screen and (max-width: 767px) {
  /* line 47, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-heading {
    padding-bottom: 30px;
    font-size: 26px;
    line-height: normal;
  }
}

/* line 71, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-wrapper {
  display: none;
  flex-direction: column;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  /* line 71, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-filter-wrapper {
    display: flex;
  }
}

/* line 80, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  color: #00449d;
}

/* line 91, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head.is-open svg.svg-arrow {
  opacity: 0;
  visibility: hidden;
  transform: scale(0) rotate(90deg);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 97, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head.is-open svg.svg-close {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 107, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head p {
  font-weight: 600;
  font-size: 16px;
  color: #00449d;
}

/* line 113, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head svg {
  position: absolute;
  top: 50%;
  right: 21px;
  margin-top: -4px;
  width: 12px;
  height: 12px;
}

/* line 122, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head svg.svg-arrow {
  opacity: 1;
  visibility: visible;
  transform: rotate(90deg);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 128, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-head svg.svg-close {
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* line 138, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-body {
  display: none;
}

/* line 142, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-body .c-form__field {
  margin-bottom: 20px;
}

/* line 146, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-body .c-form__label {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

/* line 153, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-filter-body .c-form__select {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 160, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

/* line 165, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  /* line 165, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-dropdown-wrapper {
    display: none;
  }
}

/* line 173, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-wrapper > div {
  padding-right: 60px;
}

@media screen and (max-width: 1279px) {
  /* line 173, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-dropdown-wrapper > div {
    padding-right: 30px;
    width: 100%;
    max-width: calc(100% / 3);
  }
}

/* line 182, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-wrapper > div:last-of-type {
  padding-right: 0;
}

/* line 188, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-title {
  padding-right: 16px;
  font-size: 20px;
  color: #222;
}

@media screen and (max-width: 1279px) {
  /* line 188, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-dropdown-title {
    font-size: 18px;
  }
}

/* line 197, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-btn {
  cursor: pointer;
  padding-right: 7px;
  font-size: 20px;
  color: #00449D;
}

@media screen and (max-width: 1279px) {
  /* line 197, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-dropdown-btn {
    flex: 1;
    font-size: 18px;
    text-align: right;
  }
}

/* line 208, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-btn svg {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  fill: #00449D;
}

/* line 216, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-menu {
  z-index: 899;
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  padding-top: 2px;
  padding-bottom: 3px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

/* line 229, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-link {
  display: flex;
  justify-content: flex-end;
  padding: 5px 15px 5px 30px;
  color: #00449D;
  font-size: 16px;
  line-height: 1.3;
  transition: background-color 0.3s ease-in-out;
}

/* line 238, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-dropdown-link:hover {
  background-color: #f4f9ff;
  transition: background-color 0.3s ease-in-out;
}

/* line 245, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box {
  cursor: pointer;
  height: 100%;
  padding: 30px 15px 30px 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {
  /* line 245, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box {
    padding: 20px 10px 20px 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 245, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box {
    white-space: normal;
  }
}

/* line 260, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box.is-active {
  box-shadow: none;
  background-color: #ecf1f6;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 265, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box.is-active .scheduler__main-box-title,
.scheduler__main-box.is-active .scheduler__main-box-subtitle {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}

/* line 272, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box:hover {
  background-color: #00449d;
  transition: background-color 0.3s ease-in-out;
}

/* line 276, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box:hover .scheduler__main-box-title,
.scheduler__main-box:hover .scheduler__main-box-subtitle,
.scheduler__main-box:hover .scheduler__main-box-desc {
  opacity: 1;
  color: #ffffff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 285, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin-left: -40px;
}

@media screen and (max-width: 1023px) {
  /* line 285, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box-wrapper {
    width: calc(100% + 15px);
    margin-left: -15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 285, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    display: block;
    width: calc(100% + 30px);
    padding-bottom: 15px;
    font-size: 0;
  }
}

/* line 304, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 3);
  padding-left: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 1023px) {
  /* line 304, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box-wrapper > div {
    padding-left: 15px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 304, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box-wrapper > div {
    display: inline-block;
    vertical-align: top;
    max-width: 295px;
  }
}

/* line 321, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box p {
  transition: color 0.3s ease-in-out;
}

/* line 325, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  font-weight: 800;
  font-size: 22px;
  color: #000;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 336, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box-subtitle {
  padding-bottom: 15px;
  font-size: 16px;
  color: #222;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {
  /* line 336, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-box-subtitle {
    font-size: 16px;
  }
}

/* line 346, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-box-desc {
  overflow: hidden;
  position: relative;
  height: 44px;
  font-size: 16px;
  line-height: 1.2;
  color: rgba(34, 34, 34, 0.4);
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 357, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 357, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-btn {
    padding-top: 15px;
  }
}

/* line 363, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn .c-btn {
  max-width: unset;
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
}

@media screen and (max-width: 767px) {
  /* line 363, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-btn .c-btn {
    position: relative;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 425px;
    padding-left: 20px;
    padding-right: 52px;
    font-size: 16px;
  }
}

/* line 381, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn .c-btn.is-active svg {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-in-out;
}

/* line 387, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn .c-btn p {
  padding-right: 5px;
}

/* line 391, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn .c-btn span {
  padding-right: 20px;
}

/* line 396, src/scss/_components/scheduler/_scheduler-main.scss */
.scheduler__main-btn svg {
  position: relative;
  top: 2px;
  width: 12px;
  height: 12px;
  transform: rotate(90deg);
  backface-visibility: hidden;
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 396, src/scss/_components/scheduler/_scheduler-main.scss */
  .scheduler__main-btn svg {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 20px;
  }
}

/* line 3, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data {
  display: none;
  padding-top: 129px;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data {
    padding-top: 65px;
    padding-bottom: 30px;
  }
}

/* line 12, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list {
  position: relative;
  transform: translateX(-38px);
  display: flex;
  flex-direction: column;
  padding-bottom: 115px;
}

@media screen and (max-width: 1279px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list {
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  /* line 12, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list {
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    padding-bottom: 70px;
  }
}

/* line 28, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li {
  display: flex;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  /* line 28, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li {
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
  }
}

/* line 37, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li:last-of-type {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 37, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li:last-of-type {
    max-width: 100%;
  }
}

/* line 45, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li > div:nth-of-type(1) {
  width: 40px;
  padding-top: 3px;
}

@media screen and (max-width: 767px) {
  /* line 45, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li > div:nth-of-type(1) {
    width: 35px;
    padding-top: 5px;
  }
}

/* line 53, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li > div:nth-of-type(2) {
  width: 100%;
  max-width: 180px;
}

@media screen and (max-width: 767px) {
  /* line 53, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li > div:nth-of-type(2) {
    max-width: calc(100% - 35px);
  }
}

/* line 60, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li > div:nth-of-type(3) {
  flex: 1;
  min-width: 1px;
  max-width: 500px;
}

@media screen and (max-width: 767px) {
  /* line 60, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li > div:nth-of-type(3) {
    padding-left: 40px;
  }
}

/* line 70, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li p,
.scheduler__data-list li span {
  font-size: 20px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 70, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li p,
  .scheduler__data-list li span {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) {
  /* line 70, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li p,
  .scheduler__data-list li span {
    font-size: 15px;
  }
}

/* line 81, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li p a,
.scheduler__data-list li span a {
  color: #00449d;
}

/* line 86, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li p {
  font-weight: 700;
}

/* line 90, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-list li svg {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  /* line 90, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-list li svg {
    position: relative;
    top: -4px;
  }
}

/* line 101, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 35px;
}

/* line 108, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option > div:nth-of-type(1), .scheduler__data-option > div:nth-of-type(3) {
  width: 100%;
  max-width: 180px;
}

@media screen and (max-width: 767px) {
  /* line 108, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option > div:nth-of-type(1), .scheduler__data-option > div:nth-of-type(3) {
    width: auto;
    max-width: unset;
  }
}

/* line 118, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option > div:nth-of-type(1) {
  transform: translateX(-23px);
}

@media screen and (max-width: 1279px) {
  /* line 118, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option > div:nth-of-type(1) {
    transform: translate(0);
  }
}

/* line 124, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option > div:nth-of-type(3) {
  transform: translateX(23px);
}

@media screen and (max-width: 1279px) {
  /* line 124, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option > div:nth-of-type(3) {
    transform: translate(0);
  }
}

/* line 131, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option > div:nth-of-type(2) {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  /* line 131, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option > div:nth-of-type(2) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* line 140, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option > div:nth-of-type(3) {
  display: flex;
  justify-content: flex-end;
}

/* line 146, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option h4 {
  font-size: 26px;
  font-weight: normal;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 1279px) {
  /* line 146, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option h4 {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  /* line 146, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option h4 {
    font-size: 18px;
  }
}

/* line 159, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option a {
  position: relative;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

/* line 165, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option a.swiper-button-disabled, .scheduler__data-option a.scheduler__data-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.3s ease-in-out;
}

/* line 171, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option a.swiper-button-disabled p,
.scheduler__data-option a.swiper-button-disabled span, .scheduler__data-option a.scheduler__data-disabled p,
.scheduler__data-option a.scheduler__data-disabled span {
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.3s ease-in-out;
}

/* line 179, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option svg {
  width: 21px;
  height: 21px;
}

/* line 184, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option p,
.scheduler__data-option span {
  font-weight: bold;
  font-size: 20px;
  color: #00449d;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 1279px) {
  /* line 184, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option p,
  .scheduler__data-option span {
    font-size: 17px;
  }
}

@media screen and (max-width: 767px) {
  /* line 184, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option p,
  .scheduler__data-option span {
    font-size: 14px;
    font-weight: 600;
  }
}

/* line 199, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-option p {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 199, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option p {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  /* line 206, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-option span {
    display: none;
  }
}

/* line 213, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-prev {
  left: -15px;
}

@media screen and (max-width: 1279px) {
  /* line 213, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-prev {
    left: 0;
  }
}

/* line 219, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-prev svg {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  /* line 219, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-prev svg {
    margin-right: 10px;
  }
}

/* line 227, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-next {
  right: -15px;
}

@media screen and (max-width: 1279px) {
  /* line 227, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-next {
    right: 0;
  }
}

/* line 233, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-next svg {
  margin-left: 20px;
  transform: scale(-1);
}

@media screen and (max-width: 767px) {
  /* line 233, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-next svg {
    margin-left: 10px;
  }
}

/* line 242, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slider {
  overflow: hidden;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  /* line 242, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slider {
    left: -15px;
    width: calc(100% + 30px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* line 258, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 145px;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 258, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide {
    height: 73px;
    padding: 5px;
  }
}

/* line 275, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.is-active, .scheduler__data-slide:hover {
  box-shadow: none;
  background-color: #00449d;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 281, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.is-active span,
.scheduler__data-slide.is-active h5,
.scheduler__data-slide.is-active p,
.scheduler__data-slide.is-active h4, .scheduler__data-slide:hover span,
.scheduler__data-slide:hover h5,
.scheduler__data-slide:hover p,
.scheduler__data-slide:hover h4 {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 290, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.scheduler__data-slide--empty {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

/* line 296, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.scheduler__data-slide--disabled {
  pointer-events: none;
  box-shadow: none;
  background-color: #f8f8f8;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 302, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.scheduler__data-slide--disabled span,
.scheduler__data-slide.scheduler__data-slide--disabled h5,
.scheduler__data-slide.scheduler__data-slide--disabled p,
.scheduler__data-slide.scheduler__data-slide--disabled h4 {
  color: rgba(0, 0, 0, 0.4);
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 313, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide.scheduler__data-slide--today h5,
  .scheduler__data-slide.scheduler__data-slide--today h4 {
    color: #cd084b;
  }
}

/* line 319, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide.scheduler__data-slide--today i,
.scheduler__data-slide.scheduler__data-slide--today span {
  opacity: 1;
  visibility: visible;
}

/* line 327, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide--top {
  width: 100%;
  padding: 0 7px;
}

/* line 332, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide i {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cd084b;
}

@media screen and (max-width: 767px) {
  /* line 332, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide i {
    display: none;
  }
}

/* line 347, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide span,
.scheduler__data-slide h5,
.scheduler__data-slide p,
.scheduler__data-slide h4 {
  transition: color 0.3s ease-in-out;
}

/* line 354, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide span {
  opacity: 0;
  visibility: hidden;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.2em;
  line-height: 20px;
  text-align: left;
  color: #cd084b;
}

@media screen and (max-width: 767px) {
  /* line 354, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide span {
    display: none;
  }
}

/* line 369, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide h5,
.scheduler__data-slide p {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

@media screen and (max-width: 767px) {
  /* line 369, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide h5,
  .scheduler__data-slide p {
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }
}

/* line 383, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide h5 {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 383, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide h5 {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  /* line 390, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide p {
    display: none;
  }
}

/* line 396, src/scss/_components/scheduler/_scheduler-data.scss */
.scheduler__data-slide h4 {
  font-weight: 600;
  font-size: 50px;
  line-height: 40px;
  color: #000;
}

@media screen and (max-width: 1279px) {
  /* line 396, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide h4 {
    font-size: 40px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 396, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide h4 {
    font-size: 33px;
    line-height: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 396, src/scss/_components/scheduler/_scheduler-data.scss */
  .scheduler__data-slide h4 {
    font-size: 26px;
    line-height: 20px;
  }
}

/* line 3, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time {
  display: none;
  padding-top: 120px;
  padding-bottom: 140px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

/* line 13, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* line 18, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-row-1 {
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 18, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time-row-1 {
    padding-bottom: 20px;
  }
  /* line 23, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time-row-1 .scheduler__time-col {
    max-width: 100%;
  }
}

/* line 30, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-col {
  width: 100%;
  max-width: calc((100% - 40px) / 2);
}

@media screen and (max-width: 767px) {
  /* line 30, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time-col {
    max-width: calc((100% - 20px) / 2);
  }
}

/* line 38, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 38, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time-btn {
    margin-bottom: 10px;
  }
}

/* line 53, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-btn:last-of-type {
  margin-bottom: 0;
}

/* line 57, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-btn.is-active, .scheduler__time-btn:hover {
  box-shadow: none;
  background-color: #00449d;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* line 63, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-btn.is-active span, .scheduler__time-btn:hover span {
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

/* line 69, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-btn span {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* line 69, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time-btn span {
    font-size: 16px;
    line-height: normal;
  }
}

/* line 84, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time .c-form__field--select {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 84, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time .c-form__field--select {
    display: block;
  }
}

/* line 90, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time .c-form__field--select.c-form__field--dropdown {
  display: block;
}

@media screen and (max-width: 767px) {
  /* line 90, src/scss/_components/scheduler/_scheduler-time.scss */
  .scheduler__time .c-form__field--select.c-form__field--dropdown {
    display: none;
  }
}

/* line 99, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time .c-form__label {
  color: rgba(0, 0, 0, 0.5);
}

/* line 103, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time .c-form__select {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 108, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown {
  position: relative;
}

/* line 111, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 121, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-btn span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  line-height: 60px;
  width: calc(100% - 52px);
  padding-left: 20px;
  color: #000;
  font-size: 18px;
}

/* line 137, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-btn > div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 52px;
  height: 100%;
}

/* line 147, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-btn > div svg {
  width: 12px;
  fill: #000;
}

/* line 154, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-search {
  outline: none;
  display: flex;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.05);
}

/* line 166, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 3px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transform: translateY(20px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 181, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content.is-open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* line 189, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  height: 75px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

/* line 196, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content > div:nth-of-type(2) {
  overflow-y: auto;
  max-height: 300px;
}

/* line 202, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  color: #00449D;
  line-height: 40px;
  font-size: 18px;
  transition: background-color 0.3s ease-in-out;
}

/* line 215, src/scss/_components/scheduler/_scheduler-time.scss */
.scheduler__time-dropdown-content a:hover {
  background-color: #f4f9ff;
  transition: background-color 0.3s ease-in-out;
}

/* line 3, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information {
  display: none;
}

/* line 6, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information--join {
  display: block;
}

/* line 10, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information-copyright {
  font-size: 20px;
  line-height: 1.3;
  color: #5390e3;
}

@media screen and (max-width: 767px) {
  /* line 10, src/scss/_components/scheduler/_scheduler-information.scss */
  .scheduler__information-copyright {
    font-size: 16px;
    line-height: normal;
  }
}

/* line 21, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request:before {
  top: auto;
  right: -160px;
  bottom: -176px;
  margin-right: 0;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  /* line 21, src/scss/_components/scheduler/_scheduler-information.scss */
  .scheduler__information.request:before {
    right: -75px;
    bottom: -75px;
    background-position: bottom right;
  }
}

/* line 35, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .request__bg {
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}

/* line 41, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .request__wrapper {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  /* line 41, src/scss/_components/scheduler/_scheduler-information.scss */
  .scheduler__information.request .request__wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

/* line 52, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__row {
  padding-left: 0;
  padding-right: 0;
}

/* line 57, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__textarea {
  resize: none;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
  border: none;
  box-shadow: none;
  color: #000;
  font-size: 18px;
}

/* line 67, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__textarea-wrapper {
  width: 100%;
  height: 200px;
}

/* line 74, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--textarea {
  width: 100%;
}

/* line 78, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--btn {
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 139px;
}

@media screen and (max-width: 767px) {
  /* line 78, src/scss/_components/scheduler/_scheduler-information.scss */
  .scheduler__information.request .c-form__field--btn {
    padding-bottom: 100px;
  }
}

/* line 86, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--btn .c-btn {
  min-width: 213px;
  margin-left: 0;
  margin-right: 20px;
}

/* line 91, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--btn .c-btn:last-of-type {
  margin-right: 0;
}

/* line 95, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--btn .c-btn.c-btn--border {
  border: 2px solid #5390e3;
  color: #ffffff;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 100, src/scss/_components/scheduler/_scheduler-information.scss */
.scheduler__information.request .c-form__field--btn .c-btn.c-btn--border:hover {
  border-color: #CD084B;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* line 3, src/scss/_components/scheduler/_scheduler-summary.scss */
.scheduler__summary {
  overflow: hidden;
  position: relative;
  padding-top: 65px;
  padding-bottom: 114px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/scheduler/_scheduler-summary.scss */
  .scheduler__summary {
    padding-top: 35px;
    padding-bottom: 55px;
  }
}

/* line 13, src/scss/_components/scheduler/_scheduler-summary.scss */
.scheduler__summary .scheduler__data-list {
  transform: translate(0);
}

/* line 17, src/scss/_components/scheduler/_scheduler-summary.scss */
.scheduler__summary-bg {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 17, src/scss/_components/scheduler/_scheduler-summary.scss */
  .scheduler__summary-bg {
    display: none;
  }
}

/* line 29, src/scss/_components/scheduler/_scheduler-summary.scss */
.scheduler__summary-bg img {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
}

@media screen and (max-width: 1279px) {
  /* line 29, src/scss/_components/scheduler/_scheduler-summary.scss */
  .scheduler__summary-bg img {
    right: -100px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 29, src/scss/_components/scheduler/_scheduler-summary.scss */
  .scheduler__summary-bg img {
    right: -300px;
  }
}

/* line 46, src/scss/_components/scheduler/_scheduler-summary.scss */
.scheduler__summary-wrapper {
  z-index: 2;
  position: relative;
}

/* line 3, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f8fafc;
}

/* line 8, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box {
  display: block;
}

/* line 12, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--away .scheduler__invite-box-badge {
  background-color: #f7b500;
}

/* line 17, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--hidden .scheduler__invite-box-badge {
  background-color: #BBBBBB;
}

/* line 22, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--online .scheduler__invite-box-badge {
  background-color: #6DD400;
}

/* line 27, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--wait .scheduler__invite-box-badge {
  background-color: #00449D;
}

/* line 32, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--decline .scheduler__invite-box-badge {
  background-color: #CD084B;
}

/* line 38, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--more .scheduler__invite-box-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5ecf5;
}

/* line 44, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--more .scheduler__invite-box-image span {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #00449d;
}

/* line 53, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box.scheduler__invite-box--more .scheduler__invite-box-name {
  color: #00449d;
  font-weight: 700;
}

/* line 61, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box:hover .scheduler__invite-box-name,
.scheduler__invite-box:hover .scheduler__invite-box-pos {
  color: #00449d;
  transition: color 0.3s ease-in-out;
}

/* line 68, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* line 72, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-wrapper > div {
  width: 100%;
  max-width: calc(100% / 7);
  padding-bottom: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 72, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-box-wrapper > div {
    max-width: calc(100% / 5);
  }
}

@media screen and (max-width: 767px) {
  /* line 72, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-box-wrapper > div {
    max-width: calc(100% / 3);
  }
}

@media screen and (max-width: 424px) {
  /* line 72, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-box-wrapper > div {
    max-width: calc(100% / 2);
  }
}

/* line 88, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-image {
  overflow: hidden;
  position: relative;
  width: 110px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* line 98, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-image-wrapper {
  position: relative;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
}

/* line 105, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 116, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 6px solid #f8fafc;
  background-color: #f8fafc;
}

/* line 129, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-badge svg {
  width: 18px;
  height: 18px;
  fill: #fff;
}

/* line 136, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-name, .scheduler__invite-box-pos {
  font-size: 20px;
  text-align: center;
  transition: color 0.3s ease-in-out;
}

/* line 143, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-name {
  font-weight: 800;
  color: #000;
}

/* line 148, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-box-pos {
  display: block;
  color: #222;
}

/* line 154, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-btn {
  display: flex;
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  /* line 154, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-btn {
    flex-direction: column;
    padding-bottom: 0;
  }
}

/* line 162, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-btn .c-btn {
  width: auto;
  max-width: unset;
  padding-left: 60px;
  padding-right: 60px;
  margin-right: 20px;
}

@media screen and (max-width: 1023px) {
  /* line 162, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-btn .c-btn {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  /* line 162, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-btn .c-btn {
    width: 100%;
    margin-bottom: 15px;
  }
}

/* line 179, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-btn .c-btn.c-btn--bg {
  box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
}

/* line 183, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-btn .c-btn:last-of-type {
  margin-right: 0;
}

/* line 189, src/scss/_components/scheduler/_scheduler-invite.scss */
.scheduler__invite-copyright {
  padding-top: 140px;
  font-size: 20px;
  color: #aab3b7;
}

@media screen and (max-width: 767px) {
  /* line 189, src/scss/_components/scheduler/_scheduler-invite.scss */
  .scheduler__invite-copyright {
    padding-top: 70px;
    font-size: 16px;
    line-height: normal;
  }
}

/* line 13, src/scss/_components/scheduler/_scheduler.scss */
.scheduler__title {
  padding-bottom: 40px;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
}

@media screen and (max-width: 1279px) {
  /* line 13, src/scss/_components/scheduler/_scheduler.scss */
  .scheduler__title {
    font-size: 34px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 767px) {
  /* line 13, src/scss/_components/scheduler/_scheduler.scss */
  .scheduler__title {
    padding-bottom: 30px;
    font-size: 24px;
    line-height: normal;
  }
}

/* line 29, src/scss/_components/scheduler/_scheduler.scss */
.scheduler__title span {
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.2);
}

/* line 34, src/scss/_components/scheduler/_scheduler.scss */
.scheduler__title--white {
  color: #ffffff;
}

/* line 41, src/scss/_components/scheduler/_scheduler.scss */
div#ui-datepicker-div {
  width: 270px;
}

@media screen and (max-width: 424px) {
  /* line 41, src/scss/_components/scheduler/_scheduler.scss */
  div#ui-datepicker-div {
    width: 260px;
  }
}

/* line 4, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper {
  padding-top: 45px;
  padding-bottom: 145px;
}

@media screen and (max-width: 767px) {
  /* line 4, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper {
    padding-bottom: 100px;
  }
}

/* line 11, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

/* line 19, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-header > div:nth-of-type(2) {
  padding-left: 20px;
  padding-right: 20px;
}

/* line 24, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-header > div svg {
  fill: #00449D;
}

/* line 28, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-header > div span {
  display: block;
  width: 2px;
  height: 38px;
  background-color: rgba(0, 68, 157, 0.1);
}

/* line 35, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-header > div p {
  font-weight: 300;
  font-size: 20px;
  color: #000000;
}

@media screen and (max-width: 767px) {
  /* line 35, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-header > div p {
    font-size: 18px;
  }
}

@media screen and (max-width: 424px) {
  /* line 35, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-header > div p {
    font-size: 16px;
  }
}

/* line 49, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-body {
  padding-top: 100px;
}

@media screen and (max-width: 1023px) {
  /* line 49, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-body {
    padding-top: 75px;
  }
}

/* line 56, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-footer {
  display: flex;
  justify-content: center;
  padding-top: 160px;
}

@media screen and (max-width: 767px) {
  /* line 56, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-footer {
    padding-top: 100px;
  }
}

/* line 64, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__wrapper-footer p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 767px) {
  /* line 64, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-footer p {
    font-size: 16px;
  }
}

@media screen and (max-width: 424px) {
  /* line 64, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__wrapper-footer p {
    font-size: 14px;
  }
}

/* line 77, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__title {
  padding-bottom: 30px;
  font-weight: 900;
  font-size: 60px;
  color: #000000;
  text-align: center;
}

@media screen and (max-width: 1365px) {
  /* line 77, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__title {
    font-size: 55px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 77, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__title {
    font-size: 48px;
  }
}

@media screen and (max-width: 767px) {
  /* line 77, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__title {
    font-size: 42px;
  }
}

@media screen and (max-width: 424px) {
  /* line 77, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__title {
    font-size: 36px;
  }
}

/* line 97, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__desc {
  width: 100%;
  max-width: 771px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 63px;
  font-weight: 300;
  font-size: 22px;
  color: #000000;
  text-align: center;
}

@media screen and (max-width: 1365px) {
  /* line 97, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__desc {
    font-size: 20px;
  }
}

@media screen and (max-width: 1023px) {
  /* line 97, src/scss/_components/event-issue/_event-issue.scss */
  .event-issue__desc {
    font-size: 18px;
  }
}

/* line 115, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

/* line 122, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__img {
  display: flex;
  align-items: flex-start;
}

/* line 126, src/scss/_components/event-issue/_event-issue.scss */
.event-issue__img img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 3, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main {
  overflow: hidden;
  position: relative;
  padding-top: 177px;
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main {
    padding-top: 125px;
    padding-bottom: 75px;
  }
}

/* line 14, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__bg {
  overflow: hidden;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + 300px);
  margin-left: -150px;
  height: 50%;
  border-top-left-radius: 50% 0;
  border-top-right-radius: 50% 0;
  border-bottom-right-radius: 50% 100px;
  border-bottom-left-radius: 50% 100px;
}

/* line 28, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__bg-inner {
  width: 100%;
  height: 100%;
  background-color: #f8fafc;
}

/* line 35, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__wrapper {
  z-index: 2;
  position: relative;
}

/* line 40, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__title {
  padding-bottom: 30px;
  font-weight: 300;
  color: #000000;
  text-align: center;
  font-size: 30px;
}

@media screen and (min-width: 320px) {
  /* line 40, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__title {
    font-size: calc(30px + 20 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 40, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__title {
    font-size: 50px;
  }
}

/* line 48, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__desc {
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 45px;
  color: #939393;
  text-align: center;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 48, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__desc {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 48, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__desc {
    font-size: 19px;
  }
}

/* line 60, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 73px;
}

/* line 68, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

/* line 77, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__subtitle {
  padding-bottom: 20px;
  font-weight: 300;
  color: #000000;
  text-align: center;
  font-size: 26px;
}

@media screen and (min-width: 320px) {
  /* line 77, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__subtitle {
    font-size: calc(26px + 14 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 77, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__subtitle {
    font-size: 40px;
  }
}

/* line 85, src/scss/_components/calendar-and-scheduler/_main.scss */
.cs-main__text {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  color: #939393;
  text-align: center;
  line-height: 1.6;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 85, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__text {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 85, src/scss/_components/calendar-and-scheduler/_main.scss */
  .cs-main__text {
    font-size: 19px;
  }
}

/* line 3, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description {
  padding-top: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* line 12, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description--bb0 {
  border-bottom: none;
}

/* line 16, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper {
  display: flex;
}

@media screen and (max-width: 767px) {
  /* line 16, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__wrapper {
    flex-wrap: wrap;
  }
}

/* line 22, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper-left, .cs-description__wrapper-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 22, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__wrapper-left, .cs-description__wrapper-right {
    width: 100%;
  }
}

/* line 30, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper-left {
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  /* line 30, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__wrapper-left {
    padding-right: 0;
    padding-bottom: 50px;
  }
}

/* line 37, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper-left > div {
  width: 100%;
  max-width: 460px;
}

@media screen and (max-width: 767px) {
  /* line 37, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__wrapper-left > div {
    max-width: 100%;
  }
}

/* line 46, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper-right {
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  /* line 46, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__wrapper-right {
    padding-left: 0;
  }
}

/* line 52, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__wrapper-right img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 62, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description .c-title {
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 62, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description .c-title {
    padding-bottom: 30px;
  }
}

/* line 69, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description .c-title__text span {
  font-weight: bold;
}

/* line 75, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__image {
  padding-bottom: 40px;
}

/* line 78, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__image img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 87, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__text {
  color: #939393;
  line-height: 1.6;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 87, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__text {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 87, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__text {
    font-size: 19px;
  }
}

/* line 92, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__text:not(:last-of-type) {
  padding-bottom: 40px;
}

/* line 97, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__list {
  display: flex;
  flex-wrap: wrap;
}

/* line 101, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;
  padding-bottom: 60px;
}

@media screen and (max-width: 767px) {
  /* line 101, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__list-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
  }
}

/* line 115, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__list-icon {
  display: flex;
  align-items: center;
  min-height: 50px;
}

/* line 121, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__list svg {
  fill: #00449D;
}

@media screen and (max-width: 767px) {
  /* line 121, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__list svg {
    transform: scale(0.9);
  }
}

/* line 128, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__list p {
  width: 100%;
  max-width: 204px;
  padding-top: 20px;
  text-align: center;
  font-weight: 700;
  color: #000000;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 128, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__list p {
    font-size: calc(16px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 128, src/scss/_components/calendar-and-scheduler/_description.scss */
  .cs-description__list p {
    font-size: 20px;
  }
}

/* line 142, src/scss/_components/calendar-and-scheduler/_description.scss */
.cs-description__slider .swiper-slide img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 3, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider {
  padding-top: 65px;
  padding-bottom: 65px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* line 12, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider .c-grid {
  max-width: 1270px;
}

/* line 16, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__wrapper {
  position: relative;
}

/* line 20, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider .c-title {
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  /* line 20, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider .c-title {
    padding-bottom: 40px;
  }
}

/* line 27, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider .c-title__text span {
  font-weight: bold;
}

/* line 33, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__container {
  width: 100%;
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1279px) {
  /* line 33, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 767px) {
  /* line 33, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 50, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__btn {
  position: absolute;
  top: 50%;
}

/* line 54, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__btn.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}

/* line 60, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__btn-wrapper {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  /* line 60, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__btn-wrapper {
    display: none;
  }
}

/* line 72, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__btn--prev {
  left: 0;
  transform: translateY(-50%) rotate(90deg);
}

@media screen and (max-width: 1279px) {
  /* line 72, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__btn--prev {
    left: -5px;
  }
}

/* line 80, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__btn--next {
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
}

@media screen and (max-width: 1279px) {
  /* line 80, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__btn--next {
    right: -5px;
  }
}

/* line 89, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  /* line 89, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block {
    flex-wrap: wrap;
  }
}

/* line 96, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-left, .cs-slider__block-right {
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 96, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-left, .cs-slider__block-right {
    width: 100%;
  }
}

/* line 104, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-left {
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  /* line 104, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-left {
    padding-right: 0;
  }
  /* line 109, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-left .cs-slider__block-description {
    display: none;
  }
}

/* line 115, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-right {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  /* line 115, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-right {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
  }
}

/* line 124, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-right .cs-slider__block-description {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 124, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-right .cs-slider__block-description {
    display: block;
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 133, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-title, .cs-slider__block-subtitle, .cs-slider__block-description {
    text-align: center;
  }
}

/* line 141, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-title {
  padding-bottom: 20px;
  font-weight: 700;
  color: #CD084B;
  font-size: 20px;
}

@media screen and (min-width: 320px) {
  /* line 141, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-title {
    font-size: calc(20px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 141, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-title {
    font-size: 24px;
  }
}

/* line 147, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-title--pb0 {
  padding-bottom: 0;
}

/* line 152, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-subtitle {
  padding-bottom: 20px;
  color: #000000;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 152, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-subtitle {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 152, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-subtitle {
    font-size: 19px;
  }
}

/* line 158, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block-description {
  width: 100%;
  max-width: 460px;
  color: #939393;
  line-height: 1.6;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 158, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-description {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 158, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-description {
    font-size: 19px;
  }
}

@media screen and (max-width: 767px) {
  /* line 158, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider__block-description {
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 170, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider__block img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 180, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider .swiper-container {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 180, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider .swiper-container {
    padding-bottom: 50px;
  }
}

/* line 186, src/scss/_components/calendar-and-scheduler/_slider.scss */
.cs-slider .swiper-pagination {
  display: none;
  position: absolute;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  /* line 186, src/scss/_components/calendar-and-scheduler/_slider.scss */
  .cs-slider .swiper-pagination {
    display: flex;
  }
}

/* line 3, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps {
  padding-top: 100px;
  padding-bottom: 50px;
  border-bottom: 1px solid #D8D8D8;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps {
    padding-top: 75px;
    padding-bottom: 25px;
  }
}

/* line 12, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps .c-title {
  padding-bottom: 60px;
}

/* line 16, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps .c-title__text span {
  font-weight: bold;
}

/* line 22, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 30px 30px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1023px) {
  /* line 22, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block {
    padding: 0 20px 20px;
  }
}

@media screen and (max-width: 767px) {
  /* line 22, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block {
    padding: 0 30px 30px;
  }
}

/* line 39, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 39, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-wrapper {
    flex-wrap: wrap;
  }
}

/* line 46, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-wrapper > div {
  width: 100%;
  max-width: calc((100% - (35px * 2)) / 3);
  min-height: 100%;
  padding-bottom: 50px;
}

@media screen and (max-width: 1023px) {
  /* line 46, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-wrapper > div {
    max-width: calc((100% - (15px * 2)) / 3);
  }
}

@media screen and (max-width: 767px) {
  /* line 46, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-wrapper > div {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
  }
}

/* line 63, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-num {
  font-weight: 900;
  color: rgba(0, 68, 157, 0.05);
  text-align: right;
  font-size: 80px;
}

@media screen and (min-width: 320px) {
  /* line 63, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-num {
    font-size: calc(80px + 30 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 63, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-num {
    font-size: 110px;
  }
}

/* line 70, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-icon {
  min-height: 80px;
  padding-bottom: 30px;
}

/* line 75, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-title {
  padding-bottom: 20px;
  font-weight: 700;
  color: #CD084B;
  font-size: 20px;
}

@media screen and (min-width: 320px) {
  /* line 75, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-title {
    font-size: calc(20px + 6 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 75, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-title {
    font-size: 26px;
  }
}

/* line 82, src/scss/_components/calendar-and-scheduler/_steps.scss */
.cs-steps__block-desc {
  color: #939393;
  line-height: 1.6;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 82, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-desc {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 82, src/scss/_components/calendar-and-scheduler/_steps.scss */
  .cs-steps__block-desc {
    font-size: 19px;
  }
}

/* line 3, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more {
  padding-top: 105px;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more {
    padding-top: 75px;
  }
}

/* line 9, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more .c-title {
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  /* line 9, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more .c-title {
    padding-bottom: 60px;
  }
}

/* line 16, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more .c-title__text span {
  font-weight: bold;
}

/* line 22, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block {
  display: flex;
  align-items: center;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  /* line 22, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 75px;
  }
}

/* line 32, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block--reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  /* line 32, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block--reverse {
    flex-direction: column-reverse;
  }
}

/* line 38, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block--reverse .cs-more__block-left {
  padding-right: 0;
  padding-left: 90px;
}

@media screen and (max-width: 1023px) {
  /* line 38, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block--reverse .cs-more__block-left {
    padding-left: 45px;
  }
}

@media screen and (max-width: 767px) {
  /* line 38, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block--reverse .cs-more__block-left {
    padding-left: 0;
  }
}

/* line 50, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block-wrapper {
  display: flex;
  flex-direction: column;
}

/* line 55, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block-left, .cs-more__block-right {
  width: 50%;
}

/* line 60, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block-left {
  padding-right: 90px;
}

@media screen and (max-width: 1023px) {
  /* line 60, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block-left {
    width: 60%;
    padding-right: 45px;
  }
}

@media screen and (max-width: 767px) {
  /* line 60, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block-left {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }
}

/* line 72, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block-left > div {
  width: 100%;
  max-width: 460px;
}

@media screen and (max-width: 767px) {
  /* line 72, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block-left > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  /* line 81, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block-right {
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  /* line 81, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block-right {
    width: 100%;
    padding-bottom: 30px;
  }
}

/* line 91, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block p {
  padding-bottom: 20px;
  font-weight: 700;
  color: #CD084B;
  font-size: 20px;
}

@media screen and (min-width: 320px) {
  /* line 91, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block p {
    font-size: calc(20px + 4 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 91, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block p {
    font-size: 24px;
  }
}

/* line 98, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block span {
  display: block;
  color: #939393;
  line-height: 1.6;
  font-size: 16px;
}

@media screen and (min-width: 320px) {
  /* line 98, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block span {
    font-size: calc(16px + 3 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 98, src/scss/_components/calendar-and-scheduler/_more.scss */
  .cs-more__block span {
    font-size: 19px;
  }
}

/* line 105, src/scss/_components/calendar-and-scheduler/_more.scss */
.cs-more__block img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/* line 3, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful {
  padding-top: 102px;
  padding-bottom: 117px;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  /* line 3, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* line 12, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful .c-title {
  padding-bottom: 20px;
}

/* line 16, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful .c-title__text span {
  font-weight: bold;
}

/* line 22, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful .c-text {
  padding-bottom: 60px;
}

/* line 27, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* line 27, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block-wrapper {
    flex-wrap: wrap;
  }
}

/* line 34, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-wrapper > div {
  width: 100%;
  max-width: calc(100% / 5);
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 1023px) {
  /* line 34, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block-wrapper > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  /* line 34, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block-wrapper > div {
    max-width: 50%;
    padding-bottom: 50px;
  }
  /* line 47, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block-wrapper > div:last-of-type {
    max-width: 100%;
  }
}

/* line 54, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-head {
  overflow: hidden;
  position: relative;
  width: 105px;
  height: 105px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  background-color: #D8D8D8;
}

/* line 64, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-head--border {
  border: 1px solid #D4D4D4;
}

/* line 68, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-head img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* line 79, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block-body {
  padding-top: 20px;
}

/* line 83, src/scss/_components/calendar-and-scheduler/_powerful.scss */
.cs-powerful__block p {
  font-weight: 700;
  color: #000000;
  text-align: center;
  font-size: 18px;
}

@media screen and (min-width: 320px) {
  /* line 83, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block p {
    font-size: calc(18px + 2 * (100vw - 320px) / 1120);
  }
}

@media screen and (min-width: 1440px) {
  /* line 83, src/scss/_components/calendar-and-scheduler/_powerful.scss */
  .cs-powerful__block p {
    font-size: 20px;
  }
}

/* line 3, src/scss/_components/calendar-and-scheduler/_info.scss */
.cs-info {
  padding-top: 92px;
  padding-bottom: 100px;
}

/* line 7, src/scss/_components/calendar-and-scheduler/_info.scss */
.cs-info__desc {
  width: 100%;
  max-width: 788px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 45px;
  font-size: 19px;
  color: #939393;
  text-align: center;
}

/* line 20, src/scss/_components/calendar-and-scheduler/_info.scss */
.cs-info__btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
