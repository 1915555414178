// MAIN :: START

.main {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    opacity: 0.275;
    background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-main.png");
    background-repeat: no-repeat;
    background-position: center 151%;
    background-size: cover;
  }

  &__bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: calc(100% - 102px);
    border-top-left-radius: 50% 0;
    border-top-right-radius: 50% 0;
    border-bottom-right-radius: 50% 100px;
    border-bottom-left-radius: 50% 100px;

    &-inner {
      width: 100%;
      height: 100%;
      @include background-gradient(#00449D  , #001632, 'horizontal');
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    padding-top: 146px;
    @include respond(max-small) {
      padding-top: 100px;
    }
  }

  &__subtitle,
  &__title {
    text-align: center;
    font-family: 'Muli', sans-serif;
  }

  &__subtitle {
    padding-bottom: 20px;
    color: rgba($c-white, 0.4);
    text-transform: uppercase;
    letter-spacing: 2.25px;
    font-weight: bold;
    @include fluid(font-size, 320px, 1440px, 16px, 18px);
  }

  &__title {
    width: 100%;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 39px;
    color: $c-white;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 30px, 50px);
  }

  &__btn {
    //max-width: 287px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
  }

  &__video {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    //background-color: #F7F7F7;
    @include fluid(min-height, 320px, 1440px, 320px, 472px);
    //object-fit: cover;
    //object-position: center;
    //background-image: url('../img/img-video-poster.png');
    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: cover;
    @include adaptive(767) {
      min-height: unset;
    }

    &-wrapper {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      width: 100%;
      max-width: 840px;
      margin-left: auto;
      margin-right: auto;

      &:hover {
        .main__video-progress {
          transform: translateY(0);
          @include transition(transform 0.5s);
        }
      }

      &:before {
        z-index: 0;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-video-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &-btn {
      z-index: 2;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: $c-white;
      @include adaptive(767) {
        width: 125px;
        height: 125px;
      }

      &:hover {
        svg {
          transform: scale(0.9);
          @include transition(transform 0.5s);
          @include adaptive(767) {
            transform: scale(0.75);
          }
        }
      }

      svg {
        margin-left: 5px;
        fill: #003F91;
        transform-origin: center;
        @include transition(transform);
        @include adaptive(767) {
          transform: scale(0.85);
        }
      }
    }

    &-progress {
      z-index: 2;
      cursor: pointer;
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 41px;
      width: calc(100% - 100px);
      height: 10px;
      transform: translateY(100px);
      margin-left: auto;
      margin-right: auto;
      border-radius: 7px;
      background-color: #E1E1E1;
      @include transition(transform);
      @include respond(max-small) {
        width: calc(100% - 30px);
        bottom: 30px;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #CD084B;
        @include transition(width);
      }
    }
  }
}

// MAIN :: END
