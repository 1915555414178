// FOOTER :: START

.footer {
  background-color: #191919;

  &__wrapper {
    padding-top: 75px;
    padding-bottom: 107px;
    @include respond(max-extra-small) {
      padding-top: 37px;
      padding-bottom: 53px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;

    &-0 {
      @include respond(max-small) {
        flex-wrap: wrap;
      }
    }

    &-1 {
      justify-content: center;
      padding-top: 24px;
    }
  }

  &__col {
    &-0 {
      width: 326px;
      @include respond(max-medium) {
        width: 236px;
      }
      @include respond(max-extra-small) {
        padding-bottom: 27px;
      }
    }

    &-1,
    &-2 {
      width: 100%;
      max-width: calc((100% - (326px + 126px)) / 2);
      padding-right: 15px;
      @include respond(max-medium) {
        max-width: calc((100% - (236px + 126px)) / 2);
      }
    }

    &-3 {
      width: 126px;
    }

    &-0,
    &-1,
    &-2,
    &-3 {
      @include respond(max-small) {
        width: 50%;
        max-width: 50%;
      }
      @include respond(max-extra-small) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__title {
    min-height: 30px;
    padding-bottom: 28px;
    color: $c-white;
    font-size: 16px;
    font-weight: bold;
    @include respond(max-extra-small) {
      text-align: center;
    }
  }
}

// FOOTER :: END