// SECTION :: START

.r-main {
  overflow: hidden;
  position: relative;

  &__bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: calc(100% - 0px);
    border-top-left-radius: 50% 0;
    border-top-right-radius: 50% 0;
    border-bottom-right-radius: 50% 100px;
    border-bottom-left-radius: 50% 100px;

    &-inner {
      width: 100%;
      height: 100%;
      @include background-gradient(#F8FAFC, #F8FAFC, 'horizontal');
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    min-height: 519px;
    padding-top: 177px;
  }

  &__title {
    padding-bottom: 40px;
    color: $c-black;
    text-align: center;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 30px, 50px);
  }

  &__subtitle {
    padding-bottom: 10px;
    color: rgba(#00449D, 0.4);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.25px;
    font-weight: bold;
    @include fluid(font-size, 320px, 1440px, 14px, 18px);
  }

  &__btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 30px;
    border-radius: 15px;
    border: 2px solid rgba(#00449D, 0.4);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    color: rgba(#00449D, 0.4);
    letter-spacing: 2px;
    text-transform: uppercase;
    @include transition(border-color, background-color, color);
    @include respond(max-extra-small) {
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }

    &.is-active,
    &:hover {
      border-color: #00449D;
      background-color: #00449D;
      color: #fff;
      @include transition(border-color, background-color, color);
    }

    &-wrapper {
      z-index: -1;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 632px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 91px;
      @include respond(max-extra-small) {
        padding-bottom: 45px;
      }
    }
  }
}

// SECTION :: END