// AI :: START

.ai {
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #D8D8D8;

  .c-grid-fluid {
    @include respond(medium) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__wrapper {
    padding-top: 99px;
    padding-bottom: 30px;
    @include respond(max-small) {
      padding-top: 49px;
    }
  }

  &__row {
    display: flex;
    @include respond(max-large) {
      align-items: center;
    }
    @include respond(max-small) {
      flex-wrap: wrap;
    }

    &-left,
    &-right {
      width: 50%;
      @include respond(max-small) {
        width: 100%;
      }
    }

    &-left {
      padding-right: 70px;
      padding-left: 15px;
      @include respond(max-small) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &__text {
    text-align: left;

    &-wrapper {
      width: 100%;
      max-width: 450px;
      margin-left: auto;
      padding-top: 46px;
      @include respond(max-small) {
        max-width: 100%;
        padding-top: 0;
        margin-bottom: 30px;
      }
    }

    &--pb {
      padding-bottom: 29px;

      &:last-of-type {
        padding-bottom: 38px;
      }
    }
  }

  &__btn {
    display: flex;

    .c-btn {
      min-width: 232px;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    @include respond(max-small) {
      position: relative;
      right: -15px;
    }
  }
}

// AI :: END
