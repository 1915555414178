// SECTION scheduler :: START

&__invite {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f8fafc;

  &-box {
    display: block;

    &.scheduler__invite-box--away {
      .scheduler__invite-box-badge {
        background-color: #f7b500;
      }
    }
    &.scheduler__invite-box--hidden {
      .scheduler__invite-box-badge {
        background-color: #BBBBBB;
      }
    }
    &.scheduler__invite-box--online {
      .scheduler__invite-box-badge {
        background-color: #6DD400;
      }
    }
    &.scheduler__invite-box--wait {
      .scheduler__invite-box-badge {
        background-color: #00449D;
      }
    }
    &.scheduler__invite-box--decline {
      .scheduler__invite-box-badge {
        background-color: #CD084B;
      }
    }
    &.scheduler__invite-box--more {
      .scheduler__invite-box {
        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e5ecf5;

          span {
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
            color: #00449d;
          }
        }
        &-name {
          color: #00449d;
          font-weight: 700;
        }
      }
    }

    &:hover {
      .scheduler__invite-box-name,
      .scheduler__invite-box-pos {
        color: #00449d;
        @include transition(color);
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 100%;
        max-width: calc(100% / 7);
        padding-bottom: 50px;
        @include adaptive(1023) {
          max-width: calc(100% / 5);
        }
        @include adaptive(767) {
          max-width: calc(100% / 3);
        }
        @include adaptive(424) {
          max-width: calc(100% / 2);
        }
      }
    }

    &-image {
      overflow: hidden;
      position: relative;
      width: 110px;
      height: 110px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      margin-bottom: 10px;

      &-wrapper {
        position: relative;
        width: 110px;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -5px;
      right: -5px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 6px solid #f8fafc;
      background-color: #f8fafc;

      svg {
        width: 18px;
        height: 18px;
        fill: #fff;
      }
    }

    &-name,
    &-pos {
      font-size: 20px;
      text-align: center;
      @include transition(color);
    }

    &-name {
      font-weight: 800;
      color: #000;
    }

    &-pos {
      display: block;
      color: #222;
    }
  }

  &-btn {
    display: flex;
    padding-top: 50px;
    @include adaptive(767) {
      flex-direction: column;
      padding-bottom: 0;
    }

    .c-btn {
      width: auto;
      max-width: unset;
      padding-left: 60px;
      padding-right: 60px;
      margin-right: 20px;
      @include adaptive(1023) {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        font-size: 16px;
      }
      @include adaptive(767) {
        width: 100%;
        margin-bottom: 15px;
      }

      &.c-btn--bg {
        box-shadow: 0px 10px 15px rgba(205, 8, 75, 0.2);
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-copyright {
    padding-top: 140px;
    font-size: 20px;
    color: #aab3b7;
    @include adaptive(767) {
      padding-top: 70px;
      font-size: 16px;
      line-height: normal;
    }
  }
}

// SECTION scheduler :: END