// SECTION scheduler :: START

&__data {
  display: none;
  padding-top: 129px;
  padding-bottom: 60px;
  @include adaptive(767) {
    padding-top: 65px;
    padding-bottom: 30px;
  }

  &-list {
    position: relative;
    transform: translateX(-38px);
    display: flex;
    flex-direction: column;
    padding-bottom: 115px;
    @include adaptive(1279) {
      transform: translateX(0);
    }
    @include adaptive(767) {
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: row;
      padding-bottom: 70px;
    }

    li {
      display: flex;
      padding-bottom: 20px;
      @include adaptive(767) {
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
      }

      &:last-of-type {
        padding-bottom: 0;
        @include adaptive(767) {
          max-width: 100%;
        }
      }

      > div {
        &:nth-of-type(1) {
          width: 40px;
          padding-top: 3px;
          @include adaptive(767) {
            width: 35px;
            padding-top: 5px;
          }
        }
        &:nth-of-type(2) {
          width: 100%;
          max-width: 180px;
          @include adaptive(767) {
            max-width: calc(100% - 35px);
          }
        }
        &:nth-of-type(3) {
          flex: 1;
          min-width: 1px;
          max-width: 500px;
          @include adaptive(767) {
            padding-left: 40px;
          }
        }
      }

      p,
      span {
        font-size: 20px;
        color: #000;
        @include adaptive(767) {
          font-size: 16px;
        }
        @include adaptive(374) {
          font-size: 15px;
        }

        a {
          color: #00449d;
        }
      }

      p {
        font-weight: 700;
      }

      svg {
        width: 20px;
        height: 20px;
        @include adaptive(767) {
          position: relative;
          top: -4px;
        }
      }
    }
  }

  &-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;

    > div {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: 100%;
        max-width: 180px;
        @include adaptive(767) {
          width: auto;
          max-width: unset;
        }
      }

      &:nth-of-type(1) {
        transform: translateX(-23px);
        @include adaptive(1279) {
          transform: translate(0);
        }
      }
      &:nth-of-type(3) {
        transform: translateX(23px);
        @include adaptive(1279) {
          transform: translate(0);
        }
      }

      &:nth-of-type(2) {
        padding-left: 15px;
        padding-right: 15px;
        @include adaptive(767) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      &:nth-of-type(3) {
        display: flex;
        justify-content: flex-end;
      }
    }

    h4 {
      font-size: 26px;
      font-weight: normal;
      text-align: center;
      color: #000;
      @include adaptive(1279) {
        font-size: 22px;
      }
      @include adaptive(767) {
        font-size: 18px;
      }
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      @include transition(color);

      &.swiper-button-disabled,
      &.scheduler__data-disabled {
        pointer-events: none;
        color: rgba(#000, 0.4);
        @include transition(color);

        p,
        span {
          color: rgba(#000, 0.4);
          @include transition(color);
        }
      }
    }

    svg {
      width: 21px;
      height: 21px;
    }

    p,
    span {
      font-weight: bold;
      font-size: 20px;
      color: #00449d;
      @include transition(color);
      @include adaptive(1279) {
        font-size: 17px;
      }
      @include adaptive(767) {
        font-size: 14px;
        font-weight: 600;
      }
    }

    p {
      display: none;
      @include adaptive(767) {
        display: block;
      }
    }

    span {
      @include adaptive(767) {
        display: none;
      }
    }
  }

  &-prev {
    left: -15px;
    @include adaptive(1279) {
      left: 0;
    }

    svg {
      margin-right: 20px;
      @include adaptive(767) {
        margin-right: 10px;
      }
    }
  }

  &-next {
    right: -15px;
    @include adaptive(1279) {
      right: 0;
    }

    svg {
      margin-left: 20px;
      transform: scale(-1);
      @include adaptive(767) {
        margin-left: 10px;
      }
    }
  }

  &-slider {
    overflow: hidden;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    @include adaptive(767) {
      left: -15px;
      width: calc(100% + 30px);
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-slide {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 145px;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    @include transition(box-shadow, background-color);
    @include adaptive(767) {
      height: 73px;
      padding: 5px;
    }

    &.is-active,
    &:hover {
      box-shadow: none;
      background-color: #00449d;
      @include transition(box-shadow, background-color);

      span,
      h5,
      p,
      h4 {
        color: #ffffff;
        @include transition(color);
      }
    }

    &.scheduler__data-slide--empty {
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }

    &.scheduler__data-slide--disabled {
      pointer-events: none;
      box-shadow: none;
      background-color: #f8f8f8;
      @include transition(box-shadow, background-color);

      span,
      h5,
      p,
      h4 {
        color: rgba(#000, 0.4);
        @include transition(color);
      }
    }

    &.scheduler__data-slide--today {
      @include adaptive(767) {
        h5,
        h4 {
          color: #cd084b;
        }
      }

      i,
      span {
        opacity: 1;
        visibility: visible;
      }

    }

    &--top {
      width: 100%;
      padding: 0 7px;
    }

    i {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cd084b;
      @include adaptive(767) {
        display: none;
      }
    }

    span,
    h5,
    p,
    h4 {
      @include transition(color);
    }

    span {
      opacity: 0;
      visibility: hidden;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 10px;
      letter-spacing: 0.2em;
      line-height: 20px;
      text-align: left;
      color: #cd084b;
      @include adaptive(767) {
        display: none;
      }
    }

    h5,
    p {
      padding-bottom: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000;
      @include adaptive(767) {
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    h5 {
      display: none;
      @include adaptive(767) {
        display: block;
      }
    }

    p {
      @include adaptive(767) {
        display: none;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 50px;
      line-height: 40px;
      color: #000;
      @include adaptive(1279) {
        font-size: 40px;
        line-height: 30px;
      }
      @include adaptive(1023) {
        font-size: 33px;
        line-height: 30px;
      }
      @include adaptive(767) {
        font-size: 26px;
        line-height: 20px;
      }
    }
  }
}

// SECTION scheduler :: END
