// SECTION event issue :: START

.event-issue {
  &__wrapper {
    padding-top: 45px;
    padding-bottom: 145px;
    @include adaptive(767) {
      padding-bottom: 100px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
      padding-bottom: 16px;

      > div {
        &:nth-of-type(2) {
          padding-left: 20px;
          padding-right: 20px;
        }

        svg {
          fill: #00449D;
        }

        span {
          display: block;
          width: 2px;
          height: 38px;
          background-color: rgba(#00449D, 0.1);
        }

        p {
          font-weight: 300;
          font-size: 20px;
          color: #000000;
          @include adaptive(767) {
            font-size: 18px;
          }
          @include adaptive(424) {
            font-size: 16px;
          }
        }
      }
    }

    &-body {
      padding-top: 100px;
      @include adaptive(1023) {
        padding-top: 75px;
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      padding-top: 160px;
      @include adaptive(767) {
        padding-top: 100px;
      }

      p {
        font-size: 20px;
        color: rgba(#000000, 0.4);
        @include adaptive(767) {
          font-size: 16px;
        }
        @include adaptive(424) {
          font-size: 14px;
        }
      }
    }
  }

  &__title {
    padding-bottom: 30px;
    font-weight: 900;
    font-size: 60px;
    color: #000000;
    text-align: center;
    @include adaptive(1365) {
      font-size: 55px;
    }
    @include adaptive(1023) {
      font-size: 48px;
    }
    @include adaptive(767) {
      font-size: 42px;
    }
    @include adaptive(424) {
      font-size: 36px;
    }
  }

  &__desc {
    width: 100%;
    max-width: 771px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 63px;
    font-weight: 300;
    font-size: 22px;
    color: #000000;
    text-align: center;
    @include adaptive(1365) {
      font-size: 20px;
    }
    @include adaptive(1023) {
      font-size: 18px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }

  &__img {
    display: flex;
    align-items: flex-start;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// SECTION event issue :: END
