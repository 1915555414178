// SECTION plan :: START

.plan {
  overflow: hidden;
  position: relative;

  &__bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: calc(100% - 0px);
    border-top-left-radius: 50% 0;
    border-top-right-radius: 50% 0;
    border-bottom-right-radius: 50% 100px;
    border-bottom-left-radius: 50% 100px;

    &-inner {
      width: 100%;
      height: 100%;
      @include background-gradient(#F8FAFC, #F8FAFC, 'horizontal');
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    min-height: 519px;
    padding-top: 177px;
  }

  &__title {
    padding-bottom: 40px;
    color: $c-black;
    text-align: center;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 30px, 50px);
  }

  &__subtitle {
    padding-bottom: 10px;
    color: rgba(#00449D, 0.4);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.25px;
    font-weight: bold;
    @include fluid(font-size, 320px, 1440px, 14px, 18px);
  }

  &__tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    will-change: color;
    @include transition(color);
    @include adaptive(1279) {
      font-size: 18px;
    }
    @include adaptive(767) {
      width: 100%;
      max-width: calc(100% / 3);
      padding-left: 10px;
      padding-right: 10px;
    }
    @include adaptive(575) {
      font-size: 16px;
    }
    @include adaptive(374) {
      font-size: 14px;
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 25px;
      background-color: #00449d;
      box-shadow: 0px 15px 30px rgba(0, 68, 157, 0.2);
      backface-visibility: hidden;
      transform-origin: center;
      will-change: width, transform;
      @include transition(width,transform);
    }

    &:after {
      opacity: 0;
      z-index: 0;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 30px;
      background-color: rgba(#00449d, 0.1);
      @include transition(opacity);
    }

    &:first-of-type {
      &:after {
        opacity: 1;
        right: 0;
        @include transition(opacity);
      }

      &.is-active {
        &:after {
          opacity: 0;
          @include transition(opacity);
        }
      }
    }

    &:last-of-type {
      &:after {
        opacity: 1;
        left: 0;
        @include transition(opacity);
      }

      &.is-active {
        &:after {
          opacity: 0;
          @include transition(opacity);
        }
      }
    }

    &:hover {
      color: #00449D;
      @include transition(color);
    }

    &.is-active {
      color: $c-white;
      @include transition(color);
    }

    &.is-border-hide {
      &:after {
        opacity: 0;
        @include transition(opacity);
      }
    }

    span {
      z-index: 2;
      position: relative;
    }

    &-cover {
      display: flex;
      justify-content: center;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 25px;
      background-color: #fff;
      @include adaptive(767) {
        width: 100%;
        max-width: 100%;
      }
      @include adaptive(424) {
        height: 40px;
      }
    }
  }
}

// SECTION plan :: END