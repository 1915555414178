// SECTION exchequer :: START

.exchequer {
  padding-top: 101px;
  padding-bottom: 170px;

  &__title {
    padding-bottom: 70px;
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 1.5;
  }

  &__block {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
    padding: 20px 20px 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 15px 35px 0 rgba(0,68,157,0.07);

    &:hover {
      .exchequer__block-top {
        img {
          transform: scale(1.1);
          @include transition(transform);
        }
      }
    }

    @each $classMod, $bg in
        ('articles', #d0021b),
        ('feature', #7319C1),
        ('releases', #F5A623),
        ('study', #0054C1),
        ('video', #7ED321)
    {
      &--#{$classMod} {
        .exchequer__block-badge {
          background-color: $bg;
        }
      }
    }

    &-top {
      overflow: hidden;
      position: relative;
      z-index: 1;
      height: 200px;
      margin-bottom: 26px;
      border-radius: 7px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform-origin: center;
        @include transition(transform);
      }
    }

    &-play {
      z-index: 10;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      svg {
        fill: #fff;
      }
    }

    &-badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 24px;
      margin-bottom: 25px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 12px;

      p {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        color: #FFF;
        letter-spacing: 1px;
        line-height: 1.3;
      }
    }

    &-title {
      padding-bottom: 10px;
      font-size: 26px;
      color: #000000;
      letter-spacing: 0;
      line-height: 1.3;
    }

    &-text {
      font-size: 17px;
      color: rgba(0,0,0,0.40);
      letter-spacing: 0;
      line-height: 25px;
    }

    &-wrapper {
      position: relative;
      margin-left: -40px;
      display: flex;
      flex-wrap: wrap;
      @include respond(max-medium) {
        margin-left: -15px;
      }

      > div {
        display: flex;
        width: 100%;
        max-width: calc(100% / 3);
        padding-left: 40px;
        @include respond(max-medium) {
          padding-left: 15px;
        }
        @include respond(max-small) {
          max-width: calc(100% / 2);
        }
        @include respond(max-extra-small) {
          max-width: 100%
        }

        &.is-hide {
          display: none;
        }
      }
    }
  }
}

// SECTION exchequer :: END
