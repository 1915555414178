// PIPELINES :: START

.pipelines {
  overflow: hidden;
  position: relative;

  .c-grid-fluid {
    padding-left: 0;
    padding-right: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;

    &-0 {
      padding-top: 100px;
      @include respond(max-small) {
        padding-top: 50px;
      }
    }

    &-1 {}

    &-2 {
      padding-bottom: 118px;
      @include respond(max-small) {
        padding-bottom: 59px;
      }
    }
  }

  &__slider {
    position: relative;

    &-item {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.is-show {
        display: block;
        animation-fill-mode: both;
        animation-duration: 1s;
        animation-name: c-fadeIn;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__tabs {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 12px;
    margin-left: 25px;
    margin-right: 25px;
    color: #939393;
    font-weight: bold;
    text-align: center;
    @include transition(color);
    @include fluid(font-size, 320px, 1440px, 12px, 18px);
    @include fluid(margin-left margin-right, 320px, 1440px, 5px, 25px);
    @include respond(max-small) {
      width: 100%;
      max-width: calc(100% / 3);
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: transparent;
      transform: translateX(-100%);
      @include transition(background-color, transform);
    }

    &.is-active {
      color: $c-black;
      @include transition(color);

      &:after {
        background-color: $c-black;
        transform: translateX(0);
        @include transition(background-color, transform);
      }
    }

    &:hover {
      color: $c-black;
      @include transition(color);
    }


    &-head {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-body {
      position: relative;
      width: 100%;
      max-width: 824px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 13px;

      > div {
        opacity: 1;
        z-index: -1;
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        min-height: 191px;
        padding: 35px 45px;
        border-radius: 13px;
        background-color: rgba(#F2F5FA, 0.5);
        transform: scale(0.95);
        @include fluid(padding-top padding-bottom, 320px, 1440px, 25px, 35px);
        @include fluid(padding-left padding-right, 320px, 1440px, 30px, 45px);
        @include transition(bottom 0.5s, transform 0.5s, background-color 0.5s);

        &.is-active {
          display: flex;
          z-index: 1;
          position: relative;
          bottom: 0;
          transform: scale(1);
          background-color: rgba(#F2F5FA, 1);
          @include transition(bottom 0.5s, transform 0.5s, background-color 0.5s);

          p {
            opacity: 1;
            display: block;
            @include transition(opacity 0.5s);
          }
        }

        p {
          opacity: 0;
          display: none;
          line-height: 1.8;
          text-align: center;
          @include transition(opacity 0.5s);
        }
      }
    }
  }
}

@keyframes c-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// PIPELINES :: END