// SECTION join :: START

.join {
  overflow: hidden;
  position: relative;
  min-height: 530px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: linear-gradient(250deg, #001632 0%, #00449d);
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 1300px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      //mix-blend-mode: soft-light;
      opacity: 0.275;
      background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-join-bg@2x.png");
      background-repeat: no-repeat;
      background-position: center 35%;
      background-size: cover;
    }
  }
  
  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__desc {
    padding-bottom: 60px;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
    color: #ffffff;
    @include adaptive(1279) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
    }
    @include adaptive(424) {
      font-size: 30px;
    }
  }

  &__btn {
    min-width: 239px;
    box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
    will-change: background-color, border-color, color;
    @include transition(background-color, border-color, color, box-shadow);

    &:hover {
      border-color: #fff;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
      @include transition(background-color, border-color, color, box-shadow);
    }

    &-wrapper {
      display: flex;
    }
  }
}

// SECTION join :: END
