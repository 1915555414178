// TITLE :: START

.c-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  &--features {
    padding-bottom: 71px;
  }
  &--request {
    padding-bottom: 50px;
    .c-title__text {
      color: $c-white;
    }
  }
  &--cloud {
    padding-bottom: 20px;
  }
  &--analytics {
    padding-bottom: 27px;
  }
  &--pipelines {
    padding-bottom: 28px;
  }
  &--leads {
    padding-bottom: 18px;
  }
  &--ai {
    padding-bottom: 34px;
  }
  &--apps {
    padding-bottom: 30px;
  }
  &--mind {
    padding-bottom: 30px;
  }

  &__text {
    text-align: center;
    line-height: 1.2;
    letter-spacing: 0.8px;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    @include fluid(font-size, 320px, 1600px, 25px, 40px);

    &-wrap {}
  }

  &__icon {
    &-wrap {
      padding-bottom: 18px;

      svg {
        fill: #cd084b;
      }

      .icon-request {
        fill: rgba($c-white, 0.4);
      }
    }
  }
}

// TITLE :: END