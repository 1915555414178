.modal {
  position: relative;
  width: 100%;
  //max-width: 500px;
  //min-height: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;

  &#viewAgreement,
  &#declineEvent,
  &#rescheduleEvent,
  &#contactOrganizer,
  &#makeWithdrawal,
  &#paymentHistory,
  &#startFreeTrial,
  &#shareArticle,
  &#enterprisePlans,
  &#updateInfo {
    overflow: hidden;
    max-width: 1040px;
    border-radius: 10px;
    background-color: #ffffff;

    .mfp-close {
      display: none;
    }
  }

  &#startFreeTrial,
  &#enterprisePlans {
    overflow: unset;
  }

  &#paymentHistory,
  &#viewAgreement {
    height: 80vh;
  }

  &#shareArticle {
    max-width: 728px;
  }
}

.mfp-container {
  padding: 15px;
  border-radius: 10px;
}


//
// Zoom in...
// ==========
.mfp-zoom-in {
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}


//
// Slide in {Change translate{X,Y} option for choose direction slide - top, left, bottom, right} - default slide in left.
// ==========
.mfp-slide-in{
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  &.mfp-removing {
    .mfp-with-anim {
      transform: translateX(100%);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

.mfp-content {
  //border-radius: 10px;
  //border: 10px solid #fff;
}

.mfp-iframe-holder .mfp-close {
  //top: -50px;
  //padding-right: 0;
  //font-size: 40px;
}
