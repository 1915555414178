// SECTION :: START

.cs-steps {
  padding-top: 100px;
  padding-bottom: 50px;
  border-bottom: 1px solid #D8D8D8;
  @include adaptive(767) {
    padding-top: 75px;
    padding-bottom: 25px;
  }

  .c-title {
    padding-bottom: 60px;

    &__text {
      span {
        font-weight: bold;
      }
    }
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 30px 30px;
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
    @include adaptive(1023) {
      padding: 0 20px 20px;
    }
    @include adaptive(767) {
      padding: 0 30px 30px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;
        max-width: calc((100% - (35px * 2)) / 3);
        min-height: 100%;
        padding-bottom: 50px;
        @include adaptive(1023) {
          max-width: calc((100% - (15px * 2)) / 3);
        }
        @include adaptive(767) {
          max-width: 360px;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 30px;
        }
      }
    }

    &-num {
      font-weight: 900;
      color: rgba(#00449D, 0.05);
      text-align: right;
      @include fluid(font-size, 320px, 1440px, 80px, 110px);
    }

    &-icon {
      min-height: 80px;
      padding-bottom: 30px;
    }

    &-title {
      padding-bottom: 20px;
      font-weight: 700;
      color: #CD084B;
      @include fluid(font-size, 320px, 1440px, 20px, 26px);
    }

    &-desc {
      color: #939393;
      line-height: 1.6;
      @include fluid(font-size, 320px, 1440px, 16px, 19px);
    }
  }
}

// SECTION :: END
