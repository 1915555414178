// FORM :: START

.c-form {
  display: flex;
  flex-direction: column;

  &--assistance,
  &--r-main {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &.is-focus {}

  &__row {
    display: flex;

    &--request {
      padding-left: 20px;
      padding-right: 20px;
      @include respond(max-medium) {
        padding-left: 0;
        padding-right: 0;
      }
      @include respond(max-small) {
        flex-wrap: wrap;
      }

      .c-form__row-left {
        padding-right: 20px;
        @include respond(max-medium) {
          padding-right: 10px;
        }
        @include respond(max-small) {
          padding-right: 0;
        }
      }
      .c-form__row-right {
        padding-left: 20px;
        @include respond(max-medium) {
          padding-left: 10px;
        }
        @include respond(max-small) {
          padding-left: 0;
        }
      }
    }

    &-left,
    &-right {
      width: 50%;
      @include respond(max-small) {
        width: 100%;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &--request {
      @include respond(max-extra-small) {
       flex-wrap: wrap;
      }

      .c-form__group-col {
        width: 50%;
        @include respond(max-extra-small) {
          width: 100%;
        }

        &-0 {
          padding-right: 10px;
          @include respond(max-extra-small) {
            padding-right: 0;
          }
        }

        &-1 {
          padding-left: 10px;
          @include respond(max-extra-small) {
            padding-left: 0;
          }
        }
      }
    }

    &-col {
      &-0 {}
      &-1 {}
    }
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &--input {}
    &--select {}
    &--textarea {}
    &--radio {}
    &--checkbox {}
    &--btn {
      width: 100%;
      padding-top: 30px;
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-bottom: 6px;
    color: rgba($c-white, 0.5);
    font-size: 14px;
  }

  &__input,
  &__textarea {
    &-wrapper {
      position: relative;
      display: flex;
    }
  }

  &__input,
  &__textarea {
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__input {
    padding: 0 20px;
    border: none;
    box-shadow: none;
    border-radius: 10px;
    color: $c-black;
    font-size: 18px;

    .c-form--assistance &,
    .c-form--r-main & {
      border: 2px solid rgba(#00449D, 0.1);
    }

    &-wrapper {
      min-height: 60px;
    }
  }

  &__select {
    position: relative;
    height: 100%;
    min-height: 60px;
    border-radius: 10px;
    background-color: $c-white;

    select {
      opacity: 0;
      cursor: pointer;
      outline: none;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: none;
      box-shadow: none;
    }

    &-value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      line-height: 60px;
      width: calc(100% - 52px);
      padding-left: 20px;
      color: $c-black;
      font-size: 18px;
    }

    &-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      width: 52px;
      height: 100%;

      svg {
        width: 12px;
      }
    }
  }

  &__textarea {
    &-wrapper {}
  }

  &__radio {}

  &__checkbox {}

  &__notify {
    display: none;
  }

  &__btn {
    min-width: 256px;
    margin-left: auto;
    margin-right: auto;
  }
}

// FORM :: END
