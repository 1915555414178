// MIND :: START

.mind {
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
  @include respond(max-small) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__box {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 191px;
    padding: 35px 45px;
    border-radius: 13px;
    background-color: rgba(#F2F5FA, 1);
    @include fluid(padding-top padding-bottom, 320px, 1440px, 25px, 35px);
    @include fluid(padding-left padding-right, 320px, 1440px, 30px, 45px);

    &-wrapper {
      position: relative;
      max-width: 824px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }

    &--outer {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 20px;
      width: 100%;
      height: 100%;
      transform: scale(0.95);
      background-color: rgba(#F2F5FA, 0.5);
    }

    p {
      line-height: 1.8;
      text-align: center;
    }
  }

  &__btn {
    display: flex;
  }
}

// MIND :: END
