// SECTION facilities :: START

.facilities {
  &__main {
    display: flex;
    align-items: flex-end;
    min-height: 700px;
    padding-top: 150px;
    padding-bottom: 100px;
    background: #000000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include respond(max-medium) {
      min-height: 600px;
      padding-top: 100px;
    }
    @include respond(max-extra-small) {
      height: auto;
      padding-top: 125px;
    }

    .c-grid {
      height: 100%;
      @include respond(max-extra-small) {
        min-height: 100%;
      }
    }

    .facilities__wrapper {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    &-row {
      display: flex;
      align-items: center;
      width: 100%;
      @include respond(max-small) {
        flex-wrap: wrap;
      }

      > div {
        &:nth-of-type(1) {
          flex: 1;
          padding-right: 30px;
          @include respond(max-small) {
            padding-right: 0;
            padding-bottom: 30px;
            text-align: center;
          }
        }

        &:nth-of-type(2) {
          display: flex;
          justify-content: flex-end;
          width: 324px;
          @include respond(max-small) {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      svg {
        width: 14px;
        height: 14px;
        fill: #fff;
      }

      span {
        padding-left: 20px;
        font-size: 19px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 1.3;
      }
    }

    &-title {
      padding-bottom: 20px;
      font-weight: 200;
      font-size: 50px;
      color: #FFFFFF;
      letter-spacing: 0;
      @include respond(max-small) {
        font-size: 40px;
      }
    }

    &-badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      padding: 5px 20px;
      border-radius: 15px;
      background-color: #F5A623;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 2px;
      text-align: center;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 100%;
      min-width: 216px;
      height: 70px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 1.35px;
      text-align: center;
      line-height: 32px;
      background-color: #CD084B;
      border: 2px solid #cd084b;
      border-radius: 10px;
      @include transition(color, background-color);

      &:hover {
        color: #CD084B;
        background-color: transparent;
        @include transition(color, background-color);
      }

      &-wrapper {
        display: flex;
      }
    }
  }

  &__description {
    padding-top: 80px;
    padding-bottom: 80px;
    @include respond(max-extra-small) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    p,
    li {
      a {
        color: #00449d;

        &:visited,
        &:hover,
        &:focus {
          color: #00449d;
        }
      }
    }

    p {
      padding-bottom: 30px;
      font-size: 17px;
      color: #575757;
      letter-spacing: 0;
      line-height: 26px;
      @include respond(max-extra-small) {
        font-size: 15px;
      }
    }

    h6 {
      display: flex;
      align-items: center;
      min-height: 66px;
      margin-bottom: 40px;
      padding: 10px 30px;
      border-radius: 10px;
      background-color: #f2f5fa;
      font-size: 17px;
      font-weight: normal;
      color: #575757;
      letter-spacing: 0;
      line-height: 26px;
      @include respond(max-extra-small) {
        font-size: 15px;
      }
    }

    h2 {
      padding-bottom: 10px;
      font-weight: normal;
      font-size: 26px;
      color: #000000;
      letter-spacing: 0;
      line-height: 50px;
      @include respond(max-extra-small) {
        font-size: 22px;
      }
    }

    ul {
      padding-left: 15px;

      li {
        padding-left: 20px;
        padding-bottom: 15px;
        list-style-type: lower-alpha;
        font-size: 17px;
        color: #575757;
        letter-spacing: 0;
        line-height: 26px;
        @include respond(max-extra-small) {
          font-size: 15px;
        }
      }
    }
  }

  &__more {
    padding-bottom: 80px;
    @include respond(max-extra-small) {
      padding-bottom: 40px;
    }

    .facilities__block {
      overflow: hidden;
      display: flex;
      min-height: 408px;
      margin-bottom: 60px;
      border-radius: 10px;
      background-color: #00449d;
      @include respond(max-small) {
        flex-wrap: wrap;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-left {
        overflow: hidden;
        position: relative;
        flex: 1;
        width: 100%;
        max-width: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        @include respond(max-small) {
          flex: auto;
          height: 350px;
          border-bottom-left-radius: 10px;
        }
        @include respond(max-extra-small) {
          height: 250px;
        }

        &:hover {
          .main__video-progress {
            transform: translateY(0);
            @include transition(transform 0.5s);
          }
        }

        &--image {
          display: flex;
          align-items: flex-end;
          padding: 22px 22px 0;
          @include respond(max-extra-small) {
            //height: auto;
          }

          img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
          }
        }

        img,
        iframe {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        video {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0;
          background-color: #F7F7F7;
          object-fit: cover;
          object-position: center;
        }

        .main__video-btn {
          background-color: transparent;

          svg {
            fill: #fff;
            width: auto;
            height: 54px;
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
        width: 360px;
        padding: 30px 49px 30px 41px;
        @include respond(max-medium) {
          width: 300px;
          padding-left: 25px;
          padding-right: 25px;
        }
        @include respond(max-small) {
          width: 100%;
        }
        @include respond(max-extra-small) {
          padding: 30px 15px;
        }
      }

      &-title {
        padding-bottom: 20px;
        font-size: 26px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 40px;
        @include respond(max-extra-small) {
          font-size: 22px;
        }
      }

      &-text {
        font-size: 17px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 26px;
        @include respond(max-small) {
          padding-bottom: 40px;
        }
        @include respond(max-extra-small) {
          font-size: 15px;
        }
      }

      &-link {
        display: flex;
        align-items: center;

        span {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0.82px;
          line-height: 26px;
          @include respond(max-extra-small) {
            font-size: 12px;
          }
        }

        svg {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          fill: #fff;
        }
      }
    }
  }

  &__share {
    padding-bottom: 80px;
    @include respond(max-extra-small) {
      padding-bottom: 40px;
    }

    .facilities__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;
      border-radius: 10px;
      background-color: #f2f5fa;
      @include respond(max-extra-small) {
        flex-wrap: wrap;
      }

      > p {
        padding-right: 30px;
        font-weight: bold;
        font-size: 18px;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 50px;
        @include respond(max-extra-small) {
          font-size: 16px;
          padding-right: 0;
          padding-bottom: 15px;
        }
      }
    }

    &-social {
      display: flex;
      align-items: center;
      @include respond(max-extra-small) {
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
      }

      &--fb {
        background-image: linear-gradient(-179deg, #4C79D3 2%, #224A9B 94%);

        svg {
          fill: #fff;
        }
      }
      &--ln {
        background-image: linear-gradient(-179deg, #2198D3 3%, #107FB7 95%);

        svg {
          fill: #fff;
        }
      }
      &--tw {
        background-image: linear-gradient(-179deg, #4FBAFD 5%, #2AA3EF 92%);

        svg {
          fill: #fff;
        }
      }
      &--em {
        background-image: linear-gradient(-179deg, #4B4B4B 7%, #000000 91%);

        svg {
          fill: #fff;
        }
      }
      &--sh {
        background-image: linear-gradient(-179deg, #FFFFFF 7%, #FDFDFD 88%);
        box-shadow: 0 2px 10px 0 rgba(0,68,157,0.10);

        svg {
          fill: #000;
        }
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__similar {
    padding-bottom: 131px;

    &-title {
      padding-bottom: 30px;
      font-size: 26px;
      color: #000000;
      letter-spacing: 0;
      line-height: 50px;
      @include respond(max-extra-small) {
        font-size: 22px;
      }
    }
  }
}

// SECTION facilities :: END
