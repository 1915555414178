// NAV :: START

.nav {
  &--header {
    display: flex;
    justify-content: center;
    width: 100%;

    .nav__list {
      display: flex;
      @include respond(max-small) {
        flex-direction: column;
        width: 100%;
      }
    }

    .nav__item {
      &.is-active {
        .nav__link {
          color: $c-black;
          @include transition(color);

          .header__wrapper-right--inner & {
            color: rgba(#fff, 0.4);
            @include transition(color);
          }

          &:after {
            background-color: #CD084B;
            @include transition(background-color);
          }

          .header--fixed & {
            color: $c-white;
            @include adaptive(767) {
              color: #000000;
              @include transition(color);
            }
          }
        }
      }
    }

    .nav__link {
      position: relative;
      display: flex;
      margin-left: 25px;
      margin-right: 25px;
      color: $c-white;
      font-weight: bold;
      font-family: 'Muli', sans-serif;
      @include transition(color);
      @include respond(max-medium) {
        margin-left: 10px;
        margin-right: 10px;
      }
      @include respond(max-small) {
        align-items: center;
        justify-content: center;
        min-height: 50px;
        color: $c-black;
        text-align: center;
        border-bottom: 1px solid rgba($c-black, 0.025);
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        width: 100%;
        height: 3px;
        background-color: transparent;
        @include transition(background-color);
        @include respond(max-small) {
          top: calc(100% - 3px);
        }
      }

      .header--blue & {
        color: #00449D;
      }

      .header--fixed & {
        color: $c-white;
      }
    }
  }

  &--footer {
    .nav__list {
      padding-bottom: 55px;
      @include respond(max-extra-small) {
        padding-bottom: 27px;
      }
    }

    .nav__link {
      display: flex;
      align-items: center;
      min-height: 40px;
      color: #8F8F8F;
      font-size: 14px;
      @include respond(max-extra-small) {
        justify-content: center;
      }

      &:hover {
        color: $c-white;
      }
    }
  }

  &__link {
    @include transition(color);

    &:hover {
      @include transition(color);
    }
  }
}

// NAV :: END
