// SECTION scheduler :: START

&__main {
  padding-top: 110px;
  padding-bottom: 120px;
  background-color: #f8fafc;
  @include adaptive(767) {
    padding-top: 55px;
    padding-bottom: 60px;
  }

  &-subtitle {
    padding-bottom: 24px;
    font-weight: 300;
    font-size: 26px;
    color: #000;
    @include adaptive(1279) {
      font-size: 22px;
    }
    @include adaptive(767) {
      padding-bottom: 12px;
      font-size: 16px;
    }
  }

  &-title {
    padding-bottom: 37px;
    font-weight: normal;
    font-size: 50px;
    color: #000;
    @include adaptive(1279) {
      font-size: 40px;
      line-height: normal;

      br {
        display: none;
      }
    }
    @include adaptive(1023) {
      font-size: 33px;
    }
    @include adaptive(767) {
      font-size: 26px;
    }
  }

  &-heading {
    width: 100%;
    max-width: 750px;
    padding-bottom: 40px;
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    color: #000;
    @include adaptive(1439) {
      font-size: 50px;
      line-height: 60px;
    }
    @include adaptive(1279) {
      font-size: 45px;
      line-height: 55px;
    }
    @include adaptive(767) {
      padding-bottom: 30px;
      font-size: 26px;
      line-height: normal;
    }
  }

  &-filter {
    &-wrapper {
      display: none;
      flex-direction: column;
      padding-bottom: 10px;
      @include adaptive(767) {
        display: flex;
      }
    }

    &-head {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      color: #00449d;

      &.is-open {
        svg {
          &.svg {
            &-arrow {
              opacity: 0;
              visibility: hidden;
              transform: scale(0) rotate(90deg);
              @include transition(opacity, visibility, transform);
            }
            &-close {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
              @include transition(opacity, visibility, transform);
            }
          }
        }
      }

      p {
        font-weight: 600;
        font-size: 16px;
        color: #00449d;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 21px;
        margin-top: -4px;
        width: 12px;
        height: 12px;

        &.svg {
          &-arrow {
            opacity: 1;
            visibility: visible;
            transform: rotate(90deg);
            @include transition(opacity, visibility, transform);
          }
          &-close {
            opacity: 0;
            visibility: hidden;
            transform: scale(0);
            @include transition(opacity, visibility, transform);
          }
        }
      }
    }

    &-body {
      display: none;

      .c-form {
        &__field {
          margin-bottom: 20px;
        }

        &__label {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 14px;
          color: rgba(#000, 0.5);
        }

        &__select {
          border: 2px solid rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &-dropdown {
    position: relative;
    display: flex;
    align-items: center;

    &-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 50px;
      @include adaptive(767) {
        display: none;
      }

      > div {
        padding-right: 60px;
        @include adaptive(1279) {
          padding-right: 30px;
          width: 100%;
          max-width: calc(100% / 3);

        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    &-title {
      padding-right: 16px;
      font-size: 20px;
      color: #222;
      @include adaptive(1279) {
        font-size: 18px;
      }
    }

    &-btn {
      cursor: pointer;
      padding-right: 7px;
      font-size: 20px;
      color: #00449D;
      @include adaptive(1279) {
        flex: 1;
        font-size: 18px;
        text-align: right;
      }

      svg {
        width: 10px;
        height: 10px;
        transform: rotate(90deg);
        fill: #00449D;
      }
    }

    &-menu {
      z-index: 899;
      display: none;
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      padding-top: 2px;
      padding-bottom: 3px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }

    &-link {
      display: flex;
      justify-content: flex-end;
      padding: 5px 15px 5px 30px;
      color: #00449D;
      font-size: 16px;
      line-height: 1.3;
      @include transition(background-color);

      &:hover {
        background-color: lighten(#00449D, 67%);
        @include transition(background-color);
      }
    }
  }

  &-box {
    cursor: pointer;
    height: 100%;
    padding: 30px 15px 30px 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    @include transition(box-shadow, background-color);
    @include adaptive(1023) {
      padding: 20px 10px 20px 20px;
    }
    @include adaptive(767) {
      white-space: normal;
    }

    &.is-active {
      box-shadow: none;
      background-color: #ecf1f6;
      @include transition(box-shadow, background-color);

      .scheduler__main-box-title,
      .scheduler__main-box-subtitle {
        opacity: 0.4;
        @include transition(opacity);
      }
    }

    &:hover {
      background-color: #00449d;
      @include transition(background-color);

      .scheduler__main-box-title,
      .scheduler__main-box-subtitle,
      .scheduler__main-box-desc {
        opacity: 1;
        color: #ffffff;
        @include transition(opacity, color);
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 40px);
      margin-left: -40px;
      @include adaptive(1023) {
        width: calc(100% + 15px);
        margin-left: -15px;
      }
      @include adaptive(767) {
        overflow-x: auto;
        white-space: nowrap;
        position: relative;
        display: block;
        width: calc(100% + 30px);
        padding-bottom: 15px;
        font-size: 0;
      }

      > div {
        width: 100%;
        max-width: calc(100% / 3);
        padding-left: 40px;
        padding-bottom: 40px;
        @include adaptive(1023) {
          padding-left: 15px;
          padding-bottom: 15px;
        }
        @include adaptive(767) {
          display: inline-block;
          vertical-align: top;
          max-width: 295px;
        }
      }
    }

    p {
      @include transition(color);
    }

    &-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 5px;
      font-weight: 800;
      font-size: 22px;
      color: #000;
      @include transition(opacity, color);
    }

    &-subtitle {
      padding-bottom: 15px;
      font-size: 16px;
      color: #222;
      @include transition(opacity, color);
      @include adaptive(1023) {
        font-size: 16px;
      }
    }

    &-desc {
      overflow: hidden;
      position: relative;
      height: 44px;
      font-size: 16px;
      line-height: 1.2;
      color: rgba(#222, 0.4);
      @include transition(opacity, color);
    }
  }

  &-btn {
    display: none;
    @include adaptive(767) {
      padding-top: 15px;
    }

    .c-btn {
      max-width: unset;
      width: auto;
      padding-left: 60px;
      padding-right: 60px;
      @include adaptive(767) {
        position: relative;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 425px;
        padding-left: 20px;
        padding-right: 52px;
        font-size: 16px;
      }

      &.is-active {
        svg {
          transform: rotate(-90deg);
          @include transition(transform);
        }
      }

      p {
        padding-right: 5px;
      }

      span {
        padding-right: 20px;
      }
    }

    svg {
      position: relative;
      top: 2px;
      width: 12px;
      height: 12px;
      transform: rotate(90deg);
      backface-visibility: hidden;
      transform-origin: center;
      @include transition(transform);
      @include adaptive(767) {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 20px;
      }
    }
  }
}

// SECTION scheduler :: END
