.swiper {
  &-container {
    width: 100%;
    padding-bottom: 25px;

    &.schedulerData {
      overflow: unset;
      width: calc(100% + 40px);
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      @include adaptive(767) {
        width: calc(100% + 30px);
        margin-left: -15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  &-pagination {
    position: relative;
    display: flex;
    align-items: center;

    &-bullet {
      opacity: 1;
      outline: none;
      width: 15px;
      height: 15px;
      margin: 0 !important;
      margin-right: 15px !important;
      background-color: #C8C8C8;

      &:last-of-type {
        @include respond(medium) {
          margin-right: 40px !important;
        }
        @include respond(max-small) {
          margin-right: 0 !important;
        }
      }

      &-active {
        background-color: #CD084B;
      }
    }
  }
}