// SECTION scheduler :: START

&__summary {
  overflow: hidden;
  position: relative;
  padding-top: 65px;
  padding-bottom: 114px;
  @include adaptive(767) {
    padding-top: 35px;
    padding-bottom: 55px;
  }

  .scheduler__data-list {
    transform: translate(0);
  }

  &-bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include adaptive(767) {
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      @include adaptive(1279) {
        right: -100px;
      }
      @include adaptive(1023) {
        right: -300px;
      }
    }
  }

  &-wrapper {
    z-index: 2;
    position: relative;
  }
}

// SECTION scheduler :: END