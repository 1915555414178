// SECTION impressive :: START

.impressive {
  position: relative;
  padding-top: 130px;
  @include adaptive(767) {
    padding-top: 100px;
  }

  &--mod {
    .impressive {
      &__wrapper {
        &-bg-1 {
          display: none;
        }

        &-1 {
          padding-bottom: 100px;
        }
      }
    }
  }

  &--mod2 {
    padding: 0;

    &.is-sticky {
      .impressive {
        &__form {
          transform: translateY(0);
          @include transition(transform);
        }
      }
    }

    &.is-normal {
      .impressive {
        &__form {
          position: static;
          background-color: transparent;
          transform: translateY(0);
          @include transition(transform);
        }
      }
    }

    .impressive {
      &__wrapper {
        &-2 {
          padding-top: 150px;
          padding-bottom: 105px;
          @include adaptive(767) {
            padding-top: 100px;
            padding-bottom: 100px;
          }
        }
      }

      &__subtitle {
        width: 100%;
        max-width: 791px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 80px;
        font-weight: 900;
        font-size: 30px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 70px;
        @include adaptive(767) {
          padding-bottom: 50px;
          font-size: 25px;
          line-height: 1.8;
        }
      }

      &__form {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 741px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 150px;
        @include transition(transform);
        @include adaptive(767) {
          //position: sticky;
          //bottom: 0;
          //left: 0;
          transform: translateY(100%);
          flex-direction: column;
          //padding-bottom: 75px;
          z-index: 1050;
          position: fixed;
          bottom: 0;
          left: 0;
          justify-content: center;
          width: 100%;
          height: 142px;
          padding: 5px;
          background-color: $c-white;
          box-shadow: 0 2px 15px 3px rgba($c-black, 0.25);
          @include transition(transform);
        }

        &-input {
          outline: none;
          position: relative;
          width: calc(100% - (186px + 10px));
          height: 66px;
          padding: 0 11px;
          border: none;
          box-shadow: none;
          border-radius: 7px;
          background-color: $c-white;
          font-size: 14px;
          color: rgba(0,0,0,1);
          letter-spacing: 0;
          @include inputPlaceholder {
            font-size: 14px;
            color: rgba(0,0,0,0.40);
            letter-spacing: 0;
          }
          @include adaptive(767) {
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid rgba(0,0,0,0.10);
          }
        }

        &-btn {
          max-width: 186px;
          @include adaptive(767) {
            max-width: 100%;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      &__copyright {
        @include adaptive(767) {
          padding-top: 50px;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: -180px;
      right: 0;
    }
  }

  &__wrapper {
    position: relative;

    &-1 {}

    &-2 {
      padding-top: 140px;
      padding-bottom: 120px;
      background-color: #191919;
      @include adaptive(767) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    &-bg {
      z-index: 0;
      position: absolute;

      &-1 {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        background-color: #191919;
      }

      &-2 {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 1300px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        //mix-blend-mode: soft-light;
        opacity: 0.275;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-impressive-bg@2x.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
      }
    }
  }

  &__row {
    z-index: 2;
    position: relative;

    &-1 {}
    &-2 {}
  }

  &__title {
    padding-bottom: 60px;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #000;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
      text-align: center;

      br {
        display: none;
      }
    }

    span {
      position: relative;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 98%;
        height: 22px;
        background-color: #ffd400;
        @include adaptive(1023) {
          height: 20px;
        }
      }
    }
  }

  &__subtitle,
  &__desc {
    padding-bottom: 50px;
    text-align: center;
    color: #ffffff;
  }

  &__subtitle {
    font-size: 60px;
    font-weight: 900;
    line-height: 1.17;
    @include adaptive(1279) {
      font-size: 55px;
    }
    @include adaptive(767) {
      font-size: 50px;
    }
    @include adaptive(424) {
      font-size: 42px;
    }
  }

  &__desc {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.25;
    @include adaptive(1279) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
    }
  }

  &__text {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    font-size: 19px;
    line-height: 1.58;
    text-align: center;
    color: #939393;
  }

  &__btn {
    min-width: 239px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
    @include transition(background-color, color, box-shadow);

    &:hover {
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
      @include transition(background-color, color, box-shadow);
    }

    &-wrapper {
      display: flex;
      padding-bottom: 140px;
      @include adaptive(767) {
        padding-bottom: 100px;
      }
    }
  }

  &__copyright {
    font-size: 16px;
    text-align: center;
    color: #8f8f8f;
  }

  &__card {
    width: 100%;
    min-height: 380px;
    padding: 30px 15px 30px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    @include adaptive(1279) {
      padding: 30px 15px;
    }
    @include adaptive(767) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    &-1 {
      svg {
        width: 40px;
        height: 40px;
      }
    }
    &-2 {
      svg {
        width: 40px;
        height: 40px;
      }
    }
    &-3 {
      svg {
        width: 34px;
        height: 42px;
      }
    }

    &-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        position: relative;
        width: 100%;
        max-width: calc((100% - 70px) / 3);
        @include adaptive(1279) {
          max-width: calc((100% - 30px) / 3);
        }
        @include adaptive(767) {
          max-width: 100%;
          padding-bottom: 30px;

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }
    }

    svg {
      fill: #cd084b;
    }

    &-title {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 19px;
      font-weight: bold;
      line-height: 2.11;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #cd084b;
    }

    &-desc {
      padding-bottom: 40px;
      font-size: 19px;
      line-height: 1.58;
      color: #939393;
      @include adaptive(1279) {
        min-height: 124px;
        font-size: 18px;
      }
      @include adaptive(767) {
        min-height: unset;
      }
    }

    &-info {
      padding-bottom: 10px;
      font-size: 19px;
      line-height: 1.58;
      color: #000000;
    }

    &-price {
      font-size: 44px;
      font-weight: 900;
      line-height: 1.14;
      color: #000000;
      @include adaptive(1279) {
        font-size: 40px;
      }
    }
  }

  &__table {
    position: relative;
    display: flex;
    flex-direction: column;
    @include adaptive(1023) {
      width: 1024px;
    }

    &-wrapper {
      @include adaptive(1023) {
        white-space: nowrap;
        overflow-x: auto;
        position: relative;
        width: calc(100% + 15px);
      }
    }

    &-bg {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;
        border-radius: 20px;

        &:nth-of-type(1) {
          max-width: 265px;
          @include adaptive(575) {
            max-width: 175px;
          }
        }
        &:nth-of-type(2) {
          max-width: 200px;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
        }
        &:nth-of-type(3) {
          max-width: 230px;
        }
        &:nth-of-type(4) {
          max-width: 150px;
          @include adaptive(575) {
            max-width: 200px;
          }
        }
        &:nth-of-type(5) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }
    }

    &-head,
    &-body,
    &-foot {
      display: flex;
      flex-direction: column;
    }

    &-head {
      .impressive__table {
        &-row {
          &-1 {
            > div {
              padding: 30px 5px;
              font-size: 19px;
              font-weight: bold;
              line-height: 2.11;
              letter-spacing: 1px;
              color: #cd084b;
              text-align: center;
              text-transform: uppercase;
            }
          }

          &-2 {
            span,
            p {
              text-align: center;
              color: #000000;
            }

            span {
              display: block;
              font-size: 19px;
            }

            p {
              font-size: 29px;
              font-weight: 900;
            }
          }
        }

        &-col {
          &-1 {
            p {
              font-size: 19px;
              font-weight: 400;
              color: #939393;
              text-align: left;
            }
          }
        }
      }
    }

    &-body {
      .impressive__table {
        &-row {
          min-height: 60px;

          &:nth-of-type(odd) {
            &::before {
              content: '';
              position: absolute;
              left: -40px;
              top: 0;
              width: calc(100% + 80px);
              height: 100%;
              border-radius: 10px;
              background-color: rgba(#00449d, 0.03);
              @include adaptive(1279) {
                left: -15px;
                width: calc(100% + 30px);
              }
            }
          }
        }

        &-col {
          display: flex;
          align-items: center;
          justify-content: center;

          &-1 {
            justify-content: flex-start;

            p {
              text-align: left !important;
            }
          }

          p {
            font-size: 19px;
            text-align: center;
            color: #000000;

            strong {
              font-weight: 700;
            }
          }
        }
      }
    }

    &-foot {
      .impressive__table-col {
        padding: 10px;
      }

      p {
        padding-bottom: 20px;
        font-size: 19px;
        text-align: center;
        color: #000000;
      }

      a {
        min-width: unset;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
        @include transition(background-color, color, box-shadow);

        &:hover {
          color: #cd084b;
          box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0);
          @include transition(background-color, color, box-shadow);
        }
      }
    }

    &-row {
      position: relative;
      display: flex;
    }

    &-col {
      width: 100%;
      padding: 15px 5px;

      &-1 {
        max-width: 265px;
        @include adaptive(575) {
          max-width: 175px;
        }

        p {
          font-size: 19px;
          color: #939393 !important;
          text-align: left;
        }
      }
      &-2 {
        max-width: 200px;
      }
      &-3 {
        max-width: 230px;
      }
      &-4 {
        max-width: 150px;
        @include adaptive(575) {
          max-width: 200px;
        }
      }
      &-5 {
        flex: 1;
        min-width: 1px;
        max-width: 100%;
      }

      > * {
        z-index: 5;
        white-space: normal;
        position: relative;
      }
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #00449d;

      svg {
        width: 10px;
        height: 15px;
        fill: #fff;
      }
    }
  }
}

// SECTION impressive :: END
