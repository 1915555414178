// ANALYTICS :: START

.analytics {
  overflow: hidden;
  background-color: #F8FAFC;

  &__wrapper {
    padding-top: 98px;
    padding-bottom: 113px;
    @include respond(max-small) {
      padding-top: 49px;
      padding-bottom: 50px;
    }
  }

  &__text {
    padding-bottom: 60px;
    @include respond(max-extra-small) {
      padding-bottom: 30px;
    }
  }

  &__row {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    @include respond(max-medium) {
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
    }

    &-left,
    &-right {
      width: 50%;
      @include respond(max-medium) {
        width: 100%;
      }
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      @include respond(max-medium) {
        margin-top: 30px;
      }
    }
  }

  &__card {
    width: 100%;
    max-width: 430px;

    &-0 {
      .analytics__card {
        &-box {
          padding: 30px 30px 30px 60px;

          &--inner {
            left: 0;
          }
          &--outer {
            left: 30px;
          }
        }
        &-circle {
          left: 44px;

          &:hover {
            svg {
              animation-name: swing;
            }
          }
        }
      }
    }

    &-1 {
      .analytics__card {
        &-box {
          padding: 30px 60px 30px 30px;

          &--inner {
            right: 0;
          }
          &--outer {
            right: 30px;
          }
        }
        &-circle {
          left: 15px;

          &:hover {
            svg {
              animation-name: tada;
            }
          }
        }
        &-wrapper {
          flex-direction: column;
        }
      }
    }

    &-box {
      position: relative;
      width: 100%;
      height: 280px;
      margin-bottom: 45px;

      &--inner {
        z-index: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        max-width: 410px;
        height: 240px;
        border-radius: 20px;
        background-color: rgba($c-white, 0.5);
        box-shadow: 0 10px 20px 0 rgba($c-black, 0.05);
      }

      &--outer {
        z-index: 1;
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 400px;
        height: 100%;
        border-radius: 20px;
        background-color: $c-white;
        box-shadow: 0 10px 20px 0 rgba($c-black, 0.05);
      }
    }

    &-circle {
      z-index: 1;
      cursor: pointer;
      position: absolute;
      bottom: -35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00439B;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      border: 10px solid #F8F8F8;

      svg {
        fill: $c-white;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-duration: 1.5s;
      }
    }

    &-wrapper {
      z-index: 1;
      position: relative;
      display: flex;
    }

    &-left {
      width: 110px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 2px solid #E9E9E9;

        svg {
          fill: #CD084B;
        }
      }
    }

    &-right {
      width: 100%;
      max-width: calc(100% - 110px);
      padding-top: 18px;
    }

    &-line {
      border-radius: 4px;
      background-color: #E9E9E9;

      &-0 {
        height: 20px;
        margin-bottom: 14px;
      }
      &-1 {
        width: 50%;
        height: 20px;
        margin-bottom: 47px;
      }
      &-2 {
        width: 100%;
        max-width: 122px;
        height: 12px;
      }
      &-3 {
        width: 100%;
        max-width: 154px;
        height: 12px;
      }
      &-4 {
        height: 20px;
        margin-bottom: 14px;
      }
      &-5 {
        width: 100%;
        max-width: 260px;
        height: 12px;
      }
    }

    &-list {
      li {
        display: flex;
        align-items: center;
        padding-bottom: 14px;

        &:last-child {
          padding-bottom: 0;
        }

        > div {
          &:first-child {
            width: 50px;

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: #CD084B;

              svg {
                width: 16px;
                fill: $c-white;
              }
            }
          }
          &:last-child {
            width: 100%;
            max-width: calc(100% - 50px);
          }
        }
      }
    }

    &-graph {
      margin-bottom: 23px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      padding-left: 60px;
      letter-spacing: 0.6px;
      font-weight: bold;
      @include fluid(font-size, 320px, 1440px, 18px, 24px);
    }
  }
}

// ANALYTICS :: END