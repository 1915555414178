html,
body {
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;

  &.is-hideScroll {
    overflow: hidden;
  }
}


html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  background-color: $c-white;
  line-height: 1.42857143;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 auto;

    &--start {
      justify-content: flex-start;
    }
  }
}


* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}


button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}


button,
input {
  overflow: visible;
}

input[type='text'],
input[type='email'] {
  appearance: none;
}

button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}


[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}


[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


textarea {
  overflow: auto;
}


p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}


ul {
  margin: 0;
  padding: 0;


  li {
    list-style: none;
  }
}


a {
  outline: none;
  text-decoration: none;
  color: #00449D;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}


input[type="text"] {
  appearance: none;
}

picture {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
