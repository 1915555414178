// CONTROL :: START

.control {
  padding-bottom: 100px;
  @include respond(max-small) {
    padding-bottom: 50px;
  }

  .c-grid-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  &__wrapper {
    padding-top: 100px;
    padding-bottom: 50px;
    @include respond(max-small) {
      padding-top: 50px;
      padding-bottom: 30px;
    }
  }
}

// CONTROL :: END