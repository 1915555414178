// SECTION results :: START

.results {
  overflow: hidden;
  position: relative;
  min-height: 770px;
  padding-top: 140px;
  padding-bottom: 60px;
  background-image: linear-gradient(242deg, #001632 0%, #00449d);
  @include adaptive(767) {
    padding-top: 100px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1300px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    //mix-blend-mode: soft-light;
    opacity: 0.275;
    background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-bg-main.png");
    background-repeat: no-repeat;
    background-position: center 120px;
    background-size: cover;
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__title {
    padding-bottom: 80px;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #ffffff;
    @include adaptive(1023) {
      font-size: 36px;
    }
    @include adaptive(767) {
      font-size: 33px;
    }
    @include adaptive(424) {
      text-align: center;

      br {
        display: none;
      }
    }

    span {
      position: relative;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 98%;
        height: 22px;
        background-color: #cd084b;
        @include adaptive(1023) {
          height: 20px;
        }
      }
    }
  }

  &__box {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 100%;
        max-width: calc(100% / 3);
        padding-bottom: 80px;
        @include adaptive(767) {
          max-width: calc(100% / 2);

          &:nth-of-type(even) {
            text-align: right;
          }
        }
        @include adaptive(424) {
          max-width: 100%;
          padding-bottom: 40px;
          text-align: center;

          &:nth-of-type(even) {
            text-align: center;
          }
        }

        &:nth-of-type(2),
        &:nth-of-type(5) {
          padding-left: 50px;
          @include adaptive(1023) {
            padding-left: 0;
          }
        }

        &:nth-of-type(3),
        &:nth-of-type(6) {
          padding-left: 100px;
          @include adaptive(1023) {
            padding-left: 0;
          }
        }
      }
    }

    &-num {
      padding-bottom: 20px;
      font-size: 60px;
      font-weight: 300;
      line-height: 1.17;
      color: #ffffff;
      @include adaptive(1023) {
        font-size: 50px;
      }
      @include adaptive(767) {
        font-size: 45px;
      }
      @include adaptive(424) {
        padding-bottom: 10px;
      }
    }

    &-desc {
      font-size: 19px;
      line-height: 1.58;
      color: rgba(255, 255, 255, 0.5);
      @include adaptive(1023) {
        font-size: 18px;
      }
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }
}

// SECTION results :: END
