// SECTION :: START

.cs-info {
  padding-top: 92px;
  padding-bottom: 100px;

  &__desc {
    width: 100%;
    max-width: 788px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 45px;
    font-size: 19px;
    color: #939393;
    text-align: center;
  }

  &__btn {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// SECTION :: END
