// SECTION :: START

.tired {
  position: relative;
  padding-top: 40px;
  padding-bottom: 91px;
  @include adaptive(767) {
    padding-bottom: 50px;
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      @include adaptive(767) {
        opacity: 0;
        visibility: hidden;
      }

      &-1 {
        top: 228px;
        left: 157px;
        width: 100px;
        height: 101px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-2@2x.png");
        background-size: cover;
        @include adaptive(1023) {
          top: 40%;
          left: 30px;
        }

        svg {
          transform: translate(2px, -8px);
        }
      }
      &-2 {
        left: 57px;
        bottom: 96px;
        width: 144px;
        height: 153px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-5@2x.png");
        background-size: cover;
        @include adaptive(1279) {
          left: 0;
        }
        @include adaptive(1023) {
          display: none;
        }

        svg {
          transform: translate(2px, -20px);
        }
      }
      &-4 {
        top: calc(50% - 54px);
        right: 50px;
        width: 130px;
        height: 165px;
        background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/images/img-obj-hero-9@2x.png");
        background-size: cover;
        @include adaptive(1279) {
          right: 0;
        }
        @include adaptive(1023) {
          opacity: 0;
          visibility: hidden;
        }

        svg {
          transform: translate(0px, -25px);;
        }
      }

      svg {
        fill: #fff;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &__logo {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    @include adaptive(767) {
      padding-bottom: 75px;
    }

    svg {
      fill: #00449D;
    }
  }

  &__title {
    width: 100%;
    max-width: 866px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    font-weight: 900;
    font-size: 60px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.17;
    @include adaptive(1279) {
      font-size: 55px;
    }
    @include adaptive(1023) {
      font-size: 48px;
    }
    @include adaptive(767) {
      font-size: 42px;

      br {
        display: none;
      }
    }
    @include adaptive(575) {
      font-size: 36px;
    }
  }

  &__subtitle {
    position: relative;
    width: 100%;
    max-width: 604px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
    @include adaptive(1279) {
      font-size: 26px;
    }
    @include adaptive(1023) {
      font-size: 24px;
    }
    @include adaptive(767) {
      font-size: 17px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 20px;
      right: 20px;
      width: calc(100% - 40px);
      height: 2px;
      margin-left: auto;
      margin-right: auto;
      background-color: #00449d;
    }

    span {
      display: block;
      font-weight: 900;
      color: #00449d;
    }
  }

  &__text {
    padding-top: 40px;
    padding-bottom: 38px;
    font-size: 19px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
    @include adaptive(1279) {
      font-size: 18px;
    }
    @include adaptive(767) {
      font-size: 16px;
      line-height: 1.4;
    }
  }

  &__form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 741px;
    margin-left: auto;
    margin-right: auto;
    @include adaptive(767) {
      flex-direction: column;
    }

    &-input {
      outline: none;
      position: relative;
      width: calc(100% - (186px + 10px));
      height: 66px;
      padding: 0 11px;
      box-shadow: none;
      border-radius: 7px;
      border: 1px solid rgba(0,0,0,0.10);
      background-color: $c-white;
      font-size: 14px;
      color: rgba(0,0,0,1);
      letter-spacing: 0;
      @include inputPlaceholder {
        font-size: 14px;
        color: rgba(0,0,0,0.40);
        letter-spacing: 0;
      }
      @include adaptive(767) {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &-btn {
      max-width: 186px;
      box-shadow: 0 10px 20px 0 rgba(205,8,75,0.30);
      @include transition(box-shadow, background-color, color);
      @include adaptive(767) {
        max-width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:hover {
        box-shadow: none;
        @include transition(box-shadow, background-color, color);
      }
    }
  }
}

// SECTION :: END
