// SUBTITLE :: START

.c-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    padding-bottom: 20px;
    color: #CD084B;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 2.25px;
    font-weight: bold;
    @include fluid(font-size, 320px, 1600px, 12px, 18px);

    &-wrap {}
  }
}

// SUBTITLE :: END