// SECTION scheduler :: START

&__header {
  overflow: hidden;
  position: relative;
  background-color: #ffffff;

  .c-grid {
    position: relative;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    height: 158px;
    @include adaptive(1279) {
      height: 108px;
    }
    @include adaptive(767) {
      height: 70px;
    }

    &-left {
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 2px;
        height: 38px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: rgba(#00449D, 0.1);
        @include adaptive(767) {
          margin-left: 15px;
          margin-right: 15px;
        }
      }

      p {
        font-weight: 300;
        font-size: 20px;
        color: #000;
        @include adaptive(767) {
          font-size: 18px;
        }
        @include adaptive(424) {
          font-size: 16px;
        }
        @include adaptive(374) {
          font-size: 14px;
        }
      }
    }

    &-right {
      @include adaptive(767) {
        display: none;
      }
    }
  }

  &-marker {
    position: absolute;
    top: -6px;
    right: 15px;
    @include adaptive(1279) {
      top: -36px;
    }
  }

  svg {
    fill: #00449D;
  }
}

// SECTION scheduler :: END