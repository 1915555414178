// CLOUD :: START

.cloud {
  position: relative;
  border-bottom: 1px solid #D8D8D8;

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-0 {
      overflow: hidden;
    }

    &-img {
      position: absolute;
      max-width: 100%;
      height: auto;
      @include respond(max-extra-small) {
        transform: scale(0.5);
      }

      &-0 {
        top: 52px;
        left: 473px;
        @include respond(max-extra-small) {
          left: 20%;
        }
      }
      &-1 {
        left: -96px;
        bottom: 77px;
      }
      &-2 {
        left: 72px;
        bottom: 97px;
        mix-blend-mode: multiply;
      }
      &-3 {
        right: 329px;
        bottom: -36px;
        @include respond(max-extra-small) {
          right: 20%;
        }
      }
      &-4 {
        bottom: 213px;
        right: -96px;
      }
      &-5 {
        right: -40px;
        bottom: 190px;
        mix-blend-mode: multiply;
      }
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    padding-top: 98px;
    padding-bottom: 100px;
    @include respond(max-small) {
      padding-top: 49px;
      padding-bottom: 50px;
    }
  }
}

// CLOUD :: END