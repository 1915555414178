// SECTION affiliate :: START

.affiliate {

  // main
  &-main {
    position: relative;
    padding-top: 163px;
    padding-bottom: 100px;
    @include adaptive(767) {
      padding-top: 125px;
    }

    &__bg {
      z-index: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      > div {
        position: absolute;
        top: -200px;
        right: -200px;
        width: 1080px;
        height: 1080px;
        border-radius: 50%;
        background-color: #f8fafc;
        @include adaptive(1023) {
          width: 100%;
          height: 100%;
        }
        @include adaptive(767) {
          display: none;
        }
      }
    }

    &__wrapper {
      z-index: 2;
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 100px;
      @include adaptive(1023) {
        padding-bottom: 75px;
      }
      @include adaptive(767) {
        flex-wrap: wrap;
        padding-bottom: 50px;
      }

      &-left,
      &-right {
        width: 50%;
        @include adaptive(1023) {
          width: 100%;
        }
      }

      &-left {
        @include adaptive(1023) {
          max-width: 55%;
        }
        @include adaptive(767) {
          max-width: 100%;
        }

        .affiliate-main__img {
          display: none;
          max-width: 50%;
          height: auto;
          margin-left: auto;
          margin-right: auto;
          @include adaptive(767) {
            display: block;
            margin-bottom: 50px;
          }
        }
      }

      &-right {
        @include adaptive(1023) {
          max-width: 45%;
        }
        @include adaptive(767) {
          max-width: 100%;
        }
      }
    }

    &__title {
      padding-bottom: 30px;
      font-size: 60px;
      font-weight: 900;
      line-height: 1.17;
      @include adaptive(1279) {
        font-size: 55px;
      }
      @include adaptive(1023) {
        font-size: 46px;
      }
      @include adaptive(767) {
        font-size: 36px;
        text-align: center;
      }
    }

    &__desc {
      padding-bottom: 60px;
      font-size: 40px;
      font-weight: 300;
      line-height: 1.25;
      @include adaptive(1279) {
        font-size: 36px;
      }
      @include adaptive(1023) {
        font-size: 30px;
      }
      @include adaptive(767) {
        padding-bottom: 50px;
        font-size: 26px;
        text-align: center;
      }
    }

    &__btn {
      width: auto;
      min-width: 225px;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
      @include transition(background-color, color, box-shadow);
      @include adaptive(767) {
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        box-shadow: none;
        @include transition(background-color, color, box-shadow);
      }

      &-wrapper {
        display: flex;
      }
    }

    &__img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      @include adaptive(767) {
        display: none;
      }
    }

    &__text {
      z-index: 2;
      position: relative;
      font-size: 19px;
      line-height: 1.58;
      @include adaptive(767) {
        font-size: 16px;
        text-align: center;
      }
    }
  }
  // main :: END

  // benefits
  &-benefits {
    padding-bottom: 60px;

    &__title {
      padding-bottom: 40px;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.25;
      @include adaptive(767) {
        font-size: 33px;
        text-align: center;
      }
    }

    &__desc {
      padding-bottom: 60px;
      font-size: 19px;
      line-height: 1.58;
      @include adaptive(767) {
        font-size: 16px;
        text-align: center;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex;
      padding-bottom: 50px;
      margin-bottom: 50px;
      border-bottom: solid 1px rgba(#d8d8d8, 0.8);
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      &:last-of-type {
        margin-bottom: 0;
        border: none;
      }

      > div {
        width: 100%;

        &:nth-of-type(1) {
          max-width: 80px;
          padding-top: 10px;
          @include adaptive(767) {
            max-width: 100%;
            padding-top: 0;
            padding-bottom: 15px;
          }

          svg {
            fill: #00449D;
            @include adaptive(767) {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        &:nth-of-type(2) {
          max-width: 330px;
          @include adaptive(1023) {
            max-width: 270px;
          }
          @include adaptive(767) {
            max-width: 100%;
            text-align: center;
          }
        }

        &:nth-of-type(3) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }

      h6 {
        padding-bottom: 20px;
        font-size: 40px;
        font-weight: 300;
        line-height: 1.25;
        color: #00449d;
        @include adaptive(767) {
          font-size: 33px;
        }
      }

      span {
        display: block;
        width: 100%;
        max-width: 200px;
        font-size: 19px;
        line-height: 1.58;
        color: #939393;
        @include adaptive(767) {
          max-width: 100%;
          padding-bottom: 15px;
          font-size: 16px;
        }
      }

      p {
        font-size: 19px;
        line-height: 1.58;
        @include adaptive(767) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  // benefits :: END

  // commisions
  &-commisions {
    position: relative;
    padding-top: 110px;
    padding-bottom: 110px;
    background: linear-gradient(237deg, #001632 0.09%, #00449d 100%);
    @include adaptive(767) {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    &__bg {
      z-index: 0;
      opacity: 0.5;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //mix-blend-mode: soft-light;
      opacity: 0.275;
      background-image: url("https://s3.us-east-2.amazonaws.com/tapdesk/static/svg/commissions.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
      @include adaptive(767) {
        background-size: cover;
      }
    }

    &__wrapper {
      z-index: 2;
      position: relative;
    }

    &__title {
      padding-bottom: 40px;
      color: #ffffff;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.25;
      @include adaptive(767) {
        font-size: 33px;
        text-align: center;
      }
    }

    &__desc {
      padding-bottom: 60px;
      font-size: 19px;
      line-height: 1.58;
      color: #ffffff;
      @include adaptive(767) {
        font-size: 16px;
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__box {
      &-wrapper {
        display: flex;
        padding-bottom: 70px;
        @include adaptive(767) {
          flex-wrap: wrap;
          padding-bottom: 50px;
        }

        > div {
          width: 100%;
          max-width: calc(100% / 5);
          @include adaptive(767) {
            max-width: 100%;
          }
        }
      }

      p {
        padding-bottom: 10px;
        font-size: 40px;
        line-height: 1.25;
        color: #ffffff;
        @include adaptive(1023) {
          font-size: 36px;
        }
        @include adaptive(767) {
          font-size: 33px;
          text-align: center;
        }
      }

      span {
        display: block;
        width: 100%;
        max-width: 170px;
        font-size: 19px;
        line-height: 1.58;
        color: rgba(#ffffff, 0.5);
        @include adaptive(1023) {
          padding-right: 15px;
          font-size: 17px;
        }
        @include adaptive(767) {
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;
          padding-right: 0;
          padding-bottom: 15px;
          font-size: 14px;
          text-align: center;
        }
      }
    }

    &__text {
      display: flex;
      @include adaptive(767) {
        flex-wrap: wrap;
      }

      > div {
        width: 100%;

        &:nth-of-type(1) {
          max-width: 230px;
          @include adaptive(767) {
            max-width: 100%;
            text-align: center;
            padding-bottom: 15px;
          }
        }
        &:nth-of-type(2) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }

      p {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.25;
        color: #ffd400;
        @include adaptive(767) {
          font-size: 33px;
        }
      }

      span {
        display: block;
        font-size: 19px;
        line-height: 1.58;
        color: #ffffff;
        @include adaptive(767) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
  // commisions :: END

  // becoming
  &-becoming {
    padding-top: 110px;
    padding-bottom: 20px;
    @include adaptive(1023) {
      padding-bottom: 40px;
    }

    &__title {
      padding-bottom: 40px;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.25;
      @include adaptive(767) {
        font-size: 33px;
        text-align: center;
      }
    }

    &__desc {
      padding-bottom: 60px;
      font-size: 19px;
      line-height: 1.58;
      @include adaptive(767) {
        font-size: 16px;
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__box {
      height: 100%;
      padding: 30px;
      border-radius: 20px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      @include adaptive(767) {
        width: 100%;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        @include adaptive(767) {
          flex-wrap: wrap;
        }
        
        > div {
          width: 100%;
          max-width: calc((100% - 70px) / 3);
          min-height: 100%;
          padding-bottom: 35px;
          @include adaptive(1023) {
            max-width: calc((100% - 30px) / 3);
            padding-bottom: 15px;
          }
          @include adaptive(767) {
            max-width: 100%;
            min-height: unset;
          }
        }
      }

      &-num {
        text-align: right;
        font-size: 110px;
        font-weight: 900;
        line-height: 1;
        color: rgba(#00449d, 0.05);
        @include adaptive(1023) {
          font-size: 100px;
        }
        @include adaptive(767) {
          font-size: 90px;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        min-height: 51px;
      }

      &-title {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 26px;
        font-weight: 900;
        line-height: 1.15;
        color: #000000;
        @include adaptive(1023) {
          font-size: 24px;
        }
        @include adaptive(767) {
          font-size: 22px;
        }
      }

      &-desc {
        font-size: 19px;
        line-height: 1.58;
        color: #939393;
        @include adaptive(1023) {
          font-size: 17px;
        }
        @include adaptive(767) {
          font-size: 16px;
        }
      }
    }
  }
  // becoming :: END

  // question
  &-question {
    padding-top: 55px;
    padding-bottom: 200px;
    @include adaptive(767) {
      padding-bottom: 100px;
    }

    &__title {
      padding-bottom: 40px;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.25;
      @include adaptive(767) {
        font-size: 33px;
        text-align: center;
      }
    }

    &__desc {
      padding-bottom: 60px;
      font-size: 19px;
      line-height: 1.58;
      @include adaptive(767) {
        font-size: 16px;
        text-align: center;
        padding-bottom: 40px;
      }
    }

    &__btn {
      min-width: 225px;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
      @include transition(background-color, color, box-shadow);
      @include adaptive(767) {
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        box-shadow: none;
        @include transition(background-color, color, box-shadow);
      }

      &-wrapper {
        display: flex;
      }
    }
  }
  // question :: END

  // appForm
  &-appForm {
    padding-top: 200px;
    padding-bottom: 200px;
    @include adaptive(1023) {
      padding-top: 150px;
    }
    @include adaptive(767) {
      padding-top: 125px;
    }

    &__title {
      padding-bottom: 30px;
      font-size: 60px;
      font-weight: 900;
      line-height: 1.17;
      @include adaptive(1279) {
        font-size: 50px;
      }
      @include adaptive(767) {
        font-size: 36px;
        text-align: center;
      }
    }

    &__subtitle,
    &__heading {
      font-size: 40px;
      line-height: 1.25;
      @include adaptive(1279) {
        font-size: 33px;
      }
      @include adaptive(767) {
        font-size: 26px;
      }
    }

    &__subtitle {
      padding-bottom: 110px;
      font-weight: 300;
      @include adaptive(1279) {
        padding-bottom: 80px;
      }
      @include adaptive(767) {
        text-align: center;
      }
    }

    &__heading {
      padding-bottom: 40px;
      font-weight: 900;

      &--pt {
        padding-top: 70px;
        @include adaptive(767) {
          padding-top: 40px;
        }
      }
    }
    
    &__btn {
      width: auto;
      max-width: unset;
      padding-left: 50px;
      padding-right: 50px;
      box-shadow: 0 10px 15px 0 rgba(205, 8, 75, 0.2);
      @include transition(background-color, color, box-shadow);
      @include adaptive(767) {
        padding-left: 30px;
        padding-right: 30px;
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        box-shadow: none;
        @include transition(background-color, color, box-shadow);
      }

      &-wrapper {
        display: flex;
        padding-top: 30px;
      }
    }

    &__tab {
      z-index: 2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 2em;
      background-color: transparent;
      font-size: 19px;
      line-height: 1.1;
      text-align: center;
      color: #000000;
      @include transition(background-color, color);
      @include adaptive(767) {
        font-size: 17px;
        padding-left: 15px;
        padding-right: 15px;
      }
      @include adaptive(424) {
        font-size: 14px;
      }

      &.is-active {
        color: #ffffff;
        @include transition(background-color, color);
      }

      &-bg {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 2em;
        background-color: #00449d;
        @include transition(transform, width);
      }

      &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
      }
    }

    &__container {
      display: none;
      padding-top: 100px;
      @include adaptive(1279) {
        padding-top: 40px;
      }
      @include adaptive(767) {
        padding-top: 20px;
      }
    }
  }
  // appForm :: END

  // appSetup
  &-appSetup {
    .affiliate-appForm__heading--pt {
      padding-top: 100px;
    }

    .affiliate-inside__link {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__wrapper {
      &-header {
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        border-radius: 0 10px 10px 0;
        background-color: #fff;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      }

      &-body {
        padding-top: 200px;
        padding-bottom: 200px;
      }
    }

    &__progress {
      width: 100%;
      max-width: 1070px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;

      &-title {
        position: relative;
        padding-bottom: 12px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.88;
        color: #000000;

        &:before {
          content: '';
          z-index: -1;
          position: absolute;
          top: 0;
          left: -10px;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
        }

        &-wrapper {
          z-index: 10;
          position: relative;
          display: flex;
          align-items: center;
        }
      }

      &-line {
        position: relative;
        height: 10px;
        border-radius: 8px;
        background-color: #e3ebf4;

        i {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          border-radius: 8px;
          background-color: #00449d;
          font-style: normal;
          @include transition(width);
        }

        span {
          position: absolute;
          right: 0;
          bottom: calc(100% + 12px);
          transform: translateX(50%);
          font-size: 16px;
          font-weight: 600;
          line-height: 1.88;
          color: #00449d;
        }
      }
    }

    &__title {
      padding-bottom: 30px;
      font-size: 60px;
      font-weight: 900;
      line-height: 1.17;
      @include adaptive(1279) {
        font-size: 50px;
      }
      @include adaptive(767) {
        font-size: 36px;
        text-align: center;
      }
    }

    &__subtitle {
      font-size: 40px;
      line-height: 1.25;
      @include adaptive(1279) {
        font-size: 33px;
      }
      @include adaptive(767) {
        font-size: 26px;
      }
    }

    &__subtitle {
      padding-bottom: 110px;
      font-weight: 300;
      @include adaptive(1279) {
        padding-bottom: 80px;
      }
      @include adaptive(767) {
        text-align: center;
      }
    }

    &__step {
      display: none;

      &-1 {
        display: block;

        .affiliate-appForm__heading {
          padding-top: 0;
        }
      }
    }
  }
  // appSetup :: END

  // inside
  &-inside {
    padding-top: 200px;
    padding-bottom: 200px;
    @include adaptive(1279) {
      padding-top: 150px;
      padding-bottom: 150px;
    }
    @include adaptive(767) {
      padding-top: 125px;
      padding-bottom: 125px;
    }

    &__wrapper {
      &-1 {
        padding-bottom: 110px;
        @include adaptive(1279) {
          padding-bottom: 100px;
        }
      }
      &-2 {
        padding-bottom: 110px;
        @include adaptive(1279) {
          padding-bottom: 100px;
        }
      }
      &-3 {
        padding-top: 100px;
        padding-bottom: 100px;
        background-image: linear-gradient(254deg, #001632 0%, #00449d);
        @include adaptive(767) {
          padding-top: 75px;
          padding-bottom: 75px;
        }
      }
      &-4 {
        padding-top: 110px;
        padding-bottom: 110px;
        @include adaptive(1279) {
          padding-top: 100px;
          padding-bottom: 100px;
        }
      }
    }

    &__title {
      padding-bottom: 30px;
      font-size: 60px;
      font-weight: 900;
      line-height: 1.17;
      color: #000000;
      @include adaptive(1279) {
        font-size: 50px;
      }
      @include adaptive(767) {
        font-size: 45px;
      }
    }

    &__subtitle {
      font-size: 40px;
      font-weight: 300;
      line-height: 1.25;
      color: #000000;
      @include adaptive(1279) {
        font-size: 36px;
      }
      @include adaptive(767) {
        font-size: 30px;
      }

      span {
        position: relative;
        font-weight: bold;

        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 50%;
          background-color: #ffd400;
        }
      }
    }

    &__heading {
      padding-bottom: 40px;
      color: #ffffff;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.25;
      @include adaptive(1279) {
        font-size: 36px;
      }
      @include adaptive(767) {
        font-size: 30px;
      }

      &--black {
        color: #000000;
      }
    }

    &__list {
      display: flex;
      align-items: center;
      padding-bottom: 50px;
      @include adaptive(767) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        padding-right: 100px;
        @include adaptive(767) {
          padding-right: 0;
          padding-bottom: 15px;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      p {
        padding-bottom: 10px;
        font-size: 40px;
        font-weight: bold;
        line-height: 1.25;
        color: #000000;
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(767) {
          padding-bottom: 0;
        }
      }

      span {
        font-size: 19px;
        line-height: 1.58;
        color: rgba(#000000, 0.5);
        @include adaptive(1279) {
          font-size: 17px;
        }
      }
    }

    &__btn {
      height: 60px;
      margin-right: 40px;
      letter-spacing: 1.35px;
      @include adaptive(767) {
        margin-bottom: 15px;
      }

      &:last-of-type {
        margin-right: 0;
        @include adaptive(767) {
          margin-bottom: 0;
        }
      }

      &-wrapper {
        display: flex;
        @include adaptive(767) {
          flex-direction: column;
        }

        &-2 {
          .affiliate-inside__btn {
            width: auto;
            max-width: unset;
            margin-right: 0;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }

    &__table {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      @include adaptive(767) {
        width: 768px;
      }

      &-scroll {
        position: relative;
        @include adaptive(767) {
          overflow-x: auto;
          width: calc(100% + 15px);
        }
      }

      &-head {
        border-radius: 10px;
        background-color: #f8fafc;
      }

      &-body {
        > div {
          border-bottom: 1px solid rgba(#d8d8d8, 0.8);

          &:last-of-type {
            border: none;
          }
        }
      }

      &-tr {
        display: flex;
        align-items: center;
      }

      &-th,
      &-td {
        flex: 1;
        min-width: 1px;
        max-width: 100%;
      }

      &-th {
        padding: 15px 30px;
        font-size: 19px;
        font-weight: bold;
        color: #000000;
        @include adaptive(1023) {
          padding: 15px;
          font-size: 17px;
        }
      }

      &-td {
        padding: 30px;
        font-size: 19px;
        line-height: 1.58;
        color: #000000;
        @include adaptive(1023) {
          padding: 15px;
          font-size: 17px;
        }
      }
    }

    &__input {
      &-title {
        padding-bottom: 20px;
        font-size: 19px;
        line-height: 1.58;
        color: #ffffff;
        @include adaptive(767) {
          font-size: 17px;
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
        @include adaptive(767) {
          flex-direction: column;
          align-items: flex-start;
        }

        input {
          outline: none;
          flex: 1;
          min-width: 1px;
          max-width: 100%;
          height: 60px;
          padding: 0 20px;
          border-radius: 10px;
          box-shadow: none;
          border: solid 2px rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
          font-size: 19px;
          color: #000000;
          @include adaptive(767) {
            flex: unset;
            width: 100%;
            font-size: 17px;
          }
        }

        a {
          display: block;
          margin-left: 20px;
          padding-right: 5px;
          font-size: 19px;
          font-weight: bold;
          line-height: 1.58;
          text-align: right;
          color: #ffd400;
          @include adaptive(767) {
            margin-left: 0;
            padding-top: 15px;
            font-size: 17px;
          }
        }
      }
    }

    &__link {
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        span {
          &:before {
            width: calc(100% - 20px);
            @include transition(width);
          }
        }
        
        svg {
          transform: scale(-1) translateX(-5px);
          @include transition(transform);
        }
      }

      span {
        overflow: hidden;
        position: relative;
        padding-right: 20px;
        font-size: 19px;
        font-weight: bold;
        color: #00449d;
        
        &:before {
          content: '';
          z-index: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          background-color: #00449d;
          @include transition(width);
        }
      }

      svg {
        fill: #00449d;
        width: 20px;
        height: 20px;
        transform: scale(-1);
        @include transition(transform);
      }
    }
  }
  // inside :: END

  // submitted
  &-submitted {
    &__wrapper {
      padding-top: 200px;
      padding-bottom: 200px;
      @include adaptive(1279) {
        padding-top: 150px;
        padding-bottom: 150px;
      }
      @include adaptive(767) {
        padding-top: 125px;
        padding-bottom: 100px;
      }
    }

    &__img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;

      &-wrapper {
        padding-bottom: 80px;
      }
    }

    &__title,
    &__desc {
      color: #000000;
      text-align: center;
    }

    &__title {
      padding-bottom: 15px;
      font-weight: 900;
      font-size: 60px;
      line-height: 1.4;
      @include adaptive(1279) {
        font-size: 50px;
      }
      @include adaptive(1023) {
        font-size: 45px;
      }
      @include adaptive(767) {
        font-size: 40px;
        line-height: normal;
      }
    }

    &__desc {
      width: 100%;
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
      font-size: 19px;
      @include adaptive(1279) {
        font-size: 17px;
      }
      @include adaptive(767) {
        font-size: 15px;
      }
    }
  }
  // submitted :: END

  &__form {
    &-hidden {
      display: none;
    }

    &-group {
      display: flex;
      justify-content: space-between;
      @include adaptive(767) {
        flex-wrap: wrap;
      }
    }

    &-col {
      width: 100%;
      max-width: calc((100% - 40px) / 2);
      @include adaptive(767) {
        max-width: 100%;
      }
    }
  }

  &__input {
    &-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      &--empty {
        @include adaptive(767) {
          display: none;
        }
        label {
          opacity: 0;
        }

        .affiliate__input {
          display: flex;
          align-items: center;
          height: 60px;
        }

        p {
          font-size: 19px;
          line-height: 1.58;
          color: #000000;
        }
      }
    }

    &-label {
      display: block;
      padding-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.88;
      color: rgba(#000000, 0.5);
      @include adaptive(767) {
        font-size: 14px;
      }
    }

    input {
      outline: none;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      border-radius: 10px;
      box-shadow: none;
      border: solid 2px rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      font-size: 19px;
      color: #000000;
      @include adaptive(767) {
        height: 50px;
        font-size: 16px;
      }

      &[disabled] {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &__radio {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .affiliate__radio-label {
        .affiliate__radio-circle {
          border-color: #00449d;
          background-color: #00449d;
          @include transition(border-color, background-color);

          &:before {
            transform: scale(1);
            @include transition(transform);
          }
        }
      }
    }

    &-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 30px;

      &--wrap {
        @include adaptive(767) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &-col {
      padding-right: 60px;
      @include adaptive(1023) {
        padding-right: 30px;
      }
      @include adaptive(767) {
        padding-right: 30px;
        padding-bottom: 10px;
      }
    }

    &-label {
      cursor: pointer;
      display: flex;
      align-items: center;

      p {
        &:nth-of-type(1) {
          width: 50px;
        }
        &:nth-of-type(2) {
          flex: 1;
          min-width: 1px;
          max-width: 100%;
        }
      }
    }

    &-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: solid 2px rgba(0, 0, 0, 0.05);
      background-color: transparent;
      @include transition(border-color, background-color);

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #ffffff;
        transform: scale(0);
        @include transition(transform);
      }
    }

    &-text {
      font-size: 20px;
      line-height: 1.5;
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }
}

// SECTION affiliate :: END
