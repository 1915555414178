// SECTION ASSISTANCE :: START

.assistance {
  overflow: hidden;
  position: relative;

  &__bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw + 300px);
    margin-left: -150px;
    height: calc(100% - 0px);
    border-top-left-radius: 50% 0;
    border-top-right-radius: 50% 0;
    border-bottom-right-radius: 50% 100px;
    border-bottom-left-radius: 50% 100px;

    &-inner {
      width: 100%;
      height: 100%;
      @include background-gradient(#F8FAFC, #F8FAFC, 'horizontal');
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    min-height: 519px;
    padding-top: 177px;
  }

  &__title {
    padding-bottom: 40px;
    color: $c-black;
    text-align: center;
    line-height: 1.2;
    font-weight: 200;
    @include fluid(font-size, 320px, 1440px, 30px, 50px);
  }

  &__subtitle {
    padding-bottom: 10px;
    color: rgba(#00449D, 0.4);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.25px;
    font-weight: bold;
    @include fluid(font-size, 320px, 1440px, 14px, 18px);
  }

  &__info {
    z-index: -1;
    position: relative;
    text-align: center;
    @include fluid(padding-bottom, 320px, 1440px, 50px, 112px);
    @include fluid(font-size, 320px, 1440px, 16px, 19px);

    a {
      color: #00449D;
    }
  }
}

// SECTION ASSISTANCE :: END
