// LOGOTYPE :: START

.logo {
  &--footer {
    padding-bottom: 25px;
  }

  &__link {
    display: flex;
    @include respond(max-extra-small) {
      justify-content: center;
    }

    &:hover {
      svg {
        fill: #cd084b;
        @include transition(fill);

        .header--blue & {
          fill: #cd084b;
        }

        .header--fixed & {
          fill: #cd084b;
        }
      }
    }

    svg {
      fill: $c-white;
      @include transition(fill);

      .header--blue & {
        fill: #00449D;
      }

      .header--iteration & {
        fill: #00449D;
      }

      .header--fixed & {
        fill: $c-white;
      }
    }
  }
}

// LOGOTYPE :: END