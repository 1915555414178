// SECTION plans :: START

.plans {
  @include fluid(padding-top, 320px, 1439px, 25px, 50px);
  @include fluid(padding-bottom, 320px, 1439px, 75px, 71px);

  &__wrapper {
    display: none;
  }

  &__title {
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    @include fluid(font-size, 320px, 1600px, 30px, 40px);
    @include fluid(padding-bottom, 320px, 1439px, 40px, 80px);
  }

  &__block {
    display: flex;
    align-items: flex-start;
    @include respond(max-extra-small) {
      flex-wrap: wrap;
    }

    &-3,
    &-5 {
      li {
        &:last-of-type {
          p {
            color: $c-black;
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      > div {
        min-height: 150px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid #D8D8D8;

        &:last-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }
      }
    }

    &-left {
      flex: 1;
      min-width: 1px;
      max-width: 100%;
      //width: 100%;
      //max-width: calc(100% - 252px);
      padding-right: 30px;
      @include respond(max-small) {
        //max-width: calc(100% - 185px);
        padding-right: 15px;
      }
      @include respond(max-extra-small) {
        flex: unset;
        max-width: 100%;
      }
    }

    &-right {
      min-width: 252px;
      padding-right: 67px;
      @include respond(max-small) {
        min-width: 185px;
        padding-right: 0;
      }
      @include respond(max-extra-small) {
        min-width: unset;
        width: 100%;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      padding-bottom: 21px;
      font-weight: 500;
      @include fluid(font-size, 320px, 767px, 22px, 26px);
      @include respond(max-extra-small) {
        flex-direction: column-reverse;
        text-align: center;
      }

      span {
        display: flex;
        align-items: center;
        height: 24px;
        margin-left: 19px;
        padding: 5px 15px;
        border-radius: 12px;
        background-color: #00449D;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        @include fluid(font-size, 320px, 767px, 12px, 14px);
        @include respond(max-extra-small) {
          margin-left: 0;
          margin-bottom: 5px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        padding-bottom: 15px;

        &.is-hide {
          display: none;
        }

        > div {
          &:nth-of-type(1) {
            width: 30px;
          }

          &:nth-of-type(2) {
            width: 100%;
            max-width: calc(100% - 30px);
          }
        }

        svg {
          height: 16px;
          fill: #00449D;
        }

        p {
          color: #939393;
          @include fluid(font-size, 320px, 767px, 16px, 19px);
        }
      }
    }

    &-price {
      padding-bottom: 10px;
      font-weight: bold;
      @include fluid(font-size, 320px, 767px, 35px, 40px);
      @include respond(max-extra-small) {
        text-align: center;
      }
    }

    &-per {
      padding-bottom: 20px;
      @include fluid(font-size, 320px, 767px, 16px, 19px);
      @include respond(max-extra-small) {
        text-align: center;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 185px;
      height: 80px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      border: 2px solid #CD084B;
      background-color: transparent;
      color: #CD084B;
      letter-spacing: 1.35px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      @include transition(background-color, color);

      &:hover {
        background-color: #CD084B;
        color: $c-white;
        @include transition(background-color, color);
      }

      &-wrapper {
        display: flex;
      }
    }
  }

  .types__checkbox {
    opacity: 1;

    .c-form {
      &__field {
        margin-bottom: 0;
      }
    }
  }

  &__row {
    display: none;
    padding-top: 50px;

    &-1 {
      display: block;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
    @include adaptive(767) {
      padding-bottom: 50px;
    }

    &-1 {
      svg {
        width: 40px;
        height: 40px;
      }
    }
    &-2 {
      svg {
        width: 42px;
        height: 42px;
      }
    }
    &-3 {
      svg {
        width: 34px;
        height: 42px;
      }
    }

    svg {
      fill: #CD084B;
    }

    &-title {
      padding-top: 20px;
      padding-bottom: 20px;
      font-weight: 300;
      font-size: 40px;
      text-align: center;
      color: #000;
      @include adaptive(767) {
        font-size: 36px;
      }
    }

    &-subtitle {
      font-size: 19px;
      text-align: center;
      color: #575757;
      @include adaptive(767) {
        font-size: 16px;
      }
    }
  }

  &__box {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 10px);
    padding: 40px;
    margin-top: 10px;
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid #f8fafc;
    @include adaptive(1023) {
      padding-left: 20px;
      padding-right: 15px;
    }
    @include adaptive(767) {
      padding-bottom: 20px;
    }

    &--popular {
      height: calc(100% + 10px);
      margin-top: 0;
      padding-top: 50px;
      background-color: $c-white;
      box-shadow: 0px 15px 35px rgba(0, 68, 157, 0.07);

      img {
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &-title {
      z-index: 3;
      position: relative;
      padding-bottom: 50px;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
      color: #000;
      @include adaptive(1023) {
        font-size: 22px;
      }
    }

    &-body {
      z-index: 3;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &-price {
      padding-bottom: 50px;

      p {
        font-weight: 500;
        font-size: 45px;
        text-align: center;
        color: #00449d;
        line-height: 1;
        @include adaptive(1023) {
          font-size: 40px;
        }

        sup {
          position: relative;
          left: 10px;
          top: -22px;
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 0.13em;
          color: rgba(#00449d, 0.4);
          @include adaptive(1023) {
            top: -20px;
          }
        }

        i {
          font-style: normal;
        }
      }

      span {
        position: relative;
        left: 20px;
        display: block;
        font-size: 18px;
        text-align: center;
        color: #939393;
        line-height: 1;
        @include adaptive(1023) {
          left: 0;
          font-size: 16px;
        }
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 185px;
      height: 60px;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      background-color: transparent;
      border: 2px solid #cd084b;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.08em;
      text-align: center;
      color: #cd084b;
      will-change: background-color, color;
      @include transition(background-color, color);
      @include adaptive(1023) {
        height: 55px;
      }

      &:hover {
        background-color: #cd084b;
        color: #ffffff;
        @include transition(background-color, color);
      }

      &-wrapper {
        display: flex;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      &--pt0 {
        padding-top: 0;
        @include adaptive(767) {
          padding-top: 50px;
        }
      }

      li {
        position: relative;
        display: flex;
        padding-bottom: 20px;
        @include adaptive(1023) {
          padding-bottom: 15px;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        i {
          position: absolute;
          top: 8px;
          left: -18px;
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #00449d;
          @include adaptive(1023) {
            left: -10px;
          }
        }

        p {
          font-size: 16px;
          color: #939393;

          &.hide {
            span {
              &:nth-of-type(1) {}
              &:nth-of-type(2) {
                opacity: 0;
              }
            }
          }

          span {
            font-weight: 500;
            color: #000000;
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 50px;

      &-1 {
        > div {
          max-width: calc((100% - 80px) / 3);
          @include adaptive(1023) {
            max-width: calc((100% - 30px) / 3);
          }
        }
      }
      &-2 {
        max-width: 858px;

        > div {
          max-width: calc((100% - 40px) / 2);
          @include adaptive(1023) {
            max-width: calc((100% - 15px) / 2);
          }
        }
      }
      &-3 {
        max-width: 854px;

        > div {
          max-width: 100%;
        }

        .plans__box {
          svg {
            z-index: 0;
            position: absolute;
            right: -20px;
            bottom: 60px;
            width: 320px;
            height: 395px;
            fill: #F8FAFC;
          }

          &-title {
            text-align: left;
          }

          &-body {
            flex-direction: row-reverse;
            justify-content: space-between;
            @include adaptive(767) {
              flex-direction: column;
            }

            &-right {
              padding-left: 20px;
              @include adaptive(767) {
                padding-left: 0;
              }
            }
          }
        }
      }

      > div {
        width: 100%;
        @include adaptive(767) {
          max-width: 100%;
          padding-bottom: 30px;
        }
      }
    }
  }
}

// SECTION plans :: END
