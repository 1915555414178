// SECTION scheduler :: START

&__time {
  display: none;
  padding-top: 120px;
  padding-bottom: 140px;
  background-color: #f8fafc;
  @include adaptive(767) {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-1 {
      padding-bottom: 60px;
      @include adaptive(767) {
        padding-bottom: 20px;

        .scheduler__time-col {
          max-width: 100%;
        }
      }
    }
  }

  &-col {
    width: 100%;
    max-width: calc((100% - 40px) / 2);
    @include adaptive(767) {
      max-width: calc((100% - 20px) / 2);
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    @include transition(box-shadow, background-color);
    @include adaptive(767) {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.is-active,
    &:hover {
      box-shadow: none;
      background-color: #00449d;
      @include transition(box-shadow, background-color);

      span {
        color: #ffffff;
        @include transition(color);
      }
    }

    span {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #000;
      @include transition(color);
      @include adaptive(767) {
        font-size: 16px;
        line-height: normal;
      }
    }
  }

  .c-form {
    &__field {
      &--select {
        display: none;
        @include adaptive(767) {
          display: block;
        }

        &.c-form__field--dropdown {
          display: block;
          @include adaptive(767) {
            display: none;
          }
        }
      }
    }

    &__label {
      color: rgba(#000, 0.5);
    }

    &__select {
      border: 2px solid rgba(0, 0, 0, 0.05);
    }
  }

  &-dropdown {
    position: relative;

    &-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 60px;
      border-radius: 10px;
      background-color: #fff;
      border: 2px solid rgba(0, 0, 0, 0.05);

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        line-height: 60px;
        width: calc(100% - 52px);
        padding-left: 20px;
        color: #000;
        font-size: 18px;
      }

      > div {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 52px;
        height: 100%;

        svg {
          width: 12px;
          fill: #000;
        }
      }
    }

    &-search {
      outline: none;
      display: flex;
      width: 100%;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 10px;
      background-color: #fff;
      border: 2px solid rgba(0, 0, 0, 0.05);
    }

    &-content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding-top: 2px;
      padding-bottom: 3px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      transform: translateY(20px);
      @include transition(transform, opacity, visibility);

      &.is-open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        @include transition(transform, opacity, visibility);
      }

      > div {
        &:nth-of-type(1) {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          height: 75px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        }
        &:nth-of-type(2) {
          overflow-y: auto;
          max-height: 300px;
        }
      }

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        min-height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        color: #00449D;
        line-height: 40px;
        font-size: 18px;
        @include transition(background-color);

        &:hover {
          background-color: lighten(#00449D, 67%);
          @include transition(background-color);
        }
      }
    }
  }
}

// SECTION scheduler :: END