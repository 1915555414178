// SECTION iteration :: START

.iteration {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  @include adaptive(767) {
    overflow-y: auto;
    height: auto;
    max-height: unset;
  }

  &__slide {
    z-index: 5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding: 75px 15px;
    @include adaptive(1023) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @include adaptive(767) {
      height: auto;
      max-height: unset;
      padding-left: 15px;
      padding-right: 15px;
    }
    @include adaptive(424) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      max-height: 100%;

      img {
        max-height: 100%;
      }
    }

    &--image {
      img {
        z-index: 2;
        position: relative;
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--text {
      p {
        z-index: 2;
        position: relative;
        width: 100%;
        max-width: 898px;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        font-size: 52px;
        color: #9b9b9b;
        @include adaptive(1439) {
          font-size: 44px;
        }
        @include adaptive(1365) {
          font-size: 40px;
        }
        @include adaptive(1279) {
          font-size: 36px;
        }
        @include adaptive(1023) {
          max-width: 600px !important;
          font-size: 33px;
        }
        @include adaptive(767) {
          max-width: 100% !important;
          font-size: 26px;
        }

        span {
          color: #000000;
        }
      }
    }

    &--1 {
      @include adaptive(767) {
        padding-top: 150px;
      }
    }

    &--2 {
      padding-left: 120px;
      padding-right: 354px;
      @include adaptive(1439) {
        padding-left: 70px;
        padding-right: 304px;
      }
      @include adaptive(1279) {
        padding-right: 244px;
      }
      @include adaptive(1023) {
        padding-right: 15px;
        padding-left: 15px;
      }
      @include adaptive(767) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--4 {
      padding-left: 368px;
      padding-right: 250px;
      @include adaptive(1439) {
        padding-left: 318px;
        padding-right: 200px;
      }
      @include adaptive(1279) {
        padding-left: 258px;
        padding-right: 140px;
      }
      @include adaptive(1023) {
        padding-left: 100px;
        padding-right: 90px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--5,
    &--27,
    &--33 {
      .iteration {
        &__bg {
          &-path {
            top: 50%;
            right: -36%;
            transform: translateY(-50%);
            width: 1298px;
            height: 1298px;
            border-radius: 50%;
            background-color: #fafafa;
            @include adaptive(1279) {
              width: 1024px;
              height: 1024px;
            }
            @include adaptive(1023) {
              left: 56%;
              right: auto;
            }
            @include adaptive(767) {
              width: 600px;
              height: 600px;
            }
          }
        }
      }
    }

    &--5 {
      padding-right: 320px;
      @include adaptive(1439) {
        padding-right: 170px;
      }
      @include adaptive(1279) {
        padding-right: 110px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--6 {
      padding-right: 303px;
      @include adaptive(1439) {
        padding-right: 253px;
      }
      @include adaptive(1279) {
        padding-right: 193px;
      }
      @include adaptive(1023) {
        padding-right: 143px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }

      p {
        max-width: 1040px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--7,
    &--8,
    &--10,
    &--11,
    &--13,
    &--14,
    &--15,
    &--17,
    &--19,
    &--20,
    &--23,
    &--24,
    &--25,
    &--26,
    &--27,
    &--28,
    &--29,
    &--30,
    &--31 {
      padding-right: 282px;
      @include adaptive(1439) {
        padding-right: 232px;
      }
      @include adaptive(1279) {
        padding-right: 173px;
      }
      @include adaptive(1023) {
        padding-right: 123px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--8 {
      p {
        max-width: 760px;
      }
    }

    &--9,
    &--16 {
      .iteration {
        &__bg {
          &-path {
            top: 0;
            right: -68%;
            width: 1286px;
            height: 100%;
            transform: skewX(-30deg);
            background-color: #f8fafc;
            @include adaptive(1279) {
              width: 1086px;
            }
            @include adaptive(1023) {
              left: 55%;
              right: auto;
            }
          }
        }
      }
    }

    &--9 {
      padding-right: 170px;
      @include adaptive(1439) {
        padding-right: 120px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--10 {
      .iteration {
        &__bg {
          img {
            position: absolute;

            &:nth-of-type(1) {
              right: 220px;
              bottom: 250px;
            }

            &:nth-of-type(2) {
              right: -350px;
              bottom: -90px;
              @include adaptive(1365) {
                transform: scale(0.5);
              }
            }
          }
        }
      }
    }

    &--11 {
      p {
        max-width: 1040px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--13,
    &--15,
    &--18,
    &--22,
    &--24,
    &--26,
    &--32 {
      p {
        max-width: 1315px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--16 {
      padding-right: 329px;
      @include adaptive(1439) {
        padding-right: 279px;
      }
      @include adaptive(1279) {
        padding-right: 219px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--18 {
      padding-right: 231px;
      @include adaptive(1439) {
        padding-right: 181px;
      }
      @include adaptive(1279) {
        padding-right: 121px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--20 {
      p {
        max-width: 920px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--21 {
      padding-right: 418px;
      @include adaptive(1439) {
        padding-right: 368px;
      }
      @include adaptive(1279) {
        padding-right: 308px;
      }
      @include adaptive(1023) {
        padding-right: 228px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--22 {
      padding-right: 241px;
      @include adaptive(1439) {
        padding-right: 191px;
      }
      @include adaptive(1279) {
        padding-right: 131px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--27 {
      padding-left: 135px;
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .iteration {
        &__bg {
          &-path {
            right: -20%;
            @include adaptive(767) {
              left: 50%;
              right: auto;
            }
          }
        }
      }
    }

    &--28 {
      p {
        max-width: 1180px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--29 {
      .iteration {
        &__title {
          padding-left: 227px;
          @include adaptive(1279) {
            padding-left: 167px;
          }
          @include adaptive(1023) {
            padding-left: 80px;
          }
          @include adaptive(767) {
            padding-left: 0;
          }
        }
      }
    }

    &--30 {
      p {
        max-width: 1420px;
        @include adaptive(1279) {
          max-width: 898px;
        }
      }
    }

    &--32 {
      padding-right: 158px;
      @include adaptive(1439) {
        padding-right: 108px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &--33 {
      padding-left: 110px;
      padding-right: 59px;
      @include adaptive(1439) {
        padding-left: 60px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .iteration {
        &__bg {
          &-path {
            left: 0;
            right: auto;
            @include adaptive(767) {
              left: 60%;
            }
          }
        }
      }
    }

    &--34 {
      padding-left: 210px;
      padding-right: 170px;
      @include adaptive(1439) {
        padding-left: 160px;
        padding-right: 120px;
      }
      @include adaptive(1023) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include adaptive(767) {
        padding-bottom: 150px;
      }

      .iteration {
        &__bg {
          img {
            position: absolute;

            &:nth-of-type(1) {
              top: 100px;
              left: 100px;
              @include adaptive(767) {
                top: 50px;
                left: 50px;
                transform: scale(0.75);
              }
            }

            &:nth-of-type(2) {
              right: -100px;
              bottom: -100px;
              @include adaptive(767) {
                transform: scale(0.5);
                right: -160px;
                bottom: -160px;
              }
            }
          }
        }
      }
    }
  }

  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;

    &-path {
      position: absolute;
    }
  }

  &__title {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 350px;
    line-height: 341px;
    color: #fafafa;
    @include adaptive(1534) {
      font-size: 300px;
      line-height: 300px;
    }
    @include adaptive(1439) {
      font-size: 250px;
      line-height: 250px;
    }
    @include adaptive(1335) {
      font-size: 200px;
      line-height: 190px;
    }
    @include adaptive(1279) {
      font-size: 150px;
      line-height: 140px;
    }
    @include adaptive(1023) {
      font-size: 100px;
      line-height: 110px;
    }
  }

  &__advantages {
    width: 100%;
    max-width: calc(100% / 3);
    @include adaptive(767) {
      max-width: 100%;
      padding-bottom: 30px;
    }

    &-1,
    &-3 {
      .iteration {
        &__advantages {
          &-img {
            img {
              position: relative;
              left: -25px;
            }
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      width: 100%;
      max-width: 1253px;
      height: auto !important;
      margin-right: auto;
      @include adaptive(767) {
        flex-wrap: wrap;
      }
    }

    &-img {
      min-height: 220px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        @include adaptive(767) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &-text {
      width: 100%;
      max-width: 310px;
      padding-left: 35px;
      padding-top: 35px;
      @include adaptive(767) {
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  &__search {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;

    &-title {
      width: 100%;
      max-width: 525px;
      padding-bottom: 90px;
      font-weight: bold;
      font-size: 26px;
      color: #000;
      @include adaptive(767) {
        font-size: 22px;
      }
    }

    &-img {
      padding-bottom: 50px;
      max-height: 100%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
      }
    }

    &-desc {
      padding-left: 80px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        font-size: 18px;
      }
    }
  }

  &__ai {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;

    &-title {
      width: 100%;
      max-width: 525px;
      padding-bottom: 90px;
      font-weight: bold;
      font-size: 26px;
      color: #000;
      @include adaptive(767) {
        padding-bottom: 50px;
        font-size: 22px;
      }
    }

    &-badge {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 630px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 60px;
      @include adaptive(767) {
        padding-bottom: 50px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 151px;
        height: 40px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 20px;
        border-radius: 8px;
        background-color: #00449d;
        @include adaptive(767) {
          max-width: 141px;
          margin-left: 5px;
          margin-right: 5px;
        }

        p {
          position: relative;
          top: -1px;
          font-family: 'Open Sans', sans-serif;
          padding-right: 20px;
          line-height: 1;
          font-size: 18px;
          color: #fff;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &-desc {
      padding-left: 80px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        font-size: 18px;
      }
    }
  }

  &__column {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;

    &-img {
      padding-bottom: 50px;
      max-height: 100%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-text {
      padding-left: 100px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        font-size: 18px;
      }
    }
  }

  &__pipelines {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;

    &-title {
      padding-left: 112px;
      padding-bottom: 40px;
      font-weight: bold;
      font-size: 26px;
      color: #000;
      @include adaptive(767) {
        padding-left: 0;
        font-size: 22px;
      }
    }

    &-img {
      padding-bottom: 20px;
      max-height: 100%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-desc {
      padding-left: 160px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        padding-left: 80px;
        font-size: 18px;
      }
    }
  }

  &__contacts {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 920px;
    margin-right: auto;

    &-title {
      min-height: 67px;
      padding-left: 115px;
      padding-bottom: 25px;
      font-weight: bold;
      font-size: 30px;
      color: #000;
      @include adaptive(767) {
        padding-left: 0;
        font-size: 22px;
      }
    }

    &-img {
      max-height: calc(100% - 67px);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__schedule {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;

    &-img {
      max-height: calc(100% - 84px);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-desc {
      min-height: 84px;
      padding-top: 50px;
      padding-left: 120px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        padding-left: 80px;
        font-size: 18px;
      }
    }
  }

  &__data {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;

    &-title {
      width: 100%;
      max-width: 450px;
      min-height: 136px;
      padding-bottom: 80px;
      font-weight: normal;
      font-size: 20px;
      @include adaptive(767) {
        font-size: 22px;
      }
    }

    &-img {
      padding-left: 50px;
      max-height: calc(100% - 136px);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__almanac {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;

    &-img {
      max-height: calc(100% - 56px);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-desc {
      min-height: 56px;
      padding-left: 120px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        padding-top: 30px;
        padding-left: 80px;
        font-size: 18px;
      }
    }
  }

  &__flow {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;

    &-img {
      max-height: 100%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-desc {
      padding-left: 120px;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(767) {
        padding-top: 30px;
        padding-left: 80px;
        font-size: 18px;
      }
    }
  }

  &__setting {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;

    &-img {
      max-height: calc(100% - 84px);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-desc {
      width: 100%;
      max-width: 640px;
      min-height: 84px;
      margin-left: auto;
      margin-right: auto;
      font-weight: normal;
      font-size: 20px;
      color: #848484;
      @include adaptive(1023) {
        top: 0;
        padding-top: 30px;
        padding-left: 80px;
      }
      @include adaptive(767) {
        font-size: 18px;
      }
    }
  }

  &__now {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

    &-title {
      padding-bottom: 15px;
      font-weight: bold;
      font-size: 68px;
      color: #000;
      @include adaptive(1439) {
        font-size: 58px;
      }
      @include adaptive(1365) {
        font-size: 50px;
      }
      @include adaptive(1279) {
        font-size: 45px;
      }
      @include adaptive(767) {
        font-size: 40px;
      }
    }

    &-desc {
      padding-bottom: 30px;
      font-weight: bold;
      font-size: 24px;
      color: #9b9b9b;
      @include adaptive(1365) {
        font-size: 22px;
      }
      @include adaptive(1279) {
        font-size: 20px;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 100%;
      min-width: 216px;
      height: 70px;
      border-radius: 10px;
      background-color: #00449d;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.08em;
      color: #fff;
      @include adaptive(1439) {
        height: 60px;
        font-size: 16px;
      }
      @include adaptive(1365) {
        height: 55px;
        letter-spacing: 0.04em;
        font-size: 16px;
      }
      @include adaptive(1365) {
        height: 50px;
        width: 100%;
        max-width: 200px;
        letter-spacing: 0.02em;
        font-size: 14px;
      }

      &-wrapper {
        display: flex;
      }
    }
  }

  .swiper {
    &-container {
      opacity: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      @include adaptive(767) {
        opacity: 1;
      }
    }

    &-wrapper {
      position: relative;
      @include adaptive(767) {
        display: block;
        height: 100% !important;
      }
    }

    &-slide {
      z-index: 0;
      width: auto;
      @include adaptive(1023) {
        width: 100% !important;
      }

      &.viewport-startTimeline-js {
        .iteration__slide {
          > img,
          > p,
          > div,
          &__title,
          .iteration__advantages {
            animation-fill-mode: both;
            animation-duration: 0.75s;
          }

          > img,
          > p,
          > div:last-of-type {
            animation-name: slideInRight;
            @include adaptive(767) {
              animation-name: slideInUp;
            }
          }

          &__title,
          .iteration__bg {
            animation-name: fadeIn, zoomIn;
          }

          .iteration__advantages {
            animation-name: slideInRight;
            @include adaptive(767) {
              animation-name: slideInUp;
            }

            &-2 {
              animation-delay: 0.2s;
            }
            &-3 {
              animation-delay: 0.4s;
            }
          }
        }
      }

      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(3) {
        width: 1383px;
        @include adaptive(1279) {
          width: 1024px;
        }
      }
      &:nth-of-type(12) {
        width: 1250px;
        @include adaptive(1439) {
          width: 1000px;
        }
      }
      &:nth-of-type(23) {
        width: 1050px;
      }
      &:nth-of-type(33) {
        width: 1400px;
        @include adaptive(1279) {
          width: 1100px;
        }
      }
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(200px, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}

// SECTION iteration :: END
