// APPS :: START

.apps {
  padding-bottom: 100px;
  border-bottom: 1px solid #D8D8D8;
  @include respond(max-small) {
    padding-bottom: 50px;
  }

  &__wrapper {
    padding-top: 100px;
    @include respond(max-small) {
      padding-top: 50px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    @include respond(max-small) {
      width: 50%;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 58px;
      @include respond(max-small) {
        flex-wrap: wrap;
        padding-top: 30px;
      }
    }

    &-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;
    }

    &-text {
      color: $c-black;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

// APPS :: END
