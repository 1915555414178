// HEADER :: START

.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &:before {
    opacity: 0;
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include background-gradient(#00449D  , #001632, 'horizontal');
    @include transition(opacity);
  }

  &--fixed {
    &:before {
      opacity: 1;
      @include transition(opacity);
    }
  }

  &--iteration {
    @include adaptive(767) {
      background-color: #ffffff;
    }

    &.header--fixed {
      .header__close {
        svg {
          fill: #fff;
        }
      }
    }

    .header {
      &__wrapper {
        padding-top: 0;

        &-left {
          .header__close {
            display: none;
            @include adaptive(767) {
              display: flex;
            }
          }
        }

        &-right {
          display: flex;
          justify-content: flex-end;
          @include adaptive(767) {
            display: none;
          }
        }
      }

      &__close {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;

        svg {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  &--affiliate {
    background-color: #ffffff;

    .header {
      &__wrapper {
        position: relative;
      }
    }

    &:before {
      opacity: 0 !important;
    }

    .affiliate-appSetup__progress {
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      @include transition(opacity);
    }

    &.header--fixed {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      @include transition(box-shadow);

      .affiliate-appSetup__progress {
        opacity: 1;
        @include transition(opacity);
      }
    }
  }

  .c-grid {
    max-width: 100%;
    @include respond(wide-small) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &__wrapper {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 75px;
    padding-top: 19px;
    @include transition(min-height, padding-top);
    @include respond(max-small) {
      padding-top: 0;
      min-height: 55px;
    }

    .header--fixed & {
      min-height: 65px;
      padding-top: 0;
      @include transition(min-height, padding-top);
      @include respond(max-small) {
        min-height: 55px;
      }
    }

    &-left {
      width: 211px;
      @include respond(max-medium) {
        width: 170px;
      }
      @include respond(max-small) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      &--full {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > .header__wrapper-inside {
          display: flex;
          align-items: center;

          .header__get {
            margin-left: 20px;
            @include adaptive(767) {
              margin-left: 15px;
            }
          }
        }
      }

      .hamburger {
        display: none;
        @include respond(max-small) {
          display: block;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(100% - 211px);
      @include respond(max-medium) {
        max-width: calc(100% - 170px);
      }
      @include respond(max-small) {
        display: none;
        z-index: 100;
        overflow-y: auto;
        position: fixed;
        top: 55px;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 55px);
        padding: 50px;
        background-color: rgba($c-white, 0.985);

        &.is-open {
          display: block;
          animation-fill-mode: both;
          animation-duration: 0.45s;
          animation-name: c-zoomIn;

          .nav__link,
          .header__link {
            color: #00449D;
          }
        }
      }

      > div {
        display: flex;
        align-items: center;
        @include respond(max-small) {
          flex-direction: column;
        }

        &:first-of-type {
          width: 100%;
          max-width: calc(100% - 211px);
          @include respond(max-medium) {
            max-width: calc(100% - 170px);
          }
          @include respond(max-small) {
            max-width: 100%;
          }
        }

        &:last-of-type {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 211px;
          @include respond(max-medium) {
            width: 170px;
          }
          @include respond(max-small) {
            width: 100%;
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $c-white;
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    @include respond(max-small) {
      justify-content: center;
      width: 100%;
      min-height: 50px;
      color: $c-black;
      border-bottom: 1px solid rgba($c-black, 0.025);
    }

    .header--blue & {
      color: #00449D;
    }

    .header--fixed & {
      color: $c-white;
    }

    &:hover {
      svg {
        fill: rgba($c-white, 1);
        @include transition(fill);

        .header--blue & {
          fill: rgba(#00449D, 1);
        }

        .header--fixed & {
          fill: rgba($c-white, 1);
        }
      }
    }

    &-1  {
      border-bottom: none;
    }

    svg {
      fill: rgba($c-white, 0.4);
      margin-right: 12px;
      @include transition(fill);
      @include respond(max-small) {
        display: none;
      }

      .header--blue & {
        fill: rgba(#00449D, 0.4);
      }

      .header--fixed & {
        fill: rgba($c-white, 0.4);
      }
    }
  }

  &__get {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 219px;
    height: 60px;
    border: none !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.35px;
    text-align: center;
    color: #cd084b;
    text-transform: uppercase;
    @include adaptive(767) {
      max-width: 150px;
      height: 40px;
      font-size: 14px;
      letter-spacing: 0.35px;
    }
    @include adaptive(324) {
      max-width: 120px;
    }

    &--blue {
      color: #00449d;

      &:hover {
        svg {
          fill: #cd084b;
        }
      }

      svg {
        fill: #00449d;
      }
    }

    span {
      padding-right: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
      transform: scale(-1);
      will-change: transform;
      @include transition(transform);
    }

    &:hover {
      background-color: transparent;
      color: #cd084b;

      svg {
        transform: scale(-1) translateX(-50%);
        @include transition(transform);
      }
    }

    .header--fixed & {
      height: 50px;
      border-color: #fff;
      color: $c-white;

      svg {
        fill: #fff;
      }
    }
  }

  &__setting {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;

    svg {
      width: 30px;
      height: 30px;
      fill: #00449d;

      .header--fixed & {
        fill: #fff;
      }
    }
  }
}

@keyframes c-zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

// HEADER :: END
